import React from 'react';
import PropTypes from 'prop-types';

import './ReportPage.style.scss';

const ReportPage = ({
  className = '',
  pageTitle,
  pageSubTitle,
  renderIntroLeft = () => '',
  renderIntroRight = () => '',
  renderIcon,
  renderPageContent,
}) => (
  <div className={`report-page ${className}`}>
    <div className="page-head">
      <h1>
        {pageTitle}
        {renderIcon && <span className="page-icon">{renderIcon()}</span>}
      </h1>
      <span className="sub-title">{pageSubTitle}</span>
    </div>
    <div className="page-intro">
      <div className="intro-left">{renderIntroLeft()}</div>
      <div className="intro-right">{renderIntroRight()}</div>
    </div>
    <div className="page-content">{renderPageContent()}</div>
  </div>
);

ReportPage.propTypes = {
  className: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  pageSubTitle: PropTypes.string.isRequired,
  renderIcon: PropTypes.func,
  renderIntroLeft: PropTypes.func,
  renderIntroRight: PropTypes.func,
  renderPageContent: PropTypes.func.isRequired,
};

export default ReportPage;
