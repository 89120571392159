const fetchReportData = async (reportID) => {
  const response = await fetch(`/reportData/${reportID}.json`);
  return response.json();
};

const fetchPDFReportData = async (reportID) => {
  const response = await fetch(`/pdfReportData/${reportID}.json`);
  return response.json();
};

export {
  fetchReportData,
  fetchPDFReportData,
};
