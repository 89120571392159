import { connect } from 'react-redux';

import withReportSettings from 'containers/withReportSettings';
import DemographicsPage from 'components/DemographicsPage/DemographicsPage';

import {
  getCurrentReportScopeResponsesCount,
  getCurrentReportScopeDemographicItems,
} from 'selectors/reportSelectors';

const mapStateToProps = (state) => {
  return {
    totalRespondents: getCurrentReportScopeResponsesCount(state),
    demographicItems: getCurrentReportScopeDemographicItems(state),
  };
};

export default connect(mapStateToProps)(withReportSettings(DemographicsPage));
