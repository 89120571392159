import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

import ScoreDisplay from 'components/ScoreDisplay/ScoreDisplay';

const ResultComparisonRow = ({
  highlighted,
  onMouseEnter = () => {},
  title,
  score,
  scoreSuffix,
  decimalPlaces,
  percentage,
  color = 'info',
  hasScore,
}) => (
  <div
    onMouseEnter={onMouseEnter}
    className={`comparison-row ${highlighted ? 'highlighted': ''}`}>
    <div className="title">{title}</div>
    <div className="comparison-bar">
      <Progress
        value={percentage}
        max={100}
        color={color}
      >
        {!hasScore && 'Not Applicable'}
      </Progress>
    </div>
    <div className="result-display">
      {
        hasScore ? (
          <ScoreDisplay
            score={score}
            scoreSuffix={scoreSuffix}
            decimalPlaces={decimalPlaces}
          />
        ) : (
          <span className="score-display">N/A</span>
        )
      }
    </div>
  </div>
);

ResultComparisonRow.propTypes = {
  highlighted: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  title: PropTypes.string.isRequired,
  score: PropTypes.number,
  scoreSuffix: PropTypes.string,
  decimalPlaces: PropTypes.number,
  percentage: PropTypes.number,
  color: PropTypes.string,
  hasScore: PropTypes.bool.isRequired,
};

export default ResultComparisonRow;
