import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faChartPie from '@fortawesome/fontawesome-free-solid/faChartPie';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faQuestionCircle from '@fortawesome/fontawesome-free-regular/faQuestionCircle';

import { UncontrolledTooltip } from 'reactstrap';

import { toPercentage } from 'utils';
import { minResponseThreshold, minResponseRateThreshold } from '../../appConfig';
import ResponseRateBar from 'components/ResponseRateBar/ResponseRateBar.jsx';

class OrganizationsListRow extends React.Component {
  static propTypes = {
    organizationTree: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      report_id: PropTypes.number,
      completed: PropTypes.number,
      total: PropTypes.number.isRequired,
      sub_organizations: PropTypes.array,
    }).isRequired,
    level: PropTypes.number,
    expanded: PropTypes.bool,
    isRoot: PropTypes.bool,
    keyword: PropTypes.string,
  }

  static defaultProps = {
    level: 0,
    expanded: false,
    sub_organizations: [],
    isRoot: false,
  }

  state = {
    expandable: false,
    expanded: false,
  }

  static getDerivedStateFromProps(props, state) {
    const  { organizationTree: { sub_organizations = []}, expanded, isRoot, keyword} = props;
    
    return {
      expandable: !isRoot && sub_organizations.length > 0,
      expanded: isRoot || state.expanded || expanded || (keyword && keyword.length > 0)
    };
  }

  toggleExpand = () => {
    this.setState(({ expandable, expanded }) => ({
      expanded: expandable && !expanded,
    }));
  }

  render() {
    const { organizationTree: { id, name, report_id, completed, total, sub_organizations = [] }, level, isRoot, keyword } = this.props;
    const { expanded, expandable } = this.state;
    return [
      (<tr className={`level-${level}`} key={id}>
        <th scope="row" className={expandable ? 'expandable' : ''} onClick={isRoot ? () => {}: this.toggleExpand}>
          {expandable ? <FontAwesomeIcon size="sm" icon={expanded ? faAngleDown : faAngleRight} /> : null}
          &nbsp;{name}
        </th>
        {report_id ?
          (<td>
          <a href={`/reports/${report_id}`}>
            <FontAwesomeIcon icon={faChartPie} />
            &nbsp;View
          </a>
          </td>) :
          (<td className="ineligible">
            <div id={`ineligible_${id}`}>
              <FontAwesomeIcon icon={ faQuestionCircle } />
              &nbsp;Ineligible
            </div>
            <UncontrolledTooltip placement="bottom" delay={ {show: 0, hide: 0 } } target={`ineligible_${id}`}>
              {
                completed < minResponseThreshold ?
                  `At least ${minResponseThreshold} responses are required.`
                  :
                  `A minimum response rate of ${toPercentage(minResponseRateThreshold)}% is required`
              }
            </UncontrolledTooltip>
          </td>)
        }
        <td>
          <ResponseRateBar completed={completed} total={total} />
        </td>
      </tr>),
      ...(expanded ? sub_organizations.map((sub) => (<OrganizationsListRow organizationTree={sub} level={level + 1} key={sub.id} keyword={keyword} />)) : [])
    ];
  }
}

export default OrganizationsListRow;
