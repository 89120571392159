import React from 'react';
import PropTypes from 'prop-types';

import { mmLightStone } from '../../styles/_colors.scss';
import { toPercentage } from 'utils';

import DonutChartSegment from 'components/DonutChartSegment/DonutChartSegment';

class DonutChart extends React.Component {
  static propTypes = {
    highlightedIndex: PropTypes.number,
    total: PropTypes.number.isRequired,
    onMouseOver: PropTypes.func,
    dataSeries: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })).isRequired,
  }

  static defaultProps = {
    highlightedIndex: -1,
    onMouseOver: () => {},
  }

  get circleRadius() {
    return 36;
  }

  calculateSegmentProps() {
    const { dataSeries } = this.props;
    const radius = this.circleRadius;
    const c = 2 * Math.PI * radius;
    let totalSegmentLength = 0;
    let percentTotal = 0;

    return dataSeries.reduce((segProps, { label, percent, color }, index) => {
      const segLength = c * percent;
      const textProps = percent >= 0.03
        ? {
          text: `${toPercentage(percent)}%`,
          textX: 50 + 45 * Math.cos(((percentTotal + percent / 2) * 360 - 180) * Math.PI / 180),
          textY: 50 + 45 * Math.sin(((percentTotal + percent / 2) * 360 - 180) * Math.PI / 180),
        }
        : {};

      const segProp = {
        label,
        r: radius,
        stroke: color,
        strokeDashoffset: 0.5 * c + c - totalSegmentLength,
        strokeDasharray: `${segLength},${c - segLength}`,
        ...textProps,
      };
      percentTotal += percent;
      totalSegmentLength += segLength;
      return [...segProps, segProp];
    },[]);
  }

  render() {
    const radius = this.circleRadius;
    const segments = this.calculateSegmentProps();
    const { highlightedIndex, onMouseOver, dataSeries, total } = this.props;
    const highlightedData = dataSeries[highlightedIndex];

    return (
      <svg viewBox="0 0 100 100"
        version="1.1" xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio ="xMinYMin slice"
        xlinkHref="http://www.w3.org/1999/xlink"
      >
        <g className="background-circle">
          <circle
            strokeWidth="5"
            fill="none"
            stroke={mmLightStone}
            cx="50"
            cy="50"
            r={radius}
          />
        </g>
        <g className="segments">
          {segments.map((segmentProps, index) =>
            (<DonutChartSegment
                {...segmentProps}
                key={segmentProps.label}
                onMouseOver={() => onMouseOver(index)}
                highlighted={index === highlightedIndex }
             />))
          }
        </g>
        <g className="highlighted-count">
          <text
            x={50}
            y={50}
            fontSize={18}
            alignmentBaseline="middle"
            textAnchor="middle">
            {highlightedData ? highlightedData.count : total}
          </text>
          <text
            x={50}
            y={60}
            fontSize={5}
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {highlightedData ? '' : 'total '}responses
          </text>
        </g>
      </svg>
    );
  }
}

export default DonutChart;
