import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faExternalLinkAlt from '@fortawesome/fontawesome-free-solid/faExternalLinkAlt';

import ReportPage from 'components/ReportPage/ReportPage';
import ReportIcon from 'components/Icons/ReportIcon';

import './HowToReadPage.style.scss';

const HowToReadPage = () => (
  <ReportPage
    className="how-to-read-page"
    pageTitle="How to Read Your Report"
    pageSubTitle="How to get the most from your survey results"
    renderIcon={() => <ReportIcon />}
    renderPageContent={() => (
      <div className="how-to-read">
        <div className="page-section">
          <h4 className="section-title">About Your Report</h4>
          <p>
          The Teaching and Learning Conditions in Colorado (TLCC) Survey -
          formerly TELL Colorado survey - is a statewide survey of school-based
          staff (teachers and building leadership) on their perceptions of the
          teaching and learning conditions in their schools. Questions were asked
          about instructional support, professional development, managing student
          conduct, use of time, leadership, facilities and resources, family and
          community support, and future plans. Demographic questions were limited
          to ensure participant anonymity. 
          </p>
          <p>
          The TLCC results give you a powerful tool for understanding teachers
          and leaders' experience in their classrooms and schools. These results
          may conﬁrm some of what you already know about classrooms and schools,
          may surprise you with details that you didn't know, and most likely will
          open up new questions about areas you want to explore further.
          </p>
        </div>
        <div className="page-section">
          <h4 className="section-title">Survey Design</h4>
          <p>
          The survey is led by the Colorado Department of Education (CDE) and operated
          through a statewide collaborative that includes the Colorado Association of
          School Boards, Colorado Association of School Executives, Colorado Education
          Association, Colorado Education Initiative, Colorado League of Charter
          Schools, Colorado Rural Alliance and representatives from school districts,
          universities and researchers. APA Consulting developed the TLCC survey by
          working closely with the partner organizations, districts and educators in
          the field. Cambridge Education administered the inaugural launch the survey
          in January 2018.
          </p>
        </div>
        <div className="page-section">
          <h4 className="section-title">Interpreting Results</h4>
          <p>
          After responding to demographic questions, educators indicated one of 
          four response options for each item on the survey. Results displayed in this report
          are percentages based on the proportion of participants who replied “Agree”
          or “Strongly Agree.” Responses to “I don't Know” do not affect favorability ratings.
          You can see a full breakdown of how all educators
          responded in each item.
          </p>
          <p>
          Items on the TLCC have varying levels of meaning by design, so it is not as
          easy as simply looking at the highest and lowest items to identify strengths
          and areas of improvement. When examining a school’s results, you should think
          carefully about the priorities of your school(s) and departments, and then
          identify relative strengths and weaknesses across teachers and schools.
          </p>
        </div>
        <div className="page-section">
          <h4 className="section-title">Resources</h4>
          <p>
            <a
              href="http://www.cde.state.co.us/tlcc/tlccdosanddonts"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={ faExternalLinkAlt } />&nbsp;
              TLCC Data Use Dos and Don'ts
            </a>
            <br />
            <a
              href="http://www.cde.state.co.us/tlcc/usingtlccresults"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={ faExternalLinkAlt } />&nbsp;
              Using TLCC Results
            </a>
            <br />
            <a
              href="http://www2.cde.state.co.us/tell/historicaldata.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={ faExternalLinkAlt } />&nbsp;
              Historical District and School Level TELL Data
            </a>
          </p>
        </div>
      </div>
    )}
  />
);

export default HowToReadPage;