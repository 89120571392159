import React from 'react';
import PropTypes from 'prop-types';

import './LikertItemResponseDistribution.styles.scss';
import LikertItemResponseAnchor from 'components/LikertItemResponseAnchor/LikertItemResponseAnchor';

const LikertItemResponseDistribution = ({
  distributions,
  total,
}) => (
  <div className="likert-item-response-distribution">
    <div className="distributions">
      {distributions.map((dProps) => (
        <LikertItemResponseAnchor key={dProps.anchor_text} {...dProps} total={total} />
      ))}
    </div>
    <div className="total-count">
      <div className="total">{total}</div>
      <div className="total-label">Total</div>
    </div>
  </div>
);

LikertItemResponseDistribution.propTypes = {
  distributions: PropTypes.arrayOf(PropTypes.shape({
    anchor_text: PropTypes.string.isRequired, 
    number_of_responses: PropTypes.number.isRequired, 
    favorable: PropTypes.bool,
    scorable: PropTypes.bool.isRequired,
  })).isRequired,
  total: PropTypes.number.isRequired,
};

export default LikertItemResponseDistribution;