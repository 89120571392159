import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getScoreRange,
  getScoreSuffix,
  getScoreDecimalPlaces,
  getBenchmarkLabels,
  getMeasureType,
} from 'selectors/reportSelectors';

const mapStateToProps = (state) => {
  const { minScore, maxScore } = getScoreRange(state);

  return {
    minScore,
    maxScore,
    scoreSuffix: getScoreSuffix(state),
    decimalPlaces: getScoreDecimalPlaces(state),
    benchmarkLabels: getBenchmarkLabels(state),
    measureType: getMeasureType(state),
  };
};

const withReportSettings = function(WrappedComponent) {
  class ScoreSettingsProvider extends React.Component {
    static propTypes = {
      minScore: PropTypes.number.isRequired,
      maxScore: PropTypes.number.isRequired,
      scoreSuffix: PropTypes.string,
      measureType: PropTypes.string.isRequired,
      decimalPlaces: PropTypes.number.isRequired,
      benchmarkLabels: PropTypes.arrayOf(PropTypes.string),
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(mapStateToProps)(ScoreSettingsProvider);
}

export default withReportSettings;
