import React, { Component } from 'react';
import { connect } from 'react-redux';

import withReportSettings from 'containers/withReportSettings';
import withModule from 'containers/withModule/withModule';
import OrganizationInsightPage from 'components/OrganizationInsightPage/OrganizationInsightPage';
import { getInsightsChildrenTypeName } from 'selectors/reportSelectors';

const childrenScoresSorter = (
  { child_score: aScore,  threshold_met: aThresholdMet},
  { child_score: bScore,  threshold_met: bThresholdMet}
) => {
  if (aThresholdMet && bThresholdMet) {
    return aScore > bScore ? -1 : 1;
  } else if (aThresholdMet) {
    return -1;
  } else if (bThresholdMet) {
    return 1;
  }

  return 0;
};

class OrganizationInsights extends Component {
  insightsProps = () => {
    const { constructs, report_sections: { insights }} = this.props.module;
    const children_scores = [...insights.children_scores].sort(childrenScoresSorter);

    return {
      constructs,
      ...insights,
      children_scores,
    };
  }

  render() {
    return (
      <OrganizationInsightPage
        {...this.props}
        {...this.insightsProps()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const childrenType = getInsightsChildrenTypeName(state);

  return {
    childrenType,
  };
};

export default withReportSettings(connect(mapStateToProps)(withModule(OrganizationInsights)));
