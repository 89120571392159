import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PDFDownloadPage from 'components/PDFDownloadPage/PDFDownloadPage';

import { loadPDFReportData as loadPDFReportDataAction } from 'actions/reportActions';
import { getCurrentReportId, getCurrentReportPDFData } from 'selectors/reportSelectors';

const PDFDownload = (props) => {
  const {
    loaded, loading, failedReason, reportId, loadPDFReportData,
  } = props;

  useEffect(() => {
    if (!loading && !loaded && !failedReason) {
      loadPDFReportData(reportId);
    }
  }, [loading, loaded, failedReason, reportId, loadPDFReportData]);

  return (
    <PDFDownloadPage {...props} />
  );
};

const mapStateToProps = (state) => {
  const reportData = getCurrentReportPDFData(state) || {};
  const reportId = getCurrentReportId(state); 
  const { loading = false, loaded = false, failedReason, data } = reportData;

  return {
    loading,
    loaded,
    failedReason,
    reports: data,
    reportId,
  }
};

const mapDispatchToProps = (dispatch) => ({
  loadPDFReportData: (reportId) => dispatch(loadPDFReportDataAction(reportId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PDFDownload);
