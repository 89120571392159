import { fork, all } from 'redux-saga/effects';
import reportSaga from './reportSagas';
import reportPDFSaga from './reportPDFSagas';

export default function* rootSaga() {
  yield all([
    fork(reportSaga),
    fork(reportPDFSaga)
  ]);
}
