import React from "react";
import { Container, Row, Col } from "reactstrap";

import TLCCLogo from "images/tlcc_logo.png";
import CELogo from "images/ce_logo.png";
import CDELogo from "images/cde_logo.png";

import "./pageHeader.styles.scss";
import { getConfigs } from "../../appConfig";

const PageHeader = () => {
  const { tlccOfficialSite } = getConfigs();

  return (
    <header className="page-header">
      <Container>
        <Row>
          <Col>
            <div className="logos">
              <a
                href={tlccOfficialSite || "http://www.tlccsurvey.org"}
                target="_blank"
                rel="noopener noreferrer"
                title="TLCC Survey Main Site"
              >
                <img
                  src={TLCCLogo}
                  alt="TLCC Logo"
                  className="main-project-logo"
                />
              </a>
              <a
                href="http://www.camb-ed.com/americas"
                target="_blank"
                rel="noopener noreferrer"
                title="Cambridge Education Survey Services"
              >
                <img src={CELogo} alt="CE Logo" className="ce-logo" />
              </a>
              <a
                href="https://www.cde.state.co.us"
                target="_blank"
                rel="noopener noreferrer"
                title="Colorado Department of Education"
              >
                <img src={CDELogo} alt="CDE Logo" className="client-logo" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default PageHeader;
