import React from 'react';
import PropTypes from 'prop-types';

import { toPercentage, calcPercentage } from 'utils';

import ResultComparisonRow from 'components/ResultComparisonRow/ResultComparisonRow';

import './ResultComparison.styles.scss';

class ResultComparison extends React.Component {
  state = {
    highlightedIndex: -1,
  }

  static propTypes = {
    minScore: PropTypes.number.isRequired,
    maxScore: PropTypes.number.isRequired,
    score: PropTypes.number,
    thresholdMet: PropTypes.bool.isRequired,
    scoreSuffix: PropTypes.string,
    decimalPlaces: PropTypes.number,
    comparisons: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      score: PropTypes.number,
    })).isRequired,
  }

  static defaultProps = {
    scoreSuffix: '',
    decimalPlaces: 0,
  }

  highlightIndex = (index) => {
    this.setState({
      highlightedIndex: index,
    });
  } 

  scorePercentage(score) {
    const { minScore, maxScore } = this.props;
    return toPercentage(calcPercentage(score, minScore, maxScore));
  }

  render() {
    const { score, comparisons, scoreSuffix, decimalPlaces, thresholdMet } = this.props;

    return (
      <div className="result-comparison">
        <ResultComparisonRow 
          highlighted={this.state.highlightedIndex < 0}
          onMouseEnter={() => this.highlightIndex(-1)}
          title="Your result"
          score={thresholdMet ? score : null}
          scoreSuffix={scoreSuffix}
          decimalPlaces={decimalPlaces}
          percentage={thresholdMet ? this.scorePercentage(score) : 0}
          color={'success'}
          hasScore={thresholdMet && score && score >= 0}
        />
        {comparisons.map(({ score: compareScore, title }, index) => {
          const hasScore = !!(compareScore && compareScore >= 0);
          return (
            <ResultComparisonRow 
              key={`${title}-${index}`}
              highlighted={this.state.highlightedIndex === index}
              onMouseEnter={() => this.highlightIndex(index)}
              title={title}
              score={compareScore}
              scoreSuffix={scoreSuffix}
              decimalPlaces={decimalPlaces}
              percentage={hasScore ? this.scorePercentage(compareScore) : 0}
              hasScore={hasScore}
            />
          )})}
      </div>
    );
  }
}

export default ResultComparison;
