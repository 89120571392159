import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import "./ConstructOverviewCard.style.scss";

import ConstructIcon from "components/ConstructIcon/ConstructIcon";
import ScoreArc from "components/ScoreArc/ScoreArc";
import { colorForIndex } from "utils";

const ConstructOverviewCard = ({
  construct,
  constructIndex,
  description = "",
  score,
  thresholdMet,
  constructDetailsPath,
  ...reportSettings
}) => {
  const constructColor = colorForIndex(constructIndex);
  return (
    <div className="construct-card">
      <h5 className="construct-title" style={{ color: constructColor }}>
        <ConstructIcon name={construct} index={constructIndex} />
        {construct}
      </h5>
      <div className="construct-content">
        <div className="construct-description">
          <p>{description}</p>
          <Link to={constructDetailsPath}>
            <Button size="sm">See Details</Button>
          </Link>
        </div>
        <div className="construct-result">
          <span>Construct Result</span>
          <ScoreArc
            score={score}
            color={constructColor}
            thresholdMet={thresholdMet}
            {...reportSettings}
          />
        </div>
      </div>
    </div>
  );
};

ConstructOverviewCard.propTypes = {
  construct: PropTypes.string.isRequired,
  constructIndex: PropTypes.number.isRequired,
  description: PropTypes.string,
  score: PropTypes.number,
  thresholdMet: PropTypes.bool.isRequired,
  constructDetailsPath: PropTypes.string.isRequired,
  scoreSuffix: PropTypes.string,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  decimalPlaces: PropTypes.number,
};

export default ConstructOverviewCard;
