import React from 'react';

const ConstructDetailsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.658 19.658">
    <path d="M0 19.658h19.658V0H0v19.658zM18.84.818v3.53l-5.396 7.783-3.7-2.922-4.113 5.76-2.428-1.618L.82 16.127V.82h18.02zM.82 18.84v-1.454l2.53-2.95 2.485 1.656 4.077-5.706 3.708 2.93 5.218-7.527V18.84H.818z"
    fill="#b2afb0" />
  </svg>
);

export default ConstructDetailsIcon;
