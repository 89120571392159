import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import withReportSettings from 'containers/withReportSettings';
import ModuleOverViewPage from 'components/ModuleOverViewPage/ModuleOverViewPage';

import { mapRDOToProps } from 'components/ResultDisaggregation/helpers';
import { mapConstructSummary } from 'components/ConstructOverviewCard/helpers';
import withModule from 'containers/withModule/withModule';

class ModuleOverview extends Component {
  state = { }

  moduleProps = () => {
    const {
      display_name,
      is_scorable,
      constructs: constructsMetadata,
      report_sections: {
        at_a_glance: {
          subject_score,
          threshold_met,
          benchmarks = [],
          construct_summary = [],
          disaggregated_results = [],
        },
      },
    } = this.props.module;
    const benchmarkLabels = this.props.benchmarkLabels;
    const url = this.props.match.url;

    return {
      moduleName: display_name,
      scorable: is_scorable,
      score: subject_score,
      thresholdMet: threshold_met,
      comparisons: benchmarks.map((bScore, i) => ({ title: benchmarkLabels[i], score: bScore })),
      disaggregatedResults: mapRDOToProps(disaggregated_results),
      constructs: mapConstructSummary(construct_summary, constructsMetadata).map((c) => ({
        constructDetailsPath: `${url}/constructs/${c.providerKey}`,
        ...c,
      })),
    };
  }

  render() {
    return (
      <ModuleOverViewPage
        {...this.props}
        {...this.moduleProps()}
      />
    );
  }
}

export default withReportSettings(withModule(withRouter(ModuleOverview)));
