import React from 'react';
import PropTypes from 'prop-types';

import { sassListToArray } from 'utils';
import { mmLightStone, chartColors } from '../../styles/_colors.scss';

import DonutChart from 'components/DonutChart/DonutChart';
import DemographicItemResponseAnchors from 'components/DemographicItemResponseAnchors/DemographicItemResponseAnchors';

import './DemographicItemResponseDistribution.styles.scss';

class DemographicItemResponseDistribution extends React.Component {
  state = {
    highlightedIndex: -1,
    segments: []
  }

  static propTypes = {
    response_distribution: PropTypes.arrayOf(PropTypes.shape({
      anchor_text: PropTypes.string.isRequired,
      number_of_responses: PropTypes.number.isRequired,
    })).isRequired,
    total_responses: PropTypes.number.isRequired,
    missing_responses: PropTypes.number
  }

  static getDerivedStateFromProps({ response_distribution, total_responses, missing_responses }) {
    const totalRespondents = total_responses + missing_responses;
    const colors = sassListToArray(chartColors);

    let segments = response_distribution.reduce((segs, { anchor_text, number_of_responses }, index) => {
      return [...segs, {
        label: anchor_text,
        count: number_of_responses,
        percent: number_of_responses / totalRespondents,
        color: colors[index % colors.length],
      }];
    }, []);

    if (missing_responses > 0) {
      segments.push({
        label: 'Did not answer',
        count: missing_responses,
        percent: missing_responses / totalRespondents,
        color: mmLightStone,
      });
    }

    return { segments };
  }

  onMouseOver = (index) => {
    this.setState({ highlightedIndex: index });
  }

  onMouseLeave = () => {
    this.setState({ highlightedIndex: -1 });
  }

  render() {
    const { segments, highlightedIndex } = this.state;
    return (
      <div
        onMouseLeave={this.onMouseLeave}
        className="demogrpahic-item-response-distribution"
      >
        <div className="donut-chart">
          <DonutChart 
            highlightedIndex={highlightedIndex}
            onMouseOver={this.onMouseOver}
            dataSeries={segments}
            total={this.props.total_responses}
          />
        </div>
        <div className="response-anchors-container">
          <DemographicItemResponseAnchors
            highlightedIndex={highlightedIndex}
            onMouseOver={this.onMouseOver}
            anchors={segments} />
        </div>
      </div>
    );
  }
}

export default DemographicItemResponseDistribution;
