import React from 'react';

const InsightsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.677 24.284">
    <path
      d="M24.677 18.083V1H0v17.083h11.863v1.836l-4.5 2.49.46.833 4.516-2.502 4.514 2.503.46-.832-4.5-2.49v-1.837h11.863zM.95 1.95h22.777v15.184H.95V1.95z"
      fill="#b0afb0"
    />
    <path
      fill="#b0afb0"
      d="M11.71 11.61L8.896 7.686l-1.884 3.65-2.96-5.974-2.163 7.98.915.247 1.507-5.564 2.684 5.414 2.03-3.94 3.04 4.237 2.624-8.805-.908-.27zM18.982 6.695a2.852 2.852 0 0 0-2.85 2.847 2.85 2.85 0 0 0 2.85 2.847A2.85 2.85 0 0 0 21.83 9.54a2.85 2.85 0 0 0-2.848-2.847m0 4.744a1.9 1.9 0 0 1 0-3.796c1.046 0 1.897.85 1.897 1.897s-.852 1.9-1.898 1.9"
    />
  </svg>
);

export default InsightsIcon;
