import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

import { toPercentage } from 'utils';

import './responseRateBar.styles.scss';

const ResponseRateBar = ({ completed, total, threshold = 0 }) => {
  const percentage = completed / total;
  const thresholdMet = percentage >= threshold;

  return (
    <div className="response-rate-bar">
      <Progress
        value={completed}
        max={total}
        color={thresholdMet ? 'success' : 'warning'}>
        {completed} / {total}
      </Progress>
      <div className="percentage-display">
        {toPercentage(percentage)}%
      </div>
    </div>
  );
};

ResponseRateBar.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  threshold: PropTypes.number,
};

ResponseRateBar.defaultProps = {
  threshold: 0.5,
};

export default ResponseRateBar;