import {
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
} from 'constants/organizationConstants';

const setFilterKeyword = (keyword) => ({
  type: SET_FILTER_KEYWORD,
  payload: { keyword },
});

const removeFilterKeyword = () => ({
  type: REMOVE_FILTER_KEYWORD,
});

export {
  setFilterKeyword,
  removeFilterKeyword
};
