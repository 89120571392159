import React from 'react';
import PropTypes from 'prop-types';

import { colorForIndex, genInitials } from 'utils';
import { mmDarkStone } from '../../styles/_colors.scss';

const ConstructIcon = ({
  name,
  index = -1,
}) => (
  <svg
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio ="xMinYMin slice"
  >
    <circle cx="50" cy="50" r="50" fill={(index >= 0) ? colorForIndex(index) : mmDarkStone} />
    <text
      x="50"
      y="65"
      textAnchor="middle"
      fill="#fff"
      fontSize="46"
    >{genInitials(name)}</text>
  </svg>
);

ConstructIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  index: PropTypes.number,
};

export default ConstructIcon;
