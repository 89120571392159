const rootOrganizationId = 401722;
const organizations = {
  "401722": {
    "id": 401722,
    "type": "clent",
    "name": "Colorado",
    "completed": 35475,
    "total": 67785,
    "report_id": 401722,
    "children_type": "district",
    "children_ids": [
      401907,
      401906,
      401905,
      401904,
      401903,
      401902,
      401901,
      401900,
      401899,
      401898,
      401897,
      401896,
      401895,
      401894,
      401893,
      401892,
      401891,
      401890,
      401889,
      401888,
      401887,
      401886,
      401885,
      401884,
      401883,
      401882,
      401881,
      401880,
      401879,
      401878,
      401877,
      401876,
      401875,
      401874,
      401873,
      401872,
      401871,
      401870,
      401869,
      401868,
      401867,
      401866,
      401865,
      401864,
      401863,
      401862,
      401861,
      401860,
      401859,
      401858,
      401857,
      401856,
      401855,
      401854,
      401853,
      401852,
      401851,
      401850,
      401849,
      401848,
      401847,
      401846,
      401845,
      401844,
      401843,
      401842,
      401841,
      401840,
      401839,
      401838,
      401837,
      401836,
      401835,
      401834,
      401833,
      401832,
      401831,
      401830,
      401829,
      401828,
      401827,
      401826,
      401825,
      401824,
      401823,
      401822,
      401821,
      401820,
      401819,
      401818,
      401817,
      401816,
      401815,
      401814,
      401813,
      401812,
      401811,
      401810,
      401809,
      401808,
      401807,
      401806,
      401805,
      401804,
      401803,
      401802,
      401800,
      401799,
      401798,
      401797,
      401796,
      401795,
      401794,
      401793,
      401792,
      401791,
      401790,
      401789,
      401788,
      401787,
      401786,
      401785,
      401784,
      401783,
      401782,
      401781,
      401780,
      401779,
      401778,
      401777,
      401776,
      401775,
      401774,
      401773,
      401772,
      401771,
      401770,
      401769,
      401768,
      401767,
      401766,
      401765,
      401764,
      401763,
      401762,
      401761,
      401760,
      401759,
      401758,
      401757,
      401756,
      401755,
      401754,
      401753,
      401752,
      401751,
      401750,
      401749,
      401748,
      401747,
      401746,
      401745,
      401744,
      401743,
      401742,
      401741,
      401740,
      401739,
      401738,
      401737,
      401736,
      401735,
      401734,
      401733,
      401732,
      401731,
      401730,
      401729,
      401728,
      401727,
      401726,
      401725,
      401724,
      401723
    ]
  },
  "401723": {
    "id": 401723,
    "type": "district",
    "name": "MANZANOLA 3J",
    "completed": 12,
    "total": 22,
    "report_id": 401723,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401991,
      402904
    ]
  },
  "401724": {
    "id": 401724,
    "type": "district",
    "name": "MONTROSE COUNTY RE-1J",
    "completed": 388,
    "total": 420,
    "report_id": 401724,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402024,
      402204,
      402462,
      402520,
      402585,
      402637,
      402839,
      403055,
      403227,
      403434,
      403456,
      403514,
      403678,
      403746
    ]
  },
  "401725": {
    "id": 401725,
    "type": "district",
    "name": "MEEKER RE1",
    "completed": 26,
    "total": 46,
    "report_id": 401725,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402594,
      402755,
      402870
    ]
  },
  "401726": {
    "id": 401726,
    "type": "district",
    "name": "MONTEZUMA-CORTEZ RE-1",
    "completed": 174,
    "total": 197,
    "report_id": 401726,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401944,
      402166,
      402511,
      402551,
      402712,
      403137,
      403245,
      403391,
      403547,
      403565
    ]
  },
  "401727": {
    "id": 401727,
    "type": "district",
    "name": "BRANSON REORGANIZED 82",
    "completed": 5,
    "total": 36,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402355,
      403161
    ]
  },
  "401728": {
    "id": 401728,
    "type": "district",
    "name": "JOHNSTOWN-MILLIKEN RE-5J",
    "completed": 105,
    "total": 213,
    "report_id": 401728,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402150,
      402527,
      402544,
      403043,
      403237,
      403459
    ]
  },
  "401729": {
    "id": 401729,
    "type": "district",
    "name": "FOUNTAIN 8",
    "completed": 416,
    "total": 653,
    "report_id": 401729,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402321,
      402358,
      402537,
      402548,
      402852,
      402891,
      402910,
      403056,
      403178,
      403224,
      403415,
      403484,
      403512
    ]
  },
  "401730": {
    "id": 401730,
    "type": "district",
    "name": "LAMAR RE-2",
    "completed": 90,
    "total": 118,
    "report_id": 401730,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402143,
      402390,
      402422,
      402766,
      403115,
      403205
    ]
  },
  "401731": {
    "id": 401731,
    "type": "district",
    "name": "PLATEAU VALLEY 50",
    "completed": 14,
    "total": 50,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402114,
      402230,
      402836,
      403745
    ]
  },
  "401732": {
    "id": 401732,
    "type": "district",
    "name": "TRINIDAD 1",
    "completed": 30,
    "total": 77,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401930,
      402649,
      403488,
      403548
    ]
  },
  "401733": {
    "id": 401733,
    "type": "district",
    "name": "JEFFERSON COUNTY R-1",
    "completed": 4050,
    "total": 6334,
    "report_id": 401733,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401910,
      401913,
      401915,
      401923,
      401928,
      401934,
      401937,
      401953,
      401955,
      401987,
      401990,
      401998,
      402004,
      402008,
      402046,
      402049,
      402052,
      402054,
      402072,
      402079,
      402133,
      402151,
      402154,
      402158,
      402173,
      402174,
      402179,
      402196,
      402205,
      402212,
      402226,
      402251,
      402255,
      402263,
      402271,
      402279,
      402284,
      402298,
      402316,
      402328,
      402348,
      402366,
      402367,
      402372,
      402385,
      402419,
      402434,
      402437,
      402448,
      402451,
      402464,
      402478,
      402482,
      402487,
      402493,
      402519,
      402532,
      402535,
      402541,
      402543,
      402549,
      402587,
      402590,
      402599,
      402608,
      402636,
      402647,
      402656,
      402658,
      402667,
      402693,
      402699,
      402706,
      402710,
      402784,
      402799,
      402805,
      402808,
      402817,
      402851,
      402923,
      402924,
      402950,
      402955,
      402965,
      402971,
      402979,
      402982,
      403009,
      403068,
      403085,
      403097,
      403103,
      403104,
      403105,
      403122,
      403131,
      403134,
      403136,
      403139,
      403141,
      403145,
      403146,
      403155,
      403169,
      403177,
      403179,
      403181,
      403189,
      403201,
      403206,
      403215,
      403217,
      403221,
      403225,
      403248,
      403256,
      403258,
      403281,
      403303,
      403306,
      403316,
      403322,
      403332,
      403353,
      403361,
      403379,
      403382,
      403408,
      403412,
      403413,
      403430,
      403431,
      403453,
      403461,
      403462,
      403479,
      403482,
      403485,
      403486,
      403511,
      403528,
      403555,
      403584,
      403598,
      403629,
      403636,
      403640,
      403642,
      403659,
      403661,
      403668,
      403677,
      403686,
      403702,
      403704,
      403712,
      403714,
      403724,
      403748,
      403754,
      403757,
      403759,
      403774
    ]
  },
  "401734": {
    "id": 401734,
    "type": "district",
    "name": "BAYFIELD 10 JT-R",
    "completed": 65,
    "total": 112,
    "report_id": 401734,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403032,
      403150,
      403563
    ]
  },
  "401735": {
    "id": 401735,
    "type": "district",
    "name": "COTOPAXI RE-3",
    "completed": 7,
    "total": 27,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402934,
      403064
    ]
  },
  "401736": {
    "id": 401736,
    "type": "district",
    "name": "BETHUNE R-5",
    "completed": 2,
    "total": 17,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402258
    ]
  },
  "401737": {
    "id": 401737,
    "type": "district",
    "name": "ELIZABETH C-1",
    "completed": 32,
    "total": 172,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402294,
      402550,
      402801,
      402914,
      402933,
      403771
    ]
  },
  "401738": {
    "id": 401738,
    "type": "district",
    "name": "EDISON 54 JT",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401933,
      402412,
      402794
    ]
  },
  "401739": {
    "id": 401739,
    "type": "district",
    "name": "SANFORD 6J",
    "completed": 7,
    "total": 24,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402123,
      402770
    ]
  },
  "401740": {
    "id": 401740,
    "type": "district",
    "name": "WOODLIN R-104",
    "completed": 11,
    "total": 18,
    "report_id": 401740,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402099,
      402774
    ]
  },
  "401741": {
    "id": 401741,
    "type": "district",
    "name": "GARFIELD 16",
    "completed": 2,
    "total": 90,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402265,
      403024,
      403378,
      403458
    ]
  },
  "401742": {
    "id": 401742,
    "type": "district",
    "name": "COLORADO RIVER BOCES",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403670
    ]
  },
  "401743": {
    "id": 401743,
    "type": "district",
    "name": "SWINK 33",
    "completed": 31,
    "total": 39,
    "report_id": 401743,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402332,
      403013
    ]
  },
  "401744": {
    "id": 401744,
    "type": "district",
    "name": "DEER TRAIL 26J",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402772,
      402837
    ]
  },
  "401745": {
    "id": 401745,
    "type": "district",
    "name": "GUNNISON WATERSHED RE1J",
    "completed": 79,
    "total": 183,
    "report_id": 401745,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402572,
      402707,
      403325,
      403400,
      403483,
      403487,
      403602
    ]
  },
  "401746": {
    "id": 401746,
    "type": "district",
    "name": "CENTER 26 JT",
    "completed": 57,
    "total": 70,
    "report_id": 401746,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402144,
      402149,
      402489,
      403106,
      403331
    ]
  },
  "401747": {
    "id": 401747,
    "type": "district",
    "name": "EAST GRAND 2",
    "completed": 110,
    "total": 114,
    "report_id": 401747,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402073,
      402331,
      402717,
      403633
    ]
  },
  "401748": {
    "id": 401748,
    "type": "district",
    "name": "HAXTUN RE-2J",
    "completed": 23,
    "total": 34,
    "report_id": 401748,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402187,
      402542
    ]
  },
  "401749": {
    "id": 401749,
    "type": "district",
    "name": "AULT-HIGHLAND RE-9",
    "completed": 5,
    "total": 70,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402375,
      402470,
      403188
    ]
  },
  "401750": {
    "id": 401750,
    "type": "district",
    "name": "EAGLE COUNTY RE 50",
    "completed": 328,
    "total": 517,
    "report_id": 401750,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401973,
      402240,
      402335,
      402362,
      402386,
      402700,
      402736,
      402785,
      402803,
      402854,
      402862,
      402913,
      402954,
      403029,
      403148,
      403315,
      403419,
      403425
    ]
  },
  "401751": {
    "id": 401751,
    "type": "district",
    "name": "NORTH PARK R-1 ",
    "completed": 12,
    "total": 22,
    "report_id": 401751,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403432
    ]
  },
  "401752": {
    "id": 401752,
    "type": "district",
    "name": "WESTMINSTER PUBLIC SCHOOLS",
    "completed": 374,
    "total": 621,
    "report_id": 401752,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402040,
      402085,
      402280,
      402347,
      402353,
      402365,
      402396,
      402593,
      402609,
      402697,
      402741,
      402819,
      402860,
      402935,
      403267,
      403356,
      403450,
      403519,
      403665,
      403673
    ]
  },
  "401753": {
    "id": 401753,
    "type": "district",
    "name": "LAS ANIMAS RE-1",
    "completed": 36,
    "total": 53,
    "report_id": 401753,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401986,
      402261,
      402546,
      402724,
      402726,
      403609
    ]
  },
  "401754": {
    "id": 401754,
    "type": "district",
    "name": "FRENCHMAN RE-3",
    "completed": 2,
    "total": 23,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402526,
      403211
    ]
  },
  "401755": {
    "id": 401755,
    "type": "district",
    "name": "EAST OTERO R-1",
    "completed": 88,
    "total": 105,
    "report_id": 401755,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402529,
      403239,
      403301
    ]
  },
  "401756": {
    "id": 401756,
    "type": "district",
    "name": "CALHAN RJ-1",
    "completed": 15,
    "total": 42,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403031,
      403157,
      403692
    ]
  },
  "401757": {
    "id": 401757,
    "type": "district",
    "name": "COLORADO SCHOOL FOR THE DEAF AND BLIND",
    "completed": 0,
    "total": 66,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402777
    ]
  },
  "401758": {
    "id": 401758,
    "type": "district",
    "name": "MIAMI/YODER 60 JT",
    "completed": 30,
    "total": 32,
    "report_id": 401758,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402303,
      403533
    ]
  },
  "401759": {
    "id": 401759,
    "type": "district",
    "name": "GARFIELD RE-2",
    "completed": 148,
    "total": 363,
    "report_id": 401759,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402211,
      402449,
      402612,
      402725,
      402760,
      403010,
      403052,
      403509,
      403573,
      403742
    ]
  },
  "401760": {
    "id": 401760,
    "type": "district",
    "name": "PRITCHETT RE-3",
    "completed": 0,
    "total": 22,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402135,
      402447,
      403447
    ]
  },
  "401761": {
    "id": 401761,
    "type": "district",
    "name": "COLORADO SPRINGS 11",
    "completed": 1750,
    "total": 2096,
    "report_id": 401761,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402056,
      402084,
      402093,
      402147,
      402195,
      402254,
      402308,
      402350,
      402368,
      402374,
      402391,
      402392,
      402398,
      402399,
      402415,
      402418,
      402433,
      402436,
      402490,
      402524,
      402573,
      402600,
      402605,
      402620,
      402666,
      402694,
      402733,
      402754,
      402786,
      402821,
      402869,
      402919,
      402929,
      403051,
      403133,
      403151,
      403234,
      403235,
      403236,
      403249,
      403257,
      403270,
      403293,
      403309,
      403327,
      403374,
      403417,
      403465,
      403525,
      403527,
      403561,
      403588,
      403600,
      403646,
      403650,
      403761,
      403830
    ]
  },
  "401762": {
    "id": 401762,
    "type": "district",
    "name": "PARK COUNTY RE-2",
    "completed": 24,
    "total": 73,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402094,
      402225,
      403071,
      403556,
      403769
    ]
  },
  "401763": {
    "id": 401763,
    "type": "district",
    "name": "BRIGGSDALE RE-10",
    "completed": 2,
    "total": 21,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402105,
      403259
    ]
  },
  "401764": {
    "id": 401764,
    "type": "district",
    "name": "SCHOOL DISTRICT 27J",
    "completed": 783,
    "total": 1016,
    "report_id": 401764,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402051,
      402081,
      402239,
      402330,
      402456,
      402629,
      402673,
      402744,
      402816,
      402853,
      402855,
      402922,
      403057,
      403078,
      403079,
      403253,
      403340,
      403365,
      403383,
      403520,
      403562,
      403579,
      403631,
      403736,
      403751,
      403775
    ]
  },
  "401765": {
    "id": 401765,
    "type": "district",
    "name": "GENOA-HUGO C113",
    "completed": 12,
    "total": 18,
    "report_id": 401765,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401935
    ]
  },
  "401766": {
    "id": 401766,
    "type": "district",
    "name": "KIOWA C-2",
    "completed": 19,
    "total": 29,
    "report_id": 401766,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402244,
      402401,
      403593
    ]
  },
  "401767": {
    "id": 401767,
    "type": "district",
    "name": "PEYTON 23 JT",
    "completed": 42,
    "total": 59,
    "report_id": 401767,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402130,
      402206,
      402500,
      403444
    ]
  },
  "401768": {
    "id": 401768,
    "type": "district",
    "name": "POUDRE R-1",
    "completed": 1765,
    "total": 2125,
    "report_id": 401768,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401916,
      401927,
      401939,
      401948,
      401966,
      401981,
      402016,
      402108,
      402110,
      402223,
      402314,
      402333,
      402341,
      402357,
      402373,
      402441,
      402474,
      402525,
      402556,
      402663,
      402668,
      402715,
      402720,
      402732,
      402763,
      402793,
      402822,
      402841,
      402847,
      402909,
      402951,
      402967,
      403017,
      403077,
      403124,
      403168,
      403170,
      403226,
      403319,
      403328,
      403351,
      403390,
      403503,
      403513,
      403554,
      403559,
      403639,
      403641,
      403672,
      403703,
      403731,
      403735
    ]
  },
  "401769": {
    "id": 401769,
    "type": "district",
    "name": "CHEYENNE MOUNTAIN 12",
    "completed": 137,
    "total": 410,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401909,
      402233,
      402300,
      402654,
      402915,
      403182,
      403274,
      403384,
      403496,
      403542,
      403689
    ]
  },
  "401770": {
    "id": 401770,
    "type": "district",
    "name": "COLORADO DIGITAL BOCES",
    "completed": 46,
    "total": 80,
    "report_id": 401770,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402339,
      403474
    ]
  },
  "401771": {
    "id": 401771,
    "type": "district",
    "name": "LEWIS-PALMER 38",
    "completed": 320,
    "total": 476,
    "report_id": 401771,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401997,
      402159,
      402178,
      402218,
      402256,
      402304,
      402455,
      403109,
      403191
    ]
  },
  "401772": {
    "id": 401772,
    "type": "district",
    "name": "BRUSH RE-2(J)",
    "completed": 108,
    "total": 118,
    "report_id": 401772,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401963,
      402034,
      402393,
      402948
    ]
  },
  "401773": {
    "id": 401773,
    "type": "district",
    "name": "CHERRY CREEK 5",
    "completed": 1358,
    "total": 3913,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401950,
      401972,
      402050,
      402060,
      402096,
      402118,
      402182,
      402207,
      402264,
      402267,
      402270,
      402302,
      402345,
      402382,
      402411,
      402457,
      402484,
      402494,
      402501,
      402569,
      402576,
      402611,
      402630,
      402632,
      402680,
      402690,
      402698,
      402730,
      402759,
      402783,
      402790,
      402807,
      402813,
      402838,
      402859,
      402885,
      402893,
      402944,
      402946,
      402978,
      402988,
      403044,
      403081,
      403100,
      403110,
      403129,
      403207,
      403223,
      403271,
      403286,
      403294,
      403321,
      403406,
      403410,
      403442,
      403455,
      403580,
      403634,
      403635,
      403637,
      403663,
      403713,
      403743,
      403753,
      403755,
      403758
    ]
  },
  "401774": {
    "id": 401774,
    "type": "district",
    "name": "MOFFAT COUNTY RE:NO 1",
    "completed": 133,
    "total": 162,
    "report_id": 401774,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402236,
      402588,
      403121,
      403241,
      403326,
      403405,
      403516,
      403569
    ]
  },
  "401775": {
    "id": 401775,
    "type": "district",
    "name": "WELD COUNTY S/D RE-8",
    "completed": 166,
    "total": 194,
    "report_id": 401775,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402186,
      402618,
      402903,
      402941,
      403066,
      403166
    ]
  },
  "401776": {
    "id": 401776,
    "type": "district",
    "name": "SILVERTON 1",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402249,
      403230,
      403707
    ]
  },
  "401777": {
    "id": 401777,
    "type": "district",
    "name": "OURAY R-1",
    "completed": 35,
    "total": 50,
    "report_id": 401777,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402976,
      403591,
      403618
    ]
  },
  "401778": {
    "id": 401778,
    "type": "district",
    "name": "EADS RE-1",
    "completed": 17,
    "total": 28,
    "report_id": 401778,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402797,
      402942,
      402974
    ]
  },
  "401779": {
    "id": 401779,
    "type": "district",
    "name": "IGNACIO 11 JT",
    "completed": 59,
    "total": 86,
    "report_id": 401779,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401996,
      402340,
      402691
    ]
  },
  "401780": {
    "id": 401780,
    "type": "district",
    "name": "AKRON R-1",
    "completed": 20,
    "total": 43,
    "report_id": 401780,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402597,
      402781
    ]
  },
  "401781": {
    "id": 401781,
    "type": "district",
    "name": "HANOVER 28",
    "completed": 2,
    "total": 24,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402687,
      403498
    ]
  },
  "401782": {
    "id": 401782,
    "type": "district",
    "name": "KIT CARSON R-1",
    "completed": 2,
    "total": 20,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402940,
      403385
    ]
  },
  "401783": {
    "id": 401783,
    "type": "district",
    "name": "WRAY RD-2",
    "completed": 49,
    "total": 61,
    "report_id": 401783,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402958,
      403142,
      403395
    ]
  },
  "401784": {
    "id": 401784,
    "type": "district",
    "name": "ARRIBA-FLAGLER C-20",
    "completed": 11,
    "total": 19,
    "report_id": 401784,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403246
    ]
  },
  "401785": {
    "id": 401785,
    "type": "district",
    "name": "CHERAW 31",
    "completed": 6,
    "total": 20,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403336
    ]
  },
  "401786": {
    "id": 401786,
    "type": "district",
    "name": "ELLICOTT 22",
    "completed": 78,
    "total": 85,
    "report_id": 401786,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402208,
      402895,
      402902
    ]
  },
  "401787": {
    "id": 401787,
    "type": "district",
    "name": "ELBERT 200",
    "completed": 6,
    "total": 23,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402126,
      402497
    ]
  },
  "401788": {
    "id": 401788,
    "type": "district",
    "name": "BUENA VISTA R-31",
    "completed": 58,
    "total": 105,
    "report_id": 401788,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401940,
      402036,
      402920,
      403648
    ]
  },
  "401789": {
    "id": 401789,
    "type": "district",
    "name": "MANCOS RE-6",
    "completed": 5,
    "total": 50,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402037,
      402483,
      402778,
      402908
    ]
  },
  "401790": {
    "id": 401790,
    "type": "district",
    "name": "SOUTH CONEJOS RE-10",
    "completed": 23,
    "total": 30,
    "report_id": 401790,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402128
    ]
  },
  "401791": {
    "id": 401791,
    "type": "district",
    "name": "STRASBURG 31J",
    "completed": 4,
    "total": 69,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402743,
      402989,
      403421,
      403480
    ]
  },
  "401792": {
    "id": 401792,
    "type": "district",
    "name": "WELD COUNTY SCHOOL DISTRICT RE-3J",
    "completed": 138,
    "total": 176,
    "report_id": 401792,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402275,
      403007,
      403020,
      403154,
      403297,
      403585
    ]
  },
  "401793": {
    "id": 401793,
    "type": "district",
    "name": "LA VETA RE-2",
    "completed": 28,
    "total": 31,
    "report_id": 401793,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402194,
      403069
    ]
  },
  "401794": {
    "id": 401794,
    "type": "district",
    "name": "STRATTON R-4",
    "completed": 14,
    "total": 35,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401962,
      402417,
      403214
    ]
  },
  "401795": {
    "id": 401795,
    "type": "district",
    "name": "DE BEQUE 49JT",
    "completed": 22,
    "total": 22,
    "report_id": 401795,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403250
    ]
  },
  "401796": {
    "id": 401796,
    "type": "district",
    "name": "WILEY RE-13 JT",
    "completed": 17,
    "total": 35,
    "report_id": 401796,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402075,
      402299
    ]
  },
  "401797": {
    "id": 401797,
    "type": "district",
    "name": "LAKE COUNTY R-1",
    "completed": 48,
    "total": 93,
    "report_id": 401797,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401921,
      402458,
      402504,
      402809,
      403594
    ]
  },
  "401798": {
    "id": 401798,
    "type": "district",
    "name": "FORT MORGAN RE-3",
    "completed": 166,
    "total": 248,
    "report_id": 401798,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402361,
      402471,
      402528,
      402561,
      402738,
      402740,
      402939,
      403048
    ]
  },
  "401799": {
    "id": 401799,
    "type": "district",
    "name": "GRANADA RE-1",
    "completed": 0,
    "total": 24,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402669,
      402804
    ]
  },
  "401800": {
    "id": 401800,
    "type": "district",
    "name": "WALSH RE-1",
    "completed": 26,
    "total": 26,
    "report_id": 401800,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402317,
      403436
    ]
  },
  "401802": {
    "id": 401802,
    "type": "district",
    "name": "ENGLEWOOD 1",
    "completed": 205,
    "total": 227,
    "report_id": 401802,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402100,
      402377,
      402545,
      402615,
      403186,
      403506,
      403544,
      403681,
      403728
    ]
  },
  "401803": {
    "id": 401803,
    "type": "district",
    "name": "RANGELY RE-4",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402227,
      402492
    ]
  },
  "401804": {
    "id": 401804,
    "type": "district",
    "name": "DOUGLAS COUNTY RE 1",
    "completed": 2700,
    "total": 4684,
    "report_id": 401804,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401929,
      401949,
      401977,
      401979,
      401994,
      402012,
      402057,
      402059,
      402070,
      402090,
      402109,
      402137,
      402165,
      402167,
      402172,
      402180,
      402191,
      402210,
      402269,
      402272,
      402402,
      402414,
      402420,
      402453,
      402475,
      402476,
      402513,
      402521,
      402530,
      402563,
      402578,
      402589,
      402631,
      402672,
      402676,
      402683,
      402688,
      402689,
      402695,
      402703,
      402719,
      402722,
      402735,
      402748,
      402750,
      402881,
      402892,
      402936,
      402957,
      403018,
      403034,
      403076,
      403099,
      403102,
      403112,
      403120,
      403126,
      403143,
      403183,
      403187,
      403194,
      403202,
      403204,
      403209,
      403220,
      403233,
      403284,
      403298,
      403329,
      403375,
      403411,
      403416,
      403420,
      403422,
      403435,
      403530,
      403535,
      403576,
      403582,
      403606,
      403611,
      403625,
      403627,
      403643,
      403682,
      403691,
      403694,
      403741,
      403773
    ]
  },
  "401805": {
    "id": 401805,
    "type": "district",
    "name": "ST VRAIN VALLEY RE 1J",
    "completed": 1144,
    "total": 2108,
    "report_id": 401805,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401956,
      401984,
      402007,
      402031,
      402076,
      402087,
      402139,
      402200,
      402220,
      402262,
      402268,
      402306,
      402329,
      402338,
      402343,
      402383,
      402468,
      402517,
      402540,
      402580,
      402582,
      402648,
      402664,
      402675,
      402709,
      402721,
      402729,
      402849,
      402911,
      402917,
      402963,
      402984,
      402985,
      403014,
      403022,
      403036,
      403086,
      403123,
      403140,
      403197,
      403198,
      403265,
      403276,
      403320,
      403324,
      403443,
      403518,
      403529,
      403654,
      403684,
      403716,
      403834
    ]
  },
  "401806": {
    "id": 401806,
    "type": "district",
    "name": "WIGGINS RE-50(J)",
    "completed": 5,
    "total": 50,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401920,
      403075,
      403504
    ]
  },
  "401807": {
    "id": 401807,
    "type": "district",
    "name": "DELTA COUNTY 50(J)",
    "completed": 296,
    "total": 310,
    "report_id": 401807,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401925,
      401970,
      402201,
      402242,
      402309,
      402480,
      402714,
      402868,
      402878,
      403001,
      403040,
      403192,
      403292,
      403357,
      403494,
      403624,
      403766
    ]
  },
  "401808": {
    "id": 401808,
    "type": "district",
    "name": "ASPEN 1",
    "completed": 167,
    "total": 187,
    "report_id": 401808,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402446,
      403526,
      403615,
      403628,
      403699
    ]
  },
  "401809": {
    "id": 401809,
    "type": "district",
    "name": "SOUTH ROUTT RE 3",
    "completed": 14,
    "total": 42,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401995,
      402901,
      403228
    ]
  },
  "401810": {
    "id": 401810,
    "type": "district",
    "name": "CROWLEY COUNTY RE-1-J",
    "completed": 9,
    "total": 36,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402467,
      402652
    ]
  },
  "401811": {
    "id": 401811,
    "type": "district",
    "name": "SUMMIT RE-1",
    "completed": 247,
    "total": 317,
    "report_id": 401811,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402022,
      402042,
      402491,
      402617,
      402679,
      402779,
      403113,
      403341,
      403472
    ]
  },
  "401812": {
    "id": 401812,
    "type": "district",
    "name": "CREEDE SCHOOL DISTRICT",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401992
    ]
  },
  "401813": {
    "id": 401813,
    "type": "district",
    "name": "CRIPPLE CREEK-VICTOR RE-1",
    "completed": 13,
    "total": 40,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403135,
      403446
    ]
  },
  "401814": {
    "id": 401814,
    "type": "district",
    "name": "HAYDEN RE-1",
    "completed": 33,
    "total": 41,
    "report_id": 401814,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402287,
      403334,
      403541
    ]
  },
  "401815": {
    "id": 401815,
    "type": "district",
    "name": "LIMON RE-4J",
    "completed": 15,
    "total": 44,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402092,
      403196
    ]
  },
  "401816": {
    "id": 401816,
    "type": "district",
    "name": "CANON CITY RE-1",
    "completed": 214,
    "total": 281,
    "report_id": 401816,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401980,
      402132,
      402266,
      402342,
      403394,
      403560,
      403567,
      403610
    ]
  },
  "401817": {
    "id": 401817,
    "type": "district",
    "name": "ROCKY FORD R-2",
    "completed": 42,
    "total": 65,
    "report_id": 401817,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402621,
      402704,
      403062
    ]
  },
  "401818": {
    "id": 401818,
    "type": "district",
    "name": "WINDSOR RE-4",
    "completed": 279,
    "total": 400,
    "report_id": 401818,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401964,
      402017,
      402033,
      402038,
      402071,
      402170,
      402616,
      402846,
      402928,
      403715
    ]
  },
  "401819": {
    "id": 401819,
    "type": "district",
    "name": "JULESBURG RE-1",
    "completed": 3,
    "total": 48,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402601,
      402711,
      402969
    ]
  },
  "401820": {
    "id": 401820,
    "type": "district",
    "name": "BOULDER VALLEY RE 2",
    "completed": 1686,
    "total": 2440,
    "report_id": 401820,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401908,
      401985,
      402014,
      402044,
      402047,
      402077,
      402078,
      402115,
      402127,
      402145,
      402185,
      402241,
      402248,
      402292,
      402296,
      402344,
      402378,
      402395,
      402439,
      402442,
      402459,
      402477,
      402496,
      402522,
      402555,
      402657,
      402677,
      402685,
      402686,
      402713,
      402758,
      402831,
      402874,
      402876,
      402883,
      402898,
      402900,
      402916,
      402938,
      403026,
      403087,
      403111,
      403173,
      403176,
      403190,
      403231,
      403282,
      403330,
      403370,
      403407,
      403452,
      403467,
      403468,
      403478,
      403632,
      403676,
      403696,
      403827,
      403828,
      403829
    ]
  },
  "401821": {
    "id": 401821,
    "type": "district",
    "name": "TELLURIDE R-1",
    "completed": 11,
    "total": 123,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402055,
      402571,
      402622,
      402653
    ]
  },
  "401822": {
    "id": 401822,
    "type": "district",
    "name": "PLAINVIEW RE-2",
    "completed": 12,
    "total": 12,
    "report_id": 401822,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403070,
      403349
    ]
  },
  "401823": {
    "id": 401823,
    "type": "district",
    "name": "ADAMS 12 FIVE STAR SCHOOLS",
    "completed": 1440,
    "total": 2612,
    "report_id": 401823,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401943,
      401945,
      401959,
      402001,
      402015,
      402041,
      402074,
      402088,
      402102,
      402106,
      402129,
      402131,
      402160,
      402274,
      402326,
      402370,
      402416,
      402424,
      402443,
      402450,
      402507,
      402566,
      402581,
      402650,
      402718,
      402764,
      402828,
      402856,
      402897,
      402927,
      402980,
      403023,
      403050,
      403053,
      403054,
      403092,
      403107,
      403149,
      403165,
      403268,
      403307,
      403346,
      403369,
      403427,
      403463,
      403522,
      403551,
      403604,
      403690,
      403739,
      403750
    ]
  },
  "401824": {
    "id": 401824,
    "type": "district",
    "name": "BUFFALO RE-4J",
    "completed": 24,
    "total": 28,
    "report_id": 401824,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403160,
      403393
    ]
  },
  "401825": {
    "id": 401825,
    "type": "district",
    "name": "HUERFANO RE-1",
    "completed": 34,
    "total": 56,
    "report_id": 401825,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402163,
      402320,
      402514
    ]
  },
  "401826": {
    "id": 401826,
    "type": "district",
    "name": "GILPIN COUNTY RE-1",
    "completed": 5,
    "total": 44,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402633,
      403244
    ]
  },
  "401827": {
    "id": 401827,
    "type": "district",
    "name": "LITTLETON 6",
    "completed": 726,
    "total": 1068,
    "report_id": 401827,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401938,
      401941,
      401999,
      402005,
      402083,
      402188,
      402577,
      402610,
      402660,
      402716,
      402731,
      402977,
      403127,
      403174,
      403333,
      403401,
      403433,
      403491,
      403616,
      403619,
      403623,
      403644,
      403680,
      403822,
      403823
    ]
  },
  "401828": {
    "id": 401828,
    "type": "district",
    "name": "BURLINGTON RE-6J",
    "completed": 14,
    "total": 56,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402032,
      403243,
      403722
    ]
  },
  "401829": {
    "id": 401829,
    "type": "district",
    "name": "SIERRA GRANDE R-30",
    "completed": 17,
    "total": 35,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402552
    ]
  },
  "401830": {
    "id": 401830,
    "type": "district",
    "name": "MOFFAT 2",
    "completed": 33,
    "total": 33,
    "report_id": 401830,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402495,
      403719
    ]
  },
  "401831": {
    "id": 401831,
    "type": "district",
    "name": "HI-PLAINS R-23",
    "completed": 11,
    "total": 18,
    "report_id": 401831,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401976
    ]
  },
  "401832": {
    "id": 401832,
    "type": "district",
    "name": "PUEBLO CITY 60",
    "completed": 964,
    "total": 1118,
    "report_id": 401832,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401911,
      401965,
      402061,
      402116,
      402171,
      402209,
      402346,
      402425,
      402438,
      402509,
      402534,
      402562,
      402606,
      402701,
      402739,
      402745,
      402765,
      402877,
      403002,
      403033,
      403082,
      403368,
      403376,
      403392,
      403475,
      403500,
      403505,
      403523,
      403531,
      403603,
      403662,
      403687,
      403725
    ]
  },
  "401833": {
    "id": 401833,
    "type": "district",
    "name": "FOWLER R-4J",
    "completed": 15,
    "total": 39,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402030,
      403613
    ]
  },
  "401834": {
    "id": 401834,
    "type": "district",
    "name": "BIG SANDY 100J",
    "completed": 0,
    "total": 48,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401952,
      402183,
      403015
    ]
  },
  "401835": {
    "id": 401835,
    "type": "district",
    "name": "LONE STAR 101",
    "completed": 3,
    "total": 16,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403355
    ]
  },
  "401836": {
    "id": 401836,
    "type": "district",
    "name": "DENVER COUNTY 1",
    "completed": 978,
    "total": 8040,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401918,
      401924,
      401946,
      401947,
      401957,
      401958,
      401960,
      401971,
      401974,
      401983,
      402002,
      402006,
      402011,
      402019,
      402021,
      402026,
      402027,
      402028,
      402035,
      402067,
      402068,
      402107,
      402112,
      402113,
      402124,
      402134,
      402136,
      402142,
      402146,
      402168,
      402189,
      402197,
      402198,
      402202,
      402216,
      402217,
      402219,
      402222,
      402229,
      402231,
      402235,
      402237,
      402247,
      402259,
      402282,
      402285,
      402286,
      402290,
      402297,
      402313,
      402318,
      402324,
      402327,
      402364,
      402369,
      402376,
      402379,
      402380,
      402394,
      402397,
      402408,
      402432,
      402435,
      402444,
      402465,
      402473,
      402488,
      402498,
      402508,
      402523,
      402547,
      402557,
      402559,
      402565,
      402570,
      402574,
      402583,
      402586,
      402591,
      402623,
      402638,
      402643,
      402645,
      402646,
      402752,
      402767,
      402769,
      402788,
      402798,
      402818,
      402823,
      402830,
      402840,
      402842,
      402844,
      402848,
      402858,
      402863,
      402871,
      402879,
      402882,
      402896,
      402905,
      402907,
      402932,
      402943,
      402947,
      402966,
      402970,
      402975,
      402986,
      402993,
      402996,
      402997,
      403005,
      403028,
      403030,
      403039,
      403046,
      403059,
      403072,
      403080,
      403084,
      403088,
      403091,
      403101,
      403125,
      403138,
      403144,
      403164,
      403167,
      403199,
      403203,
      403216,
      403229,
      403238,
      403242,
      403247,
      403252,
      403264,
      403269,
      403273,
      403277,
      403285,
      403287,
      403290,
      403295,
      403311,
      403312,
      403313,
      403317,
      403339,
      403344,
      403350,
      403352,
      403372,
      403389,
      403396,
      403403,
      403423,
      403424,
      403428,
      403438,
      403445,
      403449,
      403451,
      403469,
      403476,
      403502,
      403517,
      403532,
      403538,
      403543,
      403550,
      403552,
      403553,
      403568,
      403570,
      403572,
      403578,
      403597,
      403608,
      403612,
      403638,
      403645,
      403647,
      403666,
      403671,
      403675,
      403705,
      403706,
      403710,
      403718,
      403726,
      403729,
      403730,
      403733,
      403738,
      403747,
      403749,
      403752,
      403756,
      403763,
      403765,
      403772
    ]
  },
  "401837": {
    "id": 401837,
    "type": "district",
    "name": "SHERIDAN 2",
    "completed": 104,
    "total": 116,
    "report_id": 401837,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401932,
      402539,
      402665,
      403335,
      403674
    ]
  },
  "401838": {
    "id": 401838,
    "type": "district",
    "name": "PLATTE VALLEY RE-7",
    "completed": 74,
    "total": 91,
    "report_id": 401838,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402273,
      402466,
      403510
    ]
  },
  "401839": {
    "id": 401839,
    "type": "district",
    "name": "REVERE SCHOOL DISTRICT",
    "completed": 4,
    "total": 22,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403163,
      403695
    ]
  },
  "401840": {
    "id": 401840,
    "type": "district",
    "name": "PLATEAU RE-5",
    "completed": 18,
    "total": 21,
    "report_id": 401840,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402864,
      403620
    ]
  },
  "401841": {
    "id": 401841,
    "type": "district",
    "name": "SARGENT RE-33J",
    "completed": 10,
    "total": 34,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402363,
      402791,
      403348
    ]
  },
  "401842": {
    "id": 401842,
    "type": "district",
    "name": "MOUNTAIN VALLEY RE 1",
    "completed": 16,
    "total": 19,
    "report_id": 401842,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402431
    ]
  },
  "401843": {
    "id": 401843,
    "type": "district",
    "name": "AGUILAR REORGANIZED 6",
    "completed": 13,
    "total": 14,
    "report_id": 401843,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402430,
      403185
    ]
  },
  "401844": {
    "id": 401844,
    "type": "district",
    "name": "WOODLAND PARK RE-2",
    "completed": 14,
    "total": 250,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402243,
      402886,
      403089,
      403362,
      403448
    ]
  },
  "401845": {
    "id": 401845,
    "type": "district",
    "name": "CHEYENNE COUNTY RE-5",
    "completed": 3,
    "total": 25,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402029,
      403364
    ]
  },
  "401846": {
    "id": 401846,
    "type": "district",
    "name": "STEAMBOAT SPRINGS RE-2",
    "completed": 165,
    "total": 210,
    "report_id": 401846,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402140,
      402175,
      402409,
      402512,
      402682,
      403539,
      403698
    ]
  },
  "401847": {
    "id": 401847,
    "type": "district",
    "name": "VILAS RE-5",
    "completed": 13,
    "total": 13,
    "report_id": 401847,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403065,
      403280
    ]
  },
  "401848": {
    "id": 401848,
    "type": "district",
    "name": "HOLLY RE-3",
    "completed": 14,
    "total": 30,
    "report_id": 401848,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402095,
      403213,
      403466
    ]
  },
  "401849": {
    "id": 401849,
    "type": "district",
    "name": "ROARING FORK RE-1",
    "completed": 400,
    "total": 510,
    "report_id": 401849,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402045,
      402111,
      402352,
      402554,
      402644,
      402684,
      402845,
      402872,
      402992,
      403367,
      403371,
      403429,
      403599
    ]
  },
  "401850": {
    "id": 401850,
    "type": "district",
    "name": "SANGRE DE CRISTO RE-22J",
    "completed": 13,
    "total": 37,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402469,
      402661
    ]
  },
  "401851": {
    "id": 401851,
    "type": "district",
    "name": "FALCON 49",
    "completed": 289,
    "total": 1260,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402013,
      402025,
      402065,
      402121,
      402122,
      402152,
      402246,
      402252,
      402354,
      402405,
      402452,
      402454,
      402533,
      402538,
      402771,
      402921,
      402972,
      402991,
      402998,
      403000,
      403147,
      403288,
      403304,
      403323,
      403489
    ]
  },
  "401852": {
    "id": 401852,
    "type": "district",
    "name": "HOEHNE REORGANIZED 3",
    "completed": 22,
    "total": 23,
    "report_id": 401852,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403399
    ]
  },
  "401853": {
    "id": 401853,
    "type": "district",
    "name": "PRIMERO REORGANIZED 2",
    "completed": 10,
    "total": 30,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402384,
      402506
    ]
  },
  "401854": {
    "id": 401854,
    "type": "district",
    "name": "IDALIA RJ-3",
    "completed": 7,
    "total": 25,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402579,
      402867
    ]
  },
  "401855": {
    "id": 401855,
    "type": "district",
    "name": "PRAIRIE RE-11",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402295,
      403697
    ]
  },
  "401856": {
    "id": 401856,
    "type": "district",
    "name": "CLEAR CREEK RE-1",
    "completed": 10,
    "total": 78,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402815,
      402995,
      403045,
      403172,
      403471
    ]
  },
  "401857": {
    "id": 401857,
    "type": "district",
    "name": "DOLORES COUNTY RE NO.2",
    "completed": 8,
    "total": 26,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402560,
      402776,
      403473
    ]
  },
  "401858": {
    "id": 401858,
    "type": "district",
    "name": "SALIDA R-32",
    "completed": 52,
    "total": 116,
    "report_id": 401858,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401936,
      402020,
      402315,
      402463,
      402827,
      403130
    ]
  },
  "401859": {
    "id": 401859,
    "type": "district",
    "name": "CENTENNIAL BOCES",
    "completed": 9,
    "total": 13,
    "report_id": 401859,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402624,
      403381
    ]
  },
  "401860": {
    "id": 401860,
    "type": "district",
    "name": "KARVAL RE-23",
    "completed": 1,
    "total": 13,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402360,
      402388
    ]
  },
  "401861": {
    "id": 401861,
    "type": "district",
    "name": "OTIS R-3",
    "completed": 23,
    "total": 24,
    "report_id": 401861,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402427,
      402956
    ]
  },
  "401862": {
    "id": 401862,
    "type": "district",
    "name": "THOMPSON R2-J",
    "completed": 1039,
    "total": 1349,
    "report_id": 401862,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401951,
      401961,
      401982,
      402000,
      402053,
      402101,
      402103,
      402169,
      402184,
      402291,
      402301,
      402311,
      402387,
      402429,
      402472,
      402614,
      402749,
      402850,
      402866,
      402949,
      402953,
      402968,
      403008,
      403037,
      403291,
      403345,
      403373,
      403404,
      403536,
      403558,
      403596,
      403614,
      403630,
      403651,
      403723
    ]
  },
  "401863": {
    "id": 401863,
    "type": "district",
    "name": "PUEBLO COUNTY 70",
    "completed": 197,
    "total": 680,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401919,
      401926,
      401942,
      401989,
      402190,
      402238,
      402359,
      402502,
      402505,
      402518,
      402602,
      402627,
      402747,
      402829,
      402912,
      402952,
      403108,
      403210,
      403308,
      403397,
      403495,
      403589,
      403740,
      403770
    ]
  },
  "401864": {
    "id": 401864,
    "type": "district",
    "name": "RIDGWAY R-2",
    "completed": 16,
    "total": 55,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402481,
      403012,
      403621
    ]
  },
  "401865": {
    "id": 401865,
    "type": "district",
    "name": "WEST END RE-2",
    "completed": 5,
    "total": 87,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402553,
      402592,
      403175,
      403727
    ]
  },
  "401866": {
    "id": 401866,
    "type": "district",
    "name": "PAWNEE RE-12",
    "completed": 2,
    "total": 14,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402824
    ]
  },
  "401867": {
    "id": 401867,
    "type": "district",
    "name": "CHARTER SCHOOL INSTITUTE",
    "completed": 227,
    "total": 1621,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401931,
      401954,
      401978,
      402003,
      402039,
      402058,
      402066,
      402080,
      402098,
      402153,
      402278,
      402322,
      402336,
      402445,
      402503,
      402671,
      402696,
      402756,
      402820,
      402826,
      402890,
      402981,
      403011,
      403019,
      403049,
      403058,
      403067,
      403074,
      403095,
      403222,
      403318,
      403347,
      403354,
      403402,
      403460,
      403477,
      403521,
      403537,
      403574,
      403688,
      403721,
      403744
    ]
  },
  "401868": {
    "id": 401868,
    "type": "district",
    "name": "EATON RE-2",
    "completed": 63,
    "total": 138,
    "report_id": 401868,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402228,
      402323,
      402692,
      403507,
      403583
    ]
  },
  "401869": {
    "id": 401869,
    "type": "district",
    "name": "DEL NORTE C-7",
    "completed": 12,
    "total": 43,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402802,
      403200
    ]
  },
  "401870": {
    "id": 401870,
    "type": "district",
    "name": "CAMPO RE-6",
    "completed": 9,
    "total": 20,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403481,
      403649
    ]
  },
  "401871": {
    "id": 401871,
    "type": "district",
    "name": "EXPEDITIONARY BOCES",
    "completed": 29,
    "total": 33,
    "report_id": 401871,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402678
    ]
  },
  "401872": {
    "id": 401872,
    "type": "district",
    "name": "ALAMOSA RE-11J",
    "completed": 89,
    "total": 189,
    "report_id": 401872,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402089,
      402224,
      402381,
      403038
    ]
  },
  "401873": {
    "id": 401873,
    "type": "district",
    "name": "ARICKAREE R-2",
    "completed": 1,
    "total": 22,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402746,
      403156
    ]
  },
  "401874": {
    "id": 401874,
    "type": "district",
    "name": "WIDEFIELD 3",
    "completed": 425,
    "total": 630,
    "report_id": 401874,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402082,
      402097,
      402104,
      402421,
      402603,
      402727,
      402751,
      402865,
      402983,
      403025,
      403063,
      403098,
      403299,
      403366,
      403380,
      403464,
      403571,
      403652
    ]
  },
  "401875": {
    "id": 401875,
    "type": "district",
    "name": "FREMONT RE-2",
    "completed": 15,
    "total": 102,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402148,
      402708,
      402833,
      403709
    ]
  },
  "401876": {
    "id": 401876,
    "type": "district",
    "name": "WELDON VALLEY RE-20(J)",
    "completed": 3,
    "total": 25,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402023,
      403083
    ]
  },
  "401877": {
    "id": 401877,
    "type": "district",
    "name": "LIBERTY J-4",
    "completed": 1,
    "total": 10,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402705
    ]
  },
  "401878": {
    "id": 401878,
    "type": "district",
    "name": "VALLEY RE-1",
    "completed": 133,
    "total": 199,
    "report_id": 401878,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402161,
      402234,
      402250,
      402584,
      402595,
      402873,
      402888
    ]
  },
  "401879": {
    "id": 401879,
    "type": "district",
    "name": "HARRISON 2",
    "completed": 132,
    "total": 898,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401988,
      402156,
      402293,
      402337,
      402400,
      402558,
      402795,
      402806,
      402825,
      402834,
      402899,
      402945,
      402973,
      402994,
      403006,
      403027,
      403128,
      403180,
      403218,
      403262,
      403302,
      403310,
      403386,
      403470,
      403575,
      403767
    ]
  },
  "401880": {
    "id": 401880,
    "type": "district",
    "name": "MC CLAVE RE-2",
    "completed": 17,
    "total": 34,
    "report_id": 401880,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402310,
      403700
    ]
  },
  "401881": {
    "id": 401881,
    "type": "district",
    "name": "SPRINGFIELD RE-4",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402857,
      403418
    ]
  },
  "401882": {
    "id": 401882,
    "type": "district",
    "name": "MONTE VISTA C-8",
    "completed": 89,
    "total": 113,
    "report_id": 401882,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402063,
      402213,
      402613,
      402884,
      402990,
      403490
    ]
  },
  "401883": {
    "id": 401883,
    "type": "district",
    "name": "ARCHULETA COUNTY 50 JT",
    "completed": 40,
    "total": 113,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402193,
      402253,
      403545
    ]
  },
  "401884": {
    "id": 401884,
    "type": "district",
    "name": "KIM REORGANIZED 88",
    "completed": 12,
    "total": 12,
    "report_id": 401884,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402479,
      403524
    ]
  },
  "401885": {
    "id": 401885,
    "type": "district",
    "name": "HOLYOKE RE-1J",
    "completed": 5,
    "total": 53,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402283,
      403094,
      403607
    ]
  },
  "401886": {
    "id": 401886,
    "type": "district",
    "name": "YUMA 1",
    "completed": 59,
    "total": 71,
    "report_id": 401886,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402723,
      403440,
      403497
    ]
  },
  "401887": {
    "id": 401887,
    "type": "district",
    "name": "ESTES PARK R-3",
    "completed": 73,
    "total": 115,
    "report_id": 401887,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402048,
      403212,
      403300,
      403701
    ]
  },
  "401888": {
    "id": 401888,
    "type": "district",
    "name": "DOLORES RE-4A",
    "completed": 28,
    "total": 62,
    "report_id": 401888,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402426,
      402918,
      403117
    ]
  },
  "401889": {
    "id": 401889,
    "type": "district",
    "name": "DURANGO 9-R",
    "completed": 327,
    "total": 542,
    "report_id": 401889,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402203,
      402215,
      402288,
      402305,
      402516,
      402604,
      402906,
      402937,
      402960,
      403208,
      403296,
      403457,
      403501,
      403515
    ]
  },
  "401890": {
    "id": 401890,
    "type": "district",
    "name": "ACADEMY 20",
    "completed": 359,
    "total": 1900,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401993,
      402009,
      402069,
      402086,
      402091,
      402117,
      402192,
      402232,
      402276,
      402319,
      402371,
      402461,
      402575,
      402634,
      402659,
      402681,
      402728,
      402753,
      402757,
      402775,
      402930,
      402959,
      403004,
      403021,
      403093,
      403118,
      403171,
      403255,
      403337,
      403441,
      403557,
      403617,
      403660,
      403683,
      403708,
      403720
    ]
  },
  "401891": {
    "id": 401891,
    "type": "district",
    "name": "MAPLETON 1",
    "completed": 382,
    "total": 503,
    "report_id": 401891,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401968,
      402064,
      402141,
      402626,
      402628,
      402641,
      402773,
      402782,
      402787,
      403162,
      403240,
      403261,
      403279,
      403343,
      403534,
      403595,
      403734,
      403760
    ]
  },
  "401892": {
    "id": 401892,
    "type": "district",
    "name": "NORWOOD R-2J",
    "completed": 21,
    "total": 28,
    "report_id": 401892,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403605
    ]
  },
  "401893": {
    "id": 401893,
    "type": "district",
    "name": "GREELEY 6",
    "completed": 526,
    "total": 1378,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401969,
      401975,
      402010,
      402181,
      402245,
      402281,
      402356,
      402406,
      402410,
      402531,
      402536,
      402655,
      402670,
      402761,
      402792,
      402812,
      402926,
      402962,
      402999,
      403035,
      403060,
      403090,
      403195,
      403278,
      403305,
      403314,
      403398,
      403414,
      403454,
      403493,
      403717
    ]
  },
  "401894": {
    "id": 401894,
    "type": "district",
    "name": "CENTENNIAL R-1",
    "completed": 42,
    "total": 45,
    "report_id": 401894,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402486
    ]
  },
  "401895": {
    "id": 401895,
    "type": "district",
    "name": "ADAMS COUNTY 14",
    "completed": 417,
    "total": 508,
    "report_id": 401895,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402312,
      402404,
      402607,
      402800,
      402810,
      402987,
      403219,
      403254,
      403338,
      403359,
      403499,
      403508,
      403592
    ]
  },
  "401896": {
    "id": 401896,
    "type": "district",
    "name": "ADAMS-ARAPAHOE 28J",
    "completed": 2127,
    "total": 2746,
    "report_id": 401896,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401912,
      401967,
      402062,
      402119,
      402120,
      402157,
      402164,
      402177,
      402199,
      402214,
      402257,
      402260,
      402277,
      402289,
      402334,
      402351,
      402403,
      402407,
      402428,
      402440,
      402460,
      402499,
      402510,
      402515,
      402564,
      402625,
      402639,
      402642,
      402662,
      402674,
      402702,
      402734,
      402737,
      402768,
      402811,
      402832,
      402835,
      402843,
      402894,
      402961,
      403003,
      403016,
      403041,
      403042,
      403061,
      403114,
      403119,
      403152,
      403184,
      403232,
      403260,
      403272,
      403283,
      403388,
      403426,
      403546,
      403590,
      403601,
      403626,
      403658,
      403685,
      403711,
      403764
    ]
  },
  "401897": {
    "id": 401897,
    "type": "district",
    "name": "PLATTE CANYON 1",
    "completed": 64,
    "total": 70,
    "report_id": 401897,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402413,
      402568,
      403363
    ]
  },
  "401898": {
    "id": 401898,
    "type": "district",
    "name": "MANITOU SPRINGS 14",
    "completed": 22,
    "total": 123,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402176,
      402651,
      403566,
      403577
    ]
  },
  "401899": {
    "id": 401899,
    "type": "district",
    "name": "WEST GRAND 1-JT",
    "completed": 29,
    "total": 45,
    "report_id": 401899,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402796,
      403358
    ]
  },
  "401900": {
    "id": 401900,
    "type": "district",
    "name": "BENNETT 29J",
    "completed": 40,
    "total": 74,
    "report_id": 401900,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402349,
      403360,
      403439
    ]
  },
  "401901": {
    "id": 401901,
    "type": "district",
    "name": "HINSDALE COUNTY RE 1",
    "completed": 18,
    "total": 18,
    "report_id": 401901,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403549
    ]
  },
  "401902": {
    "id": 401902,
    "type": "district",
    "name": "WELD COUNTY RE-1",
    "completed": 127,
    "total": 182,
    "report_id": 401902,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401914,
      402221,
      402742,
      403263,
      403377,
      403762
    ]
  },
  "401903": {
    "id": 401903,
    "type": "district",
    "name": "NORTH CONEJOS RE-1J",
    "completed": 23,
    "total": 71,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402018,
      402423,
      402814,
      402887,
      403540
    ]
  },
  "401904": {
    "id": 401904,
    "type": "district",
    "name": "CUSTER COUNTY SCHOOL DISTRICT C-1",
    "completed": 20,
    "total": 48,
    "report_id": 401904,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402125,
      403667,
      403732
    ]
  },
  "401905": {
    "id": 401905,
    "type": "district",
    "name": "BYERS 32J",
    "completed": 6,
    "total": 172,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      402598,
      402640,
      402861,
      402875,
      403132,
      403492,
      403737
    ]
  },
  "401906": {
    "id": 401906,
    "type": "district",
    "name": "SAN JUAN BOCES",
    "completed": 2,
    "total": 5,
    "report_id": null,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      403193
    ]
  },
  "401907": {
    "id": 401907,
    "type": "district",
    "name": "MESA COUNTY VALLEY 51",
    "completed": 1278,
    "total": 1606,
    "report_id": 401907,
    "children_type": "school",
    "parent_type": "client",
    "parent_id": 401722,
    "children_ids": [
      401917,
      401922,
      402043,
      402138,
      402162,
      402307,
      402325,
      402389,
      402485,
      402567,
      402596,
      402619,
      402780,
      402789,
      402880,
      402925,
      402931,
      402964,
      403047,
      403073,
      403096,
      403158,
      403159,
      403266,
      403275,
      403289,
      403342,
      403387,
      403409,
      403437,
      403564,
      403581,
      403586,
      403587,
      403622,
      403653,
      403656,
      403657,
      403664,
      403669,
      403679,
      403693,
      403768,
      403831,
      403832,
      403833
    ]
  },
  "401908": {
    "id": 401908,
    "type": "school",
    "name": "MONARCH HIGH SCHOOL",
    "completed": 64,
    "total": 104,
    "report_id": 401908,
    "parent_type": "district",
    "parent_id": 401820
  },
  "401909": {
    "id": 401909,
    "type": "school",
    "name": "SKYWAY PARK ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 27,
    "report_id": 401909,
    "parent_type": "district",
    "parent_id": 401769
  },
  "401910": {
    "id": 401910,
    "type": "school",
    "name": "ARVADA K-8",
    "completed": 47,
    "total": 65,
    "report_id": 401910,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401911": {
    "id": 401911,
    "type": "school",
    "name": "HIGHLAND PARK ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 32,
    "report_id": 401911,
    "parent_type": "district",
    "parent_id": 401832
  },
  "401912": {
    "id": 401912,
    "type": "school",
    "name": "DARTMOUTH ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 27,
    "report_id": 401912,
    "parent_type": "district",
    "parent_id": 401896
  },
  "401913": {
    "id": 401913,
    "type": "school",
    "name": "SHERIDAN GREEN ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 28,
    "report_id": 401913,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401914": {
    "id": 401914,
    "type": "school",
    "name": "GILCREST ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 22,
    "report_id": 401914,
    "parent_type": "district",
    "parent_id": 401902
  },
  "401915": {
    "id": 401915,
    "type": "school",
    "name": "ROSE STEIN INTERNATIONAL ELEMENTARY",
    "completed": 18,
    "total": 18,
    "report_id": 401915,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401916": {
    "id": 401916,
    "type": "school",
    "name": "POUDRE HIGH SCHOOL",
    "completed": 111,
    "total": 128,
    "report_id": 401916,
    "parent_type": "district",
    "parent_id": 401768
  },
  "401917": {
    "id": 401917,
    "type": "school",
    "name": "GATEWAY SCHOOL",
    "completed": 6,
    "total": 6,
    "report_id": 401917,
    "parent_type": "district",
    "parent_id": 401907
  },
  "401918": {
    "id": 401918,
    "type": "school",
    "name": "P.R.E.P. (POSITIVE REFOCUS EDUCATION PROGRAM)",
    "completed": 0,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401919": {
    "id": 401919,
    "type": "school",
    "name": "SWALLOWS CHARTER ACADEMY",
    "completed": 7,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "401920": {
    "id": 401920,
    "type": "school",
    "name": "WIGGINS MIDDLE SCHOOL",
    "completed": 0,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401806
  },
  "401921": {
    "id": 401921,
    "type": "school",
    "name": "LAKE COUNTY EARLY COLLEGE",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401797
  },
  "401922": {
    "id": 401922,
    "type": "school",
    "name": "BOOKCLIFF MIDDLE SCHOOL",
    "completed": 46,
    "total": 46,
    "report_id": 401922,
    "parent_type": "district",
    "parent_id": 401907
  },
  "401923": {
    "id": 401923,
    "type": "school",
    "name": "KEN CARYL MIDDLE SCHOOL",
    "completed": 39,
    "total": 48,
    "report_id": 401923,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401924": {
    "id": 401924,
    "type": "school",
    "name": "INSPIRE ELEMENTARY ",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401925": {
    "id": 401925,
    "type": "school",
    "name": "PAONIA HIGH SCHOOL",
    "completed": 15,
    "total": 15,
    "report_id": 401925,
    "parent_type": "district",
    "parent_id": 401807
  },
  "401926": {
    "id": 401926,
    "type": "school",
    "name": "SOUTH MESA ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "401927": {
    "id": 401927,
    "type": "school",
    "name": "ZACH ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 37,
    "report_id": 401927,
    "parent_type": "district",
    "parent_id": 401768
  },
  "401928": {
    "id": 401928,
    "type": "school",
    "name": "DRAKE JUNIOR HIGH SCHOOL",
    "completed": 9,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401929": {
    "id": 401929,
    "type": "school",
    "name": "LEGACY POINT ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 31,
    "report_id": 401929,
    "parent_type": "district",
    "parent_id": 401804
  },
  "401930": {
    "id": 401930,
    "type": "school",
    "name": "ECKHART ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401732
  },
  "401931": {
    "id": 401931,
    "type": "school",
    "name": "MONUMENT VIEW MONTESSORI CHARTER SCHOOL",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "401932": {
    "id": 401932,
    "type": "school",
    "name": "SOAR ACADEMY",
    "completed": 5,
    "total": 8,
    "report_id": 401932,
    "parent_type": "district",
    "parent_id": 401837
  },
  "401933": {
    "id": 401933,
    "type": "school",
    "name": "EDISON ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401738
  },
  "401934": {
    "id": 401934,
    "type": "school",
    "name": "FALCON BLUFFS MIDDLE SCHOOL",
    "completed": 10,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401935": {
    "id": 401935,
    "type": "school",
    "name": "GENOA-HUGO SCHOOL",
    "completed": 12,
    "total": 18,
    "report_id": 401935,
    "parent_type": "district",
    "parent_id": 401765
  },
  "401936": {
    "id": 401936,
    "type": "school",
    "name": "CREST ACADEMY",
    "completed": 2,
    "total": 3,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401858
  },
  "401937": {
    "id": 401937,
    "type": "school",
    "name": "GOVERNOR'S RANCH ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401938": {
    "id": 401938,
    "type": "school",
    "name": "JOHN WESLEY POWELL MIDDLE SCHOOL",
    "completed": 56,
    "total": 59,
    "report_id": 401938,
    "parent_type": "district",
    "parent_id": 401827
  },
  "401939": {
    "id": 401939,
    "type": "school",
    "name": "BETHKE ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 39,
    "report_id": 401939,
    "parent_type": "district",
    "parent_id": 401768
  },
  "401940": {
    "id": 401940,
    "type": "school",
    "name": "HARRY L MC GINNIS MIDDLE SCHOOL",
    "completed": 11,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401788
  },
  "401941": {
    "id": 401941,
    "type": "school",
    "name": "MOODY ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 30,
    "report_id": 401941,
    "parent_type": "district",
    "parent_id": 401827
  },
  "401942": {
    "id": 401942,
    "type": "school",
    "name": "THE CONNECT CHARTER SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "401943": {
    "id": 401943,
    "type": "school",
    "name": "HUNTERS GLEN ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 35,
    "report_id": 401943,
    "parent_type": "district",
    "parent_id": 401823
  },
  "401944": {
    "id": 401944,
    "type": "school",
    "name": "MESA ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 24,
    "report_id": 401944,
    "parent_type": "district",
    "parent_id": 401726
  },
  "401945": {
    "id": 401945,
    "type": "school",
    "name": "COTTON CREEK ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 38,
    "report_id": 401945,
    "parent_type": "district",
    "parent_id": 401823
  },
  "401946": {
    "id": 401946,
    "type": "school",
    "name": "DSST: BYERS HIGH SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401947": {
    "id": 401947,
    "type": "school",
    "name": "KEPNER MIDDLE SCHOOL",
    "completed": 0,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401948": {
    "id": 401948,
    "type": "school",
    "name": "TAVELLI ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401768
  },
  "401949": {
    "id": 401949,
    "type": "school",
    "name": "LONE TREE ELEMENTARY",
    "completed": 22,
    "total": 33,
    "report_id": 401949,
    "parent_type": "district",
    "parent_id": 401804
  },
  "401950": {
    "id": 401950,
    "type": "school",
    "name": "ASPEN CROSSING ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "401951": {
    "id": 401951,
    "type": "school",
    "name": "BERTHOUD ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 34,
    "report_id": 401951,
    "parent_type": "district",
    "parent_id": 401862
  },
  "401952": {
    "id": 401952,
    "type": "school",
    "name": "SIMLA ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401834
  },
  "401953": {
    "id": 401953,
    "type": "school",
    "name": "WILMORE DAVIS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 26,
    "report_id": 401953,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401954": {
    "id": 401954,
    "type": "school",
    "name": "HIGH POINT ACADEMY",
    "completed": 6,
    "total": 63,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "401955": {
    "id": 401955,
    "type": "school",
    "name": "SHAFFER ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 30,
    "report_id": 401955,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401956": {
    "id": 401956,
    "type": "school",
    "name": "THUNDER VALLEY PK-8",
    "completed": 34,
    "total": 62,
    "report_id": 401956,
    "parent_type": "district",
    "parent_id": 401805
  },
  "401957": {
    "id": 401957,
    "type": "school",
    "name": "GUST ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 57,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401958": {
    "id": 401958,
    "type": "school",
    "name": "DSST: CONSERVATORY GREEN MIDDLE SCHOOL",
    "completed": 0,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401959": {
    "id": 401959,
    "type": "school",
    "name": "MOUNTAIN RANGE HIGH SCHOOL",
    "completed": 58,
    "total": 109,
    "report_id": 401959,
    "parent_type": "district",
    "parent_id": 401823
  },
  "401960": {
    "id": 401960,
    "type": "school",
    "name": "COWELL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401961": {
    "id": 401961,
    "type": "school",
    "name": "STANSBERRY ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401862
  },
  "401962": {
    "id": 401962,
    "type": "school",
    "name": "STRATTON MIDDLE SCHOOL",
    "completed": 5,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401794
  },
  "401963": {
    "id": 401963,
    "type": "school",
    "name": "BRUSH MIDDLE SCHOOL",
    "completed": 21,
    "total": 21,
    "report_id": 401963,
    "parent_type": "district",
    "parent_id": 401772
  },
  "401964": {
    "id": 401964,
    "type": "school",
    "name": "WINDSOR HIGH SCHOOL",
    "completed": 58,
    "total": 86,
    "report_id": 401964,
    "parent_type": "district",
    "parent_id": 401818
  },
  "401965": {
    "id": 401965,
    "type": "school",
    "name": "CENTENNIAL HIGH SCHOOL",
    "completed": 65,
    "total": 68,
    "report_id": 401965,
    "parent_type": "district",
    "parent_id": 401832
  },
  "401966": {
    "id": 401966,
    "type": "school",
    "name": "JOHNSON ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 401966,
    "parent_type": "district",
    "parent_id": 401768
  },
  "401967": {
    "id": 401967,
    "type": "school",
    "name": "LYN KNOLL ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 21,
    "report_id": 401967,
    "parent_type": "district",
    "parent_id": 401896
  },
  "401968": {
    "id": 401968,
    "type": "school",
    "name": "BIG PICTURE COLLEGE AND CAREER ACADEMY",
    "completed": 9,
    "total": 9,
    "report_id": 401968,
    "parent_type": "district",
    "parent_id": 401891
  },
  "401969": {
    "id": 401969,
    "type": "school",
    "name": "FRANKLIN MIDDLE SCHOOL",
    "completed": 11,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "401970": {
    "id": 401970,
    "type": "school",
    "name": "DELTA HIGH SCHOOL",
    "completed": 33,
    "total": 35,
    "report_id": 401970,
    "parent_type": "district",
    "parent_id": 401807
  },
  "401971": {
    "id": 401971,
    "type": "school",
    "name": "STRIVE PREP - FEDERAL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401972": {
    "id": 401972,
    "type": "school",
    "name": "ROLLING HILLS ELEMENTARY SCHOOL",
    "completed": 40,
    "total": 46,
    "report_id": 401972,
    "parent_type": "district",
    "parent_id": 401773
  },
  "401973": {
    "id": 401973,
    "type": "school",
    "name": "EDWARDS ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "401974": {
    "id": 401974,
    "type": "school",
    "name": "GIRLS ATHLETIC LEADERSHIP SCHOOL MIDDLE SCHOOL",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401975": {
    "id": 401975,
    "type": "school",
    "name": "HEIMAN ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "401976": {
    "id": 401976,
    "type": "school",
    "name": "HI-PLAINS SCHOOL DISTRICT R-23",
    "completed": 11,
    "total": 18,
    "report_id": 401976,
    "parent_type": "district",
    "parent_id": 401831
  },
  "401977": {
    "id": 401977,
    "type": "school",
    "name": "ROCK RIDGE ELEMENTARY SCHOOL",
    "completed": 56,
    "total": 64,
    "report_id": 401977,
    "parent_type": "district",
    "parent_id": 401804
  },
  "401978": {
    "id": 401978,
    "type": "school",
    "name": "JAMES IRWIN CHARTER ACADEMY",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "401979": {
    "id": 401979,
    "type": "school",
    "name": "BUFFALO RIDGE ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 33,
    "report_id": 401979,
    "parent_type": "district",
    "parent_id": 401804
  },
  "401980": {
    "id": 401980,
    "type": "school",
    "name": "HARRISON SCHOOL",
    "completed": 57,
    "total": 57,
    "report_id": 401980,
    "parent_type": "district",
    "parent_id": 401816
  },
  "401981": {
    "id": 401981,
    "type": "school",
    "name": "RIDGEVIEW CLASSICAL CHARTER SCHOOLS",
    "completed": 0,
    "total": 59,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401768
  },
  "401982": {
    "id": 401982,
    "type": "school",
    "name": "CONRAD BALL MIDDLE SCHOOL",
    "completed": 39,
    "total": 52,
    "report_id": 401982,
    "parent_type": "district",
    "parent_id": 401862
  },
  "401983": {
    "id": 401983,
    "type": "school",
    "name": "MCAULIFFE INTERNATIONAL SCHOOL",
    "completed": 0,
    "total": 66,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "401984": {
    "id": 401984,
    "type": "school",
    "name": "EAGLE CREST ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 34,
    "report_id": 401984,
    "parent_type": "district",
    "parent_id": 401805
  },
  "401985": {
    "id": 401985,
    "type": "school",
    "name": "ELDORADO K-8 SCHOOL",
    "completed": 48,
    "total": 61,
    "report_id": 401985,
    "parent_type": "district",
    "parent_id": 401820
  },
  "401986": {
    "id": 401986,
    "type": "school",
    "name": "LAS ANIMAS ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 20,
    "report_id": 401986,
    "parent_type": "district",
    "parent_id": 401753
  },
  "401987": {
    "id": 401987,
    "type": "school",
    "name": "BRADFORD K8 SOUTH",
    "completed": 22,
    "total": 30,
    "report_id": 401987,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401988": {
    "id": 401988,
    "type": "school",
    "name": "JAMES IRWIN CHARTER HIGH SCHOOL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "401989": {
    "id": 401989,
    "type": "school",
    "name": "SKY VIEW MIDDLE SCHOOL",
    "completed": 25,
    "total": 36,
    "report_id": 401989,
    "parent_type": "district",
    "parent_id": 401863
  },
  "401990": {
    "id": 401990,
    "type": "school",
    "name": "POWDERHORN ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 40,
    "report_id": 401990,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401991": {
    "id": 401991,
    "type": "school",
    "name": "MANZANOLA JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 6,
    "total": 12,
    "report_id": 401991,
    "parent_type": "district",
    "parent_id": 401723
  },
  "401992": {
    "id": 401992,
    "type": "school",
    "name": "CREEDE SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401812
  },
  "401993": {
    "id": 401993,
    "type": "school",
    "name": "BRIARGATE PRESCHOOL",
    "completed": 1,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "401994": {
    "id": 401994,
    "type": "school",
    "name": "HOPE ONLINE LEARNING ACADEMY ELEMENTARY",
    "completed": 26,
    "total": 57,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "401995": {
    "id": 401995,
    "type": "school",
    "name": "SOROCO HIGH SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401809
  },
  "401996": {
    "id": 401996,
    "type": "school",
    "name": "IGNACIO ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 34,
    "report_id": 401996,
    "parent_type": "district",
    "parent_id": 401779
  },
  "401997": {
    "id": 401997,
    "type": "school",
    "name": "PALMER RIDGE HIGH SCHOOL",
    "completed": 44,
    "total": 73,
    "report_id": 401997,
    "parent_type": "district",
    "parent_id": 401771
  },
  "401998": {
    "id": 401998,
    "type": "school",
    "name": "BRADFORD K8 NORTH",
    "completed": 20,
    "total": 25,
    "report_id": 401998,
    "parent_type": "district",
    "parent_id": 401733
  },
  "401999": {
    "id": 401999,
    "type": "school",
    "name": "LITTLETON PREP CHARTER SCHOOL",
    "completed": 5,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402000": {
    "id": 402000,
    "type": "school",
    "name": "MONROE ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 29,
    "report_id": 402000,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402001": {
    "id": 402001,
    "type": "school",
    "name": "NORTH MOR ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 33,
    "report_id": 402001,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402002": {
    "id": 402002,
    "type": "school",
    "name": "AMESSE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402003": {
    "id": 402003,
    "type": "school",
    "name": "NEW AMERICA SCHOOL - LOWRY",
    "completed": 12,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402004": {
    "id": 402004,
    "type": "school",
    "name": "FOOTHILLS ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 24,
    "report_id": 402004,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402005": {
    "id": 402005,
    "type": "school",
    "name": "LITTLETON HIGH SCHOOL",
    "completed": 53,
    "total": 85,
    "report_id": 402005,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402006": {
    "id": 402006,
    "type": "school",
    "name": "PLACE BRIDGE ACADEMY",
    "completed": 14,
    "total": 85,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402007": {
    "id": 402007,
    "type": "school",
    "name": "ASPEN RIDGE PREPARATORY SCHOOL",
    "completed": 0,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402008": {
    "id": 402008,
    "type": "school",
    "name": "ELK CREEK ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 402008,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402009": {
    "id": 402009,
    "type": "school",
    "name": "TIMBERVIEW MIDDLE SCHOOL",
    "completed": 2,
    "total": 71,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402010": {
    "id": 402010,
    "type": "school",
    "name": "MAPLEWOOD ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 38,
    "report_id": 402010,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402011": {
    "id": 402011,
    "type": "school",
    "name": "SAMUELS ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402012": {
    "id": 402012,
    "type": "school",
    "name": "WORLD  COMPASS ACADEMY",
    "completed": 0,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402013": {
    "id": 402013,
    "type": "school",
    "name": "VISTA RIDGE HIGH SCHOOL",
    "completed": 23,
    "total": 85,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402014": {
    "id": 402014,
    "type": "school",
    "name": "LOUISVILLE MIDDLE SCHOOL",
    "completed": 40,
    "total": 44,
    "report_id": 402014,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402015": {
    "id": 402015,
    "type": "school",
    "name": "EAGLEVIEW ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 35,
    "report_id": 402015,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402016": {
    "id": 402016,
    "type": "school",
    "name": "PSD OPTIONS SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402017": {
    "id": 402017,
    "type": "school",
    "name": "WINDSOR CHARTER ACADEMY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402018": {
    "id": 402018,
    "type": "school",
    "name": "NORTH CONEJOS ALTERNATIVE PROGRAM",
    "completed": 0,
    "total": 3,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401903
  },
  "402019": {
    "id": 402019,
    "type": "school",
    "name": "COLORADO HIGH SCHOOL CHARTER - GES",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402020": {
    "id": 402020,
    "type": "school",
    "name": "HORIZONS EXPLORATORY ACADEMY",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401858
  },
  "402021": {
    "id": 402021,
    "type": "school",
    "name": "GRANT BEACON MIDDLE SCHOOL",
    "completed": 14,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402022": {
    "id": 402022,
    "type": "school",
    "name": "UPPER BLUE ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402022,
    "parent_type": "district",
    "parent_id": 401811
  },
  "402023": {
    "id": 402023,
    "type": "school",
    "name": "WELDON VALLEY JR/SR HIGH SCHOOL",
    "completed": 3,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401876
  },
  "402024": {
    "id": 402024,
    "type": "school",
    "name": "EARLY CHILDHOOD CENTER",
    "completed": 13,
    "total": 13,
    "report_id": 402024,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402025": {
    "id": 402025,
    "type": "school",
    "name": "ODYSSEY ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402026": {
    "id": 402026,
    "type": "school",
    "name": "BEAR VALLEY INTERNATIONAL SCHOOL",
    "completed": 3,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402027": {
    "id": 402027,
    "type": "school",
    "name": "STRIVE PREP - RUBY HILL",
    "completed": 0,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402028": {
    "id": 402028,
    "type": "school",
    "name": "COLLEGE VIEW ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402029": {
    "id": 402029,
    "type": "school",
    "name": "CHEYENNE WELLS JUNIOR/HIGH SCHOOL",
    "completed": 1,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401845
  },
  "402030": {
    "id": 402030,
    "type": "school",
    "name": "FOWLER ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 20,
    "report_id": 402030,
    "parent_type": "district",
    "parent_id": 401833
  },
  "402031": {
    "id": 402031,
    "type": "school",
    "name": "TRAIL RIDGE MIDDLE SCHOOL",
    "completed": 24,
    "total": 43,
    "report_id": 402031,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402032": {
    "id": 402032,
    "type": "school",
    "name": "BURLINGTON MIDDLE SCHOOL",
    "completed": 2,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401828
  },
  "402033": {
    "id": 402033,
    "type": "school",
    "name": "WINDSOR CHARTER ACADEMY MIDDLE AND EARLY COLLEGE HIGH SCHOOL",
    "completed": 9,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402034": {
    "id": 402034,
    "type": "school",
    "name": "BEAVER VALLEY ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 33,
    "report_id": 402034,
    "parent_type": "district",
    "parent_id": 401772
  },
  "402035": {
    "id": 402035,
    "type": "school",
    "name": "RESPECT ACADEMY AT LINCOLN",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402036": {
    "id": 402036,
    "type": "school",
    "name": "BUENA VISTA HIGH SCHOOL",
    "completed": 15,
    "total": 27,
    "report_id": 402036,
    "parent_type": "district",
    "parent_id": 401788
  },
  "402037": {
    "id": 402037,
    "type": "school",
    "name": "MANCOS EARLY LEARNING CENTER",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401789
  },
  "402038": {
    "id": 402038,
    "type": "school",
    "name": "SKYVIEW ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 30,
    "report_id": 402038,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402039": {
    "id": 402039,
    "type": "school",
    "name": "MOUNTAIN VILLAGE MONTESSORI CHARTER SCHOOL",
    "completed": 0,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402040": {
    "id": 402040,
    "type": "school",
    "name": "HIDDEN LAKE HIGH SCHOOL",
    "completed": 4,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402041": {
    "id": 402041,
    "type": "school",
    "name": "SILVER CREEK ELEMENTARY",
    "completed": 30,
    "total": 38,
    "report_id": 402041,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402042": {
    "id": 402042,
    "type": "school",
    "name": "SUMMIT MIDDLE SCHOOL",
    "completed": 52,
    "total": 69,
    "report_id": 402042,
    "parent_type": "district",
    "parent_id": 401811
  },
  "402043": {
    "id": 402043,
    "type": "school",
    "name": "DOS RIOS ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402043,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402044": {
    "id": 402044,
    "type": "school",
    "name": "LAFAYETTE ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 49,
    "report_id": 402044,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402045": {
    "id": 402045,
    "type": "school",
    "name": "BASALT HIGH SCHOOL",
    "completed": 38,
    "total": 43,
    "report_id": 402045,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402046": {
    "id": 402046,
    "type": "school",
    "name": "MAPLE GROVE ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 28,
    "report_id": 402046,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402047": {
    "id": 402047,
    "type": "school",
    "name": "BROOMFIELD HEIGHTS MIDDLE SCHOOL",
    "completed": 31,
    "total": 43,
    "report_id": 402047,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402048": {
    "id": 402048,
    "type": "school",
    "name": "ESTES PARK K-5 SCHOOL",
    "completed": 35,
    "total": 44,
    "report_id": 402048,
    "parent_type": "district",
    "parent_id": 401887
  },
  "402049": {
    "id": 402049,
    "type": "school",
    "name": "MORTENSEN ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402050": {
    "id": 402050,
    "type": "school",
    "name": "CHEROKEE TRAIL HIGH SCHOOL",
    "completed": 80,
    "total": 184,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402051": {
    "id": 402051,
    "type": "school",
    "name": "NORTH ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 31,
    "report_id": 402051,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402052": {
    "id": 402052,
    "type": "school",
    "name": "WEBER ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 31,
    "report_id": 402052,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402053": {
    "id": 402053,
    "type": "school",
    "name": "TURNER MIDDLE SCHOOL",
    "completed": 30,
    "total": 33,
    "report_id": 402053,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402054": {
    "id": 402054,
    "type": "school",
    "name": "RALSTON ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 23,
    "report_id": 402054,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402055": {
    "id": 402055,
    "type": "school",
    "name": "TELLURIDE INTERMEDIATE SCHOOL",
    "completed": 8,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401821
  },
  "402056": {
    "id": 402056,
    "type": "school",
    "name": "MARTINEZ ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 37,
    "report_id": 402056,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402057": {
    "id": 402057,
    "type": "school",
    "name": "COPPER MESA ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 35,
    "report_id": 402057,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402058": {
    "id": 402058,
    "type": "school",
    "name": "INDIAN PEAKS CHARTER SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402059": {
    "id": 402059,
    "type": "school",
    "name": "DOUGLAS COUNTY HIGH SCHOOL",
    "completed": 100,
    "total": 119,
    "report_id": 402059,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402060": {
    "id": 402060,
    "type": "school",
    "name": "WEST MIDDLE SCHOOL",
    "completed": 48,
    "total": 85,
    "report_id": 402060,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402061": {
    "id": 402061,
    "type": "school",
    "name": "MINNEQUA ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402061,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402062": {
    "id": 402062,
    "type": "school",
    "name": "LANSING ELEMENTARY COMMUNITY SCHOOL",
    "completed": 28,
    "total": 36,
    "report_id": 402062,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402063": {
    "id": 402063,
    "type": "school",
    "name": "MONTE VISTA SENIOR HIGH SCHOOL",
    "completed": 13,
    "total": 24,
    "report_id": 402063,
    "parent_type": "district",
    "parent_id": 401882
  },
  "402064": {
    "id": 402064,
    "type": "school",
    "name": "EXPLORE ELEMENTARY",
    "completed": 26,
    "total": 26,
    "report_id": 402064,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402065": {
    "id": 402065,
    "type": "school",
    "name": "REMINGTON ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402066": {
    "id": 402066,
    "type": "school",
    "name": "COLORADO SPRINGS EARLY COLLEGES",
    "completed": 8,
    "total": 48,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402067": {
    "id": 402067,
    "type": "school",
    "name": "OAKLAND ELEMENTARY",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402068": {
    "id": 402068,
    "type": "school",
    "name": "KIPP NORTHEAST ELEMENTARY",
    "completed": 0,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402069": {
    "id": 402069,
    "type": "school",
    "name": "HIGH PLAINS ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402070": {
    "id": 402070,
    "type": "school",
    "name": "PINE GROVE ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 42,
    "report_id": 402070,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402071": {
    "id": 402071,
    "type": "school",
    "name": "RANGE VIEW ELEMENTARY",
    "completed": 42,
    "total": 46,
    "report_id": 402071,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402072": {
    "id": 402072,
    "type": "school",
    "name": "FITZMORRIS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 19,
    "report_id": 402072,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402073": {
    "id": 402073,
    "type": "school",
    "name": "FRASER VALLEY ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 23,
    "report_id": 402073,
    "parent_type": "district",
    "parent_id": 401747
  },
  "402074": {
    "id": 402074,
    "type": "school",
    "name": "SKYVIEW ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 33,
    "report_id": 402074,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402075": {
    "id": 402075,
    "type": "school",
    "name": "WILEY ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 17,
    "report_id": 402075,
    "parent_type": "district",
    "parent_id": 401796
  },
  "402076": {
    "id": 402076,
    "type": "school",
    "name": "ERIE MIDDLE SCHOOL",
    "completed": 39,
    "total": 55,
    "report_id": 402076,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402077": {
    "id": 402077,
    "type": "school",
    "name": "MAPLETON EARLY CHILDHOOD CENTER",
    "completed": 7,
    "total": 7,
    "report_id": 402077,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402078": {
    "id": 402078,
    "type": "school",
    "name": "BEAR CREEK ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 27,
    "report_id": 402078,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402079": {
    "id": 402079,
    "type": "school",
    "name": "LITTLE ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402080": {
    "id": 402080,
    "type": "school",
    "name": "COLORADO EARLY COLLEGES - PARKER",
    "completed": 0,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402081": {
    "id": 402081,
    "type": "school",
    "name": "SOUTH ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 32,
    "report_id": 402081,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402082": {
    "id": 402082,
    "type": "school",
    "name": "WIDEFIELD HIGH SCHOOL",
    "completed": 26,
    "total": 75,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402083": {
    "id": 402083,
    "type": "school",
    "name": "HIGHLAND ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 27,
    "report_id": 402083,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402084": {
    "id": 402084,
    "type": "school",
    "name": "ADAMS ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 33,
    "report_id": 402084,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402085": {
    "id": 402085,
    "type": "school",
    "name": "GREGORY HILL PRESCHOOL",
    "completed": 9,
    "total": 11,
    "report_id": 402085,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402086": {
    "id": 402086,
    "type": "school",
    "name": "EDITH WOLFORD ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402087": {
    "id": 402087,
    "type": "school",
    "name": "LONGMONT ESTATES ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 27,
    "report_id": 402087,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402088": {
    "id": 402088,
    "type": "school",
    "name": "MOUNTAIN VIEW ELEMENTARY SCHOOL",
    "completed": 39,
    "total": 49,
    "report_id": 402088,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402089": {
    "id": 402089,
    "type": "school",
    "name": "ALAMOSA ELEMENTARY SCHOOL",
    "completed": 55,
    "total": 97,
    "report_id": 402089,
    "parent_type": "district",
    "parent_id": 401872
  },
  "402090": {
    "id": 402090,
    "type": "school",
    "name": "GOLD RUSH ELEMENTARY",
    "completed": 38,
    "total": 43,
    "report_id": 402090,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402091": {
    "id": 402091,
    "type": "school",
    "name": "ACADEMY CALVERT K-8 ONLINE SCHOOL",
    "completed": 0,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402092": {
    "id": 402092,
    "type": "school",
    "name": "LIMON JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 13,
    "total": 22,
    "report_id": 402092,
    "parent_type": "district",
    "parent_id": 401815
  },
  "402093": {
    "id": 402093,
    "type": "school",
    "name": "ACHIEVE ONLINE",
    "completed": 24,
    "total": 26,
    "report_id": 402093,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402094": {
    "id": 402094,
    "type": "school",
    "name": "LAKE GEORGE CHARTER SCHOOL",
    "completed": 1,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401762
  },
  "402095": {
    "id": 402095,
    "type": "school",
    "name": "HOLLY JUNIOR HIGH SCHOOL",
    "completed": 2,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401848
  },
  "402096": {
    "id": 402096,
    "type": "school",
    "name": "COTTONWOOD CREEK ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 42,
    "report_id": 402096,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402097": {
    "id": 402097,
    "type": "school",
    "name": "MESA RIDGE HIGH SCHOOL",
    "completed": 71,
    "total": 77,
    "report_id": 402097,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402098": {
    "id": 402098,
    "type": "school",
    "name": "FRONTIER CHARTER ACADEMY",
    "completed": 1,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402099": {
    "id": 402099,
    "type": "school",
    "name": "WOODLIN ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401740
  },
  "402100": {
    "id": 402100,
    "type": "school",
    "name": "ENGLEWOOD EARLY CHILDHOOD EDUCATION CENTER AT MADDOX",
    "completed": 12,
    "total": 15,
    "report_id": 402100,
    "parent_type": "district",
    "parent_id": 401802
  },
  "402101": {
    "id": 402101,
    "type": "school",
    "name": "BILL REED MIDDLE SCHOOL",
    "completed": 49,
    "total": 52,
    "report_id": 402101,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402102": {
    "id": 402102,
    "type": "school",
    "name": "GLACIER PEAK ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 32,
    "report_id": 402102,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402103": {
    "id": 402103,
    "type": "school",
    "name": "MOUNTAIN VIEW HIGH SCHOOL",
    "completed": 78,
    "total": 83,
    "report_id": 402103,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402104": {
    "id": 402104,
    "type": "school",
    "name": "SPROUL JUNIOR HIGH SCHOOL",
    "completed": 30,
    "total": 38,
    "report_id": 402104,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402105": {
    "id": 402105,
    "type": "school",
    "name": "BRIGGSDALE ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401763
  },
  "402106": {
    "id": 402106,
    "type": "school",
    "name": "SHADOW RIDGE MIDDLE SCHOOL",
    "completed": 32,
    "total": 58,
    "report_id": 402106,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402107": {
    "id": 402107,
    "type": "school",
    "name": "DSST: COLE HIGH SCHOOL",
    "completed": 13,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402108": {
    "id": 402108,
    "type": "school",
    "name": "BENNETT ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 36,
    "report_id": 402108,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402109": {
    "id": 402109,
    "type": "school",
    "name": "ROXBOROUGH ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 27,
    "report_id": 402109,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402110": {
    "id": 402110,
    "type": "school",
    "name": "DUNN ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 34,
    "report_id": 402110,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402111": {
    "id": 402111,
    "type": "school",
    "name": "BASALT ELEMENTARY SCHOOL",
    "completed": 47,
    "total": 55,
    "report_id": 402111,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402112": {
    "id": 402112,
    "type": "school",
    "name": "KEPNER BEACON MIDDLE SCHOOL",
    "completed": 14,
    "total": 22,
    "report_id": 402112,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402113": {
    "id": 402113,
    "type": "school",
    "name": "POLARIS ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402114": {
    "id": 402114,
    "type": "school",
    "name": "PLATEAU VALLEY ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401731
  },
  "402115": {
    "id": 402115,
    "type": "school",
    "name": "ASPEN CREEK K-8 SCHOOL",
    "completed": 58,
    "total": 69,
    "report_id": 402115,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402116": {
    "id": 402116,
    "type": "school",
    "name": "HERITAGE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 402116,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402117": {
    "id": 402117,
    "type": "school",
    "name": "MOUNTAIN RIDGE MIDDLE SCHOOL",
    "completed": 40,
    "total": 72,
    "report_id": 402117,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402118": {
    "id": 402118,
    "type": "school",
    "name": "OPTIONS SCHOOL",
    "completed": 3,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402119": {
    "id": 402119,
    "type": "school",
    "name": "YALE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 30,
    "report_id": 402119,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402120": {
    "id": 402120,
    "type": "school",
    "name": "VISTA PEAK P-8 EXPLORATORY",
    "completed": 60,
    "total": 84,
    "report_id": 402120,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402121": {
    "id": 402121,
    "type": "school",
    "name": "POWER TECHNICAL EARLY COLLEGE",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402122": {
    "id": 402122,
    "type": "school",
    "name": "STETSON ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402123": {
    "id": 402123,
    "type": "school",
    "name": "SANFORD JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 2,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401739
  },
  "402124": {
    "id": 402124,
    "type": "school",
    "name": "CHARLES M. SCHENCK (CMS) COMMUNITY SCHOOL",
    "completed": 5,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402125": {
    "id": 402125,
    "type": "school",
    "name": "CUSTER MIDDLE SCHOOL",
    "completed": 2,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401904
  },
  "402126": {
    "id": 402126,
    "type": "school",
    "name": "ELBERT JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 6,
    "total": 12,
    "report_id": 402126,
    "parent_type": "district",
    "parent_id": 401787
  },
  "402127": {
    "id": 402127,
    "type": "school",
    "name": "ANGEVINE MIDDLE SCHOOL",
    "completed": 42,
    "total": 50,
    "report_id": 402127,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402128": {
    "id": 402128,
    "type": "school",
    "name": "SOUTH CONEJOS SCHOOL",
    "completed": 23,
    "total": 30,
    "report_id": 402128,
    "parent_type": "district",
    "parent_id": 401790
  },
  "402129": {
    "id": 402129,
    "type": "school",
    "name": "WESTVIEW ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 34,
    "report_id": 402129,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402130": {
    "id": 402130,
    "type": "school",
    "name": "PEYTON SENIOR HIGH SCHOOL",
    "completed": 14,
    "total": 23,
    "report_id": 402130,
    "parent_type": "district",
    "parent_id": 401767
  },
  "402131": {
    "id": 402131,
    "type": "school",
    "name": "THORNTON HIGH SCHOOL",
    "completed": 92,
    "total": 113,
    "report_id": 402131,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402132": {
    "id": 402132,
    "type": "school",
    "name": "CANON CITY HIGH SCHOOL",
    "completed": 40,
    "total": 73,
    "report_id": 402132,
    "parent_type": "district",
    "parent_id": 401816
  },
  "402133": {
    "id": 402133,
    "type": "school",
    "name": "STOBER ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 29,
    "report_id": 402133,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402134": {
    "id": 402134,
    "type": "school",
    "name": "ELLIS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402135": {
    "id": 402135,
    "type": "school",
    "name": "PRITCHETT MIDDLE SCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401760
  },
  "402136": {
    "id": 402136,
    "type": "school",
    "name": "MC KINLEY-THATCHER ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402137": {
    "id": 402137,
    "type": "school",
    "name": "DANIEL C OAKES HIGH SCHOOL--CASTLE ROCK",
    "completed": 10,
    "total": 17,
    "report_id": 402137,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402138": {
    "id": 402138,
    "type": "school",
    "name": "PEAR PARK ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 34,
    "report_id": 402138,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402139": {
    "id": 402139,
    "type": "school",
    "name": "ERIE HIGH SCHOOL",
    "completed": 40,
    "total": 55,
    "report_id": 402139,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402140": {
    "id": 402140,
    "type": "school",
    "name": "YAMPA VALLEY HIGH SCHOOL",
    "completed": 4,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401846
  },
  "402141": {
    "id": 402141,
    "type": "school",
    "name": "ACADEMY HIGH SCHOOL",
    "completed": 24,
    "total": 24,
    "report_id": 402141,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402142": {
    "id": 402142,
    "type": "school",
    "name": "EXCEL ACADEMY",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402143": {
    "id": 402143,
    "type": "school",
    "name": "PARKVIEW ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 22,
    "report_id": 402143,
    "parent_type": "district",
    "parent_id": 401730
  },
  "402144": {
    "id": 402144,
    "type": "school",
    "name": "CENTER HIGH SCHOOL",
    "completed": 15,
    "total": 20,
    "report_id": 402144,
    "parent_type": "district",
    "parent_id": 401746
  },
  "402145": {
    "id": 402145,
    "type": "school",
    "name": "CREEKSIDE ELEMENTARY SCHOOL AT MARTIN PARK",
    "completed": 27,
    "total": 32,
    "report_id": 402145,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402146": {
    "id": 402146,
    "type": "school",
    "name": "SABIN WORLD SCHOOL",
    "completed": 19,
    "total": 57,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402147": {
    "id": 402147,
    "type": "school",
    "name": "HOLMES MIDDLE SCHOOL",
    "completed": 38,
    "total": 46,
    "report_id": 402147,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402148": {
    "id": 402148,
    "type": "school",
    "name": "PENROSE ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 20,
    "report_id": 402148,
    "parent_type": "district",
    "parent_id": 401875
  },
  "402149": {
    "id": 402149,
    "type": "school",
    "name": "SKOGLUND MIDDLE SCHOOL",
    "completed": 14,
    "total": 15,
    "report_id": 402149,
    "parent_type": "district",
    "parent_id": 401746
  },
  "402150": {
    "id": 402150,
    "type": "school",
    "name": "MILLIKEN MIDDLE SCHOOL",
    "completed": 37,
    "total": 41,
    "report_id": 402150,
    "parent_type": "district",
    "parent_id": 401728
  },
  "402151": {
    "id": 402151,
    "type": "school",
    "name": "GOLDEN HIGH SCHOOL",
    "completed": 47,
    "total": 77,
    "report_id": 402151,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402152": {
    "id": 402152,
    "type": "school",
    "name": "PIKES PEAK SCHOOL EXPEDITIONARY LEARNING",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402153": {
    "id": 402153,
    "type": "school",
    "name": "SALIDA MONTESSORI CHARTER SCHOOL",
    "completed": 0,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402154": {
    "id": 402154,
    "type": "school",
    "name": "NORTH ARVADA MIDDLE SCHOOL",
    "completed": 23,
    "total": 35,
    "report_id": 402154,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402156": {
    "id": 402156,
    "type": "school",
    "name": "CENTENNIAL ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402157": {
    "id": 402157,
    "type": "school",
    "name": "AURORA FRONTIER K-8",
    "completed": 38,
    "total": 42,
    "report_id": 402157,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402158": {
    "id": 402158,
    "type": "school",
    "name": "WEST JEFFERSON MIDDLE SCHOOL",
    "completed": 26,
    "total": 38,
    "report_id": 402158,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402159": {
    "id": 402159,
    "type": "school",
    "name": "LEWIS-PALMER HIGH SCHOOL",
    "completed": 34,
    "total": 61,
    "report_id": 402159,
    "parent_type": "district",
    "parent_id": 401771
  },
  "402160": {
    "id": 402160,
    "type": "school",
    "name": "NORTHGLENN HIGH SCHOOL",
    "completed": 90,
    "total": 119,
    "report_id": 402160,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402161": {
    "id": 402161,
    "type": "school",
    "name": "CALICHE ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402162": {
    "id": 402162,
    "type": "school",
    "name": "JUNIPER RIDGE COMMUNITY SCHOOL",
    "completed": 5,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402163": {
    "id": 402163,
    "type": "school",
    "name": "PEAKVIEW SCHOOL",
    "completed": 14,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401825
  },
  "402164": {
    "id": 402164,
    "type": "school",
    "name": "SIXTH AVENUE ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 36,
    "report_id": 402164,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402165": {
    "id": 402165,
    "type": "school",
    "name": "CRESTHILL MIDDLE SCHOOL",
    "completed": 53,
    "total": 64,
    "report_id": 402165,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402166": {
    "id": 402166,
    "type": "school",
    "name": "MANAUGH ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 18,
    "report_id": 402166,
    "parent_type": "district",
    "parent_id": 401726
  },
  "402167": {
    "id": 402167,
    "type": "school",
    "name": "SOARING HAWK ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 38,
    "report_id": 402167,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402168": {
    "id": 402168,
    "type": "school",
    "name": "PALMER ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402169": {
    "id": 402169,
    "type": "school",
    "name": "GARFIELD ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 26,
    "report_id": 402169,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402170": {
    "id": 402170,
    "type": "school",
    "name": "GRANDVIEW ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 32,
    "report_id": 402170,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402171": {
    "id": 402171,
    "type": "school",
    "name": "FOUNTAIN INTERNATIONAL MAGNET SCHOOL",
    "completed": 18,
    "total": 24,
    "report_id": 402171,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402172": {
    "id": 402172,
    "type": "school",
    "name": "SIERRA MIDDLE SCHOOL",
    "completed": 34,
    "total": 58,
    "report_id": 402172,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402173": {
    "id": 402173,
    "type": "school",
    "name": "KULLERSTRAND ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 24,
    "report_id": 402173,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402174": {
    "id": 402174,
    "type": "school",
    "name": "PARR ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 22,
    "report_id": 402174,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402175": {
    "id": 402175,
    "type": "school",
    "name": "NORTH ROUTT CHARTER SCHOOL",
    "completed": 2,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401846
  },
  "402176": {
    "id": 402176,
    "type": "school",
    "name": "MANITOU SPRINGS MIDDLE SCHOOL",
    "completed": 6,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401898
  },
  "402177": {
    "id": 402177,
    "type": "school",
    "name": "ALTURA ELEMENTARY SCHOOL",
    "completed": 40,
    "total": 41,
    "report_id": 402177,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402178": {
    "id": 402178,
    "type": "school",
    "name": "BEAR CREEK ELEMENTARY SCHOOL",
    "completed": 43,
    "total": 72,
    "report_id": 402178,
    "parent_type": "district",
    "parent_id": 401771
  },
  "402179": {
    "id": 402179,
    "type": "school",
    "name": "STANDLEY LAKE HIGH SCHOOL",
    "completed": 53,
    "total": 82,
    "report_id": 402179,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402180": {
    "id": 402180,
    "type": "school",
    "name": "GLOBAL VILLAGE ACADEMY - DOUGLAS COUNTY",
    "completed": 1,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402181": {
    "id": 402181,
    "type": "school",
    "name": "BELLA ROMERO ACADEMY OF APPLIED TECHNOLOGY",
    "completed": 14,
    "total": 72,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402182": {
    "id": 402182,
    "type": "school",
    "name": "INDIAN RIDGE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 34,
    "report_id": 402182,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402183": {
    "id": 402183,
    "type": "school",
    "name": "SIMLA HIGH SCHOOL",
    "completed": 0,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401834
  },
  "402184": {
    "id": 402184,
    "type": "school",
    "name": "LAURENE EDMONDSON ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 24,
    "report_id": 402184,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402185": {
    "id": 402185,
    "type": "school",
    "name": "ALICIA SANCHEZ INTERNATIONAL SCHOOL",
    "completed": 27,
    "total": 45,
    "report_id": 402185,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402186": {
    "id": 402186,
    "type": "school",
    "name": "FORT LUPTON HIGH SCHOOL",
    "completed": 41,
    "total": 52,
    "report_id": 402186,
    "parent_type": "district",
    "parent_id": 401775
  },
  "402187": {
    "id": 402187,
    "type": "school",
    "name": "HAXTUN ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 20,
    "report_id": 402187,
    "parent_type": "district",
    "parent_id": 401748
  },
  "402188": {
    "id": 402188,
    "type": "school",
    "name": "NEWTON MIDDLE SCHOOL",
    "completed": 40,
    "total": 42,
    "report_id": 402188,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402189": {
    "id": 402189,
    "type": "school",
    "name": "MAXWELL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402190": {
    "id": 402190,
    "type": "school",
    "name": "BEULAH MIDDLE SCHOOL",
    "completed": 5,
    "total": 7,
    "report_id": 402190,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402191": {
    "id": 402191,
    "type": "school",
    "name": "EARLY CHILDHOOD CENTER",
    "completed": 11,
    "total": 22,
    "report_id": 402191,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402192": {
    "id": 402192,
    "type": "school",
    "name": "ACADEMY ENDEAVOUR ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402193": {
    "id": 402193,
    "type": "school",
    "name": "PAGOSA SPRINGS HIGH SCHOOL",
    "completed": 18,
    "total": 34,
    "report_id": 402193,
    "parent_type": "district",
    "parent_id": 401883
  },
  "402194": {
    "id": 402194,
    "type": "school",
    "name": "LA VETA JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 15,
    "total": 16,
    "report_id": 402194,
    "parent_type": "district",
    "parent_id": 401793
  },
  "402195": {
    "id": 402195,
    "type": "school",
    "name": "TWAIN ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 402195,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402196": {
    "id": 402196,
    "type": "school",
    "name": "PEIFFER ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 25,
    "report_id": 402196,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402197": {
    "id": 402197,
    "type": "school",
    "name": "CESAR CHAVEZ ACADEMY DENVER",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402198": {
    "id": 402198,
    "type": "school",
    "name": "DENVER MONTESSORI JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 1,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402199": {
    "id": 402199,
    "type": "school",
    "name": "VASSAR ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 32,
    "report_id": 402199,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402200": {
    "id": 402200,
    "type": "school",
    "name": "MOUNTAIN VIEW ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 27,
    "report_id": 402200,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402201": {
    "id": 402201,
    "type": "school",
    "name": "CEDAREDGE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 24,
    "report_id": 402201,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402202": {
    "id": 402202,
    "type": "school",
    "name": "BARNUM ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402203": {
    "id": 402203,
    "type": "school",
    "name": "PARK ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 42,
    "report_id": 402203,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402204": {
    "id": 402204,
    "type": "school",
    "name": "COTTONWOOD ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 29,
    "report_id": 402204,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402205": {
    "id": 402205,
    "type": "school",
    "name": "D'EVELYN JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 31,
    "total": 58,
    "report_id": 402205,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402206": {
    "id": 402206,
    "type": "school",
    "name": "PEYTON ONLINE ACADEMY",
    "completed": 5,
    "total": 5,
    "report_id": 402206,
    "parent_type": "district",
    "parent_id": 401767
  },
  "402207": {
    "id": 402207,
    "type": "school",
    "name": "PRAIRIE MIDDLE SCHOOL",
    "completed": 53,
    "total": 135,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402208": {
    "id": 402208,
    "type": "school",
    "name": "ELLICOTT SENIOR HIGH SCHOOL",
    "completed": 16,
    "total": 23,
    "report_id": 402208,
    "parent_type": "district",
    "parent_id": 401786
  },
  "402209": {
    "id": 402209,
    "type": "school",
    "name": "EVA R BACA ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 24,
    "report_id": 402209,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402210": {
    "id": 402210,
    "type": "school",
    "name": "CHALLENGE TO EXCELLENCE CHARTER SCHOOL",
    "completed": 5,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402211": {
    "id": 402211,
    "type": "school",
    "name": "KATHRYN SENOR ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401759
  },
  "402212": {
    "id": 402212,
    "type": "school",
    "name": "EDGEWATER ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 34,
    "report_id": 402212,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402213": {
    "id": 402213,
    "type": "school",
    "name": "MONTE VISTA MIDDLE SCHOOL",
    "completed": 12,
    "total": 22,
    "report_id": 402213,
    "parent_type": "district",
    "parent_id": 401882
  },
  "402214": {
    "id": 402214,
    "type": "school",
    "name": "ARKANSAS ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 402214,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402215": {
    "id": 402215,
    "type": "school",
    "name": "FORT LEWIS MESA ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 18,
    "report_id": 402215,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402216": {
    "id": 402216,
    "type": "school",
    "name": "FAIRVIEW ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402217": {
    "id": 402217,
    "type": "school",
    "name": "VISTA ACADEMY",
    "completed": 14,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402218": {
    "id": 402218,
    "type": "school",
    "name": "PALMER LAKE ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 31,
    "report_id": 402218,
    "parent_type": "district",
    "parent_id": 401771
  },
  "402219": {
    "id": 402219,
    "type": "school",
    "name": "SOUTHMOOR ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402220": {
    "id": 402220,
    "type": "school",
    "name": "MEAD ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402221": {
    "id": 402221,
    "type": "school",
    "name": "VALLEY HIGH SCHOOL",
    "completed": 40,
    "total": 47,
    "report_id": 402221,
    "parent_type": "district",
    "parent_id": 401902
  },
  "402222": {
    "id": 402222,
    "type": "school",
    "name": "GREENLEE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402223": {
    "id": 402223,
    "type": "school",
    "name": "IRISH ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 402223,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402224": {
    "id": 402224,
    "type": "school",
    "name": "ALAMOSA OMBUDSMAN SCHOOL OF EXCELLENCE",
    "completed": 0,
    "total": 2,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401872
  },
  "402225": {
    "id": 402225,
    "type": "school",
    "name": "SOUTH PARK HIGH SCHOOL",
    "completed": 6,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401762
  },
  "402226": {
    "id": 402226,
    "type": "school",
    "name": "WAYNE CARLE MIDDLE SCHOOL",
    "completed": 27,
    "total": 34,
    "report_id": 402226,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402227": {
    "id": 402227,
    "type": "school",
    "name": "PARKVIEW ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401803
  },
  "402228": {
    "id": 402228,
    "type": "school",
    "name": "EATON HIGH SCHOOL",
    "completed": 6,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401868
  },
  "402229": {
    "id": 402229,
    "type": "school",
    "name": "WESTERLY CREEK ELEMENTARY",
    "completed": 1,
    "total": 57,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402230": {
    "id": 402230,
    "type": "school",
    "name": "GRAND MESA HIGH SCHOOL",
    "completed": 5,
    "total": 5,
    "report_id": 402230,
    "parent_type": "district",
    "parent_id": 401731
  },
  "402231": {
    "id": 402231,
    "type": "school",
    "name": "STRIVE PREP - MONTBELLO",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402232": {
    "id": 402232,
    "type": "school",
    "name": "ASPEN VALLEY CAMPUS",
    "completed": 9,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402233": {
    "id": 402233,
    "type": "school",
    "name": "THE VANGUARD SCHOOL (ELEMENTARY)",
    "completed": 0,
    "total": 70,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "402234": {
    "id": 402234,
    "type": "school",
    "name": "CALICHE JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 13,
    "total": 16,
    "report_id": 402234,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402235": {
    "id": 402235,
    "type": "school",
    "name": "NORTHFIELD HIGH SCHOOL",
    "completed": 1,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402236": {
    "id": 402236,
    "type": "school",
    "name": "EARLY CHILDHOOD CENTER",
    "completed": 1,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401774
  },
  "402237": {
    "id": 402237,
    "type": "school",
    "name": "JOHN F KENNEDY HIGH SCHOOL",
    "completed": 1,
    "total": 92,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402238": {
    "id": 402238,
    "type": "school",
    "name": "PUEBLO WEST HIGH SCHOOL",
    "completed": 20,
    "total": 75,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402239": {
    "id": 402239,
    "type": "school",
    "name": "SD 27J PRESCHOOL AT THE BRIGHTON LRC",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402240": {
    "id": 402240,
    "type": "school",
    "name": "BATTLE MOUNTAIN HIGH SCHOOL",
    "completed": 37,
    "total": 60,
    "report_id": 402240,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402241": {
    "id": 402241,
    "type": "school",
    "name": "COAL CREEK ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 29,
    "report_id": 402241,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402242": {
    "id": 402242,
    "type": "school",
    "name": "CEDAREDGE HIGH SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 402242,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402243": {
    "id": 402243,
    "type": "school",
    "name": "SUMMIT ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401844
  },
  "402244": {
    "id": 402244,
    "type": "school",
    "name": "KIOWA HIGH SCHOOL",
    "completed": 7,
    "total": 11,
    "report_id": 402244,
    "parent_type": "district",
    "parent_id": 401766
  },
  "402245": {
    "id": 402245,
    "type": "school",
    "name": "JEFFERSON JUNIOR/SENIOR HIGH",
    "completed": 13,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402246": {
    "id": 402246,
    "type": "school",
    "name": "IMAGINE CLASSICAL ACADEMY",
    "completed": 7,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402247": {
    "id": 402247,
    "type": "school",
    "name": "JOE SHOEMAKER SCHOOL",
    "completed": 0,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402248": {
    "id": 402248,
    "type": "school",
    "name": "KOHL ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 37,
    "report_id": 402248,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402249": {
    "id": 402249,
    "type": "school",
    "name": "SILVERTON ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401776
  },
  "402250": {
    "id": 402250,
    "type": "school",
    "name": "AYRES ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 38,
    "report_id": 402250,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402251": {
    "id": 402251,
    "type": "school",
    "name": "SUMMIT RIDGE MIDDLE SCHOOL",
    "completed": 39,
    "total": 49,
    "report_id": 402251,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402252": {
    "id": 402252,
    "type": "school",
    "name": "FALCON MIDDLE SCHOOL",
    "completed": 55,
    "total": 63,
    "report_id": 402252,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402253": {
    "id": 402253,
    "type": "school",
    "name": "PAGOSA SPRINGS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401883
  },
  "402254": {
    "id": 402254,
    "type": "school",
    "name": "CORONADO HIGH SCHOOL",
    "completed": 74,
    "total": 97,
    "report_id": 402254,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402255": {
    "id": 402255,
    "type": "school",
    "name": "EVERGREEN MIDDLE SCHOOL",
    "completed": 31,
    "total": 52,
    "report_id": 402255,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402256": {
    "id": 402256,
    "type": "school",
    "name": "MONUMENT CHARTER ACADEMY",
    "completed": 36,
    "total": 70,
    "report_id": 402256,
    "parent_type": "district",
    "parent_id": 401771
  },
  "402257": {
    "id": 402257,
    "type": "school",
    "name": "LOTUS SCHOOL FOR EXCELLENCE",
    "completed": 11,
    "total": 75,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402258": {
    "id": 402258,
    "type": "school",
    "name": "BETHUNE PUBLIC SCHOOLS",
    "completed": 2,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401736
  },
  "402259": {
    "id": 402259,
    "type": "school",
    "name": "NOEL COMMUNITY ARTS SCHOOL",
    "completed": 3,
    "total": 65,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402260": {
    "id": 402260,
    "type": "school",
    "name": "GATEWAY HIGH SCHOOL",
    "completed": 72,
    "total": 94,
    "report_id": 402260,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402261": {
    "id": 402261,
    "type": "school",
    "name": "IMMERSION SCHOOLS SCIENCE TECHNOLOGY ARTS",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401753
  },
  "402262": {
    "id": 402262,
    "type": "school",
    "name": "RED HAWK ELEMENTARY",
    "completed": 14,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402263": {
    "id": 402263,
    "type": "school",
    "name": "BEAR CREEK K-8 SCHOOL",
    "completed": 60,
    "total": 60,
    "report_id": 402263,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402264": {
    "id": 402264,
    "type": "school",
    "name": "CHERRY CREEK HIGH SCHOOL",
    "completed": 110,
    "total": 230,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402265": {
    "id": 402265,
    "type": "school",
    "name": "BEA UNDERWOOD ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401741
  },
  "402266": {
    "id": 402266,
    "type": "school",
    "name": "MOUNTAIN VIEW CORE KNOWLEDGE SCHOOL",
    "completed": 15,
    "total": 19,
    "report_id": 402266,
    "parent_type": "district",
    "parent_id": 401816
  },
  "402267": {
    "id": 402267,
    "type": "school",
    "name": "PINE RIDGE ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 55,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402268": {
    "id": 402268,
    "type": "school",
    "name": "LONGMONT HIGH SCHOOL",
    "completed": 44,
    "total": 73,
    "report_id": 402268,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402269": {
    "id": 402269,
    "type": "school",
    "name": "AMERICAN ACADEMY",
    "completed": 9,
    "total": 171,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402270": {
    "id": 402270,
    "type": "school",
    "name": "CHALLENGE SCHOOL",
    "completed": 6,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402271": {
    "id": 402271,
    "type": "school",
    "name": "SOUTH LAKEWOOD ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 35,
    "report_id": 402271,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402272": {
    "id": 402272,
    "type": "school",
    "name": "FRANKTOWN ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 29,
    "report_id": 402272,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402273": {
    "id": 402273,
    "type": "school",
    "name": "PLATTE VALLEY ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 37,
    "report_id": 402273,
    "parent_type": "district",
    "parent_id": 401838
  },
  "402274": {
    "id": 402274,
    "type": "school",
    "name": "THE INTERNATIONAL SCHOOL AT THORNTON MIDDLE",
    "completed": 36,
    "total": 76,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402275": {
    "id": 402275,
    "type": "school",
    "name": "HUDSON ACADEMY OF ARTS AND SCIENCES",
    "completed": 18,
    "total": 23,
    "report_id": 402275,
    "parent_type": "district",
    "parent_id": 401792
  },
  "402276": {
    "id": 402276,
    "type": "school",
    "name": "PINE CREEK HIGH SCHOOL",
    "completed": 32,
    "total": 108,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402277": {
    "id": 402277,
    "type": "school",
    "name": "VANGUARD CLASSICAL SCHOOL - WEST",
    "completed": 30,
    "total": 44,
    "report_id": 402277,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402278": {
    "id": 402278,
    "type": "school",
    "name": "COLORADO MILITARY ACADEMY",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402279": {
    "id": 402279,
    "type": "school",
    "name": "TWO ROADS CHARTER SCHOOL",
    "completed": 0,
    "total": 70,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402280": {
    "id": 402280,
    "type": "school",
    "name": "SHAW HEIGHTS MIDDLE SCHOOL",
    "completed": 27,
    "total": 38,
    "report_id": 402280,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402281": {
    "id": 402281,
    "type": "school",
    "name": "JACKSON ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402282": {
    "id": 402282,
    "type": "school",
    "name": "DENISON MONTESSORI SCHOOL",
    "completed": 14,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402283": {
    "id": 402283,
    "type": "school",
    "name": "HOLYOKE ALTERNATIVE SCHOOL",
    "completed": 0,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401885
  },
  "402284": {
    "id": 402284,
    "type": "school",
    "name": "SECREST ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 30,
    "report_id": 402284,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402285": {
    "id": 402285,
    "type": "school",
    "name": "DSST: COLLEGE VIEW MIDDLE SCHOOL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402286": {
    "id": 402286,
    "type": "school",
    "name": "DENVER CENTER FOR 21ST LEARNING AT WYMAN",
    "completed": 2,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402287": {
    "id": 402287,
    "type": "school",
    "name": "HAYDEN HIGH SCHOOL",
    "completed": 7,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401814
  },
  "402288": {
    "id": 402288,
    "type": "school",
    "name": "ANIMAS VALLEY ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 31,
    "report_id": 402288,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402289": {
    "id": 402289,
    "type": "school",
    "name": "VEGA COLLEGIATE ACADEMY",
    "completed": 3,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402290": {
    "id": 402290,
    "type": "school",
    "name": "COLORADO HIGH SCHOOL CHARTER",
    "completed": 0,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402291": {
    "id": 402291,
    "type": "school",
    "name": "BERTHOUD HIGH SCHOOL",
    "completed": 42,
    "total": 51,
    "report_id": 402291,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402292": {
    "id": 402292,
    "type": "school",
    "name": "BOULDER COMMUNITY SCHOOL/INTEGRATED STUDIES",
    "completed": 18,
    "total": 18,
    "report_id": 402292,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402293": {
    "id": 402293,
    "type": "school",
    "name": "ATLAS PREPARATORY HIGH SCHOOL",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402294": {
    "id": 402294,
    "type": "school",
    "name": "SINGING HILLS ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401737
  },
  "402295": {
    "id": 402295,
    "type": "school",
    "name": "PRAIRIE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401855
  },
  "402296": {
    "id": 402296,
    "type": "school",
    "name": "NEVIN PLATT MIDDLE SCHOOL",
    "completed": 36,
    "total": 45,
    "report_id": 402296,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402297": {
    "id": 402297,
    "type": "school",
    "name": "DSST: BYERS MIDDLE SCHOOL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402298": {
    "id": 402298,
    "type": "school",
    "name": "KYFFIN ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 402298,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402299": {
    "id": 402299,
    "type": "school",
    "name": "WILEY JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 8,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401796
  },
  "402300": {
    "id": 402300,
    "type": "school",
    "name": "CANON ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "402301": {
    "id": 402301,
    "type": "school",
    "name": "HIGH PLAINS SCHOOL",
    "completed": 23,
    "total": 31,
    "report_id": 402301,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402302": {
    "id": 402302,
    "type": "school",
    "name": "VILLAGE EAST COMMUNITY ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 69,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402303": {
    "id": 402303,
    "type": "school",
    "name": "MIAMI-YODER MIDDLE/HIGH SCHOOL",
    "completed": 13,
    "total": 15,
    "report_id": 402303,
    "parent_type": "district",
    "parent_id": 401758
  },
  "402304": {
    "id": 402304,
    "type": "school",
    "name": "PRAIRIE WINDS ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 33,
    "report_id": 402304,
    "parent_type": "district",
    "parent_id": 401771
  },
  "402305": {
    "id": 402305,
    "type": "school",
    "name": "DURANGO BIG PICTURE HIGH SCHOOL",
    "completed": 12,
    "total": 14,
    "report_id": 402305,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402306": {
    "id": 402306,
    "type": "school",
    "name": "BLACK ROCK ELEMENTARY",
    "completed": 34,
    "total": 42,
    "report_id": 402306,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402307": {
    "id": 402307,
    "type": "school",
    "name": "LINCOLN ORCHARD MESA ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 29,
    "report_id": 402307,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402308": {
    "id": 402308,
    "type": "school",
    "name": "HENRY ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 28,
    "report_id": 402308,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402309": {
    "id": 402309,
    "type": "school",
    "name": "CEDAREDGE MIDDLE SCHOOL",
    "completed": 13,
    "total": 13,
    "report_id": 402309,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402310": {
    "id": 402310,
    "type": "school",
    "name": "MC CLAVE ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401880
  },
  "402311": {
    "id": 402311,
    "type": "school",
    "name": "MARY BLAIR ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 30,
    "report_id": 402311,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402312": {
    "id": 402312,
    "type": "school",
    "name": "ALSUP ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 40,
    "report_id": 402312,
    "parent_type": "district",
    "parent_id": 401895
  },
  "402313": {
    "id": 402313,
    "type": "school",
    "name": "NEWLON ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402314": {
    "id": 402314,
    "type": "school",
    "name": "LINTON ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 402314,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402315": {
    "id": 402315,
    "type": "school",
    "name": "SALIDA HIGH SCHOOL",
    "completed": 11,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401858
  },
  "402316": {
    "id": 402316,
    "type": "school",
    "name": "WESTGATE ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 36,
    "report_id": 402316,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402317": {
    "id": 402317,
    "type": "school",
    "name": "WALSH HIGH SCHOOL",
    "completed": 13,
    "total": 13,
    "report_id": 402317,
    "parent_type": "district",
    "parent_id": 401800
  },
  "402318": {
    "id": 402318,
    "type": "school",
    "name": "ABRAHAM LINCOLN HIGH SCHOOL",
    "completed": 24,
    "total": 105,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402319": {
    "id": 402319,
    "type": "school",
    "name": "LIBERTY HIGH SCHOOL",
    "completed": 0,
    "total": 106,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402320": {
    "id": 402320,
    "type": "school",
    "name": "GARDNER ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 11,
    "report_id": 402320,
    "parent_type": "district",
    "parent_id": 401825
  },
  "402321": {
    "id": 402321,
    "type": "school",
    "name": "EAGLESIDE ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 47,
    "report_id": 402321,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402322": {
    "id": 402322,
    "type": "school",
    "name": "THE PINNACLE CHARTER SCHOOL HIGH",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402323": {
    "id": 402323,
    "type": "school",
    "name": "EATON ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 26,
    "report_id": 402323,
    "parent_type": "district",
    "parent_id": 401868
  },
  "402324": {
    "id": 402324,
    "type": "school",
    "name": "CASTRO ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402325": {
    "id": 402325,
    "type": "school",
    "name": "NEW EMERSON SCHOOL AT COLUMBUS",
    "completed": 9,
    "total": 11,
    "report_id": 402325,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402326": {
    "id": 402326,
    "type": "school",
    "name": "PATHWAYS FUTURE CENTER",
    "completed": 0,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402327": {
    "id": 402327,
    "type": "school",
    "name": "CARSON ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402328": {
    "id": 402328,
    "type": "school",
    "name": "CONNECTIONS LEARNING CENTER ON THE EARLE JOHNSON CAMPUS",
    "completed": 8,
    "total": 13,
    "report_id": 402328,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402329": {
    "id": 402329,
    "type": "school",
    "name": "FALL RIVER ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 31,
    "report_id": 402329,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402330": {
    "id": 402330,
    "type": "school",
    "name": "LANDMARK ACADEMY AT REUNION",
    "completed": 0,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402331": {
    "id": 402331,
    "type": "school",
    "name": "MIDDLE PARK HIGH SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402331,
    "parent_type": "district",
    "parent_id": 401747
  },
  "402332": {
    "id": 402332,
    "type": "school",
    "name": "SWINK ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 20,
    "report_id": 402332,
    "parent_type": "district",
    "parent_id": 401743
  },
  "402333": {
    "id": 402333,
    "type": "school",
    "name": "TRAUT CORE ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402333,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402334": {
    "id": 402334,
    "type": "school",
    "name": "RANGEVIEW HIGH SCHOOL",
    "completed": 61,
    "total": 117,
    "report_id": 402334,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402335": {
    "id": 402335,
    "type": "school",
    "name": "WORLD ACADEMY",
    "completed": 2,
    "total": 2,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402336": {
    "id": 402336,
    "type": "school",
    "name": "MONTESSORI DEL MUNDO CHARTER SCHOOL",
    "completed": 2,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402337": {
    "id": 402337,
    "type": "school",
    "name": "HARRISON HIGH SCHOOL",
    "completed": 0,
    "total": 67,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402338": {
    "id": 402338,
    "type": "school",
    "name": "SUNSET MIDDLE SCHOOL",
    "completed": 21,
    "total": 39,
    "report_id": 402338,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402339": {
    "id": 402339,
    "type": "school",
    "name": "COLORADO PREP ACADEMY",
    "completed": 30,
    "total": 52,
    "report_id": 402339,
    "parent_type": "district",
    "parent_id": 401770
  },
  "402340": {
    "id": 402340,
    "type": "school",
    "name": "IGNACIO MIDDLE SCHOOL",
    "completed": 9,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401779
  },
  "402341": {
    "id": 402341,
    "type": "school",
    "name": "POUDRE COMMUNITY ACADEMY",
    "completed": 16,
    "total": 16,
    "report_id": 402341,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402342": {
    "id": 402342,
    "type": "school",
    "name": "MCKINLEY ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 24,
    "report_id": 402342,
    "parent_type": "district",
    "parent_id": 401816
  },
  "402343": {
    "id": 402343,
    "type": "school",
    "name": "APEX HOME SCHOOL ENRICHMENT PROGRAM",
    "completed": 0,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402344": {
    "id": 402344,
    "type": "school",
    "name": "COMMUNITY MONTESSORI SCHOOL",
    "completed": 16,
    "total": 25,
    "report_id": 402344,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402345": {
    "id": 402345,
    "type": "school",
    "name": "SKY VISTA MIDDLE SCHOOL",
    "completed": 38,
    "total": 58,
    "report_id": 402345,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402346": {
    "id": 402346,
    "type": "school",
    "name": "RONCALLI STEM ACADEMY",
    "completed": 29,
    "total": 36,
    "report_id": 402346,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402347": {
    "id": 402347,
    "type": "school",
    "name": "SKYLINE VISTA ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 28,
    "report_id": 402347,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402348": {
    "id": 402348,
    "type": "school",
    "name": "ALLENDALE ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 16,
    "report_id": 402348,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402349": {
    "id": 402349,
    "type": "school",
    "name": "BENNETT HIGH SCHOOL",
    "completed": 9,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401900
  },
  "402350": {
    "id": 402350,
    "type": "school",
    "name": "DOHERTY HIGH SCHOOL",
    "completed": 90,
    "total": 123,
    "report_id": 402350,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402351": {
    "id": 402351,
    "type": "school",
    "name": "BOSTON K-8 SCHOOL",
    "completed": 33,
    "total": 38,
    "report_id": 402351,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402352": {
    "id": 402352,
    "type": "school",
    "name": "BRIDGES",
    "completed": 6,
    "total": 8,
    "report_id": 402352,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402353": {
    "id": 402353,
    "type": "school",
    "name": "CLARA E. METZ ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 24,
    "report_id": 402353,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402354": {
    "id": 402354,
    "type": "school",
    "name": "PATRIOT HIGH SCHOOL",
    "completed": 1,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402355": {
    "id": 402355,
    "type": "school",
    "name": "BRANSON SCHOOL ONLINE",
    "completed": 5,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401727
  },
  "402356": {
    "id": 402356,
    "type": "school",
    "name": "UNION COLONY PREPARATORY SCHOOL",
    "completed": 20,
    "total": 27,
    "report_id": 402356,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402357": {
    "id": 402357,
    "type": "school",
    "name": "FORT COLLINS MONTESSORI SCHOOL",
    "completed": 1,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402358": {
    "id": 402358,
    "type": "school",
    "name": "ABRAMS ELEMENTARY SCHOOL",
    "completed": 39,
    "total": 39,
    "report_id": 402358,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402359": {
    "id": 402359,
    "type": "school",
    "name": "VINELAND MIDDLE SCHOOL",
    "completed": 11,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402360": {
    "id": 402360,
    "type": "school",
    "name": "KARVAL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401860
  },
  "402361": {
    "id": 402361,
    "type": "school",
    "name": "FORT MORGAN MIDDLE SCHOOL",
    "completed": 45,
    "total": 49,
    "report_id": 402361,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402362": {
    "id": 402362,
    "type": "school",
    "name": "RED CANYON HIGH SCHOOL",
    "completed": 8,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402363": {
    "id": 402363,
    "type": "school",
    "name": "SARGENT SENIOR HIGH SCHOOL",
    "completed": 5,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401841
  },
  "402364": {
    "id": 402364,
    "type": "school",
    "name": "DSST: GREEN VALLEY RANCH MIDDLE SCHOOL",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402365": {
    "id": 402365,
    "type": "school",
    "name": "JOSEPHINE HODGKINS ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 42,
    "report_id": 402365,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402366": {
    "id": 402366,
    "type": "school",
    "name": "WELCHESTER ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 27,
    "report_id": 402366,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402367": {
    "id": 402367,
    "type": "school",
    "name": "JEFFERSON COUNTY OPEN SECONDARY",
    "completed": 8,
    "total": 13,
    "report_id": 402367,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402368": {
    "id": 402368,
    "type": "school",
    "name": "RUSSELL MIDDLE SCHOOL",
    "completed": 23,
    "total": 46,
    "report_id": 402368,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402369": {
    "id": 402369,
    "type": "school",
    "name": "THOMAS JEFFERSON HIGH SCHOOL",
    "completed": 31,
    "total": 74,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402370": {
    "id": 402370,
    "type": "school",
    "name": "MC ELWAIN ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402371": {
    "id": 402371,
    "type": "school",
    "name": "EAGLEVIEW MIDDLE SCHOOL",
    "completed": 18,
    "total": 67,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402372": {
    "id": 402372,
    "type": "school",
    "name": "CONIFER SENIOR HIGH SCHOOL",
    "completed": 11,
    "total": 55,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402373": {
    "id": 402373,
    "type": "school",
    "name": "CACHE LA POUDRE ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 402373,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402374": {
    "id": 402374,
    "type": "school",
    "name": "THE BIJOU SCHOOL",
    "completed": 13,
    "total": 14,
    "report_id": 402374,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402375": {
    "id": 402375,
    "type": "school",
    "name": "HIGHLAND MIDDLE SCHOOL",
    "completed": 2,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401749
  },
  "402376": {
    "id": 402376,
    "type": "school",
    "name": "STRIVE PREP - KEPNER",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402377": {
    "id": 402377,
    "type": "school",
    "name": "WM E BISHOP ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 23,
    "report_id": 402377,
    "parent_type": "district",
    "parent_id": 401802
  },
  "402378": {
    "id": 402378,
    "type": "school",
    "name": "MONARCH K-8 SCHOOL",
    "completed": 56,
    "total": 67,
    "report_id": 402378,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402379": {
    "id": 402379,
    "type": "school",
    "name": "MARRAMA ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402380": {
    "id": 402380,
    "type": "school",
    "name": "KIPP DENVER COLLEGIATE HIGH SCHOOL",
    "completed": 0,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402381": {
    "id": 402381,
    "type": "school",
    "name": "ORTEGA MIDDLE SCHOOL",
    "completed": 13,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401872
  },
  "402382": {
    "id": 402382,
    "type": "school",
    "name": "COYOTE HILLS ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402383": {
    "id": 402383,
    "type": "school",
    "name": "IMAGINE CHARTER",
    "completed": 20,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402384": {
    "id": 402384,
    "type": "school",
    "name": "PRIMERO JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 9,
    "total": 16,
    "report_id": 402384,
    "parent_type": "district",
    "parent_id": 401853
  },
  "402385": {
    "id": 402385,
    "type": "school",
    "name": "PENNINGTON ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 15,
    "report_id": 402385,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402386": {
    "id": 402386,
    "type": "school",
    "name": "EAGLE VALLEY MIDDLE SCHOOL",
    "completed": 17,
    "total": 24,
    "report_id": 402386,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402387": {
    "id": 402387,
    "type": "school",
    "name": "HAROLD FERGUSON HIGH SCHOOL",
    "completed": 9,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402388": {
    "id": 402388,
    "type": "school",
    "name": "KARVAL JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 1,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401860
  },
  "402389": {
    "id": 402389,
    "type": "school",
    "name": "DUAL IMMERSION ACADEMY SCHOOL",
    "completed": 12,
    "total": 22,
    "report_id": 402389,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402390": {
    "id": 402390,
    "type": "school",
    "name": "MELVIN HENDRICKSON DEVELOPMENT CENTER",
    "completed": 3,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401730
  },
  "402391": {
    "id": 402391,
    "type": "school",
    "name": "FREEDOM ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 402391,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402392": {
    "id": 402392,
    "type": "school",
    "name": "ROOSEVELT CHARTER ACADEMY",
    "completed": 48,
    "total": 49,
    "report_id": 402392,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402393": {
    "id": 402393,
    "type": "school",
    "name": "THOMSON PRIMARY SCHOOL",
    "completed": 25,
    "total": 28,
    "report_id": 402393,
    "parent_type": "district",
    "parent_id": 401772
  },
  "402394": {
    "id": 402394,
    "type": "school",
    "name": "DSST: STAPLETON MIDDLE SCHOOL",
    "completed": 0,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402395": {
    "id": 402395,
    "type": "school",
    "name": "HEATHERWOOD ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 33,
    "report_id": 402395,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402396": {
    "id": 402396,
    "type": "school",
    "name": "FRANCIS M. DAY ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 27,
    "report_id": 402396,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402397": {
    "id": 402397,
    "type": "school",
    "name": "ESCALANTE-BIGGS ACADEMY",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402398": {
    "id": 402398,
    "type": "school",
    "name": "PENROSE ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 402398,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402399": {
    "id": 402399,
    "type": "school",
    "name": "EDISON ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 26,
    "report_id": 402399,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402400": {
    "id": 402400,
    "type": "school",
    "name": "JAMES IRWIN CHARTER MIDDLE SCHOOL",
    "completed": 0,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402401": {
    "id": 402401,
    "type": "school",
    "name": "KIOWA MIDDLE SCHOOL",
    "completed": 5,
    "total": 9,
    "report_id": 402401,
    "parent_type": "district",
    "parent_id": 401766
  },
  "402402": {
    "id": 402402,
    "type": "school",
    "name": "WILDCAT MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 42,
    "report_id": 402402,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402403": {
    "id": 402403,
    "type": "school",
    "name": "MRACHEK MIDDLE SCHOOL",
    "completed": 56,
    "total": 56,
    "report_id": 402403,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402404": {
    "id": 402404,
    "type": "school",
    "name": "MILDRED L SANVILLE PRESCHOOL",
    "completed": 1,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401895
  },
  "402405": {
    "id": 402405,
    "type": "school",
    "name": "EVANS INTERNATIONAL ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402406": {
    "id": 402406,
    "type": "school",
    "name": "CHAPPELOW K-8 MAGNET SCHOOL",
    "completed": 42,
    "total": 42,
    "report_id": 402406,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402407": {
    "id": 402407,
    "type": "school",
    "name": "PARK LANE ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 30,
    "report_id": 402407,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402408": {
    "id": 402408,
    "type": "school",
    "name": "MC MEEN ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 53,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402409": {
    "id": 402409,
    "type": "school",
    "name": "STRAWBERRY PARK ELEMENTARY SCHOOL",
    "completed": 39,
    "total": 44,
    "report_id": 402409,
    "parent_type": "district",
    "parent_id": 401846
  },
  "402410": {
    "id": 402410,
    "type": "school",
    "name": "EARLY COLLEGE ACADEMY",
    "completed": 10,
    "total": 10,
    "report_id": 402410,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402411": {
    "id": 402411,
    "type": "school",
    "name": "HERITAGE ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402412": {
    "id": 402412,
    "type": "school",
    "name": "EDISON JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401738
  },
  "402413": {
    "id": 402413,
    "type": "school",
    "name": "PLATTE CANYON HIGH SCHOOL",
    "completed": 22,
    "total": 22,
    "report_id": 402413,
    "parent_type": "district",
    "parent_id": 401897
  },
  "402414": {
    "id": 402414,
    "type": "school",
    "name": "MEADOW VIEW ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 37,
    "report_id": 402414,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402415": {
    "id": 402415,
    "type": "school",
    "name": "CARVER ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 402415,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402416": {
    "id": 402416,
    "type": "school",
    "name": "WESTGATE CHARTER",
    "completed": 9,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402417": {
    "id": 402417,
    "type": "school",
    "name": "STRATTON ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401794
  },
  "402418": {
    "id": 402418,
    "type": "school",
    "name": "RUDY ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 402418,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402419": {
    "id": 402419,
    "type": "school",
    "name": "DEANE ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 402419,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402420": {
    "id": 402420,
    "type": "school",
    "name": "NORTHEAST ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 21,
    "report_id": 402420,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402421": {
    "id": 402421,
    "type": "school",
    "name": "S.A.WILSON",
    "completed": 5,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402422": {
    "id": 402422,
    "type": "school",
    "name": "WASHINGTON ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 23,
    "report_id": 402422,
    "parent_type": "district",
    "parent_id": 401730
  },
  "402423": {
    "id": 402423,
    "type": "school",
    "name": "CENTAURI HIGH SCHOOL",
    "completed": 15,
    "total": 29,
    "report_id": 402423,
    "parent_type": "district",
    "parent_id": 401903
  },
  "402424": {
    "id": 402424,
    "type": "school",
    "name": "LEROY DRIVE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 33,
    "report_id": 402424,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402425": {
    "id": 402425,
    "type": "school",
    "name": "BESSEMER ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 21,
    "report_id": 402425,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402426": {
    "id": 402426,
    "type": "school",
    "name": "DOLORES ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 33,
    "report_id": 402426,
    "parent_type": "district",
    "parent_id": 401888
  },
  "402427": {
    "id": 402427,
    "type": "school",
    "name": "OTIS JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 13,
    "total": 14,
    "report_id": 402427,
    "parent_type": "district",
    "parent_id": 401861
  },
  "402428": {
    "id": 402428,
    "type": "school",
    "name": "EDNA AND JOHN W. MOSLEY P-8",
    "completed": 63,
    "total": 63,
    "report_id": 402428,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402429": {
    "id": 402429,
    "type": "school",
    "name": "IVY STOCKWELL ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 27,
    "report_id": 402429,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402430": {
    "id": 402430,
    "type": "school",
    "name": "AGUILAR ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 7,
    "report_id": 402430,
    "parent_type": "district",
    "parent_id": 401843
  },
  "402431": {
    "id": 402431,
    "type": "school",
    "name": "MOUNTAIN VALLEY SCHOOL",
    "completed": 16,
    "total": 19,
    "report_id": 402431,
    "parent_type": "district",
    "parent_id": 401842
  },
  "402432": {
    "id": 402432,
    "type": "school",
    "name": "ASBURY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402433": {
    "id": 402433,
    "type": "school",
    "name": "ACADEMY FOR ADVANCED AND CREATIVE LEARNING",
    "completed": 26,
    "total": 29,
    "report_id": 402433,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402434": {
    "id": 402434,
    "type": "school",
    "name": "PATTERSON INTERNATIONAL SCHOOL",
    "completed": 22,
    "total": 26,
    "report_id": 402434,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402435": {
    "id": 402435,
    "type": "school",
    "name": "COLUMBINE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402436": {
    "id": 402436,
    "type": "school",
    "name": "LIFE SKILLS CENTER OF COLORADO SPRINGS",
    "completed": 17,
    "total": 20,
    "report_id": 402436,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402437": {
    "id": 402437,
    "type": "school",
    "name": "JEFFERSON ACADEMY ELEMENTARY",
    "completed": 4,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402438": {
    "id": 402438,
    "type": "school",
    "name": "W H HEATON MIDDLE SCHOOL",
    "completed": 46,
    "total": 46,
    "report_id": 402438,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402439": {
    "id": 402439,
    "type": "school",
    "name": "LOUISVILLE ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 46,
    "report_id": 402439,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402440": {
    "id": 402440,
    "type": "school",
    "name": "VAUGHN ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 28,
    "report_id": 402440,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402441": {
    "id": 402441,
    "type": "school",
    "name": "POLARIS EXPEDITIONARY LEARNING SCHOOL",
    "completed": 23,
    "total": 34,
    "report_id": 402441,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402442": {
    "id": 402442,
    "type": "school",
    "name": "FLATIRONS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 19,
    "report_id": 402442,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402443": {
    "id": 402443,
    "type": "school",
    "name": "HORIZON HIGH SCHOOL",
    "completed": 82,
    "total": 111,
    "report_id": 402443,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402444": {
    "id": 402444,
    "type": "school",
    "name": "MUNROE ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402445": {
    "id": 402445,
    "type": "school",
    "name": "NEW LEGACY CHARTER SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402446": {
    "id": 402446,
    "type": "school",
    "name": "ASPEN HIGH SCHOOL",
    "completed": 39,
    "total": 56,
    "report_id": 402446,
    "parent_type": "district",
    "parent_id": 401808
  },
  "402447": {
    "id": 402447,
    "type": "school",
    "name": "PRITCHETT ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401760
  },
  "402448": {
    "id": 402448,
    "type": "school",
    "name": "JEFFERSON COUNTY OPEN ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 93,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402449": {
    "id": 402449,
    "type": "school",
    "name": "WAMSLEY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401759
  },
  "402450": {
    "id": 402450,
    "type": "school",
    "name": "ADAMS12 FIVE STAR PRESCHOOL",
    "completed": 3,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402451": {
    "id": 402451,
    "type": "school",
    "name": "LEAWOOD ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 30,
    "report_id": 402451,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402452": {
    "id": 402452,
    "type": "school",
    "name": "ALLIES ",
    "completed": 2,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402453": {
    "id": 402453,
    "type": "school",
    "name": "LEGEND HIGH SCHOOL",
    "completed": 78,
    "total": 123,
    "report_id": 402453,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402454": {
    "id": 402454,
    "type": "school",
    "name": "MERIDIAN RANCH ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402455": {
    "id": 402455,
    "type": "school",
    "name": "LEWIS-PALMER ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 37,
    "report_id": 402455,
    "parent_type": "district",
    "parent_id": 401771
  },
  "402456": {
    "id": 402456,
    "type": "school",
    "name": "SECOND CREEK ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 40,
    "report_id": 402456,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402457": {
    "id": 402457,
    "type": "school",
    "name": "WALNUT HILLS COMMUNITY ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402458": {
    "id": 402458,
    "type": "school",
    "name": "LAKE COUNTY INTERMEDIATE SCHOOL",
    "completed": 15,
    "total": 26,
    "report_id": 402458,
    "parent_type": "district",
    "parent_id": 401797
  },
  "402459": {
    "id": 402459,
    "type": "school",
    "name": "SOUTHERN HILLS MIDDLE SCHOOL",
    "completed": 29,
    "total": 37,
    "report_id": 402459,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402460": {
    "id": 402460,
    "type": "school",
    "name": "KENTON ELEMENTARY SCHOOL",
    "completed": 41,
    "total": 41,
    "report_id": 402460,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402461": {
    "id": 402461,
    "type": "school",
    "name": "THE CLASSICAL ACADEMY HIGH SCHOOL",
    "completed": 0,
    "total": 81,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402462": {
    "id": 402462,
    "type": "school",
    "name": "OLATHE ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402462,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402463": {
    "id": 402463,
    "type": "school",
    "name": "SALIDA MIDDLE SCHOOL",
    "completed": 16,
    "total": 30,
    "report_id": 402463,
    "parent_type": "district",
    "parent_id": 401858
  },
  "402464": {
    "id": 402464,
    "type": "school",
    "name": "MC LAIN HIGH SCHOOL",
    "completed": 15,
    "total": 24,
    "report_id": 402464,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402465": {
    "id": 402465,
    "type": "school",
    "name": "MCAULIFFE MANUAL MIDDLE SCHOOL",
    "completed": 1,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402466": {
    "id": 402466,
    "type": "school",
    "name": "PLATTE VALLEY MIDDLE SCHOOL",
    "completed": 21,
    "total": 22,
    "report_id": 402466,
    "parent_type": "district",
    "parent_id": 401838
  },
  "402467": {
    "id": 402467,
    "type": "school",
    "name": "CROWLEY COUNTY JUNIOR AND SENIOR HIGH SCHOOL",
    "completed": 3,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401810
  },
  "402468": {
    "id": 402468,
    "type": "school",
    "name": "HYGIENE ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 26,
    "report_id": 402468,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402469": {
    "id": 402469,
    "type": "school",
    "name": "SANGRE DE CRISTO UNDIVIDED HIGH SCHOOL",
    "completed": 13,
    "total": 21,
    "report_id": 402469,
    "parent_type": "district",
    "parent_id": 401850
  },
  "402470": {
    "id": 402470,
    "type": "school",
    "name": "HIGHLAND HIGH SCHOOL",
    "completed": 3,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401749
  },
  "402471": {
    "id": 402471,
    "type": "school",
    "name": "GREEN ACRES ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 27,
    "report_id": 402471,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402472": {
    "id": 402472,
    "type": "school",
    "name": "B F KITCHEN ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 25,
    "report_id": 402472,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402473": {
    "id": 402473,
    "type": "school",
    "name": "BRADLEY INTERNATIONAL SCHOOL",
    "completed": 22,
    "total": 42,
    "report_id": 402473,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402474": {
    "id": 402474,
    "type": "school",
    "name": "RICE ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 32,
    "report_id": 402474,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402475": {
    "id": 402475,
    "type": "school",
    "name": "ACRES GREEN ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 51,
    "report_id": 402475,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402476": {
    "id": 402476,
    "type": "school",
    "name": "ROCK CANYON HIGH SCHOOL",
    "completed": 82,
    "total": 121,
    "report_id": 402476,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402477": {
    "id": 402477,
    "type": "school",
    "name": "NEDERLAND MIDDLE-SENIOR HIGH SCHOOL",
    "completed": 28,
    "total": 34,
    "report_id": 402477,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402478": {
    "id": 402478,
    "type": "school",
    "name": "COAL CREEK CANYON K-8 ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402479": {
    "id": 402479,
    "type": "school",
    "name": "KIM UNDIVIDED HIGH SCHOOL",
    "completed": 7,
    "total": 7,
    "report_id": 402479,
    "parent_type": "district",
    "parent_id": 401884
  },
  "402480": {
    "id": 402480,
    "type": "school",
    "name": "DELTA MIDDLE SCHOOL",
    "completed": 26,
    "total": 27,
    "report_id": 402480,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402481": {
    "id": 402481,
    "type": "school",
    "name": "RIDGWAY HIGH SCHOOL",
    "completed": 8,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401864
  },
  "402482": {
    "id": 402482,
    "type": "school",
    "name": "GOLDEN VIEW CLASSICAL ACADEMY",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402483": {
    "id": 402483,
    "type": "school",
    "name": "MANCOS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401789
  },
  "402484": {
    "id": 402484,
    "type": "school",
    "name": "BLACK FOREST HILLS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402485": {
    "id": 402485,
    "type": "school",
    "name": "FRUITA MONUMENT HIGH SCHOOL",
    "completed": 79,
    "total": 82,
    "report_id": 402485,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402486": {
    "id": 402486,
    "type": "school",
    "name": "CENTENNIAL SCHOOL",
    "completed": 42,
    "total": 45,
    "report_id": 402486,
    "parent_type": "district",
    "parent_id": 401894
  },
  "402487": {
    "id": 402487,
    "type": "school",
    "name": "EVERITT MIDDLE SCHOOL",
    "completed": 17,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402488": {
    "id": 402488,
    "type": "school",
    "name": "BRUCE RANDOLPH SCHOOL",
    "completed": 41,
    "total": 75,
    "report_id": 402488,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402489": {
    "id": 402489,
    "type": "school",
    "name": "THE ACADEMIC RECOVERY CENTER OF SAN LUIS VALLEY",
    "completed": 1,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401746
  },
  "402490": {
    "id": 402490,
    "type": "school",
    "name": "KELLER ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 32,
    "report_id": 402490,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402491": {
    "id": 402491,
    "type": "school",
    "name": "DILLON VALLEY ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 39,
    "report_id": 402491,
    "parent_type": "district",
    "parent_id": 401811
  },
  "402492": {
    "id": 402492,
    "type": "school",
    "name": "RANGELY JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401803
  },
  "402493": {
    "id": 402493,
    "type": "school",
    "name": "EVERGREEN HIGH SCHOOL",
    "completed": 31,
    "total": 47,
    "report_id": 402493,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402494": {
    "id": 402494,
    "type": "school",
    "name": "ARROWHEAD ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 43,
    "report_id": 402494,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402495": {
    "id": 402495,
    "type": "school",
    "name": "MOFFAT PREK-12 SCHOOL",
    "completed": 22,
    "total": 22,
    "report_id": 402495,
    "parent_type": "district",
    "parent_id": 401830
  },
  "402496": {
    "id": 402496,
    "type": "school",
    "name": "PEAK TO PEAK CHARTER SCHOOL",
    "completed": 9,
    "total": 108,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402497": {
    "id": 402497,
    "type": "school",
    "name": "ELBERT ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401787
  },
  "402498": {
    "id": 402498,
    "type": "school",
    "name": "SANDRA TODD-WILLIAMS ACADEMY",
    "completed": 2,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402499": {
    "id": 402499,
    "type": "school",
    "name": "PICKENS TECHNICAL COLLEGE",
    "completed": 49,
    "total": 49,
    "report_id": 402499,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402500": {
    "id": 402500,
    "type": "school",
    "name": "PEYTON JUNIOR HIGH SCHOOL",
    "completed": 5,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401767
  },
  "402501": {
    "id": 402501,
    "type": "school",
    "name": "CIMARRON ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402502": {
    "id": 402502,
    "type": "school",
    "name": "BEULAH ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402503": {
    "id": 402503,
    "type": "school",
    "name": "THOMAS MACLAREN STATE CHARTER SCHOOL",
    "completed": 0,
    "total": 60,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402504": {
    "id": 402504,
    "type": "school",
    "name": "WESTPARK ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 18,
    "report_id": 402504,
    "parent_type": "district",
    "parent_id": 401797
  },
  "402505": {
    "id": 402505,
    "type": "school",
    "name": "PUEBLO COUNTY HIGH SCHOOL",
    "completed": 14,
    "total": 62,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402506": {
    "id": 402506,
    "type": "school",
    "name": "PRIMERO ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401853
  },
  "402507": {
    "id": 402507,
    "type": "school",
    "name": "STELLAR ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 36,
    "report_id": 402507,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402508": {
    "id": 402508,
    "type": "school",
    "name": "SUMMIT ACADEMY",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402509": {
    "id": 402509,
    "type": "school",
    "name": "HAAFF ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 21,
    "report_id": 402509,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402510": {
    "id": 402510,
    "type": "school",
    "name": "CENTURY ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 25,
    "report_id": 402510,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402511": {
    "id": 402511,
    "type": "school",
    "name": "CORTEZ MIDDLE SCHOOL",
    "completed": 43,
    "total": 48,
    "report_id": 402511,
    "parent_type": "district",
    "parent_id": 401726
  },
  "402512": {
    "id": 402512,
    "type": "school",
    "name": "STEAMBOAT SPRINGS EARLY CHILDHOOD CENTER",
    "completed": 0,
    "total": 2,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401846
  },
  "402513": {
    "id": 402513,
    "type": "school",
    "name": "SKYVIEW ACADEMY",
    "completed": 32,
    "total": 132,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402514": {
    "id": 402514,
    "type": "school",
    "name": "JOHN MALL HIGH SCHOOL",
    "completed": 11,
    "total": 14,
    "report_id": 402514,
    "parent_type": "district",
    "parent_id": 401825
  },
  "402515": {
    "id": 402515,
    "type": "school",
    "name": "VISTA PEAK 9-12 PREPARATORY",
    "completed": 70,
    "total": 70,
    "report_id": 402515,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402516": {
    "id": 402516,
    "type": "school",
    "name": "NEEDHAM ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 39,
    "report_id": 402516,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402517": {
    "id": 402517,
    "type": "school",
    "name": "TIMBERLINE PK-8",
    "completed": 45,
    "total": 79,
    "report_id": 402517,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402518": {
    "id": 402518,
    "type": "school",
    "name": "RYE ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 22,
    "report_id": 402518,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402519": {
    "id": 402519,
    "type": "school",
    "name": "CAMPBELL ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 23,
    "report_id": 402519,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402520": {
    "id": 402520,
    "type": "school",
    "name": "OLATHE HIGH SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402520,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402521": {
    "id": 402521,
    "type": "school",
    "name": "MAMMOTH HEIGHTS ELEMENTARY",
    "completed": 42,
    "total": 45,
    "report_id": 402521,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402522": {
    "id": 402522,
    "type": "school",
    "name": "HALCYON SCHOOL (SPECIAL EDUCATION)",
    "completed": 2,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402523": {
    "id": 402523,
    "type": "school",
    "name": "HILL CAMPUS OF ARTS AND SCIENCES",
    "completed": 1,
    "total": 56,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402524": {
    "id": 402524,
    "type": "school",
    "name": "MITCHELL HIGH SCHOOL",
    "completed": 59,
    "total": 98,
    "report_id": 402524,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402525": {
    "id": 402525,
    "type": "school",
    "name": "OLANDER ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 33,
    "report_id": 402525,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402526": {
    "id": 402526,
    "type": "school",
    "name": "FLEMING HIGH SCHOOL",
    "completed": 2,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401754
  },
  "402527": {
    "id": 402527,
    "type": "school",
    "name": "ROOSEVELT HIGH SCHOOL",
    "completed": 8,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401728
  },
  "402528": {
    "id": 402528,
    "type": "school",
    "name": "BAKER ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 25,
    "report_id": 402528,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402529": {
    "id": 402529,
    "type": "school",
    "name": "LA JUNTA JR/SR HIGH SCHOOL",
    "completed": 31,
    "total": 43,
    "report_id": 402529,
    "parent_type": "district",
    "parent_id": 401755
  },
  "402530": {
    "id": 402530,
    "type": "school",
    "name": "BEAR CANYON ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 36,
    "report_id": 402530,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402531": {
    "id": 402531,
    "type": "school",
    "name": "WINOGRAD K-8 ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402532": {
    "id": 402532,
    "type": "school",
    "name": "BERGEN MEADOW PRIMARY SCHOOL",
    "completed": 18,
    "total": 19,
    "report_id": 402532,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402533": {
    "id": 402533,
    "type": "school",
    "name": "ROCKY MOUNTAIN CLASSICAL ACADEMY",
    "completed": 0,
    "total": 86,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402534": {
    "id": 402534,
    "type": "school",
    "name": "BELMONT ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 29,
    "report_id": 402534,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402535": {
    "id": 402535,
    "type": "school",
    "name": "DAKOTA RIDGE SENIOR HIGH SCHOOL",
    "completed": 62,
    "total": 91,
    "report_id": 402535,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402536": {
    "id": 402536,
    "type": "school",
    "name": "S. CHRISTA MCAULIFFE STEM ACADEMY",
    "completed": 0,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402537": {
    "id": 402537,
    "type": "school",
    "name": "MESA ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 38,
    "report_id": 402537,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402538": {
    "id": 402538,
    "type": "school",
    "name": "FALCON ELEMENTARY SCHOOL OF TECHNOLOGY",
    "completed": 3,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402539": {
    "id": 402539,
    "type": "school",
    "name": "FORT LOGAN NORTHGATE",
    "completed": 47,
    "total": 47,
    "report_id": 402539,
    "parent_type": "district",
    "parent_id": 401837
  },
  "402540": {
    "id": 402540,
    "type": "school",
    "name": "CENTRAL ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 28,
    "report_id": 402540,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402541": {
    "id": 402541,
    "type": "school",
    "name": "COLUMBINE HILLS ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 24,
    "report_id": 402541,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402542": {
    "id": 402542,
    "type": "school",
    "name": "HAXTUN HIGH SCHOOL",
    "completed": 10,
    "total": 14,
    "report_id": 402542,
    "parent_type": "district",
    "parent_id": 401748
  },
  "402543": {
    "id": 402543,
    "type": "school",
    "name": "OBERON JUNIOR HIGH SCHOOL",
    "completed": 25,
    "total": 37,
    "report_id": 402543,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402544": {
    "id": 402544,
    "type": "school",
    "name": "PIONEER RIDGE ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 36,
    "report_id": 402544,
    "parent_type": "district",
    "parent_id": 401728
  },
  "402545": {
    "id": 402545,
    "type": "school",
    "name": "COLORADO'S FINEST HIGH SCHOOL OF CHOICE",
    "completed": 22,
    "total": 24,
    "report_id": 402545,
    "parent_type": "district",
    "parent_id": 401802
  },
  "402546": {
    "id": 402546,
    "type": "school",
    "name": "LAS ANIMAS HIGH SCHOOL",
    "completed": 13,
    "total": 13,
    "report_id": 402546,
    "parent_type": "district",
    "parent_id": 401753
  },
  "402547": {
    "id": 402547,
    "type": "school",
    "name": "COLFAX ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 30,
    "report_id": 402547,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402548": {
    "id": 402548,
    "type": "school",
    "name": "ARAGON ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 40,
    "report_id": 402548,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402549": {
    "id": 402549,
    "type": "school",
    "name": "CHATFIELD HIGH SCHOOL",
    "completed": 62,
    "total": 105,
    "report_id": 402549,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402550": {
    "id": 402550,
    "type": "school",
    "name": "ELIZABETH MIDDLE SCHOOL",
    "completed": 15,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401737
  },
  "402551": {
    "id": 402551,
    "type": "school",
    "name": "BATTLE ROCK CHARTER SCHOOL",
    "completed": 4,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401726
  },
  "402552": {
    "id": 402552,
    "type": "school",
    "name": "SIERRA GRANDE K-12 SCHOOL",
    "completed": 17,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401829
  },
  "402553": {
    "id": 402553,
    "type": "school",
    "name": "PARADOX VALLEY CHARTER SCHOOL",
    "completed": 0,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401865
  },
  "402554": {
    "id": 402554,
    "type": "school",
    "name": "CARBONDALE COMMUNITY CHARTER SCHOOL",
    "completed": 5,
    "total": 9,
    "report_id": 402554,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402555": {
    "id": 402555,
    "type": "school",
    "name": "MESA ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 23,
    "report_id": 402555,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402556": {
    "id": 402556,
    "type": "school",
    "name": "SHEPARDSON ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 34,
    "report_id": 402556,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402557": {
    "id": 402557,
    "type": "school",
    "name": "LENA ARCHULETA ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402558": {
    "id": 402558,
    "type": "school",
    "name": "PANORAMA MIDDLE SCHOOL",
    "completed": 1,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402559": {
    "id": 402559,
    "type": "school",
    "name": "THE BOYS SCHOOL OF DENVER",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402560": {
    "id": 402560,
    "type": "school",
    "name": "SEVENTH STREET ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401857
  },
  "402561": {
    "id": 402561,
    "type": "school",
    "name": "SHERMAN EARLY CHILDHOOD CENTER",
    "completed": 25,
    "total": 27,
    "report_id": 402561,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402562": {
    "id": 402562,
    "type": "school",
    "name": "PUEBLO ACADEMY OF ARTS",
    "completed": 42,
    "total": 49,
    "report_id": 402562,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402563": {
    "id": 402563,
    "type": "school",
    "name": "CLOVERLEAF HOME EDUCATION",
    "completed": 5,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402564": {
    "id": 402564,
    "type": "school",
    "name": "VIRGINIA COURT ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 37,
    "report_id": 402564,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402565": {
    "id": 402565,
    "type": "school",
    "name": "BRYANT WEBSTER DUAL LANGUAGE ECE-8 SCHOOL",
    "completed": 16,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402566": {
    "id": 402566,
    "type": "school",
    "name": "CENTURY MIDDLE SCHOOL",
    "completed": 48,
    "total": 66,
    "report_id": 402566,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402567": {
    "id": 402567,
    "type": "school",
    "name": "WEST MIDDLE SCHOOL",
    "completed": 26,
    "total": 33,
    "report_id": 402567,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402568": {
    "id": 402568,
    "type": "school",
    "name": "DEER CREEK ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 31,
    "report_id": 402568,
    "parent_type": "district",
    "parent_id": 401897
  },
  "402569": {
    "id": 402569,
    "type": "school",
    "name": "MISSION VIEJO ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402570": {
    "id": 402570,
    "type": "school",
    "name": "DR. MARTIN LUTHER KING JR. EARLY COLLEGE",
    "completed": 1,
    "total": 85,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402571": {
    "id": 402571,
    "type": "school",
    "name": "TELLURIDE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401821
  },
  "402572": {
    "id": 402572,
    "type": "school",
    "name": "GUNNISON HIGH SCHOOL",
    "completed": 6,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401745
  },
  "402573": {
    "id": 402573,
    "type": "school",
    "name": "TRAILBLAZER ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 402573,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402574": {
    "id": 402574,
    "type": "school",
    "name": "SCHMITT ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402575": {
    "id": 402575,
    "type": "school",
    "name": "ROCKRIMMON ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402576": {
    "id": 402576,
    "type": "school",
    "name": "HERITAGE HEIGHTS ACADEMY",
    "completed": 0,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402577": {
    "id": 402577,
    "type": "school",
    "name": "TWAIN ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 27,
    "report_id": 402577,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402578": {
    "id": 402578,
    "type": "school",
    "name": "TRAILBLAZER ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 33,
    "report_id": 402578,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402579": {
    "id": 402579,
    "type": "school",
    "name": "IDALIA JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401854
  },
  "402580": {
    "id": 402580,
    "type": "school",
    "name": "PRAIRIE RIDGE ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 30,
    "report_id": 402580,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402581": {
    "id": 402581,
    "type": "school",
    "name": "NORTHGLENN MIDDLE SCHOOL",
    "completed": 43,
    "total": 65,
    "report_id": 402581,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402582": {
    "id": 402582,
    "type": "school",
    "name": "NIWOT ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 31,
    "report_id": 402582,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402583": {
    "id": 402583,
    "type": "school",
    "name": "DSST: GREEN VALLEY RANCH HIGH SCHOOL",
    "completed": 0,
    "total": 51,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402584": {
    "id": 402584,
    "type": "school",
    "name": "HAGEN EARLY EDUCATION CENTER",
    "completed": 13,
    "total": 15,
    "report_id": 402584,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402585": {
    "id": 402585,
    "type": "school",
    "name": "COLUMBINE MIDDLE SCHOOL",
    "completed": 35,
    "total": 45,
    "report_id": 402585,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402586": {
    "id": 402586,
    "type": "school",
    "name": "STRIVE PREP - RISE",
    "completed": 3,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402587": {
    "id": 402587,
    "type": "school",
    "name": "COMPASS MONTESSORI - WHEAT RIDGE CHARTER SCHOOL",
    "completed": 4,
    "total": 49,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402588": {
    "id": 402588,
    "type": "school",
    "name": "CRAIG MIDDLE SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 402588,
    "parent_type": "district",
    "parent_id": 401774
  },
  "402589": {
    "id": 402589,
    "type": "school",
    "name": "CIMARRON MIDDLE",
    "completed": 60,
    "total": 84,
    "report_id": 402589,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402590": {
    "id": 402590,
    "type": "school",
    "name": "STEVENS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 30,
    "report_id": 402590,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402591": {
    "id": 402591,
    "type": "school",
    "name": "TELLER ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402592": {
    "id": 402592,
    "type": "school",
    "name": "NUCLA MIDDLE SCHOOL",
    "completed": 0,
    "total": 55,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401865
  },
  "402593": {
    "id": 402593,
    "type": "school",
    "name": "WESTMINSTER HIGH SCHOOL",
    "completed": 78,
    "total": 129,
    "report_id": 402593,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402594": {
    "id": 402594,
    "type": "school",
    "name": "MEEKER ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 22,
    "report_id": 402594,
    "parent_type": "district",
    "parent_id": 401725
  },
  "402595": {
    "id": 402595,
    "type": "school",
    "name": "STERLING MIDDLE SCHOOL",
    "completed": 35,
    "total": 36,
    "report_id": 402595,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402596": {
    "id": 402596,
    "type": "school",
    "name": "GRAND JUNCTION HIGH SCHOOL",
    "completed": 75,
    "total": 107,
    "report_id": 402596,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402597": {
    "id": 402597,
    "type": "school",
    "name": "AKRON HIGH SCHOOL",
    "completed": 9,
    "total": 16,
    "report_id": 402597,
    "parent_type": "district",
    "parent_id": 401780
  },
  "402598": {
    "id": 402598,
    "type": "school",
    "name": "COLORADO VIRTUAL ACADEMY (COVA)",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "402599": {
    "id": 402599,
    "type": "school",
    "name": "NEW AMERICA SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 402599,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402600": {
    "id": 402600,
    "type": "school",
    "name": "ROGERS ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 31,
    "report_id": 402600,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402601": {
    "id": 402601,
    "type": "school",
    "name": "DESTINATIONS CAREER ACADEMY OF COLORADO",
    "completed": 0,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401819
  },
  "402602": {
    "id": 402602,
    "type": "school",
    "name": "PRAIRIE WINDS ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402603": {
    "id": 402603,
    "type": "school",
    "name": "FRENCH ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 34,
    "report_id": 402603,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402604": {
    "id": 402604,
    "type": "school",
    "name": "DURANGO HIGH SCHOOL",
    "completed": 72,
    "total": 91,
    "report_id": 402604,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402605": {
    "id": 402605,
    "type": "school",
    "name": "NIKOLA TESLA EDUCATION OPPORTUNITY CENTER",
    "completed": 19,
    "total": 28,
    "report_id": 402605,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402606": {
    "id": 402606,
    "type": "school",
    "name": "PARAGON LEARNING CENTER",
    "completed": 10,
    "total": 10,
    "report_id": 402606,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402607": {
    "id": 402607,
    "type": "school",
    "name": "HANSON ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 27,
    "report_id": 402607,
    "parent_type": "district",
    "parent_id": 401895
  },
  "402608": {
    "id": 402608,
    "type": "school",
    "name": "MITCHELL ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 36,
    "report_id": 402608,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402609": {
    "id": 402609,
    "type": "school",
    "name": "M. SCOTT CARPENTER MIDDLE SCHOOL",
    "completed": 27,
    "total": 36,
    "report_id": 402609,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402610": {
    "id": 402610,
    "type": "school",
    "name": "FRANKLIN ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 37,
    "report_id": 402610,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402611": {
    "id": 402611,
    "type": "school",
    "name": "HOMESTEAD ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402612": {
    "id": 402612,
    "type": "school",
    "name": "COAL RIDGE HIGH SCHOOL",
    "completed": 25,
    "total": 27,
    "report_id": 402612,
    "parent_type": "district",
    "parent_id": 401759
  },
  "402613": {
    "id": 402613,
    "type": "school",
    "name": "BILL METZ ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 39,
    "report_id": 402613,
    "parent_type": "district",
    "parent_id": 401882
  },
  "402614": {
    "id": 402614,
    "type": "school",
    "name": "COTTONWOOD PLAINS ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 37,
    "report_id": 402614,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402615": {
    "id": 402615,
    "type": "school",
    "name": "ENGLEWOOD LEADERSHIP ACADEMY",
    "completed": 3,
    "total": 3,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401802
  },
  "402616": {
    "id": 402616,
    "type": "school",
    "name": "WINDSOR MIDDLE SCHOOL",
    "completed": 40,
    "total": 40,
    "report_id": 402616,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402617": {
    "id": 402617,
    "type": "school",
    "name": "SILVERTHORNE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 31,
    "report_id": 402617,
    "parent_type": "district",
    "parent_id": 401811
  },
  "402618": {
    "id": 402618,
    "type": "school",
    "name": "LITTLE TRAPPERS PRESCHOOL",
    "completed": 3,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401775
  },
  "402619": {
    "id": 402619,
    "type": "school",
    "name": "ORCHARD MESA MIDDLE SCHOOL",
    "completed": 35,
    "total": 41,
    "report_id": 402619,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402620": {
    "id": 402620,
    "type": "school",
    "name": "BRISTOL ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 22,
    "report_id": 402620,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402621": {
    "id": 402621,
    "type": "school",
    "name": "WASHINGTON PRIMARY SCHOOL",
    "completed": 7,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401817
  },
  "402622": {
    "id": 402622,
    "type": "school",
    "name": "TELLURIDE MIDDLE SCHOOL",
    "completed": 1,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401821
  },
  "402623": {
    "id": 402623,
    "type": "school",
    "name": "STEDMAN ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 22,
    "report_id": 402623,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402624": {
    "id": 402624,
    "type": "school",
    "name": "INNOVATIVE CONNECTIONS HIGH SCHOOL",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401859
  },
  "402625": {
    "id": 402625,
    "type": "school",
    "name": "AURORA HILLS MIDDLE SCHOOL",
    "completed": 58,
    "total": 58,
    "report_id": 402625,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402626": {
    "id": 402626,
    "type": "school",
    "name": "MAPLETON EXPEDITIONARY SCHOOL OF THE ARTS",
    "completed": 35,
    "total": 35,
    "report_id": 402626,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402627": {
    "id": 402627,
    "type": "school",
    "name": "SIERRA VISTA ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402628": {
    "id": 402628,
    "type": "school",
    "name": "VALLEY VIEW K-8",
    "completed": 26,
    "total": 27,
    "report_id": 402628,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402629": {
    "id": 402629,
    "type": "school",
    "name": "FOUNDATIONS ACADEMY",
    "completed": 0,
    "total": 51,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402630": {
    "id": 402630,
    "type": "school",
    "name": "LAREDO MIDDLE SCHOOL",
    "completed": 6,
    "total": 75,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402631": {
    "id": 402631,
    "type": "school",
    "name": "RANCH VIEW MIDDLE SCHOOL",
    "completed": 44,
    "total": 63,
    "report_id": 402631,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402632": {
    "id": 402632,
    "type": "school",
    "name": "FALCON CREEK MIDDLE SCHOOL",
    "completed": 0,
    "total": 63,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402633": {
    "id": 402633,
    "type": "school",
    "name": "GILPIN COUNTY ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401826
  },
  "402634": {
    "id": 402634,
    "type": "school",
    "name": "CHINOOK TRAIL ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402636": {
    "id": 402636,
    "type": "school",
    "name": "GREEN MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 28,
    "report_id": 402636,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402637": {
    "id": 402637,
    "type": "school",
    "name": "CENTENNIAL MIDDLE SCHOOL",
    "completed": 43,
    "total": 46,
    "report_id": 402637,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402638": {
    "id": 402638,
    "type": "school",
    "name": "FLORENCE CRITTENTON HIGH SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402639": {
    "id": 402639,
    "type": "school",
    "name": "TOLLGATE ELEMENTARY SCHOOL OF EXPEDITIONARY LEARNING",
    "completed": 37,
    "total": 38,
    "report_id": 402639,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402640": {
    "id": 402640,
    "type": "school",
    "name": "BYERS JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "402641": {
    "id": 402641,
    "type": "school",
    "name": "MAPLETON EARLY COLLEGE HIGH SCHOOL",
    "completed": 16,
    "total": 16,
    "report_id": 402641,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402642": {
    "id": 402642,
    "type": "school",
    "name": "FLETCHER COMMUNITY SCHOOL",
    "completed": 23,
    "total": 23,
    "report_id": 402642,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402643": {
    "id": 402643,
    "type": "school",
    "name": "SOUTHWEST EARLY COLLEGE",
    "completed": 0,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402644": {
    "id": 402644,
    "type": "school",
    "name": "ROARING FORK HIGH SCHOOL",
    "completed": 27,
    "total": 37,
    "report_id": 402644,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402645": {
    "id": 402645,
    "type": "school",
    "name": "LINCOLN ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402646": {
    "id": 402646,
    "type": "school",
    "name": "ASHLEY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402647": {
    "id": 402647,
    "type": "school",
    "name": "EIBER ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 32,
    "report_id": 402647,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402648": {
    "id": 402648,
    "type": "school",
    "name": "SILVER CREEK HIGH SCHOOL",
    "completed": 38,
    "total": 69,
    "report_id": 402648,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402649": {
    "id": 402649,
    "type": "school",
    "name": "TRINIDAD MIDDLE SCHOOL",
    "completed": 5,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401732
  },
  "402650": {
    "id": 402650,
    "type": "school",
    "name": "THORNTON ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 39,
    "report_id": 402650,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402651": {
    "id": 402651,
    "type": "school",
    "name": "MANITOU SPRINGS HIGH SCHOOL",
    "completed": 16,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401898
  },
  "402652": {
    "id": 402652,
    "type": "school",
    "name": "CROWLEY COUNTY ELEMENTARY K-6",
    "completed": 6,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401810
  },
  "402653": {
    "id": 402653,
    "type": "school",
    "name": "TELLURIDE HIGH SCHOOL",
    "completed": 2,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401821
  },
  "402654": {
    "id": 402654,
    "type": "school",
    "name": "THE VANGUARD SCHOOL (HIGH)",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "402655": {
    "id": 402655,
    "type": "school",
    "name": "UNIVERSITY SCHOOLS",
    "completed": 16,
    "total": 129,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402656": {
    "id": 402656,
    "type": "school",
    "name": "SLATER ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 27,
    "report_id": 402656,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402657": {
    "id": 402657,
    "type": "school",
    "name": "SUPERIOR ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 32,
    "report_id": 402657,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402658": {
    "id": 402658,
    "type": "school",
    "name": "VANDERHOOF ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 34,
    "report_id": 402658,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402659": {
    "id": 402659,
    "type": "school",
    "name": "ANTELOPE TRAILS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402660": {
    "id": 402660,
    "type": "school",
    "name": "ARAPAHOE HIGH SCHOOL",
    "completed": 70,
    "total": 120,
    "report_id": 402660,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402661": {
    "id": 402661,
    "type": "school",
    "name": "SANGRE DE CRISTO ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401850
  },
  "402662": {
    "id": 402662,
    "type": "school",
    "name": "AURORA WEST COLLEGE PREPARATORY ACADEMY",
    "completed": 65,
    "total": 77,
    "report_id": 402662,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402663": {
    "id": 402663,
    "type": "school",
    "name": "LAUREL ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 41,
    "report_id": 402663,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402664": {
    "id": 402664,
    "type": "school",
    "name": "CENTENNIAL ELEMENTARY",
    "completed": 22,
    "total": 36,
    "report_id": 402664,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402665": {
    "id": 402665,
    "type": "school",
    "name": "ALICE TERRY ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 402665,
    "parent_type": "district",
    "parent_id": 401837
  },
  "402666": {
    "id": 402666,
    "type": "school",
    "name": "JACK SWIGERT AEROSPACE ACADEMY",
    "completed": 36,
    "total": 44,
    "report_id": 402666,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402667": {
    "id": 402667,
    "type": "school",
    "name": "BELMAR ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 26,
    "report_id": 402667,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402668": {
    "id": 402668,
    "type": "school",
    "name": "EYESTONE ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 37,
    "report_id": 402668,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402669": {
    "id": 402669,
    "type": "school",
    "name": "GRANADA ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401799
  },
  "402670": {
    "id": 402670,
    "type": "school",
    "name": "GREELEY CENTRAL HIGH SCHOOL",
    "completed": 17,
    "total": 78,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402671": {
    "id": 402671,
    "type": "school",
    "name": "THE PINNACLE CHARTER SCHOOL MIDDLE",
    "completed": 22,
    "total": 38,
    "report_id": 402671,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402672": {
    "id": 402672,
    "type": "school",
    "name": "REDSTONE ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 36,
    "report_id": 402672,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402673": {
    "id": 402673,
    "type": "school",
    "name": "OTHO E STUART MIDDLE SCHOOL",
    "completed": 44,
    "total": 44,
    "report_id": 402673,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402674": {
    "id": 402674,
    "type": "school",
    "name": "HINKLEY HIGH SCHOOL",
    "completed": 105,
    "total": 121,
    "report_id": 402674,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402675": {
    "id": 402675,
    "type": "school",
    "name": "INDIAN PEAKS ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 29,
    "report_id": 402675,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402676": {
    "id": 402676,
    "type": "school",
    "name": "MOUNTAIN RIDGE MIDDLE SCHOOL",
    "completed": 30,
    "total": 77,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402677": {
    "id": 402677,
    "type": "school",
    "name": "BOULDER HIGH SCHOOL",
    "completed": 98,
    "total": 124,
    "report_id": 402677,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402678": {
    "id": 402678,
    "type": "school",
    "name": "ROCKY MOUNTAIN SCHOOL OF EXPEDITIONARY LEARNING",
    "completed": 29,
    "total": 33,
    "report_id": 402678,
    "parent_type": "district",
    "parent_id": 401871
  },
  "402679": {
    "id": 402679,
    "type": "school",
    "name": "FRISCO ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 24,
    "report_id": 402679,
    "parent_type": "district",
    "parent_id": 401811
  },
  "402680": {
    "id": 402680,
    "type": "school",
    "name": "CANYON CREEK ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402681": {
    "id": 402681,
    "type": "school",
    "name": "MOUNTAIN VIEW ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402682": {
    "id": 402682,
    "type": "school",
    "name": "STEAMBOAT SPRINGS MIDDLE SCHOOL",
    "completed": 46,
    "total": 46,
    "report_id": 402682,
    "parent_type": "district",
    "parent_id": 401846
  },
  "402683": {
    "id": 402683,
    "type": "school",
    "name": "SADDLE RANCH ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 30,
    "report_id": 402683,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402684": {
    "id": 402684,
    "type": "school",
    "name": "CRYSTAL RIVER ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 43,
    "report_id": 402684,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402685": {
    "id": 402685,
    "type": "school",
    "name": "HORIZONS K-8 SCHOOL",
    "completed": 10,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402686": {
    "id": 402686,
    "type": "school",
    "name": "JUSTICE HIGH CHARTER SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402687": {
    "id": 402687,
    "type": "school",
    "name": "HANOVER JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401781
  },
  "402688": {
    "id": 402688,
    "type": "school",
    "name": "EAGLE RIDGE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 43,
    "report_id": 402688,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402689": {
    "id": 402689,
    "type": "school",
    "name": "TIMBER TRAIL ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 28,
    "report_id": 402689,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402690": {
    "id": 402690,
    "type": "school",
    "name": "MIDDLE SCHOOL 11",
    "completed": 12,
    "total": 15,
    "report_id": 402690,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402691": {
    "id": 402691,
    "type": "school",
    "name": "IGNACIO HIGH SCHOOL",
    "completed": 16,
    "total": 28,
    "report_id": 402691,
    "parent_type": "district",
    "parent_id": 401779
  },
  "402692": {
    "id": 402692,
    "type": "school",
    "name": "EATON MIDDLE SCHOOL",
    "completed": 19,
    "total": 32,
    "report_id": 402692,
    "parent_type": "district",
    "parent_id": 401868
  },
  "402693": {
    "id": 402693,
    "type": "school",
    "name": "DORAL ACADEMY OF COLORADO",
    "completed": 0,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402694": {
    "id": 402694,
    "type": "school",
    "name": "WILSON ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 33,
    "report_id": 402694,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402695": {
    "id": 402695,
    "type": "school",
    "name": "SAGEWOOD MIDDLE SCHOOL",
    "completed": 44,
    "total": 64,
    "report_id": 402695,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402696": {
    "id": 402696,
    "type": "school",
    "name": "GLOBAL VILLAGE ACADEMY - NORTHGLENN",
    "completed": 1,
    "total": 79,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402697": {
    "id": 402697,
    "type": "school",
    "name": "HARRIS PARK ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 23,
    "report_id": 402697,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402698": {
    "id": 402698,
    "type": "school",
    "name": "MOUNTAIN VISTA ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402699": {
    "id": 402699,
    "type": "school",
    "name": "UTE MEADOWS ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 402699,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402700": {
    "id": 402700,
    "type": "school",
    "name": "VAIL SKI AND SNOWBOARD ACADEMY (VSSA)",
    "completed": 6,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402701": {
    "id": 402701,
    "type": "school",
    "name": "SOUTH PARK ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 23,
    "report_id": 402701,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402702": {
    "id": 402702,
    "type": "school",
    "name": "SABLE ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 35,
    "report_id": 402702,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402703": {
    "id": 402703,
    "type": "school",
    "name": "MOUNTAIN VISTA HIGH SCHOOL",
    "completed": 80,
    "total": 139,
    "report_id": 402703,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402704": {
    "id": 402704,
    "type": "school",
    "name": "ROCKY FORD JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 24,
    "total": 29,
    "report_id": 402704,
    "parent_type": "district",
    "parent_id": 401817
  },
  "402705": {
    "id": 402705,
    "type": "school",
    "name": "LIBERTY SCHOOL",
    "completed": 1,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401877
  },
  "402706": {
    "id": 402706,
    "type": "school",
    "name": "HUTCHINSON ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 25,
    "report_id": 402706,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402707": {
    "id": 402707,
    "type": "school",
    "name": "CRESTED BUTTE ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 30,
    "report_id": 402707,
    "parent_type": "district",
    "parent_id": 401745
  },
  "402708": {
    "id": 402708,
    "type": "school",
    "name": "FLORENCE HIGH SCHOOL",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401875
  },
  "402709": {
    "id": 402709,
    "type": "school",
    "name": "ST. VRAIN COMMUNITY MONTESSORI SCHOOL",
    "completed": 3,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402710": {
    "id": 402710,
    "type": "school",
    "name": "MANDALAY MIDDLE SCHOOL",
    "completed": 17,
    "total": 31,
    "report_id": 402710,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402711": {
    "id": 402711,
    "type": "school",
    "name": "JULESBURG HIGH SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401819
  },
  "402712": {
    "id": 402712,
    "type": "school",
    "name": "SOUTHWEST OPEN CHARTER SCHOOL",
    "completed": 9,
    "total": 10,
    "report_id": 402712,
    "parent_type": "district",
    "parent_id": 401726
  },
  "402713": {
    "id": 402713,
    "type": "school",
    "name": "NEW VISTA HIGH SCHOOL",
    "completed": 15,
    "total": 26,
    "report_id": 402713,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402714": {
    "id": 402714,
    "type": "school",
    "name": "HOTCHKISS ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 402714,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402715": {
    "id": 402715,
    "type": "school",
    "name": "BAUDER ELEMENTARY SCHOOL",
    "completed": 42,
    "total": 42,
    "report_id": 402715,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402716": {
    "id": 402716,
    "type": "school",
    "name": "VILLAGE AT NORTH",
    "completed": 10,
    "total": 16,
    "report_id": 402716,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402717": {
    "id": 402717,
    "type": "school",
    "name": "GRANBY ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 35,
    "report_id": 402717,
    "parent_type": "district",
    "parent_id": 401747
  },
  "402718": {
    "id": 402718,
    "type": "school",
    "name": "CHERRY DRIVE ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402719": {
    "id": 402719,
    "type": "school",
    "name": "CHAPARRAL HIGH SCHOOL",
    "completed": 74,
    "total": 137,
    "report_id": 402719,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402720": {
    "id": 402720,
    "type": "school",
    "name": "ROCKY MOUNTAIN HIGH SCHOOL",
    "completed": 103,
    "total": 119,
    "report_id": 402720,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402721": {
    "id": 402721,
    "type": "school",
    "name": "LONGS PEAK MIDDLE SCHOOL",
    "completed": 22,
    "total": 35,
    "report_id": 402721,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402722": {
    "id": 402722,
    "type": "school",
    "name": "RENAISSANCE EXPEDITION LEARN OUTWARD BOUND SCHOOL",
    "completed": 20,
    "total": 26,
    "report_id": 402722,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402723": {
    "id": 402723,
    "type": "school",
    "name": "YUMA MIDDLE SCHOOL",
    "completed": 20,
    "total": 23,
    "report_id": 402723,
    "parent_type": "district",
    "parent_id": 401886
  },
  "402724": {
    "id": 402724,
    "type": "school",
    "name": "AIM GLOBAL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401753
  },
  "402725": {
    "id": 402725,
    "type": "school",
    "name": "RIFLE MIDDLE SCHOOL",
    "completed": 4,
    "total": 69,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401759
  },
  "402726": {
    "id": 402726,
    "type": "school",
    "name": "JUMP START LEARNING CENTER",
    "completed": 0,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401753
  },
  "402727": {
    "id": 402727,
    "type": "school",
    "name": "PINELLO ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 29,
    "report_id": 402727,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402728": {
    "id": 402728,
    "type": "school",
    "name": "DISCOVERY CANYON CAMPUS HIGH SCHOOL",
    "completed": 16,
    "total": 84,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402729": {
    "id": 402729,
    "type": "school",
    "name": "SKYLINE HIGH SCHOOL",
    "completed": 49,
    "total": 79,
    "report_id": 402729,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402730": {
    "id": 402730,
    "type": "school",
    "name": "OVERLAND HIGH SCHOOL",
    "completed": 39,
    "total": 166,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402731": {
    "id": 402731,
    "type": "school",
    "name": "EAST ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 33,
    "report_id": 402731,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402732": {
    "id": 402732,
    "type": "school",
    "name": "HARRIS BILINGUAL ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 30,
    "report_id": 402732,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402733": {
    "id": 402733,
    "type": "school",
    "name": "JENKINS MIDDLE SCHOOL",
    "completed": 61,
    "total": 62,
    "report_id": 402733,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402734": {
    "id": 402734,
    "type": "school",
    "name": "WHEELING ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 38,
    "report_id": 402734,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402735": {
    "id": 402735,
    "type": "school",
    "name": "CASTLE ROCK ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 36,
    "report_id": 402735,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402736": {
    "id": 402736,
    "type": "school",
    "name": "EAGLE VALLEY ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 30,
    "report_id": 402736,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402737": {
    "id": 402737,
    "type": "school",
    "name": "DALTON ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 29,
    "report_id": 402737,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402738": {
    "id": 402738,
    "type": "school",
    "name": "PIONEER ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 27,
    "report_id": 402738,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402739": {
    "id": 402739,
    "type": "school",
    "name": "CHAVEZ/HUERTA K-12 PREPARATORY ACADEMY",
    "completed": 21,
    "total": 68,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402740": {
    "id": 402740,
    "type": "school",
    "name": "LINCOLN HIGH SCHOOL",
    "completed": 5,
    "total": 6,
    "report_id": 402740,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402741": {
    "id": 402741,
    "type": "school",
    "name": "SHERRELWOOD ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 21,
    "report_id": 402741,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402742": {
    "id": 402742,
    "type": "school",
    "name": "PLATTEVILLE ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 33,
    "report_id": 402742,
    "parent_type": "district",
    "parent_id": 401902
  },
  "402743": {
    "id": 402743,
    "type": "school",
    "name": "HEMPHILL MIDDLE SCHOOL",
    "completed": 4,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401791
  },
  "402744": {
    "id": 402744,
    "type": "school",
    "name": "BRANTNER ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402744,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402745": {
    "id": 402745,
    "type": "school",
    "name": "BEULAH HEIGHTS ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 402745,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402746": {
    "id": 402746,
    "type": "school",
    "name": "ARICKAREE ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401873
  },
  "402747": {
    "id": 402747,
    "type": "school",
    "name": "VINELAND ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402748": {
    "id": 402748,
    "type": "school",
    "name": "PRAIRIE CROSSING ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 39,
    "report_id": 402748,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402749": {
    "id": 402749,
    "type": "school",
    "name": "SARAH MILNER ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 402749,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402750": {
    "id": 402750,
    "type": "school",
    "name": "HERITAGE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 34,
    "report_id": 402750,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402751": {
    "id": 402751,
    "type": "school",
    "name": "SUNRISE ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 36,
    "report_id": 402751,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402752": {
    "id": 402752,
    "type": "school",
    "name": "MANUAL HIGH SCHOOL",
    "completed": 0,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402753": {
    "id": 402753,
    "type": "school",
    "name": "TCA COLLEGE PATHWAYS",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402754": {
    "id": 402754,
    "type": "school",
    "name": "JACKSON ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 36,
    "report_id": 402754,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402755": {
    "id": 402755,
    "type": "school",
    "name": "MEEKER HIGH SCHOOL",
    "completed": 8,
    "total": 13,
    "report_id": 402755,
    "parent_type": "district",
    "parent_id": 401725
  },
  "402756": {
    "id": 402756,
    "type": "school",
    "name": "PIKES PEAK PREP",
    "completed": 2,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402757": {
    "id": 402757,
    "type": "school",
    "name": "FOOTHILLS ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 31,
    "report_id": 402757,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402758": {
    "id": 402758,
    "type": "school",
    "name": "HIGH PEAKS ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 24,
    "report_id": 402758,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402759": {
    "id": 402759,
    "type": "school",
    "name": "PRAIRIE PRESCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402760": {
    "id": 402760,
    "type": "school",
    "name": "RIFLE HIGH SCHOOL",
    "completed": 39,
    "total": 59,
    "report_id": 402760,
    "parent_type": "district",
    "parent_id": 401759
  },
  "402761": {
    "id": 402761,
    "type": "school",
    "name": "MONFORT ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 27,
    "report_id": 402761,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402763": {
    "id": 402763,
    "type": "school",
    "name": "BACON ELEMENTARY SCHOOL",
    "completed": 41,
    "total": 41,
    "report_id": 402763,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402764": {
    "id": 402764,
    "type": "school",
    "name": "CENTENNIAL ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402765": {
    "id": 402765,
    "type": "school",
    "name": "IRVING ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 26,
    "report_id": 402765,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402766": {
    "id": 402766,
    "type": "school",
    "name": "LAMAR MIDDLE SCHOOL",
    "completed": 19,
    "total": 25,
    "report_id": 402766,
    "parent_type": "district",
    "parent_id": 401730
  },
  "402767": {
    "id": 402767,
    "type": "school",
    "name": "STRIVE PREP - EXCEL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402768": {
    "id": 402768,
    "type": "school",
    "name": "EAST MIDDLE SCHOOL",
    "completed": 60,
    "total": 69,
    "report_id": 402768,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402769": {
    "id": 402769,
    "type": "school",
    "name": "SMITH ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402770": {
    "id": 402770,
    "type": "school",
    "name": "SANFORD ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401739
  },
  "402771": {
    "id": 402771,
    "type": "school",
    "name": "GOAL ACADEMY",
    "completed": 70,
    "total": 142,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402772": {
    "id": 402772,
    "type": "school",
    "name": "DEER TRAIL JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401744
  },
  "402773": {
    "id": 402773,
    "type": "school",
    "name": "GLOBAL PRIMARY ACADEMY",
    "completed": 18,
    "total": 18,
    "report_id": 402773,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402774": {
    "id": 402774,
    "type": "school",
    "name": "WOODLIN UNDIVIDED HIGH SCHOOL",
    "completed": 11,
    "total": 11,
    "report_id": 402774,
    "parent_type": "district",
    "parent_id": 401740
  },
  "402775": {
    "id": 402775,
    "type": "school",
    "name": "ACADEMY ONLINE",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402776": {
    "id": 402776,
    "type": "school",
    "name": "DOVE CREEK HIGH SCHOOL",
    "completed": 5,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401857
  },
  "402777": {
    "id": 402777,
    "type": "school",
    "name": "COLORADO SCHOOL FOR THE DEAF AND BLIND",
    "completed": 0,
    "total": 66,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401757
  },
  "402778": {
    "id": 402778,
    "type": "school",
    "name": "MANCOS MIDDLE SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401789
  },
  "402779": {
    "id": 402779,
    "type": "school",
    "name": "SUMMIT HIGH SCHOOL",
    "completed": 53,
    "total": 71,
    "report_id": 402779,
    "parent_type": "district",
    "parent_id": 401811
  },
  "402780": {
    "id": 402780,
    "type": "school",
    "name": "MOUNT GARFIELD MIDDLE SCHOOL",
    "completed": 39,
    "total": 53,
    "report_id": 402780,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402781": {
    "id": 402781,
    "type": "school",
    "name": "AKRON ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401780
  },
  "402782": {
    "id": 402782,
    "type": "school",
    "name": "COLORADO CONNECTIONS ACADEMY",
    "completed": 0,
    "total": 75,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402783": {
    "id": 402783,
    "type": "school",
    "name": "FOX HOLLOW ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 51,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402784": {
    "id": 402784,
    "type": "school",
    "name": "ADDENBROOKE CLASSICAL GRAMMAR SCHOOL",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402785": {
    "id": 402785,
    "type": "school",
    "name": "BRUSH CREEK ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402786": {
    "id": 402786,
    "type": "school",
    "name": "STRATTON ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 26,
    "report_id": 402786,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402787": {
    "id": 402787,
    "type": "school",
    "name": "MONTEREY COMMUNITY SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 402787,
    "parent_type": "district",
    "parent_id": 401891
  },
  "402788": {
    "id": 402788,
    "type": "school",
    "name": "STRIVE PREP - LAKE",
    "completed": 0,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402789": {
    "id": 402789,
    "type": "school",
    "name": "CHATFIELD ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 29,
    "report_id": 402789,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402790": {
    "id": 402790,
    "type": "school",
    "name": "ANTELOPE RIDGE ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 48,
    "report_id": 402790,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402791": {
    "id": 402791,
    "type": "school",
    "name": "SARGENT JUNIOR HIGH SCHOOL",
    "completed": 5,
    "total": 5,
    "report_id": 402791,
    "parent_type": "district",
    "parent_id": 401841
  },
  "402792": {
    "id": 402792,
    "type": "school",
    "name": "CENTENNIAL ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 41,
    "report_id": 402792,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402793": {
    "id": 402793,
    "type": "school",
    "name": "CACHE LA POUDRE MIDDLE SCHOOL",
    "completed": 24,
    "total": 26,
    "report_id": 402793,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402794": {
    "id": 402794,
    "type": "school",
    "name": "EDISON PREP",
    "completed": 0,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401738
  },
  "402795": {
    "id": 402795,
    "type": "school",
    "name": "SAND CREEK ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402796": {
    "id": 402796,
    "type": "school",
    "name": "WEST GRAND ELEMENTARY  AND MIDDLE SCHOOL",
    "completed": 21,
    "total": 30,
    "report_id": 402796,
    "parent_type": "district",
    "parent_id": 401899
  },
  "402797": {
    "id": 402797,
    "type": "school",
    "name": "EADS ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 9,
    "report_id": 402797,
    "parent_type": "district",
    "parent_id": 401778
  },
  "402798": {
    "id": 402798,
    "type": "school",
    "name": "COMPASS ACADEMY",
    "completed": 0,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402799": {
    "id": 402799,
    "type": "school",
    "name": "PROSPECT VALLEY ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402800": {
    "id": 402800,
    "type": "school",
    "name": "ADAMS CITY HIGH SCHOOL",
    "completed": 76,
    "total": 104,
    "report_id": 402800,
    "parent_type": "district",
    "parent_id": 401895
  },
  "402801": {
    "id": 402801,
    "type": "school",
    "name": "LEGACY ACADEMY",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401737
  },
  "402802": {
    "id": 402802,
    "type": "school",
    "name": "DEL NORTE HIGH SCHOOL",
    "completed": 12,
    "total": 16,
    "report_id": 402802,
    "parent_type": "district",
    "parent_id": 401869
  },
  "402803": {
    "id": 402803,
    "type": "school",
    "name": "GYPSUM CREEK MIDDLE SCHOOL",
    "completed": 21,
    "total": 28,
    "report_id": 402803,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402804": {
    "id": 402804,
    "type": "school",
    "name": "GRANADA UNDIVIDED HIGH SCHOOL",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401799
  },
  "402805": {
    "id": 402805,
    "type": "school",
    "name": "WARREN TECH CENTRAL",
    "completed": 40,
    "total": 46,
    "report_id": 402805,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402806": {
    "id": 402806,
    "type": "school",
    "name": "SIERRA HIGH SCHOOL",
    "completed": 14,
    "total": 56,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402807": {
    "id": 402807,
    "type": "school",
    "name": "SUMMIT ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402808": {
    "id": 402808,
    "type": "school",
    "name": "ROCKY MOUNTAIN ACADEMY OF EVERGREEN",
    "completed": 22,
    "total": 26,
    "report_id": 402808,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402809": {
    "id": 402809,
    "type": "school",
    "name": "PITTS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 3,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401797
  },
  "402810": {
    "id": 402810,
    "type": "school",
    "name": "MONACO ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 34,
    "report_id": 402810,
    "parent_type": "district",
    "parent_id": 401895
  },
  "402811": {
    "id": 402811,
    "type": "school",
    "name": "AURORA QUEST K-8",
    "completed": 28,
    "total": 35,
    "report_id": 402811,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402812": {
    "id": 402812,
    "type": "school",
    "name": "HEATH MIDDLE SCHOOL",
    "completed": 33,
    "total": 43,
    "report_id": 402812,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402813": {
    "id": 402813,
    "type": "school",
    "name": "SUNRISE ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 49,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402814": {
    "id": 402814,
    "type": "school",
    "name": "CENTAURI MIDDLE SCHOOL",
    "completed": 7,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401903
  },
  "402815": {
    "id": 402815,
    "type": "school",
    "name": "CARLSON ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401856
  },
  "402816": {
    "id": 402816,
    "type": "school",
    "name": "TURNBERRY ELEMENTARY",
    "completed": 42,
    "total": 42,
    "report_id": 402816,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402817": {
    "id": 402817,
    "type": "school",
    "name": "WHEAT RIDGE HIGH SCHOOL",
    "completed": 68,
    "total": 76,
    "report_id": 402817,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402818": {
    "id": 402818,
    "type": "school",
    "name": "HIGHLINE ACADEMY SOUTHEAST",
    "completed": 0,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402819": {
    "id": 402819,
    "type": "school",
    "name": "MESA ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 23,
    "report_id": 402819,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402820": {
    "id": 402820,
    "type": "school",
    "name": "COLORADO SPRINGS CHARTER ACADEMY",
    "completed": 0,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402821": {
    "id": 402821,
    "type": "school",
    "name": "NORTH MIDDLE SCHOOL",
    "completed": 33,
    "total": 55,
    "report_id": 402821,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402822": {
    "id": 402822,
    "type": "school",
    "name": "BOLTZ MIDDLE SCHOOL",
    "completed": 41,
    "total": 43,
    "report_id": 402822,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402823": {
    "id": 402823,
    "type": "school",
    "name": "VENTURE PREP HIGH SCHOOL",
    "completed": 1,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402824": {
    "id": 402824,
    "type": "school",
    "name": "PAWNEE SCHOOL PK-12",
    "completed": 2,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401866
  },
  "402825": {
    "id": 402825,
    "type": "school",
    "name": "FOX MEADOW MIDDLE SCHOOL",
    "completed": 0,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402826": {
    "id": 402826,
    "type": "school",
    "name": "VICTORY PREPARATORY ACADEMY HIGH STATE CHARTER SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402827": {
    "id": 402827,
    "type": "school",
    "name": "SALIDA EARLY CHILDHOOD CENTER",
    "completed": 2,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401858
  },
  "402828": {
    "id": 402828,
    "type": "school",
    "name": "SILVER HILLS MIDDLE SCHOOL",
    "completed": 39,
    "total": 74,
    "report_id": 402828,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402829": {
    "id": 402829,
    "type": "school",
    "name": "SWALLOWS CHARTER ACADEMY HIGH SCHOOL",
    "completed": 0,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402830": {
    "id": 402830,
    "type": "school",
    "name": "RISEUP COMMUNITY SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402831": {
    "id": 402831,
    "type": "school",
    "name": "RYAN ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 38,
    "report_id": 402831,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402832": {
    "id": 402832,
    "type": "school",
    "name": "CLYDE MILLER K-8",
    "completed": 34,
    "total": 37,
    "report_id": 402832,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402833": {
    "id": 402833,
    "type": "school",
    "name": "FREMONT MIDDLE SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401875
  },
  "402834": {
    "id": 402834,
    "type": "school",
    "name": "OTERO ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 27,
    "report_id": 402834,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402835": {
    "id": 402835,
    "type": "school",
    "name": "AURORA ACADEMY CHARTER SCHOOL",
    "completed": 24,
    "total": 43,
    "report_id": 402835,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402836": {
    "id": 402836,
    "type": "school",
    "name": "PLATEAU VALLEY MIDDLE SCHOOL",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401731
  },
  "402837": {
    "id": 402837,
    "type": "school",
    "name": "DEER TRAIL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401744
  },
  "402838": {
    "id": 402838,
    "type": "school",
    "name": "DAKOTA VALLEY ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402839": {
    "id": 402839,
    "type": "school",
    "name": "NORTHSIDE ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 402839,
    "parent_type": "district",
    "parent_id": 401724
  },
  "402840": {
    "id": 402840,
    "type": "school",
    "name": "STRIVE PREP - SMART ACADEMY",
    "completed": 0,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402841": {
    "id": 402841,
    "type": "school",
    "name": "RIFFENBURGH ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 37,
    "report_id": 402841,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402842": {
    "id": 402842,
    "type": "school",
    "name": "HENRY WORLD SCHOOL",
    "completed": 3,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402843": {
    "id": 402843,
    "type": "school",
    "name": "FULTON ACADEMY OF EXCELLENCE",
    "completed": 31,
    "total": 33,
    "report_id": 402843,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402844": {
    "id": 402844,
    "type": "school",
    "name": "EDISON ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402845": {
    "id": 402845,
    "type": "school",
    "name": "RIVERVIEW SCHOOL",
    "completed": 24,
    "total": 30,
    "report_id": 402845,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402846": {
    "id": 402846,
    "type": "school",
    "name": "SEVERANCE MIDDLE SCHOOL",
    "completed": 33,
    "total": 39,
    "report_id": 402846,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402847": {
    "id": 402847,
    "type": "school",
    "name": "FOSSIL RIDGE HIGH SCHOOL",
    "completed": 82,
    "total": 119,
    "report_id": 402847,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402848": {
    "id": 402848,
    "type": "school",
    "name": "DSST COLLEGE VIEW HIGH SCHOOL",
    "completed": 0,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402849": {
    "id": 402849,
    "type": "school",
    "name": "ST. VRAIN GLOBAL ONLINE ACADEMY",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402850": {
    "id": 402850,
    "type": "school",
    "name": "VAN BUREN ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 21,
    "report_id": 402850,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402851": {
    "id": 402851,
    "type": "school",
    "name": "COLUMBINE HIGH SCHOOL",
    "completed": 69,
    "total": 107,
    "report_id": 402851,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402852": {
    "id": 402852,
    "type": "school",
    "name": "JORDAHL ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402853": {
    "id": 402853,
    "type": "school",
    "name": "SOUTHEAST ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 34,
    "report_id": 402853,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402854": {
    "id": 402854,
    "type": "school",
    "name": "EAGLE COUNTY CHARTER ACADEMY",
    "completed": 27,
    "total": 27,
    "report_id": 402854,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402855": {
    "id": 402855,
    "type": "school",
    "name": "PRAIRIE VIEW MIDDLE SCHOOL",
    "completed": 47,
    "total": 47,
    "report_id": 402855,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402856": {
    "id": 402856,
    "type": "school",
    "name": "PRAIRIE HILLS ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402857": {
    "id": 402857,
    "type": "school",
    "name": "SPRINGFIELD ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401881
  },
  "402858": {
    "id": 402858,
    "type": "school",
    "name": "RIDGE VIEW ACADEMY CHARTER SCHOOL",
    "completed": 3,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402859": {
    "id": 402859,
    "type": "school",
    "name": "CHERRY HILLS VILLAGE ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402860": {
    "id": 402860,
    "type": "school",
    "name": "COLORADO STEM ACADEMY",
    "completed": 7,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402861": {
    "id": 402861,
    "type": "school",
    "name": "VALIANT ACADEMY",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "402862": {
    "id": 402862,
    "type": "school",
    "name": "GYPSUM ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 26,
    "report_id": 402862,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402863": {
    "id": 402863,
    "type": "school",
    "name": "KNAPP ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 53,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402864": {
    "id": 402864,
    "type": "school",
    "name": "PEETZ JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 8,
    "total": 10,
    "report_id": 402864,
    "parent_type": "district",
    "parent_id": 401840
  },
  "402865": {
    "id": 402865,
    "type": "school",
    "name": "MARTIN LUTHER KING JR ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402866": {
    "id": 402866,
    "type": "school",
    "name": "WINONA ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 42,
    "report_id": 402866,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402867": {
    "id": 402867,
    "type": "school",
    "name": "IDALIA ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 14,
    "report_id": 402867,
    "parent_type": "district",
    "parent_id": 401854
  },
  "402868": {
    "id": 402868,
    "type": "school",
    "name": "DELTA VISION SCHOOL",
    "completed": 30,
    "total": 34,
    "report_id": 402868,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402869": {
    "id": 402869,
    "type": "school",
    "name": "COMMUNITY PREP CHARTER SCHOOL",
    "completed": 15,
    "total": 25,
    "report_id": 402869,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402870": {
    "id": 402870,
    "type": "school",
    "name": "BARONE MIDDLE SCHOOL",
    "completed": 7,
    "total": 11,
    "report_id": 402870,
    "parent_type": "district",
    "parent_id": 401725
  },
  "402871": {
    "id": 402871,
    "type": "school",
    "name": "BROMWELL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402872": {
    "id": 402872,
    "type": "school",
    "name": "CARBONDALE MIDDLE SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 402872,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402873": {
    "id": 402873,
    "type": "school",
    "name": "CAMPBELL ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 33,
    "report_id": 402873,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402874": {
    "id": 402874,
    "type": "school",
    "name": "UNIVERSITY HILL ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 43,
    "report_id": 402874,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402875": {
    "id": 402875,
    "type": "school",
    "name": "COLORADO DIGITAL ACADEMY - ELEMENTARY",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "402876": {
    "id": 402876,
    "type": "school",
    "name": "PIONEER BILINGUAL ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 43,
    "report_id": 402876,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402877": {
    "id": 402877,
    "type": "school",
    "name": "BRADFORD ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 32,
    "report_id": 402877,
    "parent_type": "district",
    "parent_id": 401832
  },
  "402878": {
    "id": 402878,
    "type": "school",
    "name": "NORTH FORK MONTESSORI @ CRAWFORD",
    "completed": 13,
    "total": 13,
    "report_id": 402878,
    "parent_type": "district",
    "parent_id": 401807
  },
  "402879": {
    "id": 402879,
    "type": "school",
    "name": "WHITTIER K-8 SCHOOL",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402880": {
    "id": 402880,
    "type": "school",
    "name": "MESA VIEW ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 402880,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402881": {
    "id": 402881,
    "type": "school",
    "name": "PONDEROSA HIGH SCHOOL",
    "completed": 56,
    "total": 79,
    "report_id": 402881,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402882": {
    "id": 402882,
    "type": "school",
    "name": "STEELE ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402883": {
    "id": 402883,
    "type": "school",
    "name": "CASEY MIDDLE SCHOOL",
    "completed": 34,
    "total": 53,
    "report_id": 402883,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402884": {
    "id": 402884,
    "type": "school",
    "name": "MONTE VISTA ON-LINE ACADEMY",
    "completed": 7,
    "total": 7,
    "report_id": 402884,
    "parent_type": "district",
    "parent_id": 401882
  },
  "402885": {
    "id": 402885,
    "type": "school",
    "name": "RED HAWK RIDGE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 69,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402886": {
    "id": 402886,
    "type": "school",
    "name": "COLUMBINE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401844
  },
  "402887": {
    "id": 402887,
    "type": "school",
    "name": "MANASSA ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401903
  },
  "402888": {
    "id": 402888,
    "type": "school",
    "name": "STERLING HIGH SCHOOL",
    "completed": 21,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401878
  },
  "402890": {
    "id": 402890,
    "type": "school",
    "name": "STONE CREEK SCHOOL",
    "completed": 11,
    "total": 56,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402891": {
    "id": 402891,
    "type": "school",
    "name": "PATRIOT ELEMENTARY SCHOOL",
    "completed": 41,
    "total": 50,
    "report_id": 402891,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402892": {
    "id": 402892,
    "type": "school",
    "name": "PARKER PERFORMING ARTS",
    "completed": 21,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402893": {
    "id": 402893,
    "type": "school",
    "name": "PEAKVIEW ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402894": {
    "id": 402894,
    "type": "school",
    "name": "LAREDO ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 33,
    "report_id": 402894,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402895": {
    "id": 402895,
    "type": "school",
    "name": "ELLICOTT ELEMENTARY SCHOOL",
    "completed": 45,
    "total": 45,
    "report_id": 402895,
    "parent_type": "district",
    "parent_id": 401786
  },
  "402896": {
    "id": 402896,
    "type": "school",
    "name": "HOLM ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402897": {
    "id": 402897,
    "type": "school",
    "name": "HILLCREST ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402898": {
    "id": 402898,
    "type": "school",
    "name": "BIRCH ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 39,
    "report_id": 402898,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402899": {
    "id": 402899,
    "type": "school",
    "name": "MONTEREY ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 32,
    "report_id": 402899,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402900": {
    "id": 402900,
    "type": "school",
    "name": "COLUMBINE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 47,
    "report_id": 402900,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402901": {
    "id": 402901,
    "type": "school",
    "name": "SOROCO MIDDLE SCHOOL",
    "completed": 6,
    "total": 10,
    "report_id": 402901,
    "parent_type": "district",
    "parent_id": 401809
  },
  "402902": {
    "id": 402902,
    "type": "school",
    "name": "ELLICOTT MIDDLE SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 402902,
    "parent_type": "district",
    "parent_id": 401786
  },
  "402903": {
    "id": 402903,
    "type": "school",
    "name": "TWOMBLY ELEMENTARY SCHOOL",
    "completed": 41,
    "total": 42,
    "report_id": 402903,
    "parent_type": "district",
    "parent_id": 401775
  },
  "402904": {
    "id": 402904,
    "type": "school",
    "name": "MANZANOLA ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 10,
    "report_id": 402904,
    "parent_type": "district",
    "parent_id": 401723
  },
  "402905": {
    "id": 402905,
    "type": "school",
    "name": "MERRILL MIDDLE SCHOOL",
    "completed": 3,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402906": {
    "id": 402906,
    "type": "school",
    "name": "SUNNYSIDE ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 21,
    "report_id": 402906,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402907": {
    "id": 402907,
    "type": "school",
    "name": "MCGLONE ACADEMY",
    "completed": 0,
    "total": 67,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402908": {
    "id": 402908,
    "type": "school",
    "name": "MANCOS HIGH SCHOOL",
    "completed": 5,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401789
  },
  "402909": {
    "id": 402909,
    "type": "school",
    "name": "BEATTIE ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 28,
    "report_id": 402909,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402910": {
    "id": 402910,
    "type": "school",
    "name": "CONRAD EARLY LEARNING CENTER",
    "completed": 18,
    "total": 18,
    "report_id": 402910,
    "parent_type": "district",
    "parent_id": 401729
  },
  "402911": {
    "id": 402911,
    "type": "school",
    "name": "BURLINGTON ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 29,
    "report_id": 402911,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402912": {
    "id": 402912,
    "type": "school",
    "name": "DESERT SAGE ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402913": {
    "id": 402913,
    "type": "school",
    "name": "HOMESTAKE PEAK SCHOOL",
    "completed": 26,
    "total": 44,
    "report_id": 402913,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402914": {
    "id": 402914,
    "type": "school",
    "name": "FRONTIER HIGH SCHOOL",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401737
  },
  "402915": {
    "id": 402915,
    "type": "school",
    "name": "CHEYENNE MOUNTAIN JUNIOR HIGH SCHOOL",
    "completed": 29,
    "total": 47,
    "report_id": 402915,
    "parent_type": "district",
    "parent_id": 401769
  },
  "402916": {
    "id": 402916,
    "type": "school",
    "name": "BOULDER UNIVERSAL",
    "completed": 9,
    "total": 15,
    "report_id": 402916,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402917": {
    "id": 402917,
    "type": "school",
    "name": "ROCKY MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 33,
    "report_id": 402917,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402918": {
    "id": 402918,
    "type": "school",
    "name": "DOLORES MIDDLE SCHOOL",
    "completed": 2,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401888
  },
  "402919": {
    "id": 402919,
    "type": "school",
    "name": "GALILEO SCHOOL OF MATH AND SCIENCE",
    "completed": 47,
    "total": 48,
    "report_id": 402919,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402920": {
    "id": 402920,
    "type": "school",
    "name": "AVERY/PARSONS ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 46,
    "report_id": 402920,
    "parent_type": "district",
    "parent_id": 401788
  },
  "402921": {
    "id": 402921,
    "type": "school",
    "name": "HORIZON MIDDLE SCHOOL",
    "completed": 3,
    "total": 60,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402922": {
    "id": 402922,
    "type": "school",
    "name": "NORTHEAST ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 39,
    "report_id": 402922,
    "parent_type": "district",
    "parent_id": 401764
  },
  "402923": {
    "id": 402923,
    "type": "school",
    "name": "KENDRICK LAKES ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 29,
    "report_id": 402923,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402924": {
    "id": 402924,
    "type": "school",
    "name": "ROONEY RANCH ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 36,
    "report_id": 402924,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402925": {
    "id": 402925,
    "type": "school",
    "name": "NISLEY ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 38,
    "report_id": 402925,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402926": {
    "id": 402926,
    "type": "school",
    "name": "FRONTIER CHARTER ACADEMY",
    "completed": 0,
    "total": 94,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402927": {
    "id": 402927,
    "type": "school",
    "name": "FEDERAL HEIGHTS ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 43,
    "report_id": 402927,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402928": {
    "id": 402928,
    "type": "school",
    "name": "TOZER ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 32,
    "report_id": 402928,
    "parent_type": "district",
    "parent_id": 401818
  },
  "402929": {
    "id": 402929,
    "type": "school",
    "name": "BUENA VISTA ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 20,
    "report_id": 402929,
    "parent_type": "district",
    "parent_id": 401761
  },
  "402930": {
    "id": 402930,
    "type": "school",
    "name": "AIR ACADEMY HIGH SCHOOL",
    "completed": 5,
    "total": 96,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402931": {
    "id": 402931,
    "type": "school",
    "name": "EAST MIDDLE SCHOOL",
    "completed": 21,
    "total": 37,
    "report_id": 402931,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402932": {
    "id": 402932,
    "type": "school",
    "name": "ACADEMIA ANA MARIE SANDOVAL",
    "completed": 20,
    "total": 29,
    "report_id": 402932,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402933": {
    "id": 402933,
    "type": "school",
    "name": "ELIZABETH HIGH SCHOOL",
    "completed": 4,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401737
  },
  "402934": {
    "id": 402934,
    "type": "school",
    "name": "COTOPAXI ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401735
  },
  "402935": {
    "id": 402935,
    "type": "school",
    "name": "WESTMINSTER ACADEMY FOR INTERNATIONAL STUDIES",
    "completed": 7,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401752
  },
  "402936": {
    "id": 402936,
    "type": "school",
    "name": "THUNDERRIDGE HIGH SCHOOL",
    "completed": 33,
    "total": 125,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402937": {
    "id": 402937,
    "type": "school",
    "name": "THE JUNIPER SCHOOL",
    "completed": 11,
    "total": 18,
    "report_id": 402937,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402938": {
    "id": 402938,
    "type": "school",
    "name": "CREST VIEW ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 48,
    "report_id": 402938,
    "parent_type": "district",
    "parent_id": 401820
  },
  "402939": {
    "id": 402939,
    "type": "school",
    "name": "FORT MORGAN HIGH SCHOOL",
    "completed": 20,
    "total": 63,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401798
  },
  "402940": {
    "id": 402940,
    "type": "school",
    "name": "KIT CARSON ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401782
  },
  "402941": {
    "id": 402941,
    "type": "school",
    "name": "LEO WILLIAM BUTLER ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 38,
    "report_id": 402941,
    "parent_type": "district",
    "parent_id": 401775
  },
  "402942": {
    "id": 402942,
    "type": "school",
    "name": "EADS HIGH SCHOOL",
    "completed": 6,
    "total": 12,
    "report_id": 402942,
    "parent_type": "district",
    "parent_id": 401778
  },
  "402943": {
    "id": 402943,
    "type": "school",
    "name": "CONTEMPORARY LEARNING ACADEMY",
    "completed": 0,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402944": {
    "id": 402944,
    "type": "school",
    "name": "DRY CREEK ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 28,
    "report_id": 402944,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402945": {
    "id": 402945,
    "type": "school",
    "name": "STRATMOOR HILLS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402946": {
    "id": 402946,
    "type": "school",
    "name": "GREENWOOD ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402947": {
    "id": 402947,
    "type": "school",
    "name": "SLAVENS K-8 SCHOOL",
    "completed": 0,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402948": {
    "id": 402948,
    "type": "school",
    "name": "BRUSH HIGH SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 402948,
    "parent_type": "district",
    "parent_id": 401772
  },
  "402949": {
    "id": 402949,
    "type": "school",
    "name": "THOMPSON INTEGRATED EARLY CHILDHOOD",
    "completed": 18,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402950": {
    "id": 402950,
    "type": "school",
    "name": "MARSHDALE ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 24,
    "report_id": 402950,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402951": {
    "id": 402951,
    "type": "school",
    "name": "PRESTON MIDDLE SCHOOL",
    "completed": 60,
    "total": 70,
    "report_id": 402951,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402952": {
    "id": 402952,
    "type": "school",
    "name": "RYE HIGH SCHOOL",
    "completed": 10,
    "total": 20,
    "report_id": 402952,
    "parent_type": "district",
    "parent_id": 401863
  },
  "402953": {
    "id": 402953,
    "type": "school",
    "name": "LOVELAND CLASSICAL SCHOOL",
    "completed": 24,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402954": {
    "id": 402954,
    "type": "school",
    "name": "RED HILL ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 402954,
    "parent_type": "district",
    "parent_id": 401750
  },
  "402955": {
    "id": 402955,
    "type": "school",
    "name": "EMORY ELEMENTARY SCHOOL",
    "completed": 39,
    "total": 39,
    "report_id": 402955,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402956": {
    "id": 402956,
    "type": "school",
    "name": "OTIS ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 10,
    "report_id": 402956,
    "parent_type": "district",
    "parent_id": 401861
  },
  "402957": {
    "id": 402957,
    "type": "school",
    "name": "ROXBOROUGH INTERMEDIATE",
    "completed": 26,
    "total": 32,
    "report_id": 402957,
    "parent_type": "district",
    "parent_id": 401804
  },
  "402958": {
    "id": 402958,
    "type": "school",
    "name": "BUCHANAN MIDDLE SCHOOL",
    "completed": 15,
    "total": 18,
    "report_id": 402958,
    "parent_type": "district",
    "parent_id": 401783
  },
  "402959": {
    "id": 402959,
    "type": "school",
    "name": "EXPLORER ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 38,
    "report_id": 402959,
    "parent_type": "district",
    "parent_id": 401890
  },
  "402960": {
    "id": 402960,
    "type": "school",
    "name": "RIVERVIEW ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401889
  },
  "402961": {
    "id": 402961,
    "type": "school",
    "name": "MURPHY CREEK K-8 SCHOOL",
    "completed": 44,
    "total": 45,
    "report_id": 402961,
    "parent_type": "district",
    "parent_id": 401896
  },
  "402962": {
    "id": 402962,
    "type": "school",
    "name": "MARTINEZ ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "402963": {
    "id": 402963,
    "type": "school",
    "name": "COLUMBINE ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 38,
    "report_id": 402963,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402964": {
    "id": 402964,
    "type": "school",
    "name": "TOPE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 402964,
    "parent_type": "district",
    "parent_id": 401907
  },
  "402965": {
    "id": 402965,
    "type": "school",
    "name": "ARVADA HIGH SCHOOL",
    "completed": 49,
    "total": 53,
    "report_id": 402965,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402966": {
    "id": 402966,
    "type": "school",
    "name": "STRIVE PREP - SUNNYSIDE",
    "completed": 0,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402967": {
    "id": 402967,
    "type": "school",
    "name": "LESHER MIDDLE SCHOOL",
    "completed": 30,
    "total": 48,
    "report_id": 402967,
    "parent_type": "district",
    "parent_id": 401768
  },
  "402968": {
    "id": 402968,
    "type": "school",
    "name": "LUCILE ERWIN MIDDLE SCHOOL",
    "completed": 64,
    "total": 64,
    "report_id": 402968,
    "parent_type": "district",
    "parent_id": 401862
  },
  "402969": {
    "id": 402969,
    "type": "school",
    "name": "JULESBURG ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401819
  },
  "402970": {
    "id": 402970,
    "type": "school",
    "name": "STRIVE PREP - GVR",
    "completed": 0,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402971": {
    "id": 402971,
    "type": "school",
    "name": "KENDALLVUE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 33,
    "report_id": 402971,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402972": {
    "id": 402972,
    "type": "school",
    "name": "PIKES PEAK EARLY COLLEGE",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402973": {
    "id": 402973,
    "type": "school",
    "name": "TURMAN ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402974": {
    "id": 402974,
    "type": "school",
    "name": "EADS MIDDLE SCHOOL",
    "completed": 4,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401778
  },
  "402975": {
    "id": 402975,
    "type": "school",
    "name": "ACE COMMUNITY CHALLENGE SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402976": {
    "id": 402976,
    "type": "school",
    "name": "OURAY SENIOR HIGH SCHOOL",
    "completed": 12,
    "total": 18,
    "report_id": 402976,
    "parent_type": "district",
    "parent_id": 401777
  },
  "402977": {
    "id": 402977,
    "type": "school",
    "name": "PEABODY ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 32,
    "report_id": 402977,
    "parent_type": "district",
    "parent_id": 401827
  },
  "402978": {
    "id": 402978,
    "type": "school",
    "name": "FOX RIDGE MIDDLE SCHOOL",
    "completed": 50,
    "total": 88,
    "report_id": 402978,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402979": {
    "id": 402979,
    "type": "school",
    "name": "RALSTON VALLEY SENIOR HIGH SCHOOL",
    "completed": 84,
    "total": 109,
    "report_id": 402979,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402980": {
    "id": 402980,
    "type": "school",
    "name": "STEM LAB",
    "completed": 20,
    "total": 36,
    "report_id": 402980,
    "parent_type": "district",
    "parent_id": 401823
  },
  "402981": {
    "id": 402981,
    "type": "school",
    "name": "VICTORY PREPARATORY ACADEMY MIDDLE STATE CHARTER SCHOOL",
    "completed": 0,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "402982": {
    "id": 402982,
    "type": "school",
    "name": "HACKBERRY HILL ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 31,
    "report_id": 402982,
    "parent_type": "district",
    "parent_id": 401733
  },
  "402983": {
    "id": 402983,
    "type": "school",
    "name": "D3MYWAY ONLINE",
    "completed": 9,
    "total": 16,
    "report_id": 402983,
    "parent_type": "district",
    "parent_id": 401874
  },
  "402984": {
    "id": 402984,
    "type": "school",
    "name": "COAL RIDGE MIDDLE SCHOOL",
    "completed": 27,
    "total": 48,
    "report_id": 402984,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402985": {
    "id": 402985,
    "type": "school",
    "name": "NORTHRIDGE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "402986": {
    "id": 402986,
    "type": "school",
    "name": "VALDEZ ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402987": {
    "id": 402987,
    "type": "school",
    "name": "LESTER R ARNOLD HIGH SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 402987,
    "parent_type": "district",
    "parent_id": 401895
  },
  "402988": {
    "id": 402988,
    "type": "school",
    "name": "HOLLY HILLS ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 55,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "402989": {
    "id": 402989,
    "type": "school",
    "name": "STRASBURG HIGH SCHOOL",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401791
  },
  "402990": {
    "id": 402990,
    "type": "school",
    "name": "BYRON SYRING DELTA CENTER",
    "completed": 7,
    "total": 7,
    "report_id": 402990,
    "parent_type": "district",
    "parent_id": 401882
  },
  "402991": {
    "id": 402991,
    "type": "school",
    "name": "WOODMEN HILLS ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 56,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402992": {
    "id": 402992,
    "type": "school",
    "name": "BASALT MIDDLE SCHOOL",
    "completed": 28,
    "total": 43,
    "report_id": 402992,
    "parent_type": "district",
    "parent_id": 401849
  },
  "402993": {
    "id": 402993,
    "type": "school",
    "name": "MATHEMATICS AND SCIENCE LEADERSHIP ACADEMY",
    "completed": 16,
    "total": 23,
    "report_id": 402993,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402994": {
    "id": 402994,
    "type": "school",
    "name": "MOUNTAIN VISTA HOMESCHOOL ACADEMY",
    "completed": 0,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "402995": {
    "id": 402995,
    "type": "school",
    "name": "CLEAR CREEK MIDDLE SCHOOL",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401856
  },
  "402996": {
    "id": 402996,
    "type": "school",
    "name": "KIPP SUNSHINE PEAK ACADEMY",
    "completed": 0,
    "total": 63,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402997": {
    "id": 402997,
    "type": "school",
    "name": "DENVER SCHOOL OF INNOVATION AND SUSTAINABLE DESIGN",
    "completed": 0,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "402998": {
    "id": 402998,
    "type": "school",
    "name": "SAND CREEK HIGH SCHOOL",
    "completed": 0,
    "total": 82,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "402999": {
    "id": 402999,
    "type": "school",
    "name": "FRED TJARDES SCHOOL OF INNOVATION",
    "completed": 0,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403000": {
    "id": 403000,
    "type": "school",
    "name": "FALCON HIGH SCHOOL",
    "completed": 0,
    "total": 75,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "403001": {
    "id": 403001,
    "type": "school",
    "name": "NORTH FORK SCHOOL OF INTEGRATED STUDIES",
    "completed": 5,
    "total": 5,
    "report_id": 403001,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403002": {
    "id": 403002,
    "type": "school",
    "name": "CORWIN INTERNATIONAL MAGNET SCHOOL",
    "completed": 30,
    "total": 34,
    "report_id": 403002,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403003": {
    "id": 403003,
    "type": "school",
    "name": "APS ONLINE SCHOOL",
    "completed": 9,
    "total": 9,
    "report_id": 403003,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403004": {
    "id": 403004,
    "type": "school",
    "name": "FRONTIER ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403005": {
    "id": 403005,
    "type": "school",
    "name": "DENVER DISCOVERY SCHOOL",
    "completed": 23,
    "total": 35,
    "report_id": 403005,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403006": {
    "id": 403006,
    "type": "school",
    "name": "WILDFLOWER ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403007": {
    "id": 403007,
    "type": "school",
    "name": "WELD CENTRAL MIDDLE SCHOOL",
    "completed": 16,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401792
  },
  "403008": {
    "id": 403008,
    "type": "school",
    "name": "BIG THOMPSON ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 23,
    "report_id": 403008,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403009": {
    "id": 403009,
    "type": "school",
    "name": "ROCKY MOUNTAIN DEAF SCHOOL",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403010": {
    "id": 403010,
    "type": "school",
    "name": "RIVERSIDE SCHOOL",
    "completed": 41,
    "total": 48,
    "report_id": 403010,
    "parent_type": "district",
    "parent_id": 401759
  },
  "403011": {
    "id": 403011,
    "type": "school",
    "name": "COMMUNITY LEADERSHIP ACADEMY",
    "completed": 0,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403012": {
    "id": 403012,
    "type": "school",
    "name": "RIDGWAY MIDDLE SCHOOL",
    "completed": 0,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401864
  },
  "403013": {
    "id": 403013,
    "type": "school",
    "name": "SWINK JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 14,
    "total": 19,
    "report_id": 403013,
    "parent_type": "district",
    "parent_id": 401743
  },
  "403014": {
    "id": 403014,
    "type": "school",
    "name": "FREDERICK SENIOR HIGH SCHOOL",
    "completed": 35,
    "total": 57,
    "report_id": 403014,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403015": {
    "id": 403015,
    "type": "school",
    "name": "SIMLA JUNIOR HIGH SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401834
  },
  "403016": {
    "id": 403016,
    "type": "school",
    "name": "JEWELL ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 29,
    "report_id": 403016,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403017": {
    "id": 403017,
    "type": "school",
    "name": "MCGRAW ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 403017,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403018": {
    "id": 403018,
    "type": "school",
    "name": "SOUTH RIDGE ELEMENTARY AN IB WORLD SCHOOL",
    "completed": 32,
    "total": 46,
    "report_id": 403018,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403019": {
    "id": 403019,
    "type": "school",
    "name": "ACADEMY OF CHARTER SCHOOLS",
    "completed": 14,
    "total": 146,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403020": {
    "id": 403020,
    "type": "school",
    "name": "HOFF ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 19,
    "report_id": 403020,
    "parent_type": "district",
    "parent_id": 401792
  },
  "403021": {
    "id": 403021,
    "type": "school",
    "name": "RANCH CREEK ELEMENTARY",
    "completed": 4,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403022": {
    "id": 403022,
    "type": "school",
    "name": "FLAGSTAFF CHARTER ACADEMY",
    "completed": 31,
    "total": 68,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403023": {
    "id": 403023,
    "type": "school",
    "name": "LEGACY HIGH SCHOOL",
    "completed": 65,
    "total": 122,
    "report_id": 403023,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403024": {
    "id": 403024,
    "type": "school",
    "name": "GRAND VALLEY HIGH SCHOOL",
    "completed": 0,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401741
  },
  "403025": {
    "id": 403025,
    "type": "school",
    "name": "VENETUCCI ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 31,
    "report_id": 403025,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403026": {
    "id": 403026,
    "type": "school",
    "name": "GOLD HILL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403027": {
    "id": 403027,
    "type": "school",
    "name": "OAK CREEK ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403028": {
    "id": 403028,
    "type": "school",
    "name": "LEGACY OPTIONS HIGH SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403029": {
    "id": 403029,
    "type": "school",
    "name": "AVON ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "403030": {
    "id": 403030,
    "type": "school",
    "name": "SWANSEA ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403031": {
    "id": 403031,
    "type": "school",
    "name": "CALHAN HIGH SCHOOL",
    "completed": 8,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401756
  },
  "403032": {
    "id": 403032,
    "type": "school",
    "name": "BAYFIELD MIDDLE SCHOOL",
    "completed": 7,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401734
  },
  "403033": {
    "id": 403033,
    "type": "school",
    "name": "SUNSET PARK ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 26,
    "report_id": 403033,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403034": {
    "id": 403034,
    "type": "school",
    "name": "COYOTE CREEK ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 403034,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403035": {
    "id": 403035,
    "type": "school",
    "name": "SCOTT ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 32,
    "report_id": 403035,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403036": {
    "id": 403036,
    "type": "school",
    "name": "LEGACY ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403037": {
    "id": 403037,
    "type": "school",
    "name": "LEAP SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403038": {
    "id": 403038,
    "type": "school",
    "name": "ALAMOSA HIGH SCHOOL",
    "completed": 21,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401872
  },
  "403039": {
    "id": 403039,
    "type": "school",
    "name": "CORY ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403040": {
    "id": 403040,
    "type": "school",
    "name": "PAONIA ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 11,
    "report_id": 403040,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403041": {
    "id": 403041,
    "type": "school",
    "name": "ELKHART ELEMENTARY SCHOOL",
    "completed": 44,
    "total": 45,
    "report_id": 403041,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403042": {
    "id": 403042,
    "type": "school",
    "name": "PEORIA ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 34,
    "report_id": 403042,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403043": {
    "id": 403043,
    "type": "school",
    "name": "LETFORD ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 30,
    "report_id": 403043,
    "parent_type": "district",
    "parent_id": 401728
  },
  "403044": {
    "id": 403044,
    "type": "school",
    "name": "CREEKSIDE ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 46,
    "report_id": 403044,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403045": {
    "id": 403045,
    "type": "school",
    "name": "KING-MURPHY ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401856
  },
  "403046": {
    "id": 403046,
    "type": "school",
    "name": "TREVISTA AT HORACE MANN",
    "completed": 2,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403047": {
    "id": 403047,
    "type": "school",
    "name": "FRUITVALE ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 38,
    "report_id": 403047,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403048": {
    "id": 403048,
    "type": "school",
    "name": "COLUMBINE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 24,
    "report_id": 403048,
    "parent_type": "district",
    "parent_id": 401798
  },
  "403049": {
    "id": 403049,
    "type": "school",
    "name": "COLORADO EARLY COLLEGE FORT COLLINS",
    "completed": 44,
    "total": 112,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403050": {
    "id": 403050,
    "type": "school",
    "name": "ROCKY MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403051": {
    "id": 403051,
    "type": "school",
    "name": "TAYLOR ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403052": {
    "id": 403052,
    "type": "school",
    "name": "ELK CREEK ELEMENTARY",
    "completed": 14,
    "total": 21,
    "report_id": 403052,
    "parent_type": "district",
    "parent_id": 401759
  },
  "403053": {
    "id": 403053,
    "type": "school",
    "name": "ROCKY TOP MIDDLE SCHOOL",
    "completed": 43,
    "total": 83,
    "report_id": 403053,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403054": {
    "id": 403054,
    "type": "school",
    "name": "COYOTE RIDGE ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 33,
    "report_id": 403054,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403055": {
    "id": 403055,
    "type": "school",
    "name": "VISTA CHARTER SCHOOL",
    "completed": 9,
    "total": 10,
    "report_id": 403055,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403056": {
    "id": 403056,
    "type": "school",
    "name": "WELTE EDUCATION CENTER",
    "completed": 13,
    "total": 21,
    "report_id": 403056,
    "parent_type": "district",
    "parent_id": 401729
  },
  "403057": {
    "id": 403057,
    "type": "school",
    "name": "REUNION ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 28,
    "report_id": 403057,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403058": {
    "id": 403058,
    "type": "school",
    "name": "T.R. PAUL ACADEMY OF ARTS & KNOWLEDGE",
    "completed": 0,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403059": {
    "id": 403059,
    "type": "school",
    "name": "WYATT ACADEMY MIDDLE SCHOOL",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403060": {
    "id": 403060,
    "type": "school",
    "name": "MEEKER ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403061": {
    "id": 403061,
    "type": "school",
    "name": "SIDE CREEK ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 33,
    "report_id": 403061,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403062": {
    "id": 403062,
    "type": "school",
    "name": "JEFFERSON INTERMEDIATE SCHOOL",
    "completed": 11,
    "total": 20,
    "report_id": 403062,
    "parent_type": "district",
    "parent_id": 401817
  },
  "403063": {
    "id": 403063,
    "type": "school",
    "name": "WEBSTER ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 35,
    "report_id": 403063,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403064": {
    "id": 403064,
    "type": "school",
    "name": "COTOPAXI JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 4,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401735
  },
  "403065": {
    "id": 403065,
    "type": "school",
    "name": "VILAS UNDIVIDED HIGH SCHOOL",
    "completed": 6,
    "total": 6,
    "report_id": 403065,
    "parent_type": "district",
    "parent_id": 401847
  },
  "403066": {
    "id": 403066,
    "type": "school",
    "name": "FORT LUPTON MIDDLE SCHOOL",
    "completed": 33,
    "total": 45,
    "report_id": 403066,
    "parent_type": "district",
    "parent_id": 401775
  },
  "403067": {
    "id": 403067,
    "type": "school",
    "name": "GLOBAL VILLAGE ACADEMY - FORT COLLINS",
    "completed": 6,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403068": {
    "id": 403068,
    "type": "school",
    "name": "CREIGHTON MIDDLE SCHOOL",
    "completed": 26,
    "total": 50,
    "report_id": 403068,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403069": {
    "id": 403069,
    "type": "school",
    "name": "LA VETA ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 15,
    "report_id": 403069,
    "parent_type": "district",
    "parent_id": 401793
  },
  "403070": {
    "id": 403070,
    "type": "school",
    "name": "PLAINVIEW ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 6,
    "report_id": 403070,
    "parent_type": "district",
    "parent_id": 401822
  },
  "403071": {
    "id": 403071,
    "type": "school",
    "name": "EDITH TETER ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 23,
    "report_id": 403071,
    "parent_type": "district",
    "parent_id": 401762
  },
  "403072": {
    "id": 403072,
    "type": "school",
    "name": "MARIE L. GREENWOOD ACADEMY",
    "completed": 4,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403073": {
    "id": 403073,
    "type": "school",
    "name": "CLIFTON ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 36,
    "report_id": 403073,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403074": {
    "id": 403074,
    "type": "school",
    "name": "CROWN POINTE CHARTER ACADEMY",
    "completed": 0,
    "total": 49,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403075": {
    "id": 403075,
    "type": "school",
    "name": "WIGGINS HIGH SCHOOL",
    "completed": 0,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401806
  },
  "403076": {
    "id": 403076,
    "type": "school",
    "name": "ASPEN VIEW ACADEMY",
    "completed": 0,
    "total": 66,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403077": {
    "id": 403077,
    "type": "school",
    "name": "LOPEZ ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 403077,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403078": {
    "id": 403078,
    "type": "school",
    "name": "VIKAN MIDDLE SCHOOL",
    "completed": 39,
    "total": 40,
    "report_id": 403078,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403079": {
    "id": 403079,
    "type": "school",
    "name": "OVERLAND TRAIL MIDDLE SCHOOL",
    "completed": 35,
    "total": 37,
    "report_id": 403079,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403080": {
    "id": 403080,
    "type": "school",
    "name": "DENVER ONLINE HIGH SCHOOL",
    "completed": 7,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403081": {
    "id": 403081,
    "type": "school",
    "name": "WILLOW CREEK ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 37,
    "report_id": 403081,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403082": {
    "id": 403082,
    "type": "school",
    "name": "PUEBLO CHARTER SCHOOL FOR THE ARTS & SCIENCES",
    "completed": 2,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403083": {
    "id": 403083,
    "type": "school",
    "name": "WELDON VALLEY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401876
  },
  "403084": {
    "id": 403084,
    "type": "school",
    "name": "SOAR AT GREEN VALLEY RANCH",
    "completed": 0,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403085": {
    "id": 403085,
    "type": "school",
    "name": "VAN ARSDALE ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 31,
    "report_id": 403085,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403086": {
    "id": 403086,
    "type": "school",
    "name": "SANBORN ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 403086,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403087": {
    "id": 403087,
    "type": "school",
    "name": "FAIRVIEW HIGH SCHOOL",
    "completed": 91,
    "total": 123,
    "report_id": 403087,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403088": {
    "id": 403088,
    "type": "school",
    "name": "FORCE ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 46,
    "report_id": 403088,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403089": {
    "id": 403089,
    "type": "school",
    "name": "WOODLAND PARK HIGH SCHOOL",
    "completed": 0,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401844
  },
  "403090": {
    "id": 403090,
    "type": "school",
    "name": "WEST RIDGE ACADEMY",
    "completed": 2,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403091": {
    "id": 403091,
    "type": "school",
    "name": "UNIVERSITY PREP - ARAPAHOE ST.",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403092": {
    "id": 403092,
    "type": "school",
    "name": "STEM LAUNCH",
    "completed": 45,
    "total": 70,
    "report_id": 403092,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403093": {
    "id": 403093,
    "type": "school",
    "name": "RAMPART HIGH SCHOOL",
    "completed": 33,
    "total": 114,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403094": {
    "id": 403094,
    "type": "school",
    "name": "HOLYOKE ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401885
  },
  "403095": {
    "id": 403095,
    "type": "school",
    "name": "NEW AMERICA SCHOOL - THORNTON",
    "completed": 12,
    "total": 16,
    "report_id": 403095,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403096": {
    "id": 403096,
    "type": "school",
    "name": "FRUITA 8/9 SCHOOL",
    "completed": 37,
    "total": 49,
    "report_id": 403096,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403097": {
    "id": 403097,
    "type": "school",
    "name": "MOUNTAIN PHOENIX COMMUNITY SCHOOL",
    "completed": 0,
    "total": 65,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403098": {
    "id": 403098,
    "type": "school",
    "name": "JAMES MADISON CHARTER ACADEMY SCHOOL",
    "completed": 4,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403099": {
    "id": 403099,
    "type": "school",
    "name": "CASTLE VIEW HIGH SCHOOL",
    "completed": 86,
    "total": 124,
    "report_id": 403099,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403100": {
    "id": 403100,
    "type": "school",
    "name": "THE COTTAGE AT HOLLY RIDGE",
    "completed": 0,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403101": {
    "id": 403101,
    "type": "school",
    "name": "SOUTH HIGH SCHOOL",
    "completed": 37,
    "total": 119,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403102": {
    "id": 403102,
    "type": "school",
    "name": "ROCKY HEIGHTS MIDDLE SCHOOL",
    "completed": 75,
    "total": 93,
    "report_id": 403102,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403103": {
    "id": 403103,
    "type": "school",
    "name": "CARMODY MIDDLE SCHOOL",
    "completed": 26,
    "total": 39,
    "report_id": 403103,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403104": {
    "id": 403104,
    "type": "school",
    "name": "WESTRIDGE ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 33,
    "report_id": 403104,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403105": {
    "id": 403105,
    "type": "school",
    "name": "MOUNT CARBON ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 26,
    "report_id": 403105,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403106": {
    "id": 403106,
    "type": "school",
    "name": "HASKIN ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 33,
    "report_id": 403106,
    "parent_type": "district",
    "parent_id": 401746
  },
  "403107": {
    "id": 403107,
    "type": "school",
    "name": "STUKEY ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 34,
    "report_id": 403107,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403108": {
    "id": 403108,
    "type": "school",
    "name": "CRAVER MIDDLE SCHOOL",
    "completed": 6,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403109": {
    "id": 403109,
    "type": "school",
    "name": "RAY E KILMER ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 38,
    "report_id": 403109,
    "parent_type": "district",
    "parent_id": 401771
  },
  "403110": {
    "id": 403110,
    "type": "school",
    "name": "HIGH PLAINS ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403111": {
    "id": 403111,
    "type": "school",
    "name": "CENTENNIAL MIDDLE SCHOOL",
    "completed": 42,
    "total": 50,
    "report_id": 403111,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403112": {
    "id": 403112,
    "type": "school",
    "name": "SAND CREEK ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 40,
    "report_id": 403112,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403113": {
    "id": 403113,
    "type": "school",
    "name": "SUMMIT COVE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 26,
    "report_id": 403113,
    "parent_type": "district",
    "parent_id": 401811
  },
  "403114": {
    "id": 403114,
    "type": "school",
    "name": "GLOBAL VILLAGE ACADEMY AURORA",
    "completed": 0,
    "total": 96,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403115": {
    "id": 403115,
    "type": "school",
    "name": "ALTA VISTA CHARTER SCHOOL",
    "completed": 12,
    "total": 12,
    "report_id": 403115,
    "parent_type": "district",
    "parent_id": 401730
  },
  "403117": {
    "id": 403117,
    "type": "school",
    "name": "DOLORES HIGH SCHOOL",
    "completed": 8,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401888
  },
  "403118": {
    "id": 403118,
    "type": "school",
    "name": "DOUGLASS VALLEY ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 32,
    "report_id": 403118,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403119": {
    "id": 403119,
    "type": "school",
    "name": "AURORA CENTRAL HIGH SCHOOL",
    "completed": 70,
    "total": 129,
    "report_id": 403119,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403120": {
    "id": 403120,
    "type": "school",
    "name": "HOPE ONLINE LEARNING ACADEMY HIGH SCHOOL",
    "completed": 14,
    "total": 51,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403121": {
    "id": 403121,
    "type": "school",
    "name": "RIDGEVIEW ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 22,
    "report_id": 403121,
    "parent_type": "district",
    "parent_id": 401774
  },
  "403122": {
    "id": 403122,
    "type": "school",
    "name": "NORMANDY ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 29,
    "report_id": 403122,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403123": {
    "id": 403123,
    "type": "school",
    "name": "BLUE MOUNTAIN ELEMENTARY",
    "completed": 26,
    "total": 36,
    "report_id": 403123,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403124": {
    "id": 403124,
    "type": "school",
    "name": "FORT COLLINS HIGH SCHOOL",
    "completed": 105,
    "total": 106,
    "report_id": 403124,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403125": {
    "id": 403125,
    "type": "school",
    "name": "COLLEGIATE PREPARATORY ACADEMY",
    "completed": 5,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403126": {
    "id": 403126,
    "type": "school",
    "name": "CASTLE ROCK MIDDLE SCHOOL",
    "completed": 41,
    "total": 66,
    "report_id": 403126,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403127": {
    "id": 403127,
    "type": "school",
    "name": "LITTLETON ACADEMY",
    "completed": 6,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403128": {
    "id": 403128,
    "type": "school",
    "name": "STRATTON MEADOWS ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 29,
    "report_id": 403128,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403129": {
    "id": 403129,
    "type": "school",
    "name": "BELLEVIEW ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403130": {
    "id": 403130,
    "type": "school",
    "name": "LONGFELLOW ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 35,
    "report_id": 403130,
    "parent_type": "district",
    "parent_id": 401858
  },
  "403131": {
    "id": 403131,
    "type": "school",
    "name": "GREEN GABLES ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 22,
    "report_id": 403131,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403132": {
    "id": 403132,
    "type": "school",
    "name": "ELEVATE ACADEMY",
    "completed": 0,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "403133": {
    "id": 403133,
    "type": "school",
    "name": "SCOTT ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 40,
    "report_id": 403133,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403134": {
    "id": 403134,
    "type": "school",
    "name": "FREMONT ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 26,
    "report_id": 403134,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403135": {
    "id": 403135,
    "type": "school",
    "name": "CRIPPLE CREEK-VICTOR JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 9,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401813
  },
  "403136": {
    "id": 403136,
    "type": "school",
    "name": "SEMPER ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 26,
    "report_id": 403136,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403137": {
    "id": 403137,
    "type": "school",
    "name": "CHILDREN'S KIVA MONTESSORI SCHOOL",
    "completed": 6,
    "total": 11,
    "report_id": 403137,
    "parent_type": "district",
    "parent_id": 401726
  },
  "403138": {
    "id": 403138,
    "type": "school",
    "name": "ROCKY MOUNTAIN PREP: CREEKSIDE",
    "completed": 0,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403139": {
    "id": 403139,
    "type": "school",
    "name": "DENNISON ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 35,
    "report_id": 403139,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403140": {
    "id": 403140,
    "type": "school",
    "name": "LYONS ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 21,
    "report_id": 403140,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403141": {
    "id": 403141,
    "type": "school",
    "name": "ADDENBROOKE CLASSICAL ACADEMY",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403142": {
    "id": 403142,
    "type": "school",
    "name": "WRAY ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 24,
    "report_id": 403142,
    "parent_type": "district",
    "parent_id": 401783
  },
  "403143": {
    "id": 403143,
    "type": "school",
    "name": "RENAISSANCE SECONDARY SCHOOL",
    "completed": 1,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403144": {
    "id": 403144,
    "type": "school",
    "name": "CENTENNIAL A SCHOOL FOR EXPEDITIONARY LEARNING",
    "completed": 0,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403145": {
    "id": 403145,
    "type": "school",
    "name": "GLENNON HEIGHTS ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 21,
    "report_id": 403145,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403146": {
    "id": 403146,
    "type": "school",
    "name": "MANNING OPTIONS SCHOOL",
    "completed": 24,
    "total": 27,
    "report_id": 403146,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403147": {
    "id": 403147,
    "type": "school",
    "name": "RIDGEVIEW ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "403148": {
    "id": 403148,
    "type": "school",
    "name": "EAGLE VALLEY HIGH SCHOOL",
    "completed": 49,
    "total": 60,
    "report_id": 403148,
    "parent_type": "district",
    "parent_id": 401750
  },
  "403149": {
    "id": 403149,
    "type": "school",
    "name": "THE STUDIO SCHOOL",
    "completed": 18,
    "total": 24,
    "report_id": 403149,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403150": {
    "id": 403150,
    "type": "school",
    "name": "BAYFIELD ELEMENTARY SCHOOL",
    "completed": 46,
    "total": 52,
    "report_id": 403150,
    "parent_type": "district",
    "parent_id": 401734
  },
  "403151": {
    "id": 403151,
    "type": "school",
    "name": "CHIPETA ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 35,
    "report_id": 403151,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403152": {
    "id": 403152,
    "type": "school",
    "name": "MONTVIEW MATH & HEALTH SCIENCES ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 30,
    "report_id": 403152,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403154": {
    "id": 403154,
    "type": "school",
    "name": "LOCHBUIE ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 39,
    "report_id": 403154,
    "parent_type": "district",
    "parent_id": 401792
  },
  "403155": {
    "id": 403155,
    "type": "school",
    "name": "DUTCH CREEK ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 24,
    "report_id": 403155,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403156": {
    "id": 403156,
    "type": "school",
    "name": "ARICKAREE UNDIVIDED HIGH SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401873
  },
  "403157": {
    "id": 403157,
    "type": "school",
    "name": "CALHAN MIDDLE SCHOOL",
    "completed": 2,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401756
  },
  "403158": {
    "id": 403158,
    "type": "school",
    "name": "MESA VALLEY COMMUNITY SCHOOL",
    "completed": 5,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403159": {
    "id": 403159,
    "type": "school",
    "name": "POMONA ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 29,
    "report_id": 403159,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403160": {
    "id": 403160,
    "type": "school",
    "name": "MERINO ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 12,
    "report_id": 403160,
    "parent_type": "district",
    "parent_id": 401824
  },
  "403161": {
    "id": 403161,
    "type": "school",
    "name": "BRANSON SCHOOL",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401727
  },
  "403162": {
    "id": 403162,
    "type": "school",
    "name": "ACHIEVE ACADEMY",
    "completed": 27,
    "total": 39,
    "report_id": 403162,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403163": {
    "id": 403163,
    "type": "school",
    "name": "REVERE JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401839
  },
  "403164": {
    "id": 403164,
    "type": "school",
    "name": "LOWRY ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403165": {
    "id": 403165,
    "type": "school",
    "name": "STARGATE CHARTER SCHOOL",
    "completed": 6,
    "total": 86,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403166": {
    "id": 403166,
    "type": "school",
    "name": "QUEST ACADEMY AT DACONO",
    "completed": 12,
    "total": 12,
    "report_id": 403166,
    "parent_type": "district",
    "parent_id": 401775
  },
  "403167": {
    "id": 403167,
    "type": "school",
    "name": "DENVER GREEN SCHOOL",
    "completed": 0,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403168": {
    "id": 403168,
    "type": "school",
    "name": "MOUNTAIN SAGE COMMUNITY SCHOOL",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403169": {
    "id": 403169,
    "type": "school",
    "name": "WARDER ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 27,
    "report_id": 403169,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403170": {
    "id": 403170,
    "type": "school",
    "name": "LINCOLN MIDDLE SCHOOL",
    "completed": 53,
    "total": 56,
    "report_id": 403170,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403171": {
    "id": 403171,
    "type": "school",
    "name": "PIONEER ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403172": {
    "id": 403172,
    "type": "school",
    "name": "GEORGETOWN COMMUNITY SCHOOL",
    "completed": 1,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401856
  },
  "403173": {
    "id": 403173,
    "type": "school",
    "name": "MEADOWLARK SCHOOL",
    "completed": 39,
    "total": 47,
    "report_id": 403173,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403174": {
    "id": 403174,
    "type": "school",
    "name": "HERITAGE HIGH SCHOOL",
    "completed": 53,
    "total": 98,
    "report_id": 403174,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403175": {
    "id": 403175,
    "type": "school",
    "name": "NATURITA ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401865
  },
  "403176": {
    "id": 403176,
    "type": "school",
    "name": "BOULDER PREP CHARTER HIGH SCHOOL",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403177": {
    "id": 403177,
    "type": "school",
    "name": "LINCOLN CHARTER ACADEMY",
    "completed": 56,
    "total": 56,
    "report_id": 403177,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403178": {
    "id": 403178,
    "type": "school",
    "name": "CARSON MIDDLE SCHOOL",
    "completed": 33,
    "total": 56,
    "report_id": 403178,
    "parent_type": "district",
    "parent_id": 401729
  },
  "403179": {
    "id": 403179,
    "type": "school",
    "name": "VIVIAN ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 35,
    "report_id": 403179,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403180": {
    "id": 403180,
    "type": "school",
    "name": "GIBERSON ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403181": {
    "id": 403181,
    "type": "school",
    "name": "NORMA ANDERSON PRESCHOOL",
    "completed": 1,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403182": {
    "id": 403182,
    "type": "school",
    "name": "BROADMOOR ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 24,
    "report_id": 403182,
    "parent_type": "district",
    "parent_id": 401769
  },
  "403183": {
    "id": 403183,
    "type": "school",
    "name": "STONE MOUNTAIN ELEMENTARY",
    "completed": 24,
    "total": 46,
    "report_id": 403183,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403184": {
    "id": 403184,
    "type": "school",
    "name": "PARIS ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 26,
    "report_id": 403184,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403185": {
    "id": 403185,
    "type": "school",
    "name": "AGUILAR JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 7,
    "total": 7,
    "report_id": 403185,
    "parent_type": "district",
    "parent_id": 401843
  },
  "403186": {
    "id": 403186,
    "type": "school",
    "name": "CLAYTON ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 36,
    "report_id": 403186,
    "parent_type": "district",
    "parent_id": 401802
  },
  "403187": {
    "id": 403187,
    "type": "school",
    "name": "STEM SCHOOL HIGHLANDS RANCH",
    "completed": 88,
    "total": 110,
    "report_id": 403187,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403188": {
    "id": 403188,
    "type": "school",
    "name": "HIGHLAND ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401749
  },
  "403189": {
    "id": 403189,
    "type": "school",
    "name": "RYAN ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 33,
    "report_id": 403189,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403190": {
    "id": 403190,
    "type": "school",
    "name": "DOUGLASS ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 30,
    "report_id": 403190,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403191": {
    "id": 403191,
    "type": "school",
    "name": "LEWIS-PALMER MIDDLE SCHOOL",
    "completed": 40,
    "total": 61,
    "report_id": 403191,
    "parent_type": "district",
    "parent_id": 401771
  },
  "403192": {
    "id": 403192,
    "type": "school",
    "name": "DELTA ACADEMY OF APPLIED LEARNING",
    "completed": 0,
    "total": 2,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403193": {
    "id": 403193,
    "type": "school",
    "name": "SOUTHWEST COLORADO E-SCHOOL",
    "completed": 2,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401906
  },
  "403194": {
    "id": 403194,
    "type": "school",
    "name": "NORTH STAR ACADEMY",
    "completed": 0,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403195": {
    "id": 403195,
    "type": "school",
    "name": "UNION COLONY ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 26,
    "report_id": 403195,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403196": {
    "id": 403196,
    "type": "school",
    "name": "LIMON ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401815
  },
  "403197": {
    "id": 403197,
    "type": "school",
    "name": "TWIN PEAKS CHARTER ACADEMY",
    "completed": 59,
    "total": 70,
    "report_id": 403197,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403198": {
    "id": 403198,
    "type": "school",
    "name": "ALPINE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 35,
    "report_id": 403198,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403199": {
    "id": 403199,
    "type": "school",
    "name": "BROWN INTERNATIONAL ACADEMY",
    "completed": 9,
    "total": 49,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403200": {
    "id": 403200,
    "type": "school",
    "name": "DEL NORTE SCHOOLS K-8",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401869
  },
  "403201": {
    "id": 403201,
    "type": "school",
    "name": "THREE CREEKS ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 24,
    "report_id": 403201,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403202": {
    "id": 403202,
    "type": "school",
    "name": "SUMMIT VIEW ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 36,
    "report_id": 403202,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403203": {
    "id": 403203,
    "type": "school",
    "name": "DENVER JUSTICE HIGH SCHOOL",
    "completed": 4,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403204": {
    "id": 403204,
    "type": "school",
    "name": "SAGE CANYON ELEMENTARY",
    "completed": 31,
    "total": 51,
    "report_id": 403204,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403205": {
    "id": 403205,
    "type": "school",
    "name": "LAMAR HIGH SCHOOL",
    "completed": 16,
    "total": 31,
    "report_id": 403205,
    "parent_type": "district",
    "parent_id": 401730
  },
  "403206": {
    "id": 403206,
    "type": "school",
    "name": "PARMALEE ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 21,
    "report_id": 403206,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403207": {
    "id": 403207,
    "type": "school",
    "name": "TRAILS WEST ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403208": {
    "id": 403208,
    "type": "school",
    "name": "DISTRICT 9-R SHARED SCHOOL",
    "completed": 5,
    "total": 5,
    "report_id": 403208,
    "parent_type": "district",
    "parent_id": 401889
  },
  "403209": {
    "id": 403209,
    "type": "school",
    "name": "MOUNTAIN VIEW ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 403209,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403210": {
    "id": 403210,
    "type": "school",
    "name": "NORTH MESA ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 31,
    "report_id": 403210,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403211": {
    "id": 403211,
    "type": "school",
    "name": "FLEMING ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401754
  },
  "403212": {
    "id": 403212,
    "type": "school",
    "name": "ESTES PARK HIGH SCHOOL",
    "completed": 20,
    "total": 36,
    "report_id": 403212,
    "parent_type": "district",
    "parent_id": 401887
  },
  "403213": {
    "id": 403213,
    "type": "school",
    "name": "HOLLY HIGH SCHOOL",
    "completed": 3,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401848
  },
  "403214": {
    "id": 403214,
    "type": "school",
    "name": "STRATTON SENIOR HIGH SCHOOL",
    "completed": 5,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401794
  },
  "403215": {
    "id": 403215,
    "type": "school",
    "name": "GREEN MOUNTAIN HIGH SCHOOL",
    "completed": 60,
    "total": 73,
    "report_id": 403215,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403216": {
    "id": 403216,
    "type": "school",
    "name": "GARDEN PLACE ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403217": {
    "id": 403217,
    "type": "school",
    "name": "MONTESSORI PEAKS CHARTER ACADEMY",
    "completed": 11,
    "total": 67,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403218": {
    "id": 403218,
    "type": "school",
    "name": "CARMEL MIDDLE SCHOOL",
    "completed": 1,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403219": {
    "id": 403219,
    "type": "school",
    "name": "ADAMS CITY MIDDLE SCHOOL",
    "completed": 52,
    "total": 52,
    "report_id": 403219,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403220": {
    "id": 403220,
    "type": "school",
    "name": "MESA MIDDLE SCHOOL",
    "completed": 51,
    "total": 61,
    "report_id": 403220,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403221": {
    "id": 403221,
    "type": "school",
    "name": "BERGEN VALLEY INTERMEDIATE SCHOOL",
    "completed": 15,
    "total": 19,
    "report_id": 403221,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403222": {
    "id": 403222,
    "type": "school",
    "name": "MOUNTAIN MIDDLE SCHOOL",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403223": {
    "id": 403223,
    "type": "school",
    "name": "OUTBACK PRESCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403224": {
    "id": 403224,
    "type": "school",
    "name": "FOUNTAIN MIDDLE SCHOOL",
    "completed": 43,
    "total": 76,
    "report_id": 403224,
    "parent_type": "district",
    "parent_id": 401729
  },
  "403225": {
    "id": 403225,
    "type": "school",
    "name": "MEIKLEJOHN ELEMENTARY",
    "completed": 26,
    "total": 42,
    "report_id": 403225,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403226": {
    "id": 403226,
    "type": "school",
    "name": "WERNER ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 403226,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403227": {
    "id": 403227,
    "type": "school",
    "name": "OLATHE MIDDLE SCHOOL",
    "completed": 0,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403228": {
    "id": 403228,
    "type": "school",
    "name": "SOUTH ROUTT ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 16,
    "report_id": 403228,
    "parent_type": "district",
    "parent_id": 401809
  },
  "403229": {
    "id": 403229,
    "type": "school",
    "name": "GOLDRICK ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 48,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403230": {
    "id": 403230,
    "type": "school",
    "name": "SILVERTON HIGH SCHOOL",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401776
  },
  "403231": {
    "id": 403231,
    "type": "school",
    "name": "JAMESTOWN ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403232": {
    "id": 403232,
    "type": "school",
    "name": "CRAWFORD ELEMENTARY SCHOOL",
    "completed": 41,
    "total": 43,
    "report_id": 403232,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403233": {
    "id": 403233,
    "type": "school",
    "name": "PARKER CORE KNOWLEDGE CHARTER SCHOOL",
    "completed": 0,
    "total": 53,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403234": {
    "id": 403234,
    "type": "school",
    "name": "CIVA CHARTER ACADEMY",
    "completed": 15,
    "total": 15,
    "report_id": 403234,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403235": {
    "id": 403235,
    "type": "school",
    "name": "GLOBE CHARTER SCHOOL",
    "completed": 12,
    "total": 21,
    "report_id": 403235,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403236": {
    "id": 403236,
    "type": "school",
    "name": "COLUMBIA ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 24,
    "report_id": 403236,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403237": {
    "id": 403237,
    "type": "school",
    "name": "KNOWLEDGE QUEST ACADEMY",
    "completed": 0,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401728
  },
  "403238": {
    "id": 403238,
    "type": "school",
    "name": "FLORIDA PITT-WALLER ECE-8 SCHOOL",
    "completed": 0,
    "total": 61,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403239": {
    "id": 403239,
    "type": "school",
    "name": "LA JUNTA INTERMEDIATE SCHOOL",
    "completed": 30,
    "total": 35,
    "report_id": 403239,
    "parent_type": "district",
    "parent_id": 401755
  },
  "403240": {
    "id": 403240,
    "type": "school",
    "name": "CLAYTON PARTNERSHIP SCHOOL",
    "completed": 22,
    "total": 30,
    "report_id": 403240,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403241": {
    "id": 403241,
    "type": "school",
    "name": "SUNSET ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 20,
    "report_id": 403241,
    "parent_type": "district",
    "parent_id": 401774
  },
  "403242": {
    "id": 403242,
    "type": "school",
    "name": "NORTH HIGH SCHOOL",
    "completed": 17,
    "total": 85,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403243": {
    "id": 403243,
    "type": "school",
    "name": "BURLINGTON HIGH SCHOOL",
    "completed": 5,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401828
  },
  "403244": {
    "id": 403244,
    "type": "school",
    "name": "GILPIN COUNTY UNDIVIDED HIGH SCHOOL",
    "completed": 3,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401826
  },
  "403245": {
    "id": 403245,
    "type": "school",
    "name": "MONTEZUMA-CORTEZ HIGH SCHOOL",
    "completed": 44,
    "total": 44,
    "report_id": 403245,
    "parent_type": "district",
    "parent_id": 401726
  },
  "403246": {
    "id": 403246,
    "type": "school",
    "name": "FLAGLER PUBLIC SCHOOL",
    "completed": 11,
    "total": 19,
    "report_id": 403246,
    "parent_type": "district",
    "parent_id": 401784
  },
  "403247": {
    "id": 403247,
    "type": "school",
    "name": "UNIVERSITY PARK ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403248": {
    "id": 403248,
    "type": "school",
    "name": "STOTT ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 27,
    "report_id": 403248,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403249": {
    "id": 403249,
    "type": "school",
    "name": "MIDLAND ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 20,
    "report_id": 403249,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403250": {
    "id": 403250,
    "type": "school",
    "name": "DE BEQUE PK-12 SCHOOL DISTRICT 49JT",
    "completed": 22,
    "total": 22,
    "report_id": 403250,
    "parent_type": "district",
    "parent_id": 401795
  },
  "403252": {
    "id": 403252,
    "type": "school",
    "name": "GREEN VALLEY ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 55,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403253": {
    "id": 403253,
    "type": "school",
    "name": "BELLE CREEK CHARTER SCHOOL",
    "completed": 0,
    "total": 22,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403254": {
    "id": 403254,
    "type": "school",
    "name": "DUPONT ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 42,
    "report_id": 403254,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403255": {
    "id": 403255,
    "type": "school",
    "name": "THE CLASSICAL ACADEMY CHARTER",
    "completed": 0,
    "total": 172,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403256": {
    "id": 403256,
    "type": "school",
    "name": "WITT ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 25,
    "report_id": 403256,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403257": {
    "id": 403257,
    "type": "school",
    "name": "SABIN MIDDLE SCHOOL",
    "completed": 46,
    "total": 59,
    "report_id": 403257,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403258": {
    "id": 403258,
    "type": "school",
    "name": "JEFFERSON ACADEMY",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403259": {
    "id": 403259,
    "type": "school",
    "name": "BRIGGSDALE UNDIVIDED HIGH SCHOOL",
    "completed": 1,
    "total": 12,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401763
  },
  "403260": {
    "id": 403260,
    "type": "school",
    "name": "IOWA ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 30,
    "report_id": 403260,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403261": {
    "id": 403261,
    "type": "school",
    "name": "ADVENTURE ELEMENTARY",
    "completed": 30,
    "total": 30,
    "report_id": 403261,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403262": {
    "id": 403262,
    "type": "school",
    "name": "JAMES IRWIN CHARTER ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 63,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403263": {
    "id": 403263,
    "type": "school",
    "name": "PETE MIRICH ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 35,
    "report_id": 403263,
    "parent_type": "district",
    "parent_id": 401902
  },
  "403264": {
    "id": 403264,
    "type": "school",
    "name": "DENVER SCHOOL OF THE ARTS",
    "completed": 31,
    "total": 66,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403265": {
    "id": 403265,
    "type": "school",
    "name": "MEAD HIGH SCHOOL",
    "completed": 46,
    "total": 61,
    "report_id": 403265,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403266": {
    "id": 403266,
    "type": "school",
    "name": "INDEPENDENCE ACADEMY",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403267": {
    "id": 403267,
    "type": "school",
    "name": "FLYNN ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401752
  },
  "403268": {
    "id": 403268,
    "type": "school",
    "name": "TARVER ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403269": {
    "id": 403269,
    "type": "school",
    "name": "GRANT RANCH ECE-8 SCHOOL",
    "completed": 1,
    "total": 48,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403270": {
    "id": 403270,
    "type": "school",
    "name": "EARLY COLLEGE HIGH SCHOOL",
    "completed": 24,
    "total": 24,
    "report_id": 403270,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403271": {
    "id": 403271,
    "type": "school",
    "name": "EASTRIDGE COMMUNITY ELEMENTARY SCHOOL",
    "completed": 45,
    "total": 74,
    "report_id": 403271,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403272": {
    "id": 403272,
    "type": "school",
    "name": "WILLIAM SMITH HIGH SCHOOL",
    "completed": 21,
    "total": 25,
    "report_id": 403272,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403273": {
    "id": 403273,
    "type": "school",
    "name": "DENVER CENTER FOR INTERNATIONAL STUDIES",
    "completed": 0,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403274": {
    "id": 403274,
    "type": "school",
    "name": "PINON VALLEY ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 29,
    "report_id": 403274,
    "parent_type": "district",
    "parent_id": 401769
  },
  "403275": {
    "id": 403275,
    "type": "school",
    "name": "ROCKY MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 38,
    "report_id": 403275,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403276": {
    "id": 403276,
    "type": "school",
    "name": "LYONS MIDDLE/SENIOR HIGH SCHOOL",
    "completed": 17,
    "total": 29,
    "report_id": 403276,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403277": {
    "id": 403277,
    "type": "school",
    "name": "TRAYLOR ACADEMY",
    "completed": 7,
    "total": 41,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403278": {
    "id": 403278,
    "type": "school",
    "name": "GREELEY WEST HIGH SCHOOL",
    "completed": 50,
    "total": 91,
    "report_id": 403278,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403279": {
    "id": 403279,
    "type": "school",
    "name": "YORK INTERNATIONAL",
    "completed": 40,
    "total": 46,
    "report_id": 403279,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403280": {
    "id": 403280,
    "type": "school",
    "name": "VILAS ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 7,
    "report_id": 403280,
    "parent_type": "district",
    "parent_id": 401847
  },
  "403281": {
    "id": 403281,
    "type": "school",
    "name": "WEST WOODS ELEMENTARY SCHOOL",
    "completed": 44,
    "total": 44,
    "report_id": 403281,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403282": {
    "id": 403282,
    "type": "school",
    "name": "BROOMFIELD HIGH SCHOOL",
    "completed": 53,
    "total": 93,
    "report_id": 403282,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403283": {
    "id": 403283,
    "type": "school",
    "name": "MEADOWOOD CHILD DEVELOPMENT CENTER",
    "completed": 7,
    "total": 13,
    "report_id": 403283,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403284": {
    "id": 403284,
    "type": "school",
    "name": "LARKSPUR ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 18,
    "report_id": 403284,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403285": {
    "id": 403285,
    "type": "school",
    "name": "COLUMBIAN ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403286": {
    "id": 403286,
    "type": "school",
    "name": "HORIZON MIDDLE SCHOOL",
    "completed": 45,
    "total": 74,
    "report_id": 403286,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403287": {
    "id": 403287,
    "type": "school",
    "name": "WEST EARLY COLLEGE",
    "completed": 4,
    "total": 71,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403288": {
    "id": 403288,
    "type": "school",
    "name": "SPRINGS STUDIO FOR ACADEMIC EXCELLENCE",
    "completed": 1,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "403289": {
    "id": 403289,
    "type": "school",
    "name": "ORCHARD AVENUE ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 403289,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403290": {
    "id": 403290,
    "type": "school",
    "name": "HIGH TECH EARLY COLLEGE",
    "completed": 1,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403291": {
    "id": 403291,
    "type": "school",
    "name": "TRUSCOTT ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 32,
    "report_id": 403291,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403292": {
    "id": 403292,
    "type": "school",
    "name": "GARNET MESA ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 32,
    "report_id": 403292,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403293": {
    "id": 403293,
    "type": "school",
    "name": "MANN MIDDLE SCHOOL",
    "completed": 38,
    "total": 41,
    "report_id": 403293,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403294": {
    "id": 403294,
    "type": "school",
    "name": "SAGEBRUSH ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 34,
    "report_id": 403294,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403295": {
    "id": 403295,
    "type": "school",
    "name": "JOHNSON ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 36,
    "report_id": 403295,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403296": {
    "id": 403296,
    "type": "school",
    "name": "MILLER MIDDLE SCHOOL",
    "completed": 25,
    "total": 42,
    "report_id": 403296,
    "parent_type": "district",
    "parent_id": 401889
  },
  "403297": {
    "id": 403297,
    "type": "school",
    "name": "CARDINAL COMMUNITY ACADEMY CHARTER SCHOOL",
    "completed": 14,
    "total": 15,
    "report_id": 403297,
    "parent_type": "district",
    "parent_id": 401792
  },
  "403298": {
    "id": 403298,
    "type": "school",
    "name": "IRON HORSE ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 34,
    "report_id": 403298,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403299": {
    "id": 403299,
    "type": "school",
    "name": "TALBOTT STEAM INNOVATION SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 403299,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403300": {
    "id": 403300,
    "type": "school",
    "name": "ESTES PARK OPTIONS SCHOOL",
    "completed": 0,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401887
  },
  "403301": {
    "id": 403301,
    "type": "school",
    "name": "LA JUNTA PRIMARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 403301,
    "parent_type": "district",
    "parent_id": 401755
  },
  "403302": {
    "id": 403302,
    "type": "school",
    "name": "BRICKER ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403303": {
    "id": 403303,
    "type": "school",
    "name": "THOMSON ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 31,
    "report_id": 403303,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403304": {
    "id": 403304,
    "type": "school",
    "name": "SPRINGS RANCH ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "403305": {
    "id": 403305,
    "type": "school",
    "name": "SHAWSHEEN ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 403305,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403306": {
    "id": 403306,
    "type": "school",
    "name": "MILLER SPECIAL EDUCATION",
    "completed": 18,
    "total": 34,
    "report_id": 403306,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403307": {
    "id": 403307,
    "type": "school",
    "name": "ARAPAHOE RIDGE ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 38,
    "report_id": 403307,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403308": {
    "id": 403308,
    "type": "school",
    "name": "LIBERTY POINT INTERNATIONAL SCHOOL",
    "completed": 16,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403309": {
    "id": 403309,
    "type": "school",
    "name": "WEST ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 403309,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403310": {
    "id": 403310,
    "type": "school",
    "name": "SOARING EAGLES ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 30,
    "report_id": 403310,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403311": {
    "id": 403311,
    "type": "school",
    "name": "DSST: HENRY MIDDLE SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403312": {
    "id": 403312,
    "type": "school",
    "name": "LAKE INTERNATIONAL SCHOOL",
    "completed": 11,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403313": {
    "id": 403313,
    "type": "school",
    "name": "WEST LEADERSHIP ACADEMY",
    "completed": 1,
    "total": 62,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403314": {
    "id": 403314,
    "type": "school",
    "name": "NORTHRIDGE HIGH SCHOOL",
    "completed": 16,
    "total": 66,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403315": {
    "id": 403315,
    "type": "school",
    "name": "JUNE CREEK ELEMENTARY SCHOOL",
    "completed": 15,
    "total": 24,
    "report_id": 403315,
    "parent_type": "district",
    "parent_id": 401750
  },
  "403316": {
    "id": 403316,
    "type": "school",
    "name": "STONY CREEK ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 25,
    "report_id": 403316,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403317": {
    "id": 403317,
    "type": "school",
    "name": "PARK HILL SCHOOL",
    "completed": 13,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403318": {
    "id": 403318,
    "type": "school",
    "name": "COLORADO EARLY COLLEGES AURORA",
    "completed": 0,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403319": {
    "id": 403319,
    "type": "school",
    "name": "O'DEA ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 37,
    "report_id": 403319,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403320": {
    "id": 403320,
    "type": "school",
    "name": "OLDE COLUMBINE HIGH SCHOOL",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403321": {
    "id": 403321,
    "type": "school",
    "name": "SMOKY HILL HIGH SCHOOL",
    "completed": 104,
    "total": 135,
    "report_id": 403321,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403322": {
    "id": 403322,
    "type": "school",
    "name": "ARVADA WEST HIGH SCHOOL",
    "completed": 91,
    "total": 105,
    "report_id": 403322,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403323": {
    "id": 403323,
    "type": "school",
    "name": "SKYVIEW MIDDLE SCHOOL",
    "completed": 25,
    "total": 68,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401851
  },
  "403324": {
    "id": 403324,
    "type": "school",
    "name": "SPARK! DISCOVERY PRESCHOOL",
    "completed": 8,
    "total": 13,
    "report_id": 403324,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403325": {
    "id": 403325,
    "type": "school",
    "name": "LAKE PRESCHOOL",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401745
  },
  "403326": {
    "id": 403326,
    "type": "school",
    "name": "MOFFAT COUNTY HIGH SCHOOL",
    "completed": 22,
    "total": 39,
    "report_id": 403326,
    "parent_type": "district",
    "parent_id": 401774
  },
  "403327": {
    "id": 403327,
    "type": "school",
    "name": "STEELE ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 20,
    "report_id": 403327,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403328": {
    "id": 403328,
    "type": "school",
    "name": "CENTENNIAL HIGH SCHOOL",
    "completed": 18,
    "total": 20,
    "report_id": 403328,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403329": {
    "id": 403329,
    "type": "school",
    "name": "EDCSD",
    "completed": 0,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403330": {
    "id": 403330,
    "type": "school",
    "name": "FOOTHILL ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 43,
    "report_id": 403330,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403331": {
    "id": 403331,
    "type": "school",
    "name": "CENTER VIRTUAL ACADEMY",
    "completed": 1,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401746
  },
  "403332": {
    "id": 403332,
    "type": "school",
    "name": "EXCEL ACADEMY CHARTER SCHOOL",
    "completed": 7,
    "total": 48,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403333": {
    "id": 403333,
    "type": "school",
    "name": "EUCLID MIDDLE SCHOOL",
    "completed": 37,
    "total": 48,
    "report_id": 403333,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403334": {
    "id": 403334,
    "type": "school",
    "name": "HAYDEN VALLEY ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 18,
    "report_id": 403334,
    "parent_type": "district",
    "parent_id": 401814
  },
  "403335": {
    "id": 403335,
    "type": "school",
    "name": "SHERIDAN HIGH SCHOOL",
    "completed": 20,
    "total": 29,
    "report_id": 403335,
    "parent_type": "district",
    "parent_id": 401837
  },
  "403336": {
    "id": 403336,
    "type": "school",
    "name": "CHERAW SCHOOL",
    "completed": 6,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401785
  },
  "403337": {
    "id": 403337,
    "type": "school",
    "name": "PRAIRIE HILLS ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 403337,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403338": {
    "id": 403338,
    "type": "school",
    "name": "CENTRAL ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 39,
    "report_id": 403338,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403339": {
    "id": 403339,
    "type": "school",
    "name": "KAISER ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403340": {
    "id": 403340,
    "type": "school",
    "name": "MARY E PENNOCK ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 403340,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403341": {
    "id": 403341,
    "type": "school",
    "name": "SNOWY PEAKS HIGH SCHOOL",
    "completed": 6,
    "total": 8,
    "report_id": 403341,
    "parent_type": "district",
    "parent_id": 401811
  },
  "403342": {
    "id": 403342,
    "type": "school",
    "name": "FRUITA MIDDLE SCHOOL",
    "completed": 35,
    "total": 50,
    "report_id": 403342,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403343": {
    "id": 403343,
    "type": "school",
    "name": "MEADOW COMMUNITY SCHOOL",
    "completed": 22,
    "total": 29,
    "report_id": 403343,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403344": {
    "id": 403344,
    "type": "school",
    "name": "ROCKY MOUNTAIN PREP: SOUTHWEST",
    "completed": 2,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403345": {
    "id": 403345,
    "type": "school",
    "name": "NEW VISION CHARTER SCHOOL",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403346": {
    "id": 403346,
    "type": "school",
    "name": "WOODGLEN ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403347": {
    "id": 403347,
    "type": "school",
    "name": "RICARDO FLORES MAGON ACADEMY",
    "completed": 0,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403348": {
    "id": 403348,
    "type": "school",
    "name": "SARGENT ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401841
  },
  "403349": {
    "id": 403349,
    "type": "school",
    "name": "PLAINVIEW JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 6,
    "total": 6,
    "report_id": 403349,
    "parent_type": "district",
    "parent_id": 401822
  },
  "403350": {
    "id": 403350,
    "type": "school",
    "name": "FARRELL B. HOWELL ECE-8 SCHOOL",
    "completed": 19,
    "total": 65,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403351": {
    "id": 403351,
    "type": "school",
    "name": "PUTNAM ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 37,
    "report_id": 403351,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403352": {
    "id": 403352,
    "type": "school",
    "name": "OMAR D BLAIR CHARTER SCHOOL",
    "completed": 0,
    "total": 57,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403353": {
    "id": 403353,
    "type": "school",
    "name": "DUNSTAN MIDDLE SCHOOL",
    "completed": 33,
    "total": 42,
    "report_id": 403353,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403354": {
    "id": 403354,
    "type": "school",
    "name": "EARLY LEARNING CENTER AT NEW LEGACY CHARTER SCHOOL",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403355": {
    "id": 403355,
    "type": "school",
    "name": "LONE STAR SCHOOL",
    "completed": 3,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401835
  },
  "403356": {
    "id": 403356,
    "type": "school",
    "name": "FAIRVIEW ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 25,
    "report_id": 403356,
    "parent_type": "district",
    "parent_id": 401752
  },
  "403357": {
    "id": 403357,
    "type": "school",
    "name": "HOTCHKISS HIGH SCHOOL",
    "completed": 11,
    "total": 13,
    "report_id": 403357,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403358": {
    "id": 403358,
    "type": "school",
    "name": "WEST GRAND HIGH SCHOOL",
    "completed": 8,
    "total": 15,
    "report_id": 403358,
    "parent_type": "district",
    "parent_id": 401899
  },
  "403359": {
    "id": 403359,
    "type": "school",
    "name": "STARS EARLY LEARNING CENTER",
    "completed": 10,
    "total": 10,
    "report_id": 403359,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403360": {
    "id": 403360,
    "type": "school",
    "name": "BENNETT MIDDLE SCHOOL",
    "completed": 7,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401900
  },
  "403361": {
    "id": 403361,
    "type": "school",
    "name": "WILMOT ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403362": {
    "id": 403362,
    "type": "school",
    "name": "WOODLAND PARK MIDDLE SCHOOL",
    "completed": 10,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401844
  },
  "403363": {
    "id": 403363,
    "type": "school",
    "name": "FITZSIMMONS MIDDLE SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 403363,
    "parent_type": "district",
    "parent_id": 401897
  },
  "403364": {
    "id": 403364,
    "type": "school",
    "name": "CHEYENNE WELLS ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401845
  },
  "403365": {
    "id": 403365,
    "type": "school",
    "name": "WEST RIDGE ELEMENTARY",
    "completed": 39,
    "total": 39,
    "report_id": 403365,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403366": {
    "id": 403366,
    "type": "school",
    "name": "DISCOVERY HIGH SCHOOL",
    "completed": 6,
    "total": 9,
    "report_id": 403366,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403367": {
    "id": 403367,
    "type": "school",
    "name": "SOPRIS ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 40,
    "report_id": 403367,
    "parent_type": "district",
    "parent_id": 401849
  },
  "403368": {
    "id": 403368,
    "type": "school",
    "name": "RISLEY INTERNATIONAL ACADEMY OF INNOVATION",
    "completed": 29,
    "total": 36,
    "report_id": 403368,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403369": {
    "id": 403369,
    "type": "school",
    "name": "CORONADO HILLS ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 46,
    "report_id": 403369,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403370": {
    "id": 403370,
    "type": "school",
    "name": "CENTAURUS HIGH SCHOOL",
    "completed": 64,
    "total": 83,
    "report_id": 403370,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403371": {
    "id": 403371,
    "type": "school",
    "name": "GLENWOOD SPRINGS ELEMENTARY SCHOOL",
    "completed": 40,
    "total": 54,
    "report_id": 403371,
    "parent_type": "district",
    "parent_id": 401849
  },
  "403372": {
    "id": 403372,
    "type": "school",
    "name": "DENVER LANGUAGE SCHOOL",
    "completed": 0,
    "total": 56,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403373": {
    "id": 403373,
    "type": "school",
    "name": "THOMPSON VALLEY HIGH SCHOOL",
    "completed": 52,
    "total": 81,
    "report_id": 403373,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403374": {
    "id": 403374,
    "type": "school",
    "name": "WEST MIDDLE SCHOOL",
    "completed": 21,
    "total": 23,
    "report_id": 403374,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403375": {
    "id": 403375,
    "type": "school",
    "name": "ACADEMY CHARTER SCHOOL",
    "completed": 32,
    "total": 63,
    "report_id": 403375,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403376": {
    "id": 403376,
    "type": "school",
    "name": "SOUTH HIGH SCHOOL",
    "completed": 65,
    "total": 66,
    "report_id": 403376,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403377": {
    "id": 403377,
    "type": "school",
    "name": "SOUTH VALLEY MIDDLE SCHOOL",
    "completed": 11,
    "total": 21,
    "report_id": 403377,
    "parent_type": "district",
    "parent_id": 401902
  },
  "403378": {
    "id": 403378,
    "type": "school",
    "name": "GRAND VALLEY CENTER FOR FAMILY LEARNING",
    "completed": 2,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401741
  },
  "403379": {
    "id": 403379,
    "type": "school",
    "name": "LAKEWOOD HIGH SCHOOL",
    "completed": 87,
    "total": 124,
    "report_id": 403379,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403380": {
    "id": 403380,
    "type": "school",
    "name": "JANITELL JUNIOR HIGH SCHOOL",
    "completed": 29,
    "total": 45,
    "report_id": 403380,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403381": {
    "id": 403381,
    "type": "school",
    "name": "CENTENNIAL BOCES HIGH SCHOOL",
    "completed": 9,
    "total": 9,
    "report_id": 403381,
    "parent_type": "district",
    "parent_id": 401859
  },
  "403382": {
    "id": 403382,
    "type": "school",
    "name": "GREAT WORK MONTESSORI",
    "completed": 0,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403383": {
    "id": 403383,
    "type": "school",
    "name": "EAGLE RIDGE ACADEMY",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403384": {
    "id": 403384,
    "type": "school",
    "name": "GOLD CAMP ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "403385": {
    "id": 403385,
    "type": "school",
    "name": "KIT CARSON JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 1,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401782
  },
  "403386": {
    "id": 403386,
    "type": "school",
    "name": "CAREER READINESS ACADEMY",
    "completed": 0,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403387": {
    "id": 403387,
    "type": "school",
    "name": "CENTRAL HIGH SCHOOL",
    "completed": 86,
    "total": 95,
    "report_id": 403387,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403388": {
    "id": 403388,
    "type": "school",
    "name": "ROCKY MOUNTAIN PREP - FLETCHER CAMPUS",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403389": {
    "id": 403389,
    "type": "school",
    "name": "SKINNER MIDDLE SCHOOL",
    "completed": 0,
    "total": 51,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403390": {
    "id": 403390,
    "type": "school",
    "name": "LIVERMORE ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 7,
    "report_id": 403390,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403391": {
    "id": 403391,
    "type": "school",
    "name": "PLEASANT VIEW ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 3,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401726
  },
  "403392": {
    "id": 403392,
    "type": "school",
    "name": "PUEBLO SCH. FOR ARTS & SCIENCES AT FULTON HEIGHTS",
    "completed": 4,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403393": {
    "id": 403393,
    "type": "school",
    "name": "MERINO JUNIOR SENIOR HIGH SCHOOL",
    "completed": 15,
    "total": 16,
    "report_id": 403393,
    "parent_type": "district",
    "parent_id": 401824
  },
  "403394": {
    "id": 403394,
    "type": "school",
    "name": "CANON CITY MIDDLE SCHOOL",
    "completed": 26,
    "total": 28,
    "report_id": 403394,
    "parent_type": "district",
    "parent_id": 401816
  },
  "403395": {
    "id": 403395,
    "type": "school",
    "name": "WRAY HIGH SCHOOL",
    "completed": 12,
    "total": 19,
    "report_id": 403395,
    "parent_type": "district",
    "parent_id": 401783
  },
  "403396": {
    "id": 403396,
    "type": "school",
    "name": "STECK ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403397": {
    "id": 403397,
    "type": "school",
    "name": "CEDAR RIDGE ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403398": {
    "id": 403398,
    "type": "school",
    "name": "SALIDA DEL SOL ACADEMY",
    "completed": 30,
    "total": 47,
    "report_id": 403398,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403399": {
    "id": 403399,
    "type": "school",
    "name": "HOEHNE SCHOOLS",
    "completed": 22,
    "total": 23,
    "report_id": 403399,
    "parent_type": "district",
    "parent_id": 401852
  },
  "403400": {
    "id": 403400,
    "type": "school",
    "name": "GUNNISON MIDDLE SCHOOL",
    "completed": 11,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401745
  },
  "403401": {
    "id": 403401,
    "type": "school",
    "name": "GODDARD MIDDLE SCHOOL",
    "completed": 55,
    "total": 58,
    "report_id": 403401,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403402": {
    "id": 403402,
    "type": "school",
    "name": "GLOBAL VILLAGE ACADEMY - COLORADO SPRINGS",
    "completed": 0,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403403": {
    "id": 403403,
    "type": "school",
    "name": "DENVER CENTER FOR INTERNATIONAL STUDIES AT FAIRMONT",
    "completed": 3,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403404": {
    "id": 403404,
    "type": "school",
    "name": "LINCOLN ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 403404,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403405": {
    "id": 403405,
    "type": "school",
    "name": "EAST ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 19,
    "report_id": 403405,
    "parent_type": "district",
    "parent_id": 401774
  },
  "403406": {
    "id": 403406,
    "type": "school",
    "name": "ENDEAVOR ACADEMY",
    "completed": 6,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403407": {
    "id": 403407,
    "type": "school",
    "name": "NEDERLAND ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 24,
    "report_id": 403407,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403408": {
    "id": 403408,
    "type": "school",
    "name": "COMPASS MONTESSORI - GOLDEN CHARTER SCHOOL",
    "completed": 0,
    "total": 48,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403409": {
    "id": 403409,
    "type": "school",
    "name": "GRAND RIVER ACADEMY",
    "completed": 17,
    "total": 17,
    "report_id": 403409,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403410": {
    "id": 403410,
    "type": "school",
    "name": "GRANDVIEW HIGH SCHOOL",
    "completed": 17,
    "total": 178,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403411": {
    "id": 403411,
    "type": "school",
    "name": "HOPE ONLINE LEARNING ACADEMY MIDDLE SCHOOL",
    "completed": 9,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403412": {
    "id": 403412,
    "type": "school",
    "name": "DEVINNY ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 39,
    "report_id": 403412,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403413": {
    "id": 403413,
    "type": "school",
    "name": "BEAR CREEK HIGH SCHOOL",
    "completed": 85,
    "total": 101,
    "report_id": 403413,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403414": {
    "id": 403414,
    "type": "school",
    "name": "DOS RIOS ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 33,
    "report_id": 403414,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403415": {
    "id": 403415,
    "type": "school",
    "name": "MOUNTAINSIDE ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401729
  },
  "403416": {
    "id": 403416,
    "type": "school",
    "name": "PINE LANE ELEMENTARY",
    "completed": 45,
    "total": 60,
    "report_id": 403416,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403417": {
    "id": 403417,
    "type": "school",
    "name": "GRANT ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 37,
    "report_id": 403417,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403418": {
    "id": 403418,
    "type": "school",
    "name": "SPRINGFIELD JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 17,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401881
  },
  "403419": {
    "id": 403419,
    "type": "school",
    "name": "BERRY CREEK MIDDLE SCHOOL",
    "completed": 7,
    "total": 28,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401750
  },
  "403420": {
    "id": 403420,
    "type": "school",
    "name": "DC MONTESSORI CHARTER SCHOOL",
    "completed": 1,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403421": {
    "id": 403421,
    "type": "school",
    "name": "PRAIRIE CREEKS CHARTER SCHOOL",
    "completed": 0,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401791
  },
  "403422": {
    "id": 403422,
    "type": "school",
    "name": "FOX CREEK ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 37,
    "report_id": 403422,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403423": {
    "id": 403423,
    "type": "school",
    "name": "CHELTENHAM ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 44,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403424": {
    "id": 403424,
    "type": "school",
    "name": "STEPHEN KNIGHT CENTER FOR EARLY EDUCATION",
    "completed": 3,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403425": {
    "id": 403425,
    "type": "school",
    "name": "RED SANDSTONE ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 20,
    "report_id": 403425,
    "parent_type": "district",
    "parent_id": 401750
  },
  "403426": {
    "id": 403426,
    "type": "school",
    "name": "ACADEMY OF ADVANCED LEARNING",
    "completed": 15,
    "total": 15,
    "report_id": 403426,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403427": {
    "id": 403427,
    "type": "school",
    "name": "PROSPECT RIDGE ACADEMY",
    "completed": 0,
    "total": 101,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403428": {
    "id": 403428,
    "type": "school",
    "name": "GEORGE WASHINGTON HIGH SCHOOL",
    "completed": 18,
    "total": 92,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403429": {
    "id": 403429,
    "type": "school",
    "name": "GLENWOOD SPRINGS MIDDLE SCHOOL",
    "completed": 31,
    "total": 38,
    "report_id": 403429,
    "parent_type": "district",
    "parent_id": 401849
  },
  "403430": {
    "id": 403430,
    "type": "school",
    "name": "LUMBERG ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 34,
    "report_id": 403430,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403431": {
    "id": 403431,
    "type": "school",
    "name": "COLOROW ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 19,
    "report_id": 403431,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403432": {
    "id": 403432,
    "type": "school",
    "name": "NORTH PARK SCHOOL",
    "completed": 12,
    "total": 22,
    "report_id": 403432,
    "parent_type": "district",
    "parent_id": 401751
  },
  "403433": {
    "id": 403433,
    "type": "school",
    "name": "CENTENNIAL ACADEMY OF FINE ARTS EDUCATION",
    "completed": 29,
    "total": 40,
    "report_id": 403433,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403434": {
    "id": 403434,
    "type": "school",
    "name": "OAK GROVE ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 28,
    "report_id": 403434,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403435": {
    "id": 403435,
    "type": "school",
    "name": "NORTHRIDGE ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403436": {
    "id": 403436,
    "type": "school",
    "name": "WALSH ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 13,
    "report_id": 403436,
    "parent_type": "district",
    "parent_id": 401800
  },
  "403437": {
    "id": 403437,
    "type": "school",
    "name": "TAYLOR ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 28,
    "report_id": 403437,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403438": {
    "id": 403438,
    "type": "school",
    "name": "KIPP NORTHEAST DENVER MIDDLE SCHOOL",
    "completed": 0,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403439": {
    "id": 403439,
    "type": "school",
    "name": "BENNETT ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 28,
    "report_id": 403439,
    "parent_type": "district",
    "parent_id": 401900
  },
  "403440": {
    "id": 403440,
    "type": "school",
    "name": "KENNETH P MORRIS ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 26,
    "report_id": 403440,
    "parent_type": "district",
    "parent_id": 401886
  },
  "403441": {
    "id": 403441,
    "type": "school",
    "name": "WOODMEN-ROBERTS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403442": {
    "id": 403442,
    "type": "school",
    "name": "POLTON COMMUNITY ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403443": {
    "id": 403443,
    "type": "school",
    "name": "CARBON VALLEY ACADEMY",
    "completed": 1,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403444": {
    "id": 403444,
    "type": "school",
    "name": "PEYTON ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 19,
    "report_id": 403444,
    "parent_type": "district",
    "parent_id": 401767
  },
  "403445": {
    "id": 403445,
    "type": "school",
    "name": "EMILY GRIFFITH HIGH SCHOOL",
    "completed": 3,
    "total": 51,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403446": {
    "id": 403446,
    "type": "school",
    "name": "CRESSON ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401813
  },
  "403447": {
    "id": 403447,
    "type": "school",
    "name": "PRITCHETT HIGH SCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401760
  },
  "403448": {
    "id": 403448,
    "type": "school",
    "name": "GATEWAY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401844
  },
  "403449": {
    "id": 403449,
    "type": "school",
    "name": "HAMILTON MIDDLE SCHOOL",
    "completed": 0,
    "total": 71,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403450": {
    "id": 403450,
    "type": "school",
    "name": "TENNYSON KNOLLS ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 27,
    "report_id": 403450,
    "parent_type": "district",
    "parent_id": 401752
  },
  "403451": {
    "id": 403451,
    "type": "school",
    "name": "BEACH COURT ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403452": {
    "id": 403452,
    "type": "school",
    "name": "FIRESIDE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 26,
    "report_id": 403452,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403453": {
    "id": 403453,
    "type": "school",
    "name": "JEFFERSON JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 58,
    "total": 70,
    "report_id": 403453,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403454": {
    "id": 403454,
    "type": "school",
    "name": "PRAIRIE HEIGHTS MIDDLE SCHOOL",
    "completed": 6,
    "total": 47,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403455": {
    "id": 403455,
    "type": "school",
    "name": "CAMPUS MIDDLE SCHOOL",
    "completed": 23,
    "total": 91,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403456": {
    "id": 403456,
    "type": "school",
    "name": "JOHNSON ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 39,
    "report_id": 403456,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403457": {
    "id": 403457,
    "type": "school",
    "name": "FLORIDA MESA ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 38,
    "report_id": 403457,
    "parent_type": "district",
    "parent_id": 401889
  },
  "403458": {
    "id": 403458,
    "type": "school",
    "name": "GRAND VALLEY MIDDLE SCHOOL",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401741
  },
  "403459": {
    "id": 403459,
    "type": "school",
    "name": "MILLIKEN ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 31,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401728
  },
  "403460": {
    "id": 403460,
    "type": "school",
    "name": "LAUNCH HIGH SCHOOL",
    "completed": 7,
    "total": 8,
    "report_id": 403460,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403461": {
    "id": 403461,
    "type": "school",
    "name": "LASLEY ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 36,
    "report_id": 403461,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403462": {
    "id": 403462,
    "type": "school",
    "name": "SHELTON ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 36,
    "report_id": 403462,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403463": {
    "id": 403463,
    "type": "school",
    "name": "NORTH STAR ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 38,
    "report_id": 403463,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403464": {
    "id": 403464,
    "type": "school",
    "name": "WATSON JUNIOR HIGH SCHOOL",
    "completed": 24,
    "total": 44,
    "report_id": 403464,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403465": {
    "id": 403465,
    "type": "school",
    "name": "MCAULIFFE ELEMENTARY",
    "completed": 34,
    "total": 37,
    "report_id": 403465,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403466": {
    "id": 403466,
    "type": "school",
    "name": "HOLLY ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 14,
    "report_id": 403466,
    "parent_type": "district",
    "parent_id": 401848
  },
  "403467": {
    "id": 403467,
    "type": "school",
    "name": "WHITTIER ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 36,
    "report_id": 403467,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403468": {
    "id": 403468,
    "type": "school",
    "name": "ARAPAHOE RIDGE HIGH SCHOOL",
    "completed": 32,
    "total": 35,
    "report_id": 403468,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403469": {
    "id": 403469,
    "type": "school",
    "name": "HALLETT ACADEMY",
    "completed": 0,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403470": {
    "id": 403470,
    "type": "school",
    "name": "MOUNTAIN VISTA COMMUNITY SCHOOL",
    "completed": 0,
    "total": 50,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403471": {
    "id": 403471,
    "type": "school",
    "name": "CLEAR CREEK HIGH SCHOOL",
    "completed": 1,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401856
  },
  "403472": {
    "id": 403472,
    "type": "school",
    "name": "BRECKENRIDGE ELEMENTARY SCHOOL",
    "completed": 13,
    "total": 18,
    "report_id": 403472,
    "parent_type": "district",
    "parent_id": 401811
  },
  "403473": {
    "id": 403473,
    "type": "school",
    "name": "RICO ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 2,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401857
  },
  "403474": {
    "id": 403474,
    "type": "school",
    "name": "PIKES PEAK ONLINE SCHOOL",
    "completed": 16,
    "total": 28,
    "report_id": 403474,
    "parent_type": "district",
    "parent_id": 401770
  },
  "403475": {
    "id": 403475,
    "type": "school",
    "name": "CARLILE ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 403475,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403476": {
    "id": 403476,
    "type": "school",
    "name": "DCIS AT FORD",
    "completed": 0,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403477": {
    "id": 403477,
    "type": "school",
    "name": "THE PINNACLE CHARTER SCHOOL ELEMENTARY",
    "completed": 22,
    "total": 69,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403478": {
    "id": 403478,
    "type": "school",
    "name": "SUMMIT MIDDLE CHARTER SCHOOL",
    "completed": 0,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403479": {
    "id": 403479,
    "type": "school",
    "name": "FREE HORIZON MONTESSORI CHARTER SCHOOL",
    "completed": 29,
    "total": 33,
    "report_id": 403479,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403480": {
    "id": 403480,
    "type": "school",
    "name": "STRASBURG ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401791
  },
  "403481": {
    "id": 403481,
    "type": "school",
    "name": "CAMPO ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 10,
    "report_id": 403481,
    "parent_type": "district",
    "parent_id": 401870
  },
  "403482": {
    "id": 403482,
    "type": "school",
    "name": "BRADY EXPLORATION SCHOOL",
    "completed": 25,
    "total": 29,
    "report_id": 403482,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403483": {
    "id": 403483,
    "type": "school",
    "name": "MARBLE CHARTER SCHOOL",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401745
  },
  "403484": {
    "id": 403484,
    "type": "school",
    "name": "WEIKEL ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 58,
    "report_id": 403484,
    "parent_type": "district",
    "parent_id": 401729
  },
  "403485": {
    "id": 403485,
    "type": "school",
    "name": "WARREN TECH NORTH",
    "completed": 15,
    "total": 16,
    "report_id": 403485,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403486": {
    "id": 403486,
    "type": "school",
    "name": "JEFFCO'S 21ST CENTURY VIRTUAL ACADEMY",
    "completed": 21,
    "total": 41,
    "report_id": 403486,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403487": {
    "id": 403487,
    "type": "school",
    "name": "GUNNISON ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 49,
    "report_id": 403487,
    "parent_type": "district",
    "parent_id": 401745
  },
  "403488": {
    "id": 403488,
    "type": "school",
    "name": "FISHER'S PEAK ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401732
  },
  "403489": {
    "id": 403489,
    "type": "school",
    "name": "BANNING LEWIS RANCH ACADEMY",
    "completed": 35,
    "total": 52,
    "report_id": 403489,
    "parent_type": "district",
    "parent_id": 401851
  },
  "403490": {
    "id": 403490,
    "type": "school",
    "name": "MARSH ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 14,
    "report_id": 403490,
    "parent_type": "district",
    "parent_id": 401882
  },
  "403491": {
    "id": 403491,
    "type": "school",
    "name": "HOPKINS ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 24,
    "report_id": 403491,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403492": {
    "id": 403492,
    "type": "school",
    "name": "COLORADO DIGITAL ACADEMY - MIDDLE",
    "completed": 0,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "403493": {
    "id": 403493,
    "type": "school",
    "name": "MADISON ELEMENTARY SCHOOL",
    "completed": 35,
    "total": 35,
    "report_id": 403493,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403494": {
    "id": 403494,
    "type": "school",
    "name": "GRAND MESA CHOICE ACADEMY",
    "completed": 7,
    "total": 7,
    "report_id": 403494,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403495": {
    "id": 403495,
    "type": "school",
    "name": "70 ONLINE",
    "completed": 3,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403496": {
    "id": 403496,
    "type": "school",
    "name": "THE VANGUARD SCHOOL (MIDDLE)",
    "completed": 0,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "403497": {
    "id": 403497,
    "type": "school",
    "name": "YUMA HIGH SCHOOL",
    "completed": 18,
    "total": 22,
    "report_id": 403497,
    "parent_type": "district",
    "parent_id": 401886
  },
  "403498": {
    "id": 403498,
    "type": "school",
    "name": "PRAIRIE HEIGHTS ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401781
  },
  "403499": {
    "id": 403499,
    "type": "school",
    "name": "KEARNEY MIDDLE SCHOOL",
    "completed": 60,
    "total": 60,
    "report_id": 403499,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403500": {
    "id": 403500,
    "type": "school",
    "name": "GOODNIGHT ELEMENTARY SCHOOL",
    "completed": 38,
    "total": 40,
    "report_id": 403500,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403501": {
    "id": 403501,
    "type": "school",
    "name": "ESCALANTE MIDDLE SCHOOL",
    "completed": 27,
    "total": 45,
    "report_id": 403501,
    "parent_type": "district",
    "parent_id": 401889
  },
  "403502": {
    "id": 403502,
    "type": "school",
    "name": "ACADEMY 360",
    "completed": 0,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403503": {
    "id": 403503,
    "type": "school",
    "name": "WELLINGTON MIDDLE SCHOOL",
    "completed": 39,
    "total": 43,
    "report_id": 403503,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403504": {
    "id": 403504,
    "type": "school",
    "name": "WIGGINS ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401806
  },
  "403505": {
    "id": 403505,
    "type": "school",
    "name": "EAST HIGH SCHOOL",
    "completed": 63,
    "total": 69,
    "report_id": 403505,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403506": {
    "id": 403506,
    "type": "school",
    "name": "CHARLES HAY WORLD SCHOOL",
    "completed": 25,
    "total": 27,
    "report_id": 403506,
    "parent_type": "district",
    "parent_id": 401802
  },
  "403507": {
    "id": 403507,
    "type": "school",
    "name": "BENJAMIN EATON ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 27,
    "report_id": 403507,
    "parent_type": "district",
    "parent_id": 401868
  },
  "403508": {
    "id": 403508,
    "type": "school",
    "name": "KEMP ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 36,
    "report_id": 403508,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403509": {
    "id": 403509,
    "type": "school",
    "name": "GRAHAM MESA ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 28,
    "report_id": 403509,
    "parent_type": "district",
    "parent_id": 401759
  },
  "403510": {
    "id": 403510,
    "type": "school",
    "name": "PLATTE VALLEY HIGH SCHOOL",
    "completed": 17,
    "total": 32,
    "report_id": 403510,
    "parent_type": "district",
    "parent_id": 401838
  },
  "403511": {
    "id": 403511,
    "type": "school",
    "name": "SOBESKY ACADEMY",
    "completed": 17,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403512": {
    "id": 403512,
    "type": "school",
    "name": "FOUNTAIN-FORT CARSON HIGH SCHOOL",
    "completed": 62,
    "total": 132,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401729
  },
  "403513": {
    "id": 403513,
    "type": "school",
    "name": "WEBBER MIDDLE SCHOOL",
    "completed": 53,
    "total": 53,
    "report_id": 403513,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403514": {
    "id": 403514,
    "type": "school",
    "name": "POMONA ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 403514,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403515": {
    "id": 403515,
    "type": "school",
    "name": "COLORADO CONNECTIONS ACADEMY @ DURANGO",
    "completed": 0,
    "total": 96,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401889
  },
  "403516": {
    "id": 403516,
    "type": "school",
    "name": "SANDROCK ELEMENTARY",
    "completed": 21,
    "total": 21,
    "report_id": 403516,
    "parent_type": "district",
    "parent_id": 401774
  },
  "403517": {
    "id": 403517,
    "type": "school",
    "name": "MONTCLAIR ELEMENTARY SCHOOL",
    "completed": 3,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403518": {
    "id": 403518,
    "type": "school",
    "name": "NIWOT HIGH SCHOOL",
    "completed": 22,
    "total": 72,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403519": {
    "id": 403519,
    "type": "school",
    "name": "IVER C. RANUM MIDDLE SCHOOL",
    "completed": 38,
    "total": 48,
    "report_id": 403519,
    "parent_type": "district",
    "parent_id": 401752
  },
  "403520": {
    "id": 403520,
    "type": "school",
    "name": "HENDERSON ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 403520,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403521": {
    "id": 403521,
    "type": "school",
    "name": "ANIMAS HIGH SCHOOL",
    "completed": 9,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403522": {
    "id": 403522,
    "type": "school",
    "name": "WESTLAKE MIDDLE SCHOOL",
    "completed": 32,
    "total": 76,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403523": {
    "id": 403523,
    "type": "school",
    "name": "PARK VIEW ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 32,
    "report_id": 403523,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403524": {
    "id": 403524,
    "type": "school",
    "name": "KIM ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 5,
    "report_id": 403524,
    "parent_type": "district",
    "parent_id": 401884
  },
  "403525": {
    "id": 403525,
    "type": "school",
    "name": "MADISON ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 403525,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403526": {
    "id": 403526,
    "type": "school",
    "name": "ASPEN PRE-SCHOOL",
    "completed": 1,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401808
  },
  "403527": {
    "id": 403527,
    "type": "school",
    "name": "FREMONT ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 35,
    "report_id": 403527,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403528": {
    "id": 403528,
    "type": "school",
    "name": "FOSTER ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 37,
    "report_id": 403528,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403529": {
    "id": 403529,
    "type": "school",
    "name": "WESTVIEW MIDDLE SCHOOL",
    "completed": 23,
    "total": 44,
    "report_id": 403529,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403530": {
    "id": 403530,
    "type": "school",
    "name": "FLAGSTONE ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 33,
    "report_id": 403530,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403531": {
    "id": 403531,
    "type": "school",
    "name": "BENJAMIN FRANKLIN ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 403531,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403532": {
    "id": 403532,
    "type": "school",
    "name": "ODYSSEY SCHOOL OF DENVER",
    "completed": 3,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403533": {
    "id": 403533,
    "type": "school",
    "name": "MIAMI/YODER ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 17,
    "report_id": 403533,
    "parent_type": "district",
    "parent_id": 401758
  },
  "403534": {
    "id": 403534,
    "type": "school",
    "name": "GLOBAL INTERMEDIATE ACADEMY",
    "completed": 21,
    "total": 21,
    "report_id": 403534,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403535": {
    "id": 403535,
    "type": "school",
    "name": "COUGAR RUN ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 35,
    "report_id": 403535,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403536": {
    "id": 403536,
    "type": "school",
    "name": "LOVELAND HIGH SCHOOL",
    "completed": 76,
    "total": 104,
    "report_id": 403536,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403537": {
    "id": 403537,
    "type": "school",
    "name": "ROSS MONTESSORI SCHOOL",
    "completed": 0,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403538": {
    "id": 403538,
    "type": "school",
    "name": "VALVERDE ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403539": {
    "id": 403539,
    "type": "school",
    "name": "STEAMBOAT SPRINGS HIGH SCHOOL",
    "completed": 37,
    "total": 57,
    "report_id": 403539,
    "parent_type": "district",
    "parent_id": 401846
  },
  "403540": {
    "id": 403540,
    "type": "school",
    "name": "LA JARA ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 18,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401903
  },
  "403541": {
    "id": 403541,
    "type": "school",
    "name": "HAYDEN MIDDLE SCHOOL",
    "completed": 8,
    "total": 8,
    "report_id": 403541,
    "parent_type": "district",
    "parent_id": 401814
  },
  "403542": {
    "id": 403542,
    "type": "school",
    "name": "CHEYENNE MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "403543": {
    "id": 403543,
    "type": "school",
    "name": "MOREY MIDDLE SCHOOL",
    "completed": 5,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403544": {
    "id": 403544,
    "type": "school",
    "name": "ENGLEWOOD HIGH SCHOOL",
    "completed": 36,
    "total": 45,
    "report_id": 403544,
    "parent_type": "district",
    "parent_id": 401802
  },
  "403545": {
    "id": 403545,
    "type": "school",
    "name": "PAGOSA SPRINGS MIDDLE SCHOOL",
    "completed": 6,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401883
  },
  "403546": {
    "id": 403546,
    "type": "school",
    "name": "AXL ACADEMY",
    "completed": 16,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403547": {
    "id": 403547,
    "type": "school",
    "name": "LEWIS-ARRIOLA ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 10,
    "report_id": 403547,
    "parent_type": "district",
    "parent_id": 401726
  },
  "403548": {
    "id": 403548,
    "type": "school",
    "name": "TRINIDAD HIGH SCHOOL",
    "completed": 12,
    "total": 20,
    "report_id": 403548,
    "parent_type": "district",
    "parent_id": 401732
  },
  "403549": {
    "id": 403549,
    "type": "school",
    "name": "LAKE CITY COMMUNITY SCHOOL",
    "completed": 18,
    "total": 18,
    "report_id": 403549,
    "parent_type": "district",
    "parent_id": 401901
  },
  "403550": {
    "id": 403550,
    "type": "school",
    "name": "REACH CHARTER SCHOOL",
    "completed": 9,
    "total": 17,
    "report_id": 403550,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403551": {
    "id": 403551,
    "type": "school",
    "name": "MERIDIAN ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 49,
    "report_id": 403551,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403552": {
    "id": 403552,
    "type": "school",
    "name": "EAGLETON ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 33,
    "report_id": 403552,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403553": {
    "id": 403553,
    "type": "school",
    "name": "DCIS AT MONTBELLO",
    "completed": 2,
    "total": 85,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403554": {
    "id": 403554,
    "type": "school",
    "name": "LIBERTY COMMON CHARTER SCHOOL",
    "completed": 2,
    "total": 67,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403555": {
    "id": 403555,
    "type": "school",
    "name": "ALAMEDA INTERNATIONAL JUNIOR/SENIOR HIGH SCHOOL",
    "completed": 64,
    "total": 105,
    "report_id": 403555,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403556": {
    "id": 403556,
    "type": "school",
    "name": "GUFFEY CHARTER SCHOOL",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401762
  },
  "403557": {
    "id": 403557,
    "type": "school",
    "name": "CHALLENGER MIDDLE SCHOOL",
    "completed": 15,
    "total": 62,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403558": {
    "id": 403558,
    "type": "school",
    "name": "PONDEROSA ELEMENTARY",
    "completed": 37,
    "total": 37,
    "report_id": 403558,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403559": {
    "id": 403559,
    "type": "school",
    "name": "TIMNATH ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 28,
    "report_id": 403559,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403560": {
    "id": 403560,
    "type": "school",
    "name": "CANON EXPLORATORY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 403560,
    "parent_type": "district",
    "parent_id": 401816
  },
  "403561": {
    "id": 403561,
    "type": "school",
    "name": "HOWBERT ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 22,
    "report_id": 403561,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403562": {
    "id": 403562,
    "type": "school",
    "name": "BRIGHTON HIGH SCHOOL",
    "completed": 79,
    "total": 94,
    "report_id": 403562,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403563": {
    "id": 403563,
    "type": "school",
    "name": "BAYFIELD HIGH SCHOOL",
    "completed": 12,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401734
  },
  "403564": {
    "id": 403564,
    "type": "school",
    "name": "THUNDER MOUNTAIN ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 33,
    "report_id": 403564,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403565": {
    "id": 403565,
    "type": "school",
    "name": "KEMPER ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 403565,
    "parent_type": "district",
    "parent_id": 401726
  },
  "403566": {
    "id": 403566,
    "type": "school",
    "name": "MANITOU SPRINGS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401898
  },
  "403567": {
    "id": 403567,
    "type": "school",
    "name": "WASHINGTON ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 27,
    "report_id": 403567,
    "parent_type": "district",
    "parent_id": 401816
  },
  "403568": {
    "id": 403568,
    "type": "school",
    "name": "MONARCH MONTESSORI",
    "completed": 23,
    "total": 23,
    "report_id": 403568,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403569": {
    "id": 403569,
    "type": "school",
    "name": "MAYBELL SCHOOL",
    "completed": 0,
    "total": 1,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401774
  },
  "403570": {
    "id": 403570,
    "type": "school",
    "name": "DOULL ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403571": {
    "id": 403571,
    "type": "school",
    "name": "WIDEFIELD ELEMENTARY SCHOOL",
    "completed": 19,
    "total": 30,
    "report_id": 403571,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403572": {
    "id": 403572,
    "type": "school",
    "name": "CREATIVITY CHALLENGE COMMUNITY",
    "completed": 13,
    "total": 24,
    "report_id": 403572,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403573": {
    "id": 403573,
    "type": "school",
    "name": "HIGHLAND ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401759
  },
  "403574": {
    "id": 403574,
    "type": "school",
    "name": "EARLY COLLEGE OF ARVADA",
    "completed": 4,
    "total": 27,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403575": {
    "id": 403575,
    "type": "school",
    "name": "PIKES PEAK ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 32,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403576": {
    "id": 403576,
    "type": "school",
    "name": "SEDALIA ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 403576,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403577": {
    "id": 403577,
    "type": "school",
    "name": "UTE PASS ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 16,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401898
  },
  "403578": {
    "id": 403578,
    "type": "school",
    "name": "UNIVERSITY PREP - STEELE ST.",
    "completed": 0,
    "total": 26,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403579": {
    "id": 403579,
    "type": "school",
    "name": "BROMLEY EAST CHARTER SCHOOL",
    "completed": 29,
    "total": 66,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403580": {
    "id": 403580,
    "type": "school",
    "name": "PONDEROSA ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 59,
    "report_id": 403580,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403581": {
    "id": 403581,
    "type": "school",
    "name": "PALISADE HIGH SCHOOL",
    "completed": 35,
    "total": 67,
    "report_id": 403581,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403582": {
    "id": 403582,
    "type": "school",
    "name": "CHERRY VALLEY ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 7,
    "report_id": 403582,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403583": {
    "id": 403583,
    "type": "school",
    "name": "GALETON ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401868
  },
  "403584": {
    "id": 403584,
    "type": "school",
    "name": "LONGVIEW HIGH SCHOOL",
    "completed": 4,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403585": {
    "id": 403585,
    "type": "school",
    "name": "WELD CENTRAL SENIOR HIGH SCHOOL",
    "completed": 36,
    "total": 46,
    "report_id": 403585,
    "parent_type": "district",
    "parent_id": 401792
  },
  "403586": {
    "id": 403586,
    "type": "school",
    "name": "R-5 HIGH SCHOOL",
    "completed": 16,
    "total": 21,
    "report_id": 403586,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403587": {
    "id": 403587,
    "type": "school",
    "name": "BROADWAY ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 20,
    "report_id": 403587,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403588": {
    "id": 403588,
    "type": "school",
    "name": "QUEEN PALMER ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 23,
    "report_id": 403588,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403589": {
    "id": 403589,
    "type": "school",
    "name": "AVONDALE ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403590": {
    "id": 403590,
    "type": "school",
    "name": "SOUTH MIDDLE SCHOOL",
    "completed": 54,
    "total": 59,
    "report_id": 403590,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403591": {
    "id": 403591,
    "type": "school",
    "name": "OURAY MIDDLE SCHOOL",
    "completed": 7,
    "total": 12,
    "report_id": 403591,
    "parent_type": "district",
    "parent_id": 401777
  },
  "403592": {
    "id": 403592,
    "type": "school",
    "name": "ROSE HILL ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 37,
    "report_id": 403592,
    "parent_type": "district",
    "parent_id": 401895
  },
  "403593": {
    "id": 403593,
    "type": "school",
    "name": "KIOWA ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 9,
    "report_id": 403593,
    "parent_type": "district",
    "parent_id": 401766
  },
  "403594": {
    "id": 403594,
    "type": "school",
    "name": "LAKE COUNTY HIGH SCHOOL",
    "completed": 20,
    "total": 36,
    "report_id": 403594,
    "parent_type": "district",
    "parent_id": 401797
  },
  "403595": {
    "id": 403595,
    "type": "school",
    "name": "WELBY COMMUNITY SCHOOL",
    "completed": 11,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403596": {
    "id": 403596,
    "type": "school",
    "name": "COYOTE RIDGE ELEMENTARY SCHOOL",
    "completed": 21,
    "total": 34,
    "report_id": 403596,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403597": {
    "id": 403597,
    "type": "school",
    "name": "ACADEMY OF URBAN LEARNING",
    "completed": 1,
    "total": 13,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403598": {
    "id": 403598,
    "type": "school",
    "name": "JEFFERSON ACADEMY HIGH SCHOOL",
    "completed": 10,
    "total": 76,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403599": {
    "id": 403599,
    "type": "school",
    "name": "GLENWOOD SPRINGS HIGH SCHOOL",
    "completed": 50,
    "total": 75,
    "report_id": 403599,
    "parent_type": "district",
    "parent_id": 401849
  },
  "403600": {
    "id": 403600,
    "type": "school",
    "name": "PALMER HIGH SCHOOL",
    "completed": 94,
    "total": 121,
    "report_id": 403600,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403601": {
    "id": 403601,
    "type": "school",
    "name": "LAREDO CHILD DEVELOPMENT CENTER",
    "completed": 8,
    "total": 9,
    "report_id": 403601,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403602": {
    "id": 403602,
    "type": "school",
    "name": "CRESTED BUTTE SECONDARY SCHOOL",
    "completed": 14,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401745
  },
  "403603": {
    "id": 403603,
    "type": "school",
    "name": "COLUMBIAN ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 26,
    "report_id": 403603,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403604": {
    "id": 403604,
    "type": "school",
    "name": "MALLEY DRIVE ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 40,
    "report_id": 403604,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403605": {
    "id": 403605,
    "type": "school",
    "name": "NORWOOD PUBLIC SCHOOLS",
    "completed": 21,
    "total": 28,
    "report_id": 403605,
    "parent_type": "district",
    "parent_id": 401892
  },
  "403606": {
    "id": 403606,
    "type": "school",
    "name": "EAGLE ACADEMY",
    "completed": 14,
    "total": 18,
    "report_id": 403606,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403607": {
    "id": 403607,
    "type": "school",
    "name": "HOLYOKE SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401885
  },
  "403608": {
    "id": 403608,
    "type": "school",
    "name": "HIGHLINE ACADEMY NORTHEAST",
    "completed": 5,
    "total": 29,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403609": {
    "id": 403609,
    "type": "school",
    "name": "LAS ANIMAS JUNIOR HIGH SCHOOL",
    "completed": 3,
    "total": 3,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401753
  },
  "403610": {
    "id": 403610,
    "type": "school",
    "name": "LINCOLN SCHOOL OF SCIENCE AND TECHNOLOGY",
    "completed": 16,
    "total": 28,
    "report_id": 403610,
    "parent_type": "district",
    "parent_id": 401816
  },
  "403611": {
    "id": 403611,
    "type": "school",
    "name": "ELDORADO ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 38,
    "report_id": 403611,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403612": {
    "id": 403612,
    "type": "school",
    "name": "EAST HIGH SCHOOL",
    "completed": 63,
    "total": 162,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403613": {
    "id": 403613,
    "type": "school",
    "name": "FOWLER JUNIOR HIGH / HIGH SCHOOL",
    "completed": 4,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401833
  },
  "403614": {
    "id": 403614,
    "type": "school",
    "name": "CENTENNIAL ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 39,
    "report_id": 403614,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403615": {
    "id": 403615,
    "type": "school",
    "name": "ASPEN MIDDLE SCHOOL",
    "completed": 51,
    "total": 51,
    "report_id": 403615,
    "parent_type": "district",
    "parent_id": 401808
  },
  "403616": {
    "id": 403616,
    "type": "school",
    "name": "SANDBURG ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 35,
    "report_id": 403616,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403617": {
    "id": 403617,
    "type": "school",
    "name": "THE CLASSICAL ACADEMY MIDDLE SCHOOL",
    "completed": 0,
    "total": 58,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403618": {
    "id": 403618,
    "type": "school",
    "name": "OURAY ELEMENTARY SCHOOL",
    "completed": 16,
    "total": 20,
    "report_id": 403618,
    "parent_type": "district",
    "parent_id": 401777
  },
  "403619": {
    "id": 403619,
    "type": "school",
    "name": "RUNYON ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 32,
    "report_id": 403619,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403620": {
    "id": 403620,
    "type": "school",
    "name": "PEETZ ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 11,
    "report_id": 403620,
    "parent_type": "district",
    "parent_id": 401840
  },
  "403621": {
    "id": 403621,
    "type": "school",
    "name": "RIDGWAY ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401864
  },
  "403622": {
    "id": 403622,
    "type": "school",
    "name": "WINGATE ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 25,
    "report_id": 403622,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403623": {
    "id": 403623,
    "type": "school",
    "name": "FIELD ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 36,
    "report_id": 403623,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403624": {
    "id": 403624,
    "type": "school",
    "name": "LINCOLN ELEMENTARY SCHOOL",
    "completed": 30,
    "total": 31,
    "report_id": 403624,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403625": {
    "id": 403625,
    "type": "school",
    "name": "CLEAR SKY ELEMENTARY",
    "completed": 34,
    "total": 57,
    "report_id": 403625,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403626": {
    "id": 403626,
    "type": "school",
    "name": "COLUMBIA MIDDLE SCHOOL",
    "completed": 45,
    "total": 50,
    "report_id": 403626,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403627": {
    "id": 403627,
    "type": "school",
    "name": "ARROWWOOD ELEMENTARY SCHOOL",
    "completed": 17,
    "total": 29,
    "report_id": 403627,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403628": {
    "id": 403628,
    "type": "school",
    "name": "ASPEN ELEMENTARY SCHOOL",
    "completed": 57,
    "total": 57,
    "report_id": 403628,
    "parent_type": "district",
    "parent_id": 401808
  },
  "403629": {
    "id": 403629,
    "type": "school",
    "name": "LUKAS ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 32,
    "report_id": 403629,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403630": {
    "id": 403630,
    "type": "school",
    "name": "WALT CLARK MIDDLE SCHOOL",
    "completed": 38,
    "total": 38,
    "report_id": 403630,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403631": {
    "id": 403631,
    "type": "school",
    "name": "PRAIRIE VIEW HIGH SCHOOL",
    "completed": 95,
    "total": 97,
    "report_id": 403631,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403632": {
    "id": 403632,
    "type": "school",
    "name": "EISENHOWER ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 27,
    "report_id": 403632,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403633": {
    "id": 403633,
    "type": "school",
    "name": "EAST GRAND MIDDLE SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 403633,
    "parent_type": "district",
    "parent_id": 401747
  },
  "403634": {
    "id": 403634,
    "type": "school",
    "name": "EAGLECREST HIGH SCHOOL",
    "completed": 47,
    "total": 181,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403635": {
    "id": 403635,
    "type": "school",
    "name": "MEADOW POINT ELEMENTARY SCHOOL",
    "completed": 12,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403636": {
    "id": 403636,
    "type": "school",
    "name": "BLUE HERON ELEMENTARY SCHOOL",
    "completed": 34,
    "total": 36,
    "report_id": 403636,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403637": {
    "id": 403637,
    "type": "school",
    "name": "THUNDER RIDGE MIDDLE SCHOOL",
    "completed": 66,
    "total": 91,
    "report_id": 403637,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403638": {
    "id": 403638,
    "type": "school",
    "name": "WILLIAM (BILL) ROBERTS K-8 SCHOOL",
    "completed": 0,
    "total": 64,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403639": {
    "id": 403639,
    "type": "school",
    "name": "RED FEATHER LAKES ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 5,
    "report_id": 403639,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403640": {
    "id": 403640,
    "type": "school",
    "name": "CORONADO ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 34,
    "report_id": 403640,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403641": {
    "id": 403641,
    "type": "school",
    "name": "PSD GLOBAL ACADEMY",
    "completed": 27,
    "total": 27,
    "report_id": 403641,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403642": {
    "id": 403642,
    "type": "school",
    "name": "BELL MIDDLE SCHOOL",
    "completed": 38,
    "total": 54,
    "report_id": 403642,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403643": {
    "id": 403643,
    "type": "school",
    "name": "PLATTE RIVER CHARTER ACADEMY",
    "completed": 14,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403644": {
    "id": 403644,
    "type": "school",
    "name": "LOIS LENSKI ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 41,
    "report_id": 403644,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403645": {
    "id": 403645,
    "type": "school",
    "name": "DORA MOORE ECE-8 SCHOOL",
    "completed": 0,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403646": {
    "id": 403646,
    "type": "school",
    "name": "AUDUBON ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 29,
    "report_id": 403646,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403647": {
    "id": 403647,
    "type": "school",
    "name": "ROOTS ELEMENTARY",
    "completed": 0,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403648": {
    "id": 403648,
    "type": "school",
    "name": "CHAFFEE COUNTY HIGH SCHOOL",
    "completed": 3,
    "total": 6,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401788
  },
  "403649": {
    "id": 403649,
    "type": "school",
    "name": "CAMPO UNDIVIDED HIGH SCHOOL",
    "completed": 4,
    "total": 10,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401870
  },
  "403650": {
    "id": 403650,
    "type": "school",
    "name": "KING ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 403650,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403651": {
    "id": 403651,
    "type": "school",
    "name": "CARRIE MARTIN ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 23,
    "report_id": 403651,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403652": {
    "id": 403652,
    "type": "school",
    "name": "WIDEFIELD DISTRICT 3 PRESCHOOL",
    "completed": 22,
    "total": 41,
    "report_id": 403652,
    "parent_type": "district",
    "parent_id": 401874
  },
  "403653": {
    "id": 403653,
    "type": "school",
    "name": "SCENIC ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 18,
    "report_id": 403653,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403654": {
    "id": 403654,
    "type": "school",
    "name": "MEAD MIDDLE SCHOOL",
    "completed": 15,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403656": {
    "id": 403656,
    "type": "school",
    "name": "GRAND MESA MIDDLE SCHOOL",
    "completed": 42,
    "total": 48,
    "report_id": 403656,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403657": {
    "id": 403657,
    "type": "school",
    "name": "REDLANDS MIDDLE SCHOOL",
    "completed": 35,
    "total": 42,
    "report_id": 403657,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403658": {
    "id": 403658,
    "type": "school",
    "name": "NORTH MIDDLE SCHOOL HEALTH SCIENCES AND TECHNOLOGY CAMPUS",
    "completed": 54,
    "total": 55,
    "report_id": 403658,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403659": {
    "id": 403659,
    "type": "school",
    "name": "SWANSON ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 30,
    "report_id": 403659,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403660": {
    "id": 403660,
    "type": "school",
    "name": "ACADEMY INTERNATIONAL ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 43,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403661": {
    "id": 403661,
    "type": "school",
    "name": "PECK ELEMENTARY SCHOOL",
    "completed": 23,
    "total": 24,
    "report_id": 403661,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403662": {
    "id": 403662,
    "type": "school",
    "name": "CENTRAL HIGH SCHOOL",
    "completed": 51,
    "total": 51,
    "report_id": 403662,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403663": {
    "id": 403663,
    "type": "school",
    "name": "HIGHLINE COMMUNITY ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 49,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403664": {
    "id": 403664,
    "type": "school",
    "name": "RIM ROCK ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 38,
    "report_id": 403664,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403665": {
    "id": 403665,
    "type": "school",
    "name": "WESTMINSTER PUBLIC SCHOOLS EARLY LEARNING CENTER",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401752
  },
  "403666": {
    "id": 403666,
    "type": "school",
    "name": "GIRLS ATHLETIC LEADERSHIP SCHOOL HIGH SCHOOL",
    "completed": 0,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403667": {
    "id": 403667,
    "type": "school",
    "name": "CUSTER COUNTY HIGH SCHOOL",
    "completed": 10,
    "total": 17,
    "report_id": 403667,
    "parent_type": "district",
    "parent_id": 401904
  },
  "403668": {
    "id": 403668,
    "type": "school",
    "name": "RED ROCKS ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 21,
    "report_id": 403668,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403669": {
    "id": 403669,
    "type": "school",
    "name": "SHELLEDY ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 33,
    "report_id": 403669,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403670": {
    "id": 403670,
    "type": "school",
    "name": "YAMPAH MOUNTAIN SCHOOL",
    "completed": 0,
    "total": 5,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401742
  },
  "403671": {
    "id": 403671,
    "type": "school",
    "name": "KUNSMILLER CREATIVE ARTS ACADEMY",
    "completed": 5,
    "total": 72,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403672": {
    "id": 403672,
    "type": "school",
    "name": "STOVE PRAIRIE ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 5,
    "report_id": 403672,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403673": {
    "id": 403673,
    "type": "school",
    "name": "SUNSET RIDGE ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 25,
    "report_id": 403673,
    "parent_type": "district",
    "parent_id": 401752
  },
  "403674": {
    "id": 403674,
    "type": "school",
    "name": "EARLY CHILDHOOD EDUCATION CENTER",
    "completed": 5,
    "total": 5,
    "report_id": 403674,
    "parent_type": "district",
    "parent_id": 401837
  },
  "403675": {
    "id": 403675,
    "type": "school",
    "name": "PASCUAL LEDOUX ACADEMY",
    "completed": 5,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403676": {
    "id": 403676,
    "type": "school",
    "name": "EMERALD ELEMENTARY SCHOOL",
    "completed": 33,
    "total": 35,
    "report_id": 403676,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403677": {
    "id": 403677,
    "type": "school",
    "name": "LAWRENCE ELEMENTARY SCHOOL",
    "completed": 11,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403678": {
    "id": 403678,
    "type": "school",
    "name": "MONTROSE HIGH SCHOOL",
    "completed": 67,
    "total": 70,
    "report_id": 403678,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403679": {
    "id": 403679,
    "type": "school",
    "name": "CHIPETA ELEMENTARY SCHOOL",
    "completed": 31,
    "total": 31,
    "report_id": 403679,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403680": {
    "id": 403680,
    "type": "school",
    "name": "WILDER ELEMENTARY SCHOOL",
    "completed": 24,
    "total": 41,
    "report_id": 403680,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403681": {
    "id": 403681,
    "type": "school",
    "name": "CHERRELYN ELEMENTARY SCHOOL",
    "completed": 22,
    "total": 24,
    "report_id": 403681,
    "parent_type": "district",
    "parent_id": 401802
  },
  "403682": {
    "id": 403682,
    "type": "school",
    "name": "BEN FRANKLIN ACADEMY",
    "completed": 12,
    "total": 54,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403683": {
    "id": 403683,
    "type": "school",
    "name": "DISCOVERY CANYON CAMPUS MIDDLE SCHOOL",
    "completed": 11,
    "total": 61,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403684": {
    "id": 403684,
    "type": "school",
    "name": "ALTONA MIDDLE SCHOOL",
    "completed": 33,
    "total": 47,
    "report_id": 403684,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403685": {
    "id": 403685,
    "type": "school",
    "name": "JAMAICA CHILD DEVELOPMENT CENTER",
    "completed": 10,
    "total": 11,
    "report_id": 403685,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403686": {
    "id": 403686,
    "type": "school",
    "name": "ADAMS ELEMENTARY SCHOOL",
    "completed": 18,
    "total": 30,
    "report_id": 403686,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403687": {
    "id": 403687,
    "type": "school",
    "name": "HEROES K-8 ACADEMY",
    "completed": 25,
    "total": 25,
    "report_id": 403687,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403688": {
    "id": 403688,
    "type": "school",
    "name": "MOUNTAIN SONG COMMUNITY SCHOOL",
    "completed": 1,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403689": {
    "id": 403689,
    "type": "school",
    "name": "CHEYENNE MOUNTAIN HIGH SCHOOL",
    "completed": 44,
    "total": 95,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401769
  },
  "403690": {
    "id": 403690,
    "type": "school",
    "name": "HULSTROM OPTIONS K-8 SCHOOL",
    "completed": 28,
    "total": 46,
    "report_id": 403690,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403691": {
    "id": 403691,
    "type": "school",
    "name": "FRONTIER VALLEY ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 36,
    "report_id": 403691,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403692": {
    "id": 403692,
    "type": "school",
    "name": "CALHAN ELEMENTARY SCHOOL",
    "completed": 5,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401756
  },
  "403693": {
    "id": 403693,
    "type": "school",
    "name": "APPLETON ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 28,
    "report_id": 403693,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403694": {
    "id": 403694,
    "type": "school",
    "name": "CHEROKEE TRAIL ELEMENTARY SCHOOL",
    "completed": 27,
    "total": 40,
    "report_id": 403694,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403695": {
    "id": 403695,
    "type": "school",
    "name": "REVERE ELEMENTARY",
    "completed": 4,
    "total": 11,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401839
  },
  "403696": {
    "id": 403696,
    "type": "school",
    "name": "MANHATTAN MIDDLE SCHOOL OF THE ARTS AND ACADEMICS",
    "completed": 33,
    "total": 42,
    "report_id": 403696,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403697": {
    "id": 403697,
    "type": "school",
    "name": "PRAIRIE JUNIOR-SENIOR HIGH SCHOOL",
    "completed": 0,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401855
  },
  "403698": {
    "id": 403698,
    "type": "school",
    "name": "SODA CREEK ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 48,
    "report_id": 403698,
    "parent_type": "district",
    "parent_id": 401846
  },
  "403699": {
    "id": 403699,
    "type": "school",
    "name": "ASPEN COMMUNITY CHARTER SCHOOL",
    "completed": 19,
    "total": 19,
    "report_id": 403699,
    "parent_type": "district",
    "parent_id": 401808
  },
  "403700": {
    "id": 403700,
    "type": "school",
    "name": "MC CLAVE UNDIVIDED HIGH SCHOOL",
    "completed": 9,
    "total": 16,
    "report_id": 403700,
    "parent_type": "district",
    "parent_id": 401880
  },
  "403701": {
    "id": 403701,
    "type": "school",
    "name": "ESTES PARK MIDDLE SCHOOL",
    "completed": 18,
    "total": 29,
    "report_id": 403701,
    "parent_type": "district",
    "parent_id": 401887
  },
  "403702": {
    "id": 403702,
    "type": "school",
    "name": "COLLEGIATE ACADEMY OF COLORADO",
    "completed": 0,
    "total": 58,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403703": {
    "id": 403703,
    "type": "school",
    "name": "KRUSE ELEMENTARY SCHOOL",
    "completed": 29,
    "total": 33,
    "report_id": 403703,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403704": {
    "id": 403704,
    "type": "school",
    "name": "WEST JEFFERSON ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403705": {
    "id": 403705,
    "type": "school",
    "name": "KIPP NORTHEAST DENVER LEADERSHIP ACADEMY",
    "completed": 0,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403706": {
    "id": 403706,
    "type": "school",
    "name": "COMPASSION ROAD ACADEMY",
    "completed": 0,
    "total": 20,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403707": {
    "id": 403707,
    "type": "school",
    "name": "SILVERTON MIDDLE SCHOOL",
    "completed": 0,
    "total": 4,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401776
  },
  "403708": {
    "id": 403708,
    "type": "school",
    "name": "DISCOVERY CANYON CAMPUS ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403709": {
    "id": 403709,
    "type": "school",
    "name": "FREMONT ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401875
  },
  "403710": {
    "id": 403710,
    "type": "school",
    "name": "MONTBELLO CAREER AND TECHNICAL HIGH SCHOOL",
    "completed": 0,
    "total": 21,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403711": {
    "id": 403711,
    "type": "school",
    "name": "VANGUARD CLASSICAL SCHOOL - EAST",
    "completed": 12,
    "total": 64,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403712": {
    "id": 403712,
    "type": "school",
    "name": "MOORE MIDDLE SCHOOL",
    "completed": 30,
    "total": 31,
    "report_id": 403712,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403713": {
    "id": 403713,
    "type": "school",
    "name": "LIBERTY MIDDLE SCHOOL",
    "completed": 39,
    "total": 78,
    "report_id": 403713,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403714": {
    "id": 403714,
    "type": "school",
    "name": "MOLHOLM ELEMENTARY SCHOOL",
    "completed": 32,
    "total": 37,
    "report_id": 403714,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403715": {
    "id": 403715,
    "type": "school",
    "name": "MOUNTAIN VIEW ELEMENTARY SCHOOL",
    "completed": 20,
    "total": 28,
    "report_id": 403715,
    "parent_type": "district",
    "parent_id": 401818
  },
  "403716": {
    "id": 403716,
    "type": "school",
    "name": "ERIE ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 34,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401805
  },
  "403717": {
    "id": 403717,
    "type": "school",
    "name": "BRENTWOOD MIDDLE SCHOOL",
    "completed": 1,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401893
  },
  "403718": {
    "id": 403718,
    "type": "school",
    "name": "HIGH TECH ELEMENTARY SCHOOL",
    "completed": 1,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403719": {
    "id": 403719,
    "type": "school",
    "name": "CRESTONE CHARTER SCHOOL",
    "completed": 11,
    "total": 11,
    "report_id": 403719,
    "parent_type": "district",
    "parent_id": 401830
  },
  "403720": {
    "id": 403720,
    "type": "school",
    "name": "THE DA VINCI ACADEMY SCHOOL",
    "completed": 5,
    "total": 38,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401890
  },
  "403721": {
    "id": 403721,
    "type": "school",
    "name": "TWO RIVERS COMMUNITY SCHOOL",
    "completed": 2,
    "total": 37,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403722": {
    "id": 403722,
    "type": "school",
    "name": "BURLINGTON ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401828
  },
  "403723": {
    "id": 403723,
    "type": "school",
    "name": "NAMAQUA ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 26,
    "report_id": 403723,
    "parent_type": "district",
    "parent_id": 401862
  },
  "403724": {
    "id": 403724,
    "type": "school",
    "name": "WOODROW WILSON CHARTER ACADEMY",
    "completed": 13,
    "total": 70,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403725": {
    "id": 403725,
    "type": "school",
    "name": "MORTON ELEMENTARY SCHOOL",
    "completed": 26,
    "total": 30,
    "report_id": 403725,
    "parent_type": "district",
    "parent_id": 401832
  },
  "403726": {
    "id": 403726,
    "type": "school",
    "name": "WYATT ACADEMY",
    "completed": 0,
    "total": 39,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403727": {
    "id": 403727,
    "type": "school",
    "name": "NUCLA HIGH SCHOOL",
    "completed": 0,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401865
  },
  "403728": {
    "id": 403728,
    "type": "school",
    "name": "ENGLEWOOD MIDDLE SCHOOL",
    "completed": 30,
    "total": 30,
    "report_id": 403728,
    "parent_type": "district",
    "parent_id": 401802
  },
  "403729": {
    "id": 403729,
    "type": "school",
    "name": "STRIVE PREP - WESTWOOD",
    "completed": 0,
    "total": 36,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403730": {
    "id": 403730,
    "type": "school",
    "name": "DSST: COLE MIDDLE SCHOOL",
    "completed": 7,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403731": {
    "id": 403731,
    "type": "school",
    "name": "BLEVINS MIDDLE SCHOOL",
    "completed": 43,
    "total": 43,
    "report_id": 403731,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403732": {
    "id": 403732,
    "type": "school",
    "name": "CUSTER COUNTY ELEMENTARY SCHOOL",
    "completed": 8,
    "total": 16,
    "report_id": 403732,
    "parent_type": "district",
    "parent_id": 401904
  },
  "403733": {
    "id": 403733,
    "type": "school",
    "name": "COLE ARTS AND SCIENCE ACADEMY",
    "completed": 0,
    "total": 52,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403734": {
    "id": 403734,
    "type": "school",
    "name": "NORTH VALLEY SCHOOL FOR YOUNG ADULTS",
    "completed": 6,
    "total": 6,
    "report_id": 403734,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403735": {
    "id": 403735,
    "type": "school",
    "name": "KINARD CORE KNOWLEDGE MIDDLE SCHOOL",
    "completed": 45,
    "total": 47,
    "report_id": 403735,
    "parent_type": "district",
    "parent_id": 401768
  },
  "403736": {
    "id": 403736,
    "type": "school",
    "name": "BOLT ACADEMY",
    "completed": 5,
    "total": 5,
    "report_id": 403736,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403737": {
    "id": 403737,
    "type": "school",
    "name": "BYERS ELEMENTARY SCHOOL",
    "completed": 6,
    "total": 24,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401905
  },
  "403738": {
    "id": 403738,
    "type": "school",
    "name": "GODSMAN ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403739": {
    "id": 403739,
    "type": "school",
    "name": "VANTAGE POINT",
    "completed": 27,
    "total": 46,
    "report_id": 403739,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403740": {
    "id": 403740,
    "type": "school",
    "name": "PLEASANT VIEW MIDDLE SCHOOL",
    "completed": 4,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403741": {
    "id": 403741,
    "type": "school",
    "name": "HIGHLANDS RANCH HIGH SCHOOL",
    "completed": 71,
    "total": 115,
    "report_id": 403741,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403742": {
    "id": 403742,
    "type": "school",
    "name": "CACTUS VALLEY ELEMENTARY SCHOOL",
    "completed": 0,
    "total": 23,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401759
  },
  "403743": {
    "id": 403743,
    "type": "school",
    "name": "BUFFALO TRAIL ELEMENTARY SCHOOL",
    "completed": 28,
    "total": 47,
    "report_id": 403743,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403744": {
    "id": 403744,
    "type": "school",
    "name": "CAPROCK ACADEMY",
    "completed": 41,
    "total": 117,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401867
  },
  "403745": {
    "id": 403745,
    "type": "school",
    "name": "PLATEAU VALLEY HIGH SCHOOL",
    "completed": 2,
    "total": 15,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401731
  },
  "403746": {
    "id": 403746,
    "type": "school",
    "name": "PEAK VIRTUAL ACADEMY",
    "completed": 12,
    "total": 12,
    "report_id": 403746,
    "parent_type": "district",
    "parent_id": 401724
  },
  "403747": {
    "id": 403747,
    "type": "school",
    "name": "DOWNTOWN DENVER EXPEDITIONARY SCHOOL",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403748": {
    "id": 403748,
    "type": "school",
    "name": "FAIRMOUNT ELEMENTARY SCHOOL",
    "completed": 4,
    "total": 42,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403749": {
    "id": 403749,
    "type": "school",
    "name": "SWIGERT INTERNATIONAL SCHOOL",
    "completed": 0,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403750": {
    "id": 403750,
    "type": "school",
    "name": "RIVERDALE ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 35,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401823
  },
  "403751": {
    "id": 403751,
    "type": "school",
    "name": "BRIGHTON HERITAGE ACADEMY",
    "completed": 15,
    "total": 15,
    "report_id": 403751,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403752": {
    "id": 403752,
    "type": "school",
    "name": "INTERNATIONAL ACADEMY OF DENVER AT HARRINGTON",
    "completed": 0,
    "total": 33,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403753": {
    "id": 403753,
    "type": "school",
    "name": "CHERRY CREEK CHARTER ACADEMY",
    "completed": 38,
    "total": 38,
    "report_id": 403753,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403754": {
    "id": 403754,
    "type": "school",
    "name": "POMONA HIGH SCHOOL",
    "completed": 56,
    "total": 91,
    "report_id": 403754,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403755": {
    "id": 403755,
    "type": "school",
    "name": "INDEPENDENCE ELEMENTARY SCHOOL",
    "completed": 10,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403756": {
    "id": 403756,
    "type": "school",
    "name": "DSST: STAPLETON HIGH SCHOOL",
    "completed": 0,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403757": {
    "id": 403757,
    "type": "school",
    "name": "DEER CREEK MIDDLE SCHOOL",
    "completed": 40,
    "total": 51,
    "report_id": 403757,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403758": {
    "id": 403758,
    "type": "school",
    "name": "TIMBERLINE ELEMENTARY SCHOOL",
    "completed": 2,
    "total": 46,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401773
  },
  "403759": {
    "id": 403759,
    "type": "school",
    "name": "MC LAIN COMMUNITY HIGH SCHOOL",
    "completed": 12,
    "total": 14,
    "report_id": 403759,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403760": {
    "id": 403760,
    "type": "school",
    "name": "GLOBAL LEADERSHIP ACADEMY",
    "completed": 19,
    "total": 19,
    "report_id": 403760,
    "parent_type": "district",
    "parent_id": 401891
  },
  "403761": {
    "id": 403761,
    "type": "school",
    "name": "MONROE ELEMENTARY SCHOOL",
    "completed": 40,
    "total": 40,
    "report_id": 403761,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403762": {
    "id": 403762,
    "type": "school",
    "name": "NORTH VALLEY MIDDLE SCHOOL",
    "completed": 14,
    "total": 24,
    "report_id": 403762,
    "parent_type": "district",
    "parent_id": 401902
  },
  "403763": {
    "id": 403763,
    "type": "school",
    "name": "NORTH HIGH SCHOOL ENGAGEMENT CENTER",
    "completed": 2,
    "total": 9,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403764": {
    "id": 403764,
    "type": "school",
    "name": "APS EARLY BEGINNINGS - A ZOOM SITE",
    "completed": 0,
    "total": 7,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401896
  },
  "403765": {
    "id": 403765,
    "type": "school",
    "name": "ISABELLA BIRD COMMUNITY SCHOOL",
    "completed": 2,
    "total": 40,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403766": {
    "id": 403766,
    "type": "school",
    "name": "ACHIEVE BLENDED LEARNING ACADEMY",
    "completed": 5,
    "total": 5,
    "report_id": 403766,
    "parent_type": "district",
    "parent_id": 401807
  },
  "403767": {
    "id": 403767,
    "type": "school",
    "name": "ATLAS PREPARATORY MIDDLE SCHOOL",
    "completed": 0,
    "total": 30,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401879
  },
  "403768": {
    "id": 403768,
    "type": "school",
    "name": "LOMA ELEMENTARY SCHOOL",
    "completed": 9,
    "total": 18,
    "report_id": 403768,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403769": {
    "id": 403769,
    "type": "school",
    "name": "SOUTH PARK MIDDLE SCHOOL",
    "completed": 4,
    "total": 14,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401762
  },
  "403770": {
    "id": 403770,
    "type": "school",
    "name": "LIBERTY POINT ELEMENTARY SCHOOL",
    "completed": 14,
    "total": 45,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401863
  },
  "403771": {
    "id": 403771,
    "type": "school",
    "name": "RUNNING CREEK ELEMENTARY SCHOOL",
    "completed": 7,
    "total": 25,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401737
  },
  "403772": {
    "id": 403772,
    "type": "school",
    "name": "CAREER EDUCATION CENTER EARLY COLLEGE",
    "completed": 0,
    "total": 69,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401836
  },
  "403773": {
    "id": 403773,
    "type": "school",
    "name": "PIONEER ELEMENTARY SCHOOL",
    "completed": 25,
    "total": 37,
    "report_id": 403773,
    "parent_type": "district",
    "parent_id": 401804
  },
  "403774": {
    "id": 403774,
    "type": "school",
    "name": "SIERRA ELEMENTARY SCHOOL",
    "completed": 37,
    "total": 37,
    "report_id": 403774,
    "parent_type": "district",
    "parent_id": 401733
  },
  "403775": {
    "id": 403775,
    "type": "school",
    "name": "JOHN W THIMMIG ELEMENTARY SCHOOL",
    "completed": 36,
    "total": 37,
    "report_id": 403775,
    "parent_type": "district",
    "parent_id": 401764
  },
  "403822": {
    "id": 403822,
    "type": "school",
    "name": "OPTIONS HIGH SCHOOL",
    "completed": 12,
    "total": 15,
    "report_id": 403822,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403823": {
    "id": 403823,
    "type": "school",
    "name": "OPTIONS MIDDLE SCHOOL",
    "completed": 14,
    "total": 16,
    "report_id": 403823,
    "parent_type": "district",
    "parent_id": 401827
  },
  "403827": {
    "id": 403827,
    "type": "school",
    "name": "ED CENTER - SPED",
    "completed": 5,
    "total": 19,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403828": {
    "id": 403828,
    "type": "school",
    "name": "ED CENTER - CHILD FIND",
    "completed": 9,
    "total": 10,
    "report_id": 403828,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403829": {
    "id": 403829,
    "type": "school",
    "name": "ED CENTER - ISE",
    "completed": 22,
    "total": 28,
    "report_id": 403829,
    "parent_type": "district",
    "parent_id": 401820
  },
  "403830": {
    "id": 403830,
    "type": "school",
    "name": "SPRING COMMUNITY NIGHT SCHOOL",
    "completed": 3,
    "total": 8,
    "report_id": null,
    "parent_type": "district",
    "parent_id": 401761
  },
  "403831": {
    "id": 403831,
    "type": "school",
    "name": "VALLEY SCHOOL WEST",
    "completed": 5,
    "total": 6,
    "report_id": 403831,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403832": {
    "id": 403832,
    "type": "school",
    "name": "SUMMIT",
    "completed": 10,
    "total": 11,
    "report_id": 403832,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403833": {
    "id": 403833,
    "type": "school",
    "name": "CAREER CENTER",
    "completed": 11,
    "total": 13,
    "report_id": 403833,
    "parent_type": "district",
    "parent_id": 401907
  },
  "403834": {
    "id": 403834,
    "type": "school",
    "name": "MAIN STREET SCHOOL",
    "completed": 17,
    "total": 34,
    "report_id": 403834,
    "parent_type": "district",
    "parent_id": 401805
  }
};

export { organizations, rootOrganizationId };
