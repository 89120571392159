import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

import './ReportSideNav.styles.scss';

const ReportSideNavItem = ({ active, path, name }) => (
  <NavItem>
    <NavLink 
      tag={Link}
      active={active}
      disabled={active}
      to={path}
    >
      {name}
    </NavLink>
  </NavItem>
);

const ReportSideNav = ({
  navItems,
}) => (
  <div className="report-side-nav">
    <Nav vertical pills>
      {navItems.map(({
        id,
        name,
        path,
        active,
        header,
        subNavItems = [],
      }) => (
        <div className="nav-item-group" key={id}>
          {header && (
            <h5 className="nav-header">{header}</h5>
          )}
          <ReportSideNavItem
            name={name}
            path={path}
            active={active}
          />
          {subNavItems.length > 0 && (
            <div className="sub-nav">
              {subNavItems.map((subNavProps) => (
                <ReportSideNavItem
                  key={subNavProps.id}
                  {...subNavProps}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </Nav>
  </div>
);

const navItemBaseProps = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

ReportSideNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    ...navItemBaseProps,
    subNavItems: PropTypes.arrayOf(PropTypes.shape(navItemBaseProps)),
  })).isRequired,
};

export default ReportSideNav;
