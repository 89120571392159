import React from 'react';
import PropTypes from 'prop-types';

import { formatScore } from 'utils';

import { mmLightStone, mmTurquoise } from '../../styles/_colors.scss';
import { fontFamily } from '../../styles/_typography.scss';

import './ScoreArc.styles.scss';

const ScoreArc = ({
  minScore = 0,
  maxScore = 100, 
  color = mmTurquoise,
  decimalPlaces = 1,
  score,
  scoreSuffix,
  thresholdMet,
}) => {
  const viewBoxArcWidth = 8;
  const radius = 50 - viewBoxArcWidth / 2;
  const circumference = radius * Math.PI;
  const scorePercentage = (score - minScore) / (maxScore - minScore);
  const dasharray = `${circumference * scorePercentage},${circumference * 2}`;

  return (
    <svg viewBox="0 0 100 50"
      preserveAspectRatio ="xMinYMin slice"
      version="1.1" xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      className="score-arc-svg"
    >
      <g className="background">
        <circle
          strokeWidth={viewBoxArcWidth}
          fill="none"
          stroke={mmLightStone}
          cx="50"
          cy="50"
          r={radius}
        />
      </g>
      {thresholdMet && <g className="score-arc">
        <circle
          strokeWidth={viewBoxArcWidth}
          fill="none"
          strokeDashoffset={0 - circumference}
          stroke={color}
          strokeDasharray={dasharray}
          cx="50"
          cy="50"
          r={radius}
        />
        <line
          x1="0"
          x2={viewBoxArcWidth * 2}
          y1="50"
          y2="50"
          stroke={color}
          strokeWidth="2"
          transform={`rotate(${180 * scorePercentage} 50 50)`}
        />
        <animateTransform attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from="-180 50 50"
                  to="0 50 50"
                  dur="0.4s"
                  repeatCount="1"/>
      </g>}
      <g className={`score-text ${!thresholdMet ? 'threshold-not-met' : ''}`}>
        <text
          x="50"
          y="48"
          fontWeight={800}
          textAnchor="middle"
          fontSize="24px"
          fontFamily={fontFamily}>
          {thresholdMet && formatScore(score, decimalPlaces)}
          {thresholdMet && scoreSuffix && <tspan fontSize="14px">{scoreSuffix}</tspan>}
          {!thresholdMet && 'N/A'}
        </text>
      </g>
    </svg>
  );
};

ScoreArc.propTypes = {
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  color: PropTypes.string,
  decimalPlaces: PropTypes.number,
  score: PropTypes.number,
  scoreSuffix: PropTypes.string,
  thresholdMet: PropTypes.bool.isRequired,
};

export default ScoreArc;