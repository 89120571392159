import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faChartPie from "@fortawesome/fontawesome-free-solid/faChartPie";

import ReportPage from "components/ReportPage/ReportPage";
import ScoreArc from "components/ScoreArc/ScoreArc";
import ResultDisaggregationsPageSection from "components/ResultDisaggregationsPageSection/ResultDisaggregationsPageSection";
import ConstructDetailsIcon from "components/Icons/ConstructDetailsIcon";
import SurveyItemDetails, {
  SurveyItemDetailsBasePropsTypes,
} from "components/SurveyItemDetails/SurveyItemDetails";
import ScoreDescriptionHelper from "components/ScoreDescriptionHelper/ScoreDescriptionHelper";
import ResultComparisonsPageSection from "components/ResultComparisonsPageSection/ResultComparisonsPageSection";

import "./ConstructDetailsPage.style.scss";

const ConstructDetailsPage = ({
  construct,
  constructColor,
  description,
  scorable,
  score,
  thresholdMet,
  comparisons = [],
  disaggregatedResults = [],
  item_details = [],
  ...reportSettings
}) => (
  <ReportPage
    className="construct-details-page"
    pageTitle={`${construct}`}
    pageSubTitle="Construct details and item level results"
    renderIcon={() => <ConstructDetailsIcon />}
    renderIntroLeft={() => <p>{description}</p>}
    renderIntroRight={() =>
      scorable ? (
        <div className="construct-score-container">
          <ScoreArc
            score={score}
            color={constructColor}
            thresholdMet={thresholdMet}
            {...reportSettings}
          />
        </div>
      ) : (
        ""
      )
    }
    renderPageContent={() => (
      <div className="construct-details">
        {comparisons.length > 0 && (
          <ResultComparisonsPageSection
            className="construct-result-comparisons"
            thresholdMet={thresholdMet}
            score={score}
            comparisons={comparisons}
            {...reportSettings}
          />
        )}
        {disaggregatedResults.length > 0 && (
          <ResultDisaggregationsPageSection
            className="construct-result-disaggregation"
            disaggregatedResults={disaggregatedResults}
            {...reportSettings}
          />
        )}
        {item_details.length > 0 && (
          <div className="construct-item-details page-section">
            <h4 className="section-title">
              Survey Items
              <FontAwesomeIcon icon={faChartPie} />
            </h4>
            <div className="score-helper">
              <ScoreDescriptionHelper
                id="construct-details-score-helper"
                {...reportSettings}
              />
            </div>
            {item_details.map((itemProps, i) => (
              <SurveyItemDetails
                key={`${itemProps.item}-${i}`}
                color={constructColor}
                {...itemProps}
                {...reportSettings}
                defaultExpanded
              />
            ))}
          </div>
        )}
      </div>
    )}
  />
);

ConstructDetailsPage.propTypes = {
  construct: PropTypes.string.isRequired,
  constructColor: PropTypes.string,
  score: PropTypes.number,
  thresholdMet: PropTypes.bool.isRequired,
  description: PropTypes.string,
  scorable: PropTypes.bool.isRequired,

  scoreSuffix: PropTypes.string,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  decimalPlaces: PropTypes.number,
  bencharmLabels: PropTypes.arrayOf(PropTypes.string),
  measureType: PropTypes.string,

  item_details: PropTypes.arrayOf(
    PropTypes.shape(SurveyItemDetailsBasePropsTypes)
  ),

  ...ResultComparisonsPageSection.propTypes,
  ...ResultDisaggregationsPageSection.propTypes,
};

export default ConstructDetailsPage;
