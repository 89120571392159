import React from 'react';
import PropTypes from 'prop-types';

import ReportPage from 'components/ReportPage/ReportPage';
import SurveyItemDetails, { SurveyItemDetailsBasePropsTypes } from 'components/SurveyItemDetails/SurveyItemDetails';
import ScoreDescriptionHelper from 'components/ScoreDescriptionHelper/ScoreDescriptionHelper';

const ModuleItemsPage = ({
  items,
  ...reportSettings
}) => (
  <ReportPage
    className="module-items-page"
    pageTitle="Module Items"
    pageSubTitle="Item results in this module"
    renderPageContent={() => (
      <div className="module-items">
        <div className="score-helper" style={ {textAlign: 'right'} }>
          <ScoreDescriptionHelper
            id="module-items-score-helper"
            {...reportSettings}
          />
        </div>
        {
          items.map((itemProps, i) => (
            <SurveyItemDetails
              key={`${itemProps.item}-${i}`}
              {...itemProps}
              {...reportSettings}
              defaultExpanded
            />
          ))
        }
      </div>
    )}
  />
);

ModuleItemsPage.propTypes = {
  scoreSuffix: PropTypes.string,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  decimalPlaces: PropTypes.number,
  bencharmLabels: PropTypes.arrayOf(PropTypes.string),
  measureType: PropTypes.string,

  items: PropTypes.arrayOf(PropTypes.shape(SurveyItemDetailsBasePropsTypes)).isRequired,
};

export default ModuleItemsPage;
