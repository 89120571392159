import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle';
import { PopoverHeader, PopoverBody } from 'reactstrap';

import './ScoreDescriptionHelper.styles.scss';

class ScoreDescriptionHelper extends Component {
  static propTypes = {
    measureType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }

  state = { 
    hovered: false,
  }

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  }

  getScoreName = () => {
    switch(this.props.measureType) {
      case 'percentage_of_favorable_responses':
        return 'Favorability Rating';
      case 'average_of_response_values':
        return 'Average Result';
      default:
        return this.props.measureType;
    }
  }

  getScoreDescription = () => {
    switch(this.props.measureType) {
      case 'percentage_of_favorable_responses':
        return 'Favorability Rating is the percentage of favorable responses.'
      case 'average_of_response_values':
        return 'Average point value of all valid responses.';
      default:
        return 'This is your survey result.';
    }
  }

  getHelperTitle = () => {
    switch(this.props.measureType) {
      case 'percentage_of_favorable_responses':
        return 'Responded Favorably';
      case 'average_of_response_values':
        return 'Response Average';
      default:
        return '';
    }
  }

  render() {
    const { hovered, id } = this.state;

    return (
      <div
        className={`score-description-helper ${hovered ? 'active' : ''}`}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <span className="helper-title">{this.getHelperTitle()}</span>
        <FontAwesomeIcon icon={faInfoCircle} id={id} />
        {hovered && <div
          className="popover show bs-popover-bottom"
          xplacement="bottom"
        >
          <div className="popover-inner">
            <PopoverHeader>{this.getScoreName()}</PopoverHeader>
            <PopoverBody>{this.getScoreDescription()}</PopoverBody>
          </div>
        </div>}
      </div>
    );
  }
}

export default ScoreDescriptionHelper;
