import React from "react";
import PropTypes from "prop-types";

import ReportPage from "components/ReportPage/ReportPage";
import OrganizationInsightsTable from "components/OrganizationInsightsTable/OrganizationInsightsTable";
import InsightsIcon from "components/Icons/InsightsIcon";

import "./OrganizationInsightPage.style.scss";

const OrganizationInsightPage = ({ childrenType, ...insightTableProps }) => (
  <ReportPage
    className="org-insight-page"
    pageTitle="Insights"
    pageSubTitle="Discover important aspects of your report"
    renderIcon={() => <InsightsIcon />}
    renderIntroLeft={() => (
      <p>
        This section helps you track performance across your organization. Each
        row within the table below shows the construct-level and the overall
        results for each qualifying participating {childrenType}. This list is
        arranged by Overall Results.
      </p>
    )}
    renderPageContent={() => (
      <div className="org-insights">
        <OrganizationInsightsTable {...insightTableProps} />
      </div>
    )}
  />
);

OrganizationInsightPage.propTypes = {
  childrenType: PropTypes.string.isRequired,
  ...OrganizationInsightsTable.propTypes,
};

export default OrganizationInsightPage;
