import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeGetModuleByIndex } from 'selectors/reportSelectors';

const makeMapStateToProps = () => {
  const getModuleByIndex = makeGetModuleByIndex();

  return (state, { match: { params: { moduleIndex } } }) => ({
    module: getModuleByIndex(state, { moduleIndex }),
  });
};

const withModule = function(WrappedComponent) {
  class ReportModuleProvider extends React.Component {
    render() {
      if (!this.props.module) {
        return (<Redirect to='/not-found' />);
      }
    
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(makeMapStateToProps)(withRouter(ReportModuleProvider));
}

export default withModule;
