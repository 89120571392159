import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faArrowLeft from '@fortawesome/fontawesome-free-solid/faArrowLeft';
import { Container, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

import './ReportTopNav.styles.scss';

const ReportTopNav = ({
  currentReportScopeIndex = null,
  administration,
  reportScopes = [],
  onChangeReportScope = () => {},
}) => (
  <div className="report-top-nav">
    <Container>
      <div className="nav-items">
        <div className="return-home">
          <Link to="/" title="Return to homepage">
            <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
            Report List
          </Link>
        </div>
        <div className="report-scope-selector">
          { reportScopes.length > 1 &&
            currentReportScopeIndex !== null &&
              (<UncontrolledDropdown size="sm">
                <DropdownToggle caret disabled={reportScopes.length === 1}>
                  {reportScopes[currentReportScopeIndex]}
                </DropdownToggle>
                <DropdownMenu >
                  {reportScopes.map((scope, index) => (
                    <DropdownItem
                      key={`${scope}-${index}`}
                      onClick={() => onChangeReportScope(index)}
                      disabled={index === currentReportScopeIndex}>
                      {scope}
                    </DropdownItem>
                  ))}
                </DropdownMenu >
              </UncontrolledDropdown>)
          }
        </div>
        <div className="administration">{administration}</div>
      </div>
    </Container>
  </div>
);

ReportTopNav.propTypes = {
  currentReportScopeIndex: PropTypes.number,
  administration: PropTypes.string.isRequired,
  reportScopes: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChangeReportScope: PropTypes.func,
};

export default ReportTopNav;
