import { organizations, rootOrganizationId } from '../api/organizations-data';

import {
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
} from 'constants/organizationConstants';

const initialState = () => ({
  organizations,
  filterKeyword: null,
  rootOrganizationId,
});

export default function organizationReducer(state = initialState(), action = {}) {
  switch(action.type) {
    case SET_FILTER_KEYWORD: 
      return {
        ...state,
        filterKeyword: action.payload.keyword,
      };
    case REMOVE_FILTER_KEYWORD: 
      return {
        ...state,
        filterKeyword: null,
      };
    default:
      return state;
  }
}
