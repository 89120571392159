import React, { Component } from 'react';

import withReportSettings from 'containers/withReportSettings';
import withModule from 'containers/withModule/withModule';
import ModuleItemsPage from 'components/ModuleItemsPage/ModuleItemsPage';

class ModuleItems extends Component {
  moduleItemsProps = () => {
    const { report_sections: { module_items: items }} = this.props.module;

    return {
      items,
    };
  }

  render() {
    return (
      <ModuleItemsPage
        {...this.props}
        {...this.moduleItemsProps()}
      />
    );
  }
}

export default withReportSettings(withModule(ModuleItems));
