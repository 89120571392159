const mapConstructSummary = (construct_summary, constructs_metadata) => (
  construct_summary.map(({
    construct,
    construct_provider_key,
    subject_score,
    threshold_met,
  }, i) => ({
    construct,
    providerKey: construct_provider_key,
    constructIndex: i,
    score: subject_score,
    thresholdMet: threshold_met,
    description: constructs_metadata[i].description,
    scorable: constructs_metadata[i].is_scorable,
  }))
);

export {
  mapConstructSummary
}
