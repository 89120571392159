import React from 'react';
import PropTypes from 'prop-types';

import { mmTurquoise } from '../../styles/_colors.scss';
import { toPercentage } from 'utils';

import './ScoreBar.styles.scss';

const ScoreBar = ({
  color = mmTurquoise,
  scorePercentage,
  scoreDisplay,
  thresholdMet,
}) => (
  <div className="score-bar">
    {thresholdMet ?
      (<div
        className="score-bar-fill"
        style={ { backgroundColor: color, width: `${toPercentage(scorePercentage)}%` } }
      >
        <p className="score-bar-text">{scoreDisplay}</p>
      </div>)
      :
      ('N/A')
    }
  </div>
);

ScoreBar.propTypes = {
  color: PropTypes.string,
  thresholdMet: PropTypes.bool.isRequired,
  scoreDisplay: PropTypes.string,
  scorePercentage: PropTypes.number,
};

export default ScoreBar;
