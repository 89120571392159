import { connect } from 'react-redux';

import OrganizationSearch from 'components/OrganizationSearch/OrganizationSearch';
import { getSearchableOrganizations, getFilterKeyword } from 'selectors/organizationSelectors';
import {
  setFilterKeyword,
  removeFilterKeyword
} from 'actions/organizationActions';

const mapStateToProps = (state) => ({
  organizations: getSearchableOrganizations(state),
  keyword: getFilterKeyword(state),
});

const mapDispatchToProps = (dispatch) => ({
  applyFilterKeyword: (keyword) => dispatch(setFilterKeyword(keyword)),
  resetFilterKeyword: () => dispatch(removeFilterKeyword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSearch);
