import React from 'react';

const ReportOverviewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22.066">
    <path
      d="M22 2H0v.815h1.63v14.667h8.963v2.037h-6.52v.813h13.853v-.814h-6.52V17.48h8.963V2.815H22V2zm-2.445 14.665H2.445V2.815h17.11v13.85z"
      fill="#b1afb0"
    />
    <path d="M9.777 5.26h-4.89v4.888h4.89v-4.89zm-.815 4.073H5.704v-3.26h3.26v3.26zM11.407 9.333h5.705v.814h-5.705zM11.407 5.26h5.705v.814h-5.705zM11.407 7.296h5.705v.815h-5.705zM4.888 11.778H17.11v.815H4.89zM4.888 14.222H17.11v.814H4.89z"
      fill="#b1afb0"
    />
  </svg>  
);

export default ReportOverviewIcon;
