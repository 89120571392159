import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Container, Row, Col } from 'reactstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import './OrganizationSearch.styles.scss';

class OrganizationSearch extends React.Component {
  handleKeywordChange = (selections) => {
    const {
      applyFilterKeyword,
      resetFilterKeyword,
    } = this.props;
    if (selections.length > 0) {
      applyFilterKeyword(selections[0].name);
    } else {
      resetFilterKeyword();
    }
  }

  handleKeydown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value;
      if (value.length > 0) {
        this.props.applyFilterKeyword(value);
      }
    }
  }

  render() {
    const {
      organizations,
      keyword,
    } = this.props;
    return (
      <div className="organization-search-container">
        <Container>
          <Row>
            <Col md={6}>
              <Typeahead
                id="organization-name-search"
                labelKey="name"
                placeholder="Type to search..."
                options={organizations}
                defaultInputValue={keyword || ''}
                onChange={this.handleKeywordChange}
                onKeyDown={this.handleKeydown}
                clearButton
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

OrganizationSearch.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  applyFilterKeyword: PropTypes.func.isRequired,
  resetFilterKeyword: PropTypes.func.isRequired,
  keyword: PropTypes.string,
};

export default OrganizationSearch;
