import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Loader } from "react-loaders";
import { Container } from "reactstrap";
import { Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MediaQuery from "react-responsive";

import { setCurrentReportId, loadReport } from "../../actions/reportActions";
import {
  getCurrentReportId,
  getCurrentReport
} from "../../selectors/reportSelectors";

import ReportScopesNav from "containers/ReportScopesNav/ReportScopesNav";
import ReportScopeMasthead from "containers/ReportScopeMasthead/ReportScopeMasthead";
import ReportScopeDemographics from "containers/ReportScopeDemographics/ReportScopeDemographics";
import ModuleOverview from "containers/ModuleOverview/ModuleOverview";
import ConstructDetails from "containers/ConstructDetails/ConstructDetails";
import ModuleItems from "containers/ModuleItems/ModuleItems";
import OrganizationInsights from "containers/OrganizationInsights/OrganizationInsights";
import PDFDownloadPage from "containers/PDFDownloadPage/PDFDownloadPage";
import ReportContentNavWrapper from "containers/ReportContentNavWrapper/ReportContentNavWrapper";

import HowToReadPage from "components/HowToReadPage/HowToReadPage";
import SupportPage from "components/SupportPage/SupportPage";
import ReportSideNav from "components/ReportSideNav/ReportSideNav";
import ReportDropdownNav from "components/ReportDropdownNav/ReportDropdownNav";

import "./ReportLayout.styles.scss";

const ReportLayout = ({
  failedReason,
  loading,
  data,
  loaded,
  match: {
    path,
    params: { reportId }
  },
  location,
  setCurrentReportId,
  loadReportData
}) => {
  useEffect(() => {
    setCurrentReportId(reportId);
  }, [reportId, setCurrentReportId]);

  useEffect(() => {
    if (!loaded && !loading && !failedReason) {
      loadReportData(reportId);
    }
  }, [loaded, loading, failedReason, reportId, loadReportData]);

  if (failedReason) {
    return <Redirect to="/not-found" />;
  }

  return (
    <div className="report-layout">
      {loading && (
        <div className="loading-box">
          <Loader type="ball-grid-beat" />
          <p>Loading your report...</p>
        </div>
      )}
      {!data && loaded && "No data available for report id:" + reportId}
      {!loading && data && (
        <div>
          <ReportScopesNav />
          <ReportScopeMasthead />
          <MediaQuery maxWidth={1024}>
            <Container className="report-dropdown-nav">
              {ReportContentNavWrapper(ReportDropdownNav)}
            </Container>
          </MediaQuery>
          <Container className="report-body-container">
            <div className="report-content">
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={200}
                  classNames="fade"
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path={`${path}/surveys/:surveyIndex/intro`}
                      component={HowToReadPage}
                    />
                    <Route
                      exact
                      path={`${path}/download-pdf`}
                      component={PDFDownloadPage}
                    />
                    <Route
                      exact
                      path={`${path}/support`}
                      component={SupportPage}
                    />
                    <Route
                      exact
                      path={`${path}/surveys/:surveyIndex/demographics`}
                      component={ReportScopeDemographics}
                    />
                    <Route
                      exact
                      path={`${path}/surveys/:surveyIndex/modules/:moduleIndex`}
                      component={ModuleOverview}
                    />
                    <Route
                      exact
                      path={`${path}/surveys/:surveyIndex/modules/:moduleIndex/insights`}
                      component={OrganizationInsights}
                    />
                    <Route
                      exact
                      path={`${path}/surveys/:surveyIndex/modules/:moduleIndex/other-items`}
                      component={ModuleItems}
                    />
                    <Route
                      exact
                      path={`${path}/surveys/:surveyIndex/modules/:moduleIndex/constructs/:constructProviderKey`}
                      component={ConstructDetails}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
            <MediaQuery minWidth={1025}>
              <div className="report-nav">
                {ReportContentNavWrapper(ReportSideNav)}
              </div>
            </MediaQuery>
          </Container>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { reportId }
    }
  }
) => {
  const currentReportId = getCurrentReportId(state);
  const { loading, loaded = false, data, failedReason } =
    currentReportId === reportId ? getCurrentReport(state) || {} : {};
  return {
    currentReportId,
    loading,
    loaded,
    failedReason,
    data
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentReportId: reportId => dispatch(setCurrentReportId(reportId)),
  loadReportData: reportId => dispatch(loadReport(reportId))
});

ReportLayout.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportLayout);
