import { defaultColors } from '../styles/_colors.scss';

export function formatScore(score, decimalPlaces = 1) {
  return Number(Math.round(score + `e${decimalPlaces}`) + `e-${decimalPlaces}`);
}

export function toPercentage(decimal, decimalPlaces = 1) {
  return formatScore(decimal * 100.0, decimalPlaces);
}

export function UUIDv4(a) {
  return a ?
    // eslint-disable-next-line no-mixed-operators
    (a ^ Math.random() * 16 >> a / 4).toString(16)
    :
    ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,UUIDv4);
}

export function sassListToArray(sassList) {
  return sassList.split(', ');
}

export function calcPercentage(value, min, max) {
  return (value - min) / (max - min);
}

export function colorForIndex(index, colors = sassListToArray(defaultColors)) {
  return colors[index % colors.length]
}

export function humanize(text) {
  return text.toString().toLowerCase()
    .replace(/[_-]/g, ' ')
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function genInitials(string) {
  const words = string.split(' ').map((s) => (
    s.replace(/[^A-Za-z]/g, '').toUpperCase()
  )).filter((s) => s.length >= 1);
  
  switch (words.length) {
    case 0:
      return '';
    case 1:
      return words[0][0];
    default:
      return words[0][0] + words[words.length - 1][0];
  }
}

export function deepEqual(x, y) {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}
