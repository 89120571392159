import React from 'react';

import HeroUnit from 'components/HeroUnit/HeroUnit'
import OrganizationTable from 'containers/OrganizationTable/OrganizationTable'
import OrganizationSearch from 'containers/OrganizationSearch/OrganizationSearch';

import './home.styles.scss';

const Home = () => (
  <div className="home-page">
    <HeroUnit />
    <OrganizationSearch />
    <OrganizationTable />
  </div>
);

export default Home;
