import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import { toPercentage, UUIDv4, calcPercentage } from 'utils';
import ScoreDisplay from 'components/ScoreDisplay/ScoreDisplay';

class ResultDisaggregationRow extends React.Component {
  static propTypes = {
    minScore: PropTypes.number.isRequired,
    maxScore: PropTypes.number.isRequired,
    scoreSuffix: PropTypes.string,
    decimalPlaces: PropTypes.number,
    title: PropTypes.string.isRequired,
    score: PropTypes.number,
    thresholdMet: PropTypes.bool.isRequired,
    numberRespondents: PropTypes.number,
    highlighted: PropTypes.bool,
    onMouseOver: PropTypes.func,
  }

  state = {
    tooltipTarget: `tooltip-${UUIDv4()}`,
  }

  render() {
    const {
      minScore,
      maxScore,
      score,
      scoreSuffix,
      decimalPlaces,
      title,
      thresholdMet,
      numberRespondents,
      highlighted = false,
      onMouseOver = () => {},
    } = this.props;
    const scorePercentage = calcPercentage(score, minScore, maxScore);
    const offset = `${toPercentage(scorePercentage)}%`;

    return (
      <div className={`disaggregation-row ${highlighted ? 'highlighted' : ''}`}>
        <div onMouseOver={onMouseOver} className="title"><p>{title}</p></div>
        {thresholdMet && <div className="result-bar">
          <div className="bar"
            id={this.state.tooltipTarget}
            onMouseOver={onMouseOver}
            style={ { width: offset } }></div>
          <div className="score-container" style={ { left: offset } }>
            <ScoreDisplay
              score={score}
              scoreSuffix={scoreSuffix}
              decimalPlaces={decimalPlaces}
            />
          </div>
          {numberRespondents && <UncontrolledTooltip
            placement="bottom"
            isOpen={highlighted}
            target={`${this.state.tooltipTarget}`}
            delay={0.3}
          >{numberRespondents} respondents</UncontrolledTooltip>}
        </div>}
        {!thresholdMet && (
          <div className="result-bar threshold-not-met">
            Not enough responses
          </div>)
        }
      </div>
    );
  }
}

export default ResultDisaggregationRow;
