import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faUndo from "@fortawesome/fontawesome-free-solid/faUndo";
import faList from "@fortawesome/fontawesome-free-solid/faList";

import "./NotFoundPage.styles.scss";

export class NotFoundPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div className="not-found-page">
              <h1>Uh-oh. :(</h1>
              <h5>We are not able to locate the page you are looking for.</h5>
              <Nav vertical className="not-found-nav">
                <NavItem>
                  <NavLink onClick={this.goBack} href="#">
                    <FontAwesomeIcon icon={faUndo} />
                    Return to Previous Page
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/">
                    <FontAwesomeIcon icon={faList} />
                    Return to Report List
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(NotFoundPage);
