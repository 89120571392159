import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fontFamily } from '../../styles/_typography.scss';

class ResponsesCountBadge extends Component {
  static propTypes = {
    responsesCount: PropTypes.number.isRequired,
    totalRespondents: PropTypes.number.isRequired,
  }

  render() {
    const { responsesCount, totalRespondents } = this.props;
    const percentage = totalRespondents > 0 ? responsesCount / totalRespondents : 0;
    const radius = 48;
    const circumference = 2 * radius * Math.PI;
    const dasharray = `${circumference * percentage},${circumference}`;
    return (
      <svg viewBox="0 0 100 100"
        version="1.1" xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        className="responses-count-badge"
      >
        <g className="background-circle">
          <defs>
            <linearGradient id="circleGradient">
              <stop offset="50%"  stopColor="#00DDF0" />
              <stop offset="50%" stopColor="#00D7EB" />
            </linearGradient>
          </defs>
          <circle
            transform="rotate(48, 50, 50)"
            fill="url(#circleGradient)" cx="50" cy="50" r="48"
          />
        </g>
        <circle
          className="empty-outer-circle"
          stroke="#eeeeee"
          strokeWidth="4"
          fill="none"
          cx="50"
          cy="50"
          r={radius}
        />
        <circle className="percentage-circle"
          strokeWidth="4"
          fill="none"
          strokeLinecap="round"
          stroke="#00bcce"
          strokeDasharray={dasharray}
          transform="rotate(-90, 50, 50)"
          cx="50"
          cy="50"
          r={radius}
        />
        <text
          x="50"
          y="57"
          fontFamily={fontFamily}
          fontSize={26}
          fill="#ffffff"
          textAnchor="middle"
          textRendering="optimizeLegibility"
          className="response-rate-text"
        >{responsesCount.toLocaleString()}</text>
        <text
          x="50"
          y="70"
          fontFamily={fontFamily}
          fontSize={8}
          fill="#ffffff"
          textAnchor="middle"
          textRendering="optimizeLegibility"
        >response{responsesCount === 1 ? '' : 's'}</text>
      </svg>
    );
  }
}

export default ResponsesCountBadge;
