import { connect } from 'react-redux';

import OrganizationsList from 'components/OrganizationsList/OrganizationsList';
import { getFilteredOrganizationTree, getFilterKeyword } from 'selectors/organizationSelectors';

const mapStateToProps = (state) => ({
  organizationTree: getFilteredOrganizationTree(state),
  keyword: getFilterKeyword(state),
})

export default connect(mapStateToProps)(OrganizationsList);
