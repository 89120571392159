//import React from 'react';
import { connect } from 'react-redux';

import ReportTopNav from 'components/ReportTopNav/ReportTopNav';

import {
  setCurrentReportScopeIndex
} from 'actions/reportActions';
import {
  getCurrentReportScopes,
  getCurrentReportId,
  getCurrentReportScopeIndex,
  getAdministration,
} from 'selectors/reportSelectors';

const mapStateToProps = (state) => ({
  currentReportId: getCurrentReportId(state),
  currentReportScopeIndex: getCurrentReportScopeIndex(state),
  administration: getAdministration(state),
  reportScopes: getCurrentReportScopes(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeReportScope: (i) => dispatch(setCurrentReportScopeIndex(this.props.currentReportId, i)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportTopNav);
