import React from 'react';
import PropTypes from 'prop-types';

import ScoreBox from 'components/ScoreBox/ScoreBox';

import './SurveyItem.styles.scss';

const SurveyItem = ({
  itemText,
  scorable,
  score,
  scoreSuffix,
  decimalPlaces,
  thresholdMet,
  color,
}) => (
  <div className="survey-item">
    <div className="survey-item-row">
      <div className="q-mark">Q</div>
      <div className="item-text">{itemText}</div>
      {scorable &&
        <ScoreBox
          color={color}
          decimalPlaces={decimalPlaces}
          score={score}
          scoreSuffix={scoreSuffix}
          thresholdMet={thresholdMet}
        />}
    </div>
  </div>
);

SurveyItem.propTypes = {
  scorable: PropTypes.bool,
  itemText: PropTypes.string.isRequired,
  score: PropTypes.number,
  color: PropTypes.string,
  scoreSuffix: PropTypes.string,
  decimalPlaces: PropTypes.number,
  thresholdMet: PropTypes.bool.isRequired,
};

export default SurveyItem;
