import {
  LOAD_REPORT_FAILED,
  LOAD_REPORT_STARTED,
  LOAD_REPORT_SUCCESSFUL,
  SET_CURRENT_REPORT_ID,
  SET_CURRENT_REPORT_SCOPE_INDEX,
  LOAD_PDF_REPORT_DATA_STARTED,
  LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  LOAD_PDF_REPORT_DATA_FAILED,
} from 'constants/reportConstants.js';

const initialState = () => ({
  reports: {},
  pdfReports: {},
  currentReportId: null,
});

export default function reportReducer(state = initialState(), action = {}) {
  switch(action.type) {
    case SET_CURRENT_REPORT_ID:
      return {
        ...state,
        currentReportId: action.payload.reportId,
      };
    case LOAD_REPORT_STARTED:
      return {
        ...state,
        reports: {
          ...(state.reports),
          [action.payload.reportId]: {
            loaded: false,
            loading: true,
            failedReason: null,
          }
        }
      };
    case LOAD_REPORT_SUCCESSFUL:
      return {
        ...state,
        reports: {
          ...(state.reports),
          [action.payload.reportId]: {
            id: action.payload.reportId,
            loaded: true,
            loading: false,
            currentReportScopeIndex: 0,
            data: action.payload.data,
            failedReason: null,
          }
        }
      };
    case LOAD_REPORT_FAILED:
      return {
        ...state,
        reports: {
          ...(state.reports),
          [action.payload.reportId]: {
            ...(state.reports[action.payload.reportId]),
            id: action.payload.reportId,
            loaded: false,
            loading: false,
            failedReason: action.payload.failedReason,
          },
        }
      };
    case SET_CURRENT_REPORT_SCOPE_INDEX:
      return {
        ...state,
        reports: {
          ...(state.reports),
          [action.payload.reportId]: {
            ...(state.reports[action.payload.reportId]),
            currentReportScopeIndex: action.payload.index,
          },
        }
      }
    case LOAD_PDF_REPORT_DATA_STARTED:
      return {
        ...state,
        pdfReports: {
          ...(state.pdfReports),
          [action.payload.reportId]: {
            loaded: false,
            loading: true,
            failedReason: null,
          }
        }
      };
    case LOAD_PDF_REPORT_DATA_SUCCESSFUL:
      return {
        ...state,
        pdfReports: {
          ...(state.pdfReports),
          [action.payload.reportId]: {
            id: action.payload.reportId,
            loaded: true,
            loading: false,
            data: action.payload.data,
            failedReason: null,
          }
        }
      };
    case LOAD_PDF_REPORT_DATA_FAILED:
      return {
        ...state,
        pdfReports: {
          ...(state.pdfReports),
          [action.payload.reportId]: {
            ...(state.pdfReports[action.payload.reportId]),
            id: action.payload.reportId,
            loaded: false,
            loading: false,
            failedReason: action.payload.failedReason,
          },
        }
      };
    default:
      return state;
  }  
}
