import React from 'react';
import PropTypes from 'prop-types';

import { mmTurquoise } from '../../styles/_colors.scss';

import ScoreDisplay from 'components/ScoreDisplay/ScoreDisplay';

import './ScoreBox.styles.scss';

const ScoreBox = ({
  color = mmTurquoise,
  decimalPlaces = 0,
  score,
  scoreSuffix,
  thresholdMet,
}) => {
  const boxStyles = {
    backgroundColor: color,
  };

  return (
    <div style={boxStyles} className="score-box">
      {thresholdMet ?
        (<p className="score">
          <ScoreDisplay
            score={score}
            scoreSuffix={scoreSuffix}
            decimalPlaces={decimalPlaces}
          />
        </p>)
        :
        (<p className="score">
          N/A
        </p>)
      }
    </div>
  );
};

ScoreBox.propTypes = {
  color: PropTypes.string,
  thresholdMet: PropTypes.bool,
  decimalPlaces: PropTypes.number,
  scoreSuffix: PropTypes.string,
  score: PropTypes.number,
};

export default ScoreBox;
