import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

class InsightsTableHoverableCell extends Component {
  state = { popoverOpen: false }

  openPopOver = () => {
    this.setState({ popoverOpen: true });
  }

  closePopOver = () => {
    this.setState({ popoverOpen: false });
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    popoverTitle: PropTypes.string,
    popoverContent: PropTypes.string,
    children: PropTypes.any.isRequired,
    tagName: PropTypes.string.isRequired,
  }

  render() {
    const { id, children, popoverTitle, popoverContent } = this.props;
    const TagName = this.props.tagName;
    return (
      <TagName
        id={id}
        onMouseOver={this.openPopOver}
        onMouseLeave={this.closePopOver}
      >
        {children}
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={id}>
          <PopoverHeader>{popoverTitle}</PopoverHeader>
          <PopoverBody>{popoverContent}</PopoverBody>
        </Popover>
      </TagName>
    );
  }
}

export default InsightsTableHoverableCell;
