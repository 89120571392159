import React from 'react';

const iconElementProps = {
  fill: "none",
  stroke: "#b1afb0",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
};

const DemographicsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.6 28.6"
  >
    <circle {...iconElementProps} cx="14.3" cy="14.3" r="14" />
    <circle {...iconElementProps} cx="15.3" cy="13" r="3" />
    <circle {...iconElementProps} cx="6.8" cy="9.3" r="1.1" />
    <circle {...iconElementProps} cx="21.6" cy="6.7" r="1.1" />
    <circle {...iconElementProps} cx="6.8" cy="21.5" r="1.1" />
    <circle {...iconElementProps} cx="15.3" cy="21.5" r="1.1" />
    <path {...iconElementProps} d="M7.6 20.7l5.6-5.6M17.4 10.9l3.4-3.4M7.8 9.8l4.8 2.1M20.5 15.6l-2.7-1.1M15.3 20.4V16" />
    <circle {...iconElementProps} cx="21.6" cy="16" r="1.1" />
  </svg>
);

export default DemographicsIcon;
