import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import ReportSideNav from 'components/ReportSideNav/ReportSideNav'

class ReportDropdownNav extends Component {
  static propTypes = {
    ...ReportSideNav.propTypes,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  }

  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  renderDropdownMenu = (navItems, level = 0) => {
    const { history } = this.props;
    return navItems.map(({
      id,
      name,
      path,
      active,
      header,
      subNavItems = [],
    }, index) => (
      [
        header && <DropdownItem divider key={`nav-divider-${index}`} />,
        header && <DropdownItem header key={`nav-header-${index}`}>{header}</DropdownItem>,
        <DropdownItem
          key={id}
          active={active}
          disabled={active}
          onClick={() => history.push(path)}
          style={{ paddingLeft: `${1.5 + level * 0.5}rem` }}
        >
          {level > 0 ? '- ' : ''}{name}
        </DropdownItem>,
        ...this.renderDropdownMenu(subNavItems, level + 1),
      ]
    ));
  }

  activeItemName = (navItems) => {
    for (let { name, active, subNavItems } of navItems) {
      if (active) {
        return name;
      } else if (subNavItems && subNavItems.length > 0) {
        const activeName = this.activeItemName(subNavItems);
        if (activeName) {
          return activeName;
        }
      }
    }

    return null;
  }

  render() {
    const { navItems } = this.props;

    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          {this.activeItemName(navItems) || 'Report Navigation'}
        </DropdownToggle>
        <DropdownMenu flip={false}>{this.renderDropdownMenu(navItems)}</DropdownMenu>
      </Dropdown>
    );
  }
}

export default withRouter(ReportDropdownNav);
