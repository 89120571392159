import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faFilePdf from "@fortawesome/fontawesome-free-regular/faFilePdf";
import { Loader } from "react-loaders";

import ReportPage from "components/ReportPage/ReportPage";

import "./PDFDownloadPage.styles.scss";
import { getConfigs } from "../../appConfig";

const reportLink = ({ report_id, token }) =>
  `/pdfs/${report_id}/report_${token}.pdf`;

const PDFDownloadPage = ({ reports = [], loading, failedReason }) => {
  const { supportEmail } = getConfigs();

  return (
    <ReportPage
      className="pdf-download-page"
      pageTitle="Download PDF Reports"
      pageSubTitle="Get your reports in easily readable PDF format"
      renderIcon={() => <FontAwesomeIcon icon={faFilePdf} />}
      renderPageContent={() => (
        <div className="pdf-download">
          {reports.length > 0 ? (
            <p className="intro">
              We have prepared PDF versions of this report. Please click on the
              links below to download them. In some browsers the reports may
              open in a new page, you may right click on the report and save it
              to your computer. If you are using a pop-up blocker, please
              disable it temporarily or add this page to its whitelist.
              <br />
              <br />
              You may need a modern browser and/or a PDF reader to view those
              files electronically.
              <br />
              {supportEmail && (
                <>
                  <br />
                  If you need any assitance with those files, or have any
                  questions regarding those reports, please feel free to send us
                  an email at{" "}
                  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
                </>
              )}
            </p>
          ) : loading ? (
            <div className="loading-box">
              <Loader type="ball-grid-beat" />
              <p>Loading...</p>
            </div>
          ) : (
            <p className="intro">
              We are unable to locate any PDF reports.
              {supportEmail && (
                <>
                  Please{" "}
                  <a href={`mailto:${supportEmail}`}>send us an email </a>
                  with the URL of this page and/or names of the school and the
                  district.
                  <br />
                </>
              )}
              <br />
              {failedReason && (
                <span className="error-reason">{failedReason}</span>
              )}
            </p>
          )}
          {reports.map(({ report_id, name, token }, index) => (
            <div
              key={`${report_id}-${index}`}
              className="report-link-container"
            >
              <a
                download={`report-${report_id}.pdf`}
                href={reportLink({ report_id, token })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5>
                  <FontAwesomeIcon icon={faFilePdf} />
                  {name}
                </h5>
              </a>
            </div>
          ))}
        </div>
      )}
    />
  );
};

PDFDownloadPage.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      report_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      name: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  failedReason: PropTypes.string,
};

export default PDFDownloadPage;
