import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faClipboardList from "@fortawesome/fontawesome-free-solid/faClipboardList";

import ReportPage from "components/ReportPage/ReportPage";
import ScoreArc from "components/ScoreArc/ScoreArc";
import ReportOverviewIcon from "components/Icons/ReportOverviewIcon";
import ConstructOverviewCard from "components/ConstructOverviewCard/ConstructOverviewCard";
import ResultComparisonsPageSection from "components/ResultComparisonsPageSection/ResultComparisonsPageSection";
import ResultDisaggregationsPageSection from "components/ResultDisaggregationsPageSection/ResultDisaggregationsPageSection";

import "./ModuleOverViewPage.style.scss";

const ModuleOverViewPage = ({
  moduleName,
  score,
  scorable,
  thresholdMet,
  comparisons = [],
  disaggregatedResults = [],
  constructs = [],
  match: { url },
  ...reportSettings
}) => (
  <ReportPage
    className="module-overview-page"
    pageTitle={`${moduleName} Overview`}
    pageSubTitle="Your results at a glance"
    renderIcon={() => <ReportOverviewIcon />}
    renderIntroLeft={() => (
      <p>
        This is an overview of your module level results
        {constructs.length > 0
          ? " and how participants from your organization responded in each construct. Constructs are color-coded to help you track the items throughout the report"
          : ""}
        . This section shows performance at a high level, but there is more
        information on the following pages to provide more context and detail.
      </p>
    )}
    renderIntroRight={() =>
      scorable ? (
        <div className="module-score-container">
          <span>Module Overall Result</span>
          <ScoreArc
            score={score}
            thresholdMet={thresholdMet}
            {...reportSettings}
          />
        </div>
      ) : (
        ""
      )
    }
    renderPageContent={() => (
      <div className="module-details">
        {comparisons.length > 0 && (
          <ResultComparisonsPageSection
            className="module-result-comparisons"
            score={score}
            comparisons={comparisons}
            thresholdMet={thresholdMet}
            {...reportSettings}
          />
        )}
        {disaggregatedResults.length > 0 && (
          <ResultDisaggregationsPageSection
            className="module-result-disaggregation"
            disaggregatedResults={disaggregatedResults}
            {...reportSettings}
          />
        )}
        {constructs.length > 0 && (
          <div className="module-constructs-overview page-section">
            <h4 className="section-title">
              Constructs at a Glance
              <FontAwesomeIcon icon={faClipboardList} />
            </h4>
            {constructs.map(
              ({
                construct,
                providerKey,
                constructIndex,
                description,
                score,
                thresholdMet,
                constructDetailsPath,
              }) => (
                <ConstructOverviewCard
                  key={providerKey}
                  construct={construct}
                  constructIndex={constructIndex}
                  description={description}
                  score={score}
                  thresholdMet={thresholdMet}
                  constructDetailsPath={constructDetailsPath}
                  {...reportSettings}
                />
              )
            )}
          </div>
        )}
        {!(
          comparisons.length > 0 ||
          disaggregatedResults.length > 0 ||
          constructs.length > 0
        ) && (
          <div className="page-section">
            <p>
              Module level results are unavailable. Please check out its{" "}
              <Link to={`${url}/other-items`}>item level results</Link> instead.
            </p>
          </div>
        )}
      </div>
    )}
  />
);

ModuleOverViewPage.propTypes = {
  moduleName: PropTypes.string.isRequired,
  score: PropTypes.number,
  scorable: PropTypes.bool.isRequired,
  thresholdMet: PropTypes.bool.isRequired,
  scoreSuffix: PropTypes.string,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  decimalPlaces: PropTypes.number,
  constructs: PropTypes.arrayOf(
    PropTypes.shape({
      construct: PropTypes.string.isRequired,
      constructIndex: PropTypes.number.isRequired,
      providerKey: PropTypes.string.isRequired,
      score: PropTypes.number,
      thresholdMet: PropTypes.bool.isRequired,
      description: PropTypes.string,
      constructDetailsPath: PropTypes.string.isRequired,
    })
  ),

  ...ResultComparisonsPageSection.propTypes,
  ...ResultDisaggregationsPageSection.propTypes,
};

export default withRouter(ModuleOverViewPage);
