import React from 'react';
import PropTypes from 'prop-types';
import { Table, Container, Row, Col } from 'reactstrap';

import OrganizationsListRow from 'components/OrganizationsListRow/OrganizationsListRow';
import './organizationsList.styles.scss';

const OrganizationsList = (props) => (
  <div className="organizations-list-container">
    <Container>
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th className="name-col" scope="col">Name</th>
                <th className="report-link-col" scope="col">Report</th>
                <th className="response-rates-col" scope="col">Response Rate</th>
              </tr>
            </thead>
            <tbody>
              <OrganizationsListRow {...props} expanded isRoot />
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </div>
);

OrganizationsList.propTypes = {
  organizationTree: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    report_id: PropTypes.number,
    completed: PropTypes.number,
    total: PropTypes.number.isRequired,
    sub_organizations: PropTypes.array,
  }).isRequired,
  keyword: PropTypes.string,
};

export default OrganizationsList;
