import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import { colorForIndex, calcPercentage, formatScore } from 'utils';
import ScoreBox from 'components/ScoreBox/ScoreBox';
import ScoreBar from 'components/ScoreBar/ScoreBar';
import ConstructIcon from 'components/ConstructIcon/ConstructIcon';
import InsightsTableHoverableCell from './InsightsTableHoverableCell';

import { payneGrey } from '../../styles/_colors.scss';

import './OrganizationInsightsTable.styles.scss';

class OrganizationInsightsTable extends React.Component {
  state = { 
    constructsDict: {}
  }

  static getDerivedStateFromProps({ constructs }) {
    return {
      constructsDict: constructs.reduce((dict, { display_name, description }, index) => {
        dict[display_name] = {
          display_name,
          description,
          icon: (<ConstructIcon name={display_name} index={index} />),
          color: colorForIndex(index),
        };
        return dict;
      },{}),
    };
  }

  render() {
    const { constructs,
      subject_construct_scores,
      scoreSuffix,
      subject_score,
      threshold_met: subjectThresholdMet,
      children_scores,
      minScore,
      maxScore,
    } = this.props;
    const { constructsDict } = this.state;
    return (
      <Table hover responsive className="organization-insights-table">
        <thead>
          <tr className="construct-icons">
            <th></th>
            {constructs.map(({ display_name }, index) => (
              <InsightsTableHoverableCell
                key={`icon-${display_name}-${index}`}
                id={`insight-construct-icon-${index}`}
                popoverTitle={display_name}
                tagName={'th'}
                popoverContent={constructsDict[display_name].description}
              >{constructsDict[display_name].icon}</InsightsTableHoverableCell>
            ))}
            <th>Overall</th>
          </tr>
          <tr className="subject-scores">
            <th>Your Result</th>
            {subject_construct_scores.map(({ construct_name, threshold_met, subject_score }, i) => (
              <InsightsTableHoverableCell
                key={`subject-${construct_name}-${i}`}
                id={`insight-construct-subject-score-${i}`}
                popoverTitle={construct_name}
                popoverContent={
                  threshold_met ? (
                    `Favorability rating: ${formatScore(subject_score)}${scoreSuffix}`
                  ) : (
                    `This construct did not receive enough responses.`
                  )
                }
                tagName={'th'}
              >
                <ScoreBox
                  color={constructsDict[construct_name].color}
                  score={subject_score}
                  scoreSuffix={scoreSuffix}
                  thresholdMet={threshold_met}
                />
              </InsightsTableHoverableCell>
            ))}
            <th>
              <ScoreBox
                color={payneGrey}
                score={subject_score}
                scoreSuffix={scoreSuffix}
                thresholdMet={subjectThresholdMet}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {children_scores.map(({ child_name, child_score, threshold_met, child_construct_scores }, ci) => (
            <tr key={`insights-child-${ci}`}>
              <th scope="row">{child_name}</th>
              {child_construct_scores.map(({ construct_name, threshold_met: constructThresholdMet, subject_score }, i) => (
                <InsightsTableHoverableCell
                  key={`child-score-${construct_name}-${ci}-${i}`}
                  id={`insight-construct-child-score-${ci}-${i}`}
                  popoverTitle={construct_name}
                  popoverContent={
                    constructThresholdMet ? (
                      `${child_name}: ${formatScore(subject_score)}${scoreSuffix}`
                    ) : (
                      `${child_name} did not meet the threshold required for this construct.`
                    )
                  }
                  tagName={'td'}
                >
                  <ScoreBar
                    color={constructsDict[construct_name].color}
                    scoreDisplay={`${formatScore(subject_score)}${scoreSuffix}`}
                    scorePercentage={calcPercentage(subject_score, minScore, maxScore)}
                    thresholdMet={constructThresholdMet}
                  />
                </InsightsTableHoverableCell>
              ))}
              <InsightsTableHoverableCell
                key={`child-score-subject-${ci}`}
                id={`insight-construct-child-subject-score-${ci}`}
                popoverTitle="Overall Result"
                popoverContent={
                  threshold_met ? (
                    `${child_name}: ${formatScore(child_score)}${scoreSuffix}`
                  ) : (
                    `${child_name} did not meet the minimum number of responses required.`
                  )
                }
                tagName={'td'}
              >
                <ScoreBar
                  color={payneGrey}
                  scoreDisplay={`${formatScore(child_score)}${scoreSuffix}`}
                  scorePercentage={calcPercentage(child_score, minScore, maxScore)}
                  thresholdMet={threshold_met}
                />
              </InsightsTableHoverableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

OrganizationInsightsTable.propTypes = {
  minScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  scoreSuffix: PropTypes.string,
  subject_score: PropTypes.number,
  threshold_met: PropTypes.bool.isRequired,
  constructs: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    description: PropTypes.string,
  })),
  subject_construct_scores: PropTypes.arrayOf(PropTypes.shape({
    construct_name: PropTypes.string.isRequired,
    threshold_met: PropTypes.bool.isRequired,
    subject_score: PropTypes.number,
  })),
  children_scores: PropTypes.arrayOf(PropTypes.shape({
    child_name: PropTypes.string.isRequired,
    child_score: PropTypes.number,
    threshold_met: PropTypes.bool.isRequired,
    child_construct_scores: PropTypes.arrayOf(PropTypes.shape({
      construct_name: PropTypes.string.isRequired,
      threshold_met: PropTypes.bool.isRequired,
      subject_score: PropTypes.number,
    })),
  })).isRequired,
};

export default OrganizationInsightsTable;
