import React from "react";
import { Switch, Route } from "react-router-dom";

import PageHeader from "components/PageHeader/PageHeader";
import PageFooter from "components/PageFooter/PageFooter";
import Home from "components/Home/Home";
import ReportLayout from "components/ReportLayout/ReportLayout";
import NotFoundPage from "components/NotFoundPage/NotFoundPage";

import "./app.styles.scss";

class App extends React.Component {
  render() {
    return (
      <div className="app-container">
        <PageHeader />
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/reports/:reportId" component={ReportLayout} />
            <Route component={NotFoundPage} />
          </Switch>
        </main>
        <PageFooter />
      </div>
    );
  }
}

export default App;
