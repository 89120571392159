import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faAngleDown from "@fortawesome/fontawesome-free-solid/faAngleDown";
import faFilePdf from "@fortawesome/fontawesome-free-regular/faFilePdf";
import MediaQuery from "react-responsive";

import "./heroUnit.styles.scss";

import reportCover from "images/reportCover.png";
import { getConfigs } from "../../appConfig";

const HeroUnit = () => {
  const { summaryReportUrl } = getConfigs();

  return (
    <div className="hero-unit">
      <Container>
        <Row>
          <Col>
            <div className="intro-content-container">
              <div className="content">
                <h1>
                  Teaching and Learning Conditions Colorado
                  <br />
                  Survey Reports
                </h1>
                <p>
                  Initiated through HB08-1384, the Teaching and Learning
                  Conditions in Colorado (TLCC), formerly known as TELL
                  Colorado, is a statewide, anonymous survey intended to support
                  school, district, and state improvement planning, as well as
                  research and policy.
                </p>
                <p>
                  Please find your school/district's reports below. At least 5
                  responses and a minimum response rate of 50% is required for a
                  school/district to qualify for a report. Data has also been
                  made available to districts with at least 10 responses and a
                  minimum response rate of 40%.
                </p>
              </div>
              {summaryReportUrl && (
                <div className="action-buttons">
                  <MediaQuery minWidth={1025}>
                    <div className="report-cover-container">
                      <a
                        href={summaryReportUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="TLCC Summary Report"
                        className="report-cover-link"
                      >
                        <img
                          src={reportCover}
                          alt="Summary Report Cover"
                          className="report-cover-image"
                        />
                      </a>
                    </div>
                  </MediaQuery>
                  <Button
                    color="success"
                    size="md"
                    href={summaryReportUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="TLCC Summary Report"
                    className="summary-report-link"
                  >
                    <FontAwesomeIcon icon={faFilePdf} />
                    &nbsp; Read the Summary Report
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="round-down-arrow">
        <FontAwesomeIcon icon={faAngleDown} size="3x" />
      </div>
    </div>
  );
};

export default HeroUnit;
