import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faChartBar from "@fortawesome/fontawesome-free-regular/faChartBar";

import ResultComparison from "components/ResultComparison/ResultComparison";

const ResultComparisonsPageSection = ({
  className = "",
  thresholdMet,
  score,
  comparisons,
  ...reportSettings
}) => (
  <div className={`${className} page-section`}>
    <h4 className="section-title">
      Compare Your Results
      <FontAwesomeIcon icon={faChartBar} />
    </h4>
    <p className="page-section-intro">
      This section shows how your result compares to the state and/or district
      averages in this project. For schools, a same-level average result is also
      provided - e.g. "Elementary Avg." is the average result of all Colorado
      Elementary Schools that participated in the same survey.
    </p>
    <ResultComparison
      thresholdMet={thresholdMet}
      score={score}
      comparisons={comparisons}
      {...reportSettings}
    />
  </div>
);

ResultComparisonsPageSection.propTypes = {
  className: PropTypes.string,

  score: PropTypes.number,
  thresholdMet: PropTypes.bool.isRequired,

  scoreSuffix: PropTypes.string,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  decimalPlaces: PropTypes.number,
  bencharmLabels: PropTypes.arrayOf(PropTypes.string),
  measureType: PropTypes.string,

  comparisons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    })
  ),
};

export default ResultComparisonsPageSection;
