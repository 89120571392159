import React from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import './TabbedDetails.styles.scss';

class TabbedDetails extends React.Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      renderContent: PropTypes.func.isRequired,
    })).isRequired,
  }

  state = {
    activeTab: 0,
  }

  toggleTab = (tabId) => {
    this.setState({
      activeTab: tabId,
    });
  }

  render() {
    const { activeTab } = this.state;
    const { tabs } = this.props;

    return (
      <div className="tabbed-details">
        <Nav tabs>
          {tabs.map(({ name }, index) => (
            <NavItem key={`tab-id-${index}`}>
              <NavLink
                className={activeTab === index ? 'active' : ''}
                onClick={() => { this.toggleTab(index); }}
              >
                {name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {tabs.map(({ renderContent }, index) => (
            <TabPane tabId={index} key={`tab-content-id-${index}`}>
              {renderContent()}
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}

export default TabbedDetails;
