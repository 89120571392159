import { connect } from 'react-redux';

import ReportCover from 'components/ReportCover/ReportCover';

import {
  getCurrentReportScopeSurveyName,
  getCurrentReportScopeReportName,
  getCurrentReportScopeSubjectName,
  getCurrentReportScopeSectionScopes,
  getCurrentReportScopeSchoolNames,
  getCurrentReportScopeDistrictNames,
  getCurrentReportScopeResponsesCount,
  getCurrentReportTotalRespondents,
} from 'selectors/reportSelectors';

const mapStateToProps = (state) => {
  const responsesCount = getCurrentReportScopeResponsesCount(state);
  const totalRespondents = getCurrentReportTotalRespondents(state);

  return {
    surveyName: getCurrentReportScopeSurveyName(state),
    reportName: getCurrentReportScopeReportName(state),
    reportSubjectName: getCurrentReportScopeSubjectName(state),
    sectionScopes: getCurrentReportScopeSectionScopes(state),
    schoolNames: getCurrentReportScopeSchoolNames(state),
    districtNames: getCurrentReportScopeDistrictNames(state),
    responsesCount,
    totalRespondents,
    responseRate: responsesCount / totalRespondents,
  };
};

export default connect(mapStateToProps, null)(ReportCover);
