import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import withReportSettings from 'containers/withReportSettings';
import ConstructDetailsPage from 'components/ConstructDetailsPage/ConstructDetailsPage';
import { makeGetConstruct } from 'selectors/reportSelectors';

import { mapRDOToProps } from 'components/ResultDisaggregation/helpers';
import { colorForIndex } from 'utils';

class ConstructDetails extends Component {

  constructDetailProps = () => {
    const {
      construct_name,
      constructIndex,
      description,
      benchmarks = [],
      item_details,
      threshold_met,
      subject_score,
      is_scorable,
      disaggregated_results,
    } = this.props.construct;

    const benchmarkLabels = this.props.benchmarkLabels;

    return {
      construct: construct_name,
      constructColor: colorForIndex(constructIndex),
      score: subject_score,
      thresholdMet: threshold_met,
      description,
      scorable: is_scorable,
      comparisons: benchmarks.map((bScore, i) => ({ title: benchmarkLabels[i], score: bScore })),
      item_details,
      disaggregatedResults: mapRDOToProps(disaggregated_results),
    };
  }

  render() {
    const { construct } = this.props;

    if (!construct) {
      return (<Redirect to='/not-found' />)
    }

    return (
      <ConstructDetailsPage
        {...this.props}
        {...this.constructDetailProps()}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getConstruct = makeGetConstruct();

  return (state, { match: { params: { moduleIndex, constructProviderKey }} }) => ({
    construct: getConstruct(state, { moduleIndex, constructProviderKey }),
  })

};

export default withReportSettings(connect(makeMapStateToProps)(withRouter(ConstructDetails)));
