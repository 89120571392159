import React from 'react';
import PropTypes from 'prop-types';

const DonutChartSegment = ({
  r,
  stroke,
  strokeDashoffset,
  strokeDasharray,
  text,
  textX,
  textY,
  highlighted,
  onMouseOver = () => {},
}) => (
  <g className="segment">
    {stroke && <circle
      strokeWidth={highlighted ? 8 : 5}
      fill="none"
      cx="50"
      cy="50"
      r={r}
      stroke={stroke}
      strokeDashoffset={strokeDashoffset}
      strokeDasharray={strokeDasharray}
      onMouseOver={onMouseOver}
    />}
    {text && <text
      x={textX}
      y={textY}
      fontSize="4" alignmentBaseline="center" textAnchor="middle">
        {text}
    </text>}
  </g>
);

DonutChartSegment.propTypes = {
  highlighted: PropTypes.bool,
  r: PropTypes.number.isRequired,
  stroke: PropTypes.string,
  strokeDashoffset: PropTypes.number.isRequired,
  strokeDasharray: PropTypes.string.isRequired,
  text: PropTypes.string,
  textX: PropTypes.number,
  textY: PropTypes.number,
  onMouseOver: PropTypes.func,
};

export default DonutChartSegment;
