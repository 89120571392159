import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import ResponsesCountBadge from 'components/ResponsesCountBadge/ResponsesCountBadge';

import './ReportCover.styles.scss';
import { toPercentage, humanize } from 'utils';

const ReportCover = ({
  surveyName,
  reportName,
  reportSubjectName,
  sectionScopes = [],
  schoolNames = [],
  districtNames = [],
  responsesCount,
  totalRespondents,
  responseRate,
}) => (
  <div className="report-cover">
    <Container>
      <div className="report-cover-content">
        <div className="report-subject">
          <div className="report-name">
            <div className="survey-name">{surveyName}</div>
            <h1>{reportName}</h1>
          </div>
          <div className="subject-name">
            <span>Report Prepared for</span>
            <h5>{reportSubjectName}</h5>
          </div>
          <ul className="report-scope">
            {sectionScopes && sectionScopes.map(({ key, value }) => (
              <li key={key}>
                <span>{humanize(key)}</span>
                <h6>{value}</h6>
              </li>
            ))}
            {schoolNames && schoolNames.length > 0 && (
              <li>
                <span>School{schoolNames.length > 1 ? '(s)' : ''}:</span>
                {schoolNames.map((schoolName) => (
                  <h6 key={schoolName}>{schoolName}</h6>
                ))}
              </li>
            )}
            {districtNames && districtNames.length > 0 && (
              <li>
                <span>District{districtNames.length > 1 ? '(s)' : ''}:</span>
                {districtNames.map((districtName) => (
                  <h6 key={districtName}>{districtName}</h6>
                ))}
              </li>
            )}
          </ul>
        </div>
        <div className="responses">
          <div className="response-count">
            <ResponsesCountBadge
              responsesCount={responsesCount}
              totalRespondents={totalRespondents}
            />
          </div>
          <div className="additional-info-box">
            <p className="total-responses">
              total surveys: <span>{totalRespondents.toLocaleString()}</span>
            </p>
            <p className="response-rate">
              response rate: <span>{toPercentage(responseRate)}%</span>
            </p>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

ReportCover.propTypes = {
  surveyName: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  reportSubjectName: PropTypes.string.isRequired,
  sectionScopes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  schoolNames: PropTypes.arrayOf(PropTypes.string),
  districtNames: PropTypes.arrayOf(PropTypes.string),
  responsesCount: PropTypes.number.isRequired,
  totalRespondents: PropTypes.number.isRequired,
  responseRate: PropTypes.number.isRequired,
};

export default ReportCover;
