import React from 'react';
import { arrayOf, string, func, number, shape } from 'prop-types';

const DemographicItemResponseAnchors = ({
  anchors,
  highlightedIndex,
  onMouseOver = () => {},
}) => (
  <ul>
    {anchors.map(({ label, color }, index) => (
      <li
        className={`${index === highlightedIndex ? 'highlighted': ''}`}
        onMouseOver={() => onMouseOver(index)}
        key={`label-${label}-${index}`}>
        <span className="legend-block" style={ { background: color } } />
        {label}
      </li>
    ))}
  </ul>
);

DemographicItemResponseAnchors.propTypes = {
  highlightedIndex: number,
  onMouseOver: func,
  anchors: arrayOf(shape({
    label: string.isRequired,
    color: string.isRequired,
  })).isRequired,
};

export default DemographicItemResponseAnchors;