import {
  LOAD_REPORT_STARTED,
  LOAD_REPORT_SUCCESSFUL,
  LOAD_REPORT_FAILED,
  SET_CURRENT_REPORT_ID,
  SET_CURRENT_REPORT_SCOPE_INDEX,
  LOAD_PDF_REPORT_DATA_STARTED,
  LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  LOAD_PDF_REPORT_DATA_FAILED,
} from 'constants/reportConstants.js';

const loadReport = (reportId) => ({
  type: LOAD_REPORT_STARTED,
  payload: {
    reportId,
  }
});

const loadReportSuccessful = (reportId, data) => ({
  type: LOAD_REPORT_SUCCESSFUL,
  payload: {
    reportId,
    data,
  }
});

const loadReportFailed = (reportId, failedReason) => ({
  type: LOAD_REPORT_FAILED,
  payload: {
    reportId,
    failedReason,
  }
});

const loadPDFReportData = (reportId) => ({
  type: LOAD_PDF_REPORT_DATA_STARTED,
  payload: {
    reportId,
  }
});

const loadPDFReportDataSuccessful = (reportId, data) => ({
  type: LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  payload: {
    reportId,
    data,
  }
});

const loadPDFReportDataFailed = (reportId, failedReason) => ({
  type: LOAD_PDF_REPORT_DATA_FAILED,
  payload: {
    reportId,
    failedReason,
  }
});

const setCurrentReportId = (reportId) => ({
  type: SET_CURRENT_REPORT_ID,
  payload: {
    reportId,
  }
});

const setCurrentReportScopeIndex = (reportId, index) => ({
  type: SET_CURRENT_REPORT_SCOPE_INDEX,
  payload: {
    reportId,
    index,
  }
});

export {
  loadReport,
  setCurrentReportId,
  loadReportFailed,
  loadReportSuccessful,
  setCurrentReportScopeIndex,
  loadPDFReportData,
  loadPDFReportDataFailed,
  loadPDFReportDataSuccessful,
}
