const mapRDOToProps = (disaggregated_results = []) => (
  disaggregated_results.map(({ title, results }) => ({
    subGroupName: title,
    subGroupResults: results.map(({ value, score, threshold_met, number_of_responses}) => ({
      title: value,
      score,
      thresholdMet: threshold_met,
      numberRespondents: number_of_responses,
    }))
  }))
);

export {
  mapRDOToProps,
}
