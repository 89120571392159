export const minResponseThreshold = 5;
export const minResponseRateThreshold = 0.5;

let configs = {
  supportEmail: "uiphelp@cde.state.co.us",
  tlccOfficialSite: "https://www.cde.state.co.us/site/tlccsurvey/",
  summaryReportUrl: null,
};

export const initConfigs = () =>
  fetch(`/configs.json`)
    .then((res) => res.json())
    .then((remoteConfigs) => {
      Object.assign(configs, remoteConfigs || {});
      return configs;
    })
    .catch((e) => console.error(`Unable to fetch app configurations:`, e));

export const getConfigs = () => ({
  ...configs,
});
