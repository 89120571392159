import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faListAlt from "@fortawesome/fontawesome-free-regular/faListAlt";
import ResultDisaggregation from "components/ResultDisaggregation/ResultDisaggregation";

const ResultDisaggregationsPageSection = ({
  className = "",
  disaggregatedResults,
  ...reportSettings
}) => (
  <div className={`${className} page-section`}>
    <h4 className="section-title">
      Result Disaggregations
      <FontAwesomeIcon icon={faListAlt} />
    </h4>
    <p className="page-section-intro">
      Compare the favorability ratings across different respondent subgroups can
      help identify important differences in perceptions, and discover potential
      trends or patterns.
    </p>
    <ResultDisaggregation
      disaggregatedResults={disaggregatedResults}
      {...reportSettings}
    />
  </div>
);

ResultDisaggregationsPageSection.propTypes = {
  className: PropTypes.string,

  scoreSuffix: PropTypes.string,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  decimalPlaces: PropTypes.number,

  disaggregatedResults: PropTypes.arrayOf(
    PropTypes.shape({
      subGroupName: PropTypes.string.isRequired,
      subGroupResults: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          score: PropTypes.number,
          thresholdMet: PropTypes.bool.isRequired,
          numberRespondents: PropTypes.number,
        })
      ),
    })
  ),
};

export default ResultDisaggregationsPageSection;
