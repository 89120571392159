import React from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { deepEqual } from "utils";
import "./ResultDisaggregation.styles.scss";
import ResultDisaggregationRow from "components/ResultDisaggregationRow/ResultDisaggregationRow";

class ResultDisaggregation extends React.Component {
  static propTypes = {
    minScore: PropTypes.number.isRequired,
    maxScore: PropTypes.number.isRequired,
    scoreSuffix: PropTypes.string,
    decimalPlaces: PropTypes.number,
    disaggregatedResults: PropTypes.arrayOf(
      PropTypes.shape({
        subGroupName: PropTypes.string.isRequired,
        subGroupResults: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            score: PropTypes.number,
            thresholdMet: PropTypes.bool.isRequired,
            numberRespondents: PropTypes.number,
          })
        ).isRequired,
      })
    ).isRequired,
  };

  state = {
    highlightedIndex: null,
    selectedSubGroupIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps, this.props)) {
      this.setState({
        highlightedIndex: null,
        selectedSubGroupIndex: 0,
      });
    }
  }

  highlightIndex = (index) => {
    this.setState({
      highlightedIndex: index,
    });
  };

  clearHighlight = () => {
    this.highlightIndex(null);
  };

  selectSubGroup = (index) => {
    this.setState({
      highlightedIndex: null,
      selectedSubGroupIndex: index,
    });
  };

  render() {
    const { disaggregatedResults } = this.props;
    const { highlightedIndex, selectedSubGroupIndex } = this.state;
    const selectedSubGroup = disaggregatedResults[selectedSubGroupIndex];
    const { minScore, maxScore, scoreSuffix, decimalPlaces } = this.props;

    return (
      <div className="result-disaggregation" onMouseLeave={this.clearHighlight}>
        <div className="header">
          <div className="title">
            <UncontrolledButtonDropdown size="sm">
              <DropdownToggle caret>
                {selectedSubGroup.subGroupName}
              </DropdownToggle>
              <DropdownMenu>
                {disaggregatedResults.map(({ subGroupName }, index) => (
                  <DropdownItem
                    key={`subgroup-title-${index}`}
                    disabled={index === selectedSubGroupIndex}
                    onClick={() => this.selectSubGroup(index)}
                  >
                    {subGroupName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
          <div className="result-bar"></div>
        </div>
        <div className="disaggregation-rows">
          {selectedSubGroup.subGroupResults.map((result, index) => (
            <ResultDisaggregationRow
              key={`${result.title}-index`}
              {...result}
              minScore={minScore}
              maxScore={maxScore}
              scoreSuffix={scoreSuffix}
              decimalPlaces={decimalPlaces}
              onMouseOver={() => this.highlightIndex(index)}
              highlighted={index === highlightedIndex}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ResultDisaggregation;
