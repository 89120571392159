import React from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

import "./DemographicsPage.styles.scss";

import ReportPage from "components/ReportPage/ReportPage";
import DemographicsIcon from "components/Icons/DemographicsIcon";
import SurveyItemDetails, {
  SurveyItemDetailsBasePropsTypes,
} from "components/SurveyItemDetails/SurveyItemDetails";

const DemographicsPage = ({
  totalRespondents,
  demographicItems,
  ...reportSettings
}) => (
  <ReportPage
    className="demographics-page"
    pageTitle="Demographics"
    pageSubTitle="Who took the survey?"
    renderIcon={() => <DemographicsIcon />}
    renderIntroLeft={() => (
      <p>
        The following graphics display how those who took the survey responded
        to the demographic questions which were included in the survey. Hover
        your mouse over each response option to see their corresponding number
        of responses.
      </p>
    )}
    renderIntroRight={() => (
      <p className="total-respondents-display">
        <CountUp
          start={0}
          end={totalRespondents}
          formattingFn={(n) => n.toLocaleString()}
          className="number-of-respondents"
          duration={1}
        />
        <br />
        <span className="total-respondents-label">total respondents</span>
      </p>
    )}
    renderPageContent={() =>
      demographicItems.map((itemProps, index) => (
        <SurveyItemDetails
          key={itemProps.item + index}
          {...itemProps}
          {...reportSettings}
          defaultExpanded
        />
      ))
    }
  />
);

DemographicsPage.propTypes = {
  totalRespondents: PropTypes.number.isRequired,
  demographicItems: PropTypes.arrayOf(
    PropTypes.shape(SurveyItemDetailsBasePropsTypes)
  ).isRequired,
  minScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  scoreSuffix: PropTypes.string,
  decimalPlaces: PropTypes.number.isRequired,
  benchmarkLabels: PropTypes.arrayOf(PropTypes.string),
};

export default DemographicsPage;
