import React from "react";
import ReportPage from "components/ReportPage/ReportPage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faLifeRing from "@fortawesome/fontawesome-free-regular/faLifeRing";
import { getConfigs } from "../../appConfig";

const SupportPage = () => {
  const { supportEmail, tlccOfficialSite } = getConfigs();

  return (
    <ReportPage
      className="support-page"
      pageTitle="Contact Support"
      pageSubTitle="We are here to help"
      renderIcon={() => <FontAwesomeIcon icon={faLifeRing} />}
      renderPageContent={() => (
        <div className="support-page-content">
          {supportEmail && (
            <p>
              If you need any assitance with this report or your results, please
              feel free to send us an email at{" "}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </p>
          )}
          {tlccOfficialSite && (
            <p>
              For more information about the TLCC survey, please visit&nbsp;
              <a
                target="_blank"
                href={tlccOfficialSite}
                rel="noopener noreferrer"
              >
                {tlccOfficialSite}
              </a>
              .
            </p>
          )}
          <p>
            For more information about the Cambridge Education, please
            visit&nbsp;
            <a
              target="_blank"
              href="http://www.camb-ed.com/americas"
              rel="noopener noreferrer"
            >
              Cambridge Education North America
            </a>
            .
          </p>
          <p>Many thanks for your involvement in this project!</p>
        </div>
      )}
    />
  );
};

export default SupportPage;
