import { createSelector } from 'reselect';

import { getAllOrganizations } from './organizationSelectors'; 

const getCurrentReportId = (state) => state.reports.currentReportId;
const allReports = (state) => state.reports.reports;
const allPdfReports = (state) => state.reports.pdfReports;

const getCurrentReport = createSelector(
  [getCurrentReportId, allReports],
  (reportId, allReports = {}) => allReports[reportId]
);

const getCurrentReportData = createSelector(
  [getCurrentReport],
  (currentReport = {}) => currentReport.data
);

const getCurrentReportScopes = createSelector(
  [getCurrentReportData],
  (reportData = []) => (
    reportData.map(({ rdo: { report_scope = {} } }) => {
      const sectionScopes = report_scope.section_scopes || [];
      const sectionScopeValues = sectionScopes.map(({ values = []}) => values.join(', '));
      const schoolNames = (report_scope.school_names || []).join(', ');
      return `${schoolNames}${schoolNames.length > 0 ? ' - ': ''}${sectionScopeValues}`;
    })
  )
);

const getCurrentReportScopeIndex = createSelector(
  [getCurrentReport],
  (currentReport = {}) => currentReport.currentReportScopeIndex
);

const getCurrentReportScope = createSelector(
  [getCurrentReportData, getCurrentReportScopeIndex],
  (reportData = [], scopeIndex) => reportData[scopeIndex]
);

const getAdministration = createSelector(
  [getCurrentReportScope],
  (reportScope = {}) => reportScope.administration
);

// reportid is organization id!
const getCurrentReportOrganizationDetails = createSelector(
  [getCurrentReportId, getAllOrganizations],
  (currentReportId, allOrganizations) => allOrganizations[currentReportId]
);

const getCurrentReportTotalRespondents = createSelector(
  [getCurrentReportOrganizationDetails],
  ({ total }) => total
);

// Assuming there is only one `survey` in the RDO!
const getCurrentReportScopeSurveyName = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.surveys[0].survey_name
);

const getCurrentReportScopeSurveys = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.surveys
);

const getCurrentReportScopeDemographicItems = createSelector(
  [getCurrentReportScopeSurveys],
  ([{ demographics }]) => demographics,
);

const getScoreRange = createSelector(
  [getCurrentReportScopeSurveys],
  ([{ measure_type: { min_score, max_score } }]) => ({ minScore: min_score, maxScore: max_score })
);

const getCurrentScopeModules = createSelector(
  [getCurrentReportScopeSurveys],
  ([{ modules }]) => modules
);

const getConstruct = createSelector(
  [
    getCurrentScopeModules,
    (_, { moduleIndex, constructProviderKey }) => ({ moduleIndex, constructProviderKey }),
  ],
  (modules, { moduleIndex, constructProviderKey }) => {
    const module = modules[moduleIndex];
    if (!module) {
      return null;
    }
    const { constructs, report_sections: { at_a_glance: { construct_summary }, construct_details }} = module;
    const index = constructs.findIndex(({ construct_provider_key }) => construct_provider_key === constructProviderKey);

    if (index >= 0) {
      const construct = constructs[index];
      const summary = construct_summary.find(({ construct_provider_key }) => construct_provider_key === constructProviderKey) || {};
      const details = construct_details.find(({ construct_name }) => construct_name === construct.display_name) || {};
      return {
        constructIndex: index,
        ...construct,
        ...summary,
        ...details,
      };
    }

    return null;
  },
);

const getModuleByIndex = createSelector(
  [
    getCurrentScopeModules,
    (_, { moduleIndex }) => moduleIndex,
  ],
  (modules, moduleIndex) => modules[moduleIndex]
)

const makeGetConstruct = () => createSelector(
  getConstruct,
  (construct) => (construct)
);

const makeGetModuleByIndex = () => createSelector(
  getModuleByIndex,
  (module) => (module)
);


const getReportOptions = createSelector(
  [getCurrentReportScope],
  ({ options }) => options
);

const getMeasureType = createSelector(
  [getReportOptions],
  ({ measure_type_name }) => measure_type_name
);

const getInsightsChildrenTypeName = createSelector(
  [getReportOptions],
  ({ children_type }) => {
    switch (children_type) {
      case 'K12School':
        return 'School'; 
      case 'K12Lea':
        return 'District'; 
      case 'K12Section':
        return 'Classroom'; 
      default:
        return children_type; 
    }
  },
);

const getScoreSuffix = createSelector(
  [getMeasureType],
  (typeName) => {
    switch(typeName) {
      case 'percentage_of_favorable_responses':
        return '%';
      default:
        return '';
    }
  }
);

const getScoreDecimalPlaces = createSelector(
  [getMeasureType],
  (typeName) => {
    switch(typeName) {
      case 'average_of_response_values':
        return 1;
      default:
        return 0;
    }
  }
);

const getBenchmarkLabels = createSelector(
  [getReportOptions],
  ({ comparative_labels = [], comparative_types = [] }) => (
    comparative_types.map((type) => {
      const label = comparative_labels.find(({ type: labelType }) => (labelType === type));
      if(label) {
        return label.friendly_name;
      } else {
        return null;
      }
    }
  ))
);

const getCurrentReportScopeReportName = createSelector(
  [getCurrentReportScope],
  ({ report }) => report
);

const getCurrentReportScopeSubjectName = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.subject_name,
);

const getCurrentReportScopeSectionScopes = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.report_scope.section_scopes.map(({ type, values }) => ({
    type,
    value: values.join(', '),
  }))
);

const getCurrentReportScopeSchoolNames = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.report_scope.school_names
);

const getCurrentReportScopeDistrictNames = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.leas_name
);

const getCurrentReportScopeResponsesCount = createSelector(
  [getCurrentReportScope],
  ({ rdo = {} }) => rdo.report_scope.number_of_respondents
);

const getCurrentReportPDFData = createSelector(
  [allPdfReports, getCurrentReportId],
  (pdfReports, currentReportId) => pdfReports[currentReportId],
);

export {
  getCurrentReportId,
  getCurrentReport,
  getCurrentReportScopes,
  getCurrentReportScopeIndex,
  getCurrentReportScope,
  getAdministration,
  getCurrentReportTotalRespondents,
  getCurrentReportScopeSurveyName,
  getCurrentReportScopeReportName,
  getCurrentReportScopeSectionScopes,
  getCurrentReportScopeSchoolNames,
  getCurrentReportScopeDistrictNames,
  getCurrentReportScopeResponsesCount,
  getCurrentReportScopeSubjectName,
  getCurrentReportScopeSurveys,
  getScoreRange,
  getScoreSuffix,
  getScoreDecimalPlaces,
  getBenchmarkLabels,
  getCurrentReportScopeDemographicItems,
  getCurrentScopeModules,
  makeGetConstruct,
  makeGetModuleByIndex,
  getInsightsChildrenTypeName,
  getCurrentReportPDFData,
  getMeasureType,
};
