import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/configStore";

import { unregister } from "./registerServiceWorker";

import "./styles/index.scss";
import App from "components/App/App.jsx";
import { getConfigs, initConfigs } from "./appConfig";

initConfigs()
  .then((configs) => {
    if (configs && configs.debugMode) {
      console.log("App Initialized", configs);
    }
  })
  .finally(() => {
    const configs = getConfigs();
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter basename={configs ? configs.baseRoute || "/" : "/"}>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );
  });

// registerServiceWorker();
unregister();
