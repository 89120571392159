import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'

import { getCurrentReportScopeSurveys, getCurrentReportId } from 'selectors/reportSelectors';

const findConstructProviderKeyByName = (constructs, name) => (
  (constructs.filter(({ display_name }) => display_name === name)[0] || {})
    .construct_provider_key
);

const getReportNavItems = (surveys = [], basePath, currentUrl = '') => {
  const navs = surveys.reduce((navItems, { demographics, modules }, index) => {
    const surveyPath = `${basePath}/surveys/${index}`;
    const howToReadPath = `${surveyPath}/intro`;

    navItems.push({
      id: `how-to-read-${index}`,
      name: 'How To Read',
      path: howToReadPath,
      active: !!currentUrl.match(new RegExp(`${howToReadPath}$`)),
    });

    if(demographics && demographics.length > 0) {
      const demographicsPath = `${surveyPath}/demographics`;
      navItems.push({
        id: `demographics-${index}`,
        name: 'Demographics',
        path: demographicsPath,
        active: !!currentUrl.match(new RegExp(`${demographicsPath}$`)),
      });
    }

    modules.forEach(({
      display_name,
      constructs,
      report_sections: {
        at_a_glance,
        insights,
        construct_details,
        module_items,
      }}, moduleIndex) => {
        const modulePath = `${surveyPath}/modules/${moduleIndex}`;

        const moduleNav = {
          id: `module-${moduleIndex}`,
          name: 'Overview',
          path: modulePath,
          header: display_name,
          active: !!currentUrl.match(new RegExp(`${modulePath}$`)),
          subNavItems: [],
        };

        construct_details.forEach(({ construct_name }) => {
          const providerKey = findConstructProviderKeyByName(constructs, construct_name);
          if (providerKey) {
            const constructPath = `${modulePath}/constructs/${providerKey}`;
            moduleNav.subNavItems.push({
              id: `m${moduleIndex}-${providerKey}`,
              name: construct_name,
              path: constructPath,
              active: !!currentUrl.match(new RegExp(`${constructPath}$`)),
            });
          }
        });

        if (module_items && module_items.length > 0) {
          const moduleItemsPath = `${modulePath}/other-items`;
          moduleNav.subNavItems.push({
            id: `m${moduleIndex}-other-items`,
            name: construct_details && construct_details.length > 0 ? 'Other Items' : 'Module Items',
            path: moduleItemsPath,
            active: !!currentUrl.match(new RegExp(`${moduleItemsPath}$`)),
          });
        }

        navItems.push(moduleNav);
        
        if (insights) {
          const insightsPath = `${modulePath}/insights`;
          navItems.push({
            id: `m${moduleIndex}-insights`,
            name: 'Insights',
            path: insightsPath,
            active: !!currentUrl.match(new RegExp(`${insightsPath}$`)),
          });
        }
    });

    return navItems;
  }, []);

  navs.push({
    id: `download-pdf`,
    name: 'Download PDF Reports',
    header: 'Resources',
    path: `${basePath}/download-pdf`,
    active: !!currentUrl.match(new RegExp(`${basePath}/download-pdf`)),
  });

  navs.push({
    id: `support`,
    name: 'Support',
    path: `${basePath}/support`,
    active: !!currentUrl.match(new RegExp(`${basePath}/support`)),
  });

  return navs;
};

const mapStateToProps = (state, ownProps) => {
  const surveys = getCurrentReportScopeSurveys(state);
  const currentPathname = ownProps.location.pathname;
  const currentReportId = getCurrentReportId(state);
  const baseUrl = `/reports/${currentReportId}`;

  return {
    navItems: getReportNavItems(surveys, baseUrl, currentPathname),
  };
};

const currentActiveItem = (navItems = []) => {
  return navItems.reduce((activeItem, item) => {
    if(activeItem) return activeItem;
    if(item.active) return item;
    if(item.subNavItems) return currentActiveItem(item.subNavItems);
    return null;
  }, null);
}

function ReportContentNavWrapper(NavComponent) {
  const ReportContentNav = (props) => {
    useEffect(() => {
      if(!currentActiveItem(props.navItems)) {
        props.history.push(props.navItems[0].path);
      }
    }, [props.navItems, props.history]);

    return (
      <NavComponent {...props} />
    );
  };

  const ConnectedNav = withRouter(connect(mapStateToProps, null)(ReportContentNav));

  return <ConnectedNav />;
}

export default ReportContentNavWrapper;
