import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import CELogo from 'images/ce_logo.png';

import './pageFooter.styles.scss';

const PageFooter = () => (
  <footer className="page-footer">
    <Container>
      <Row>
        <Col>
          <p className="copyright-text">
            <a href="https://www.camb-ed.com/americas" target="_blank" rel="noopener noreferrer" title="Cambridge Education USA">
              <img src={ CELogo } alt="CE Logo" className="ce-logo" />
            </a>
            <br />
            &copy; {(new Date()).getFullYear()} Cambridge Education.
            <br />
            Cambridge Education is a trading name of&nbsp;
            <a href="https://www.mottmac.com" target="_blank" rel="noopener noreferrer" title="Mott MacDonald">
              Mott MacDonald
            </a>.
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default PageFooter;
