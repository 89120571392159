import React from 'react';
import PropTypes from 'prop-types';

import { formatScore } from 'utils';

const ScoreDisplay = ({
  score,
  scoreSuffix = '',
  decimalPlaces = 1,
}) => (
  <span className="score-display">
    {formatScore(score)}
    {
      scoreSuffix && scoreSuffix.length > 0 && 
      <span className="suffix">{scoreSuffix}</span>
    }
  </span>
);

ScoreDisplay.propTypes = {
  score: PropTypes.number.isRequired,
  scoreSuffix: PropTypes.string,
  decimalPlaces: PropTypes.number,
};

export default ScoreDisplay;