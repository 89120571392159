import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import { UUIDv4, toPercentage } from 'utils';

class LikertItemResponseAnchor extends React.Component {
  state = {
    tooltipId: `TTID-${UUIDv4()}`,
  }

  static propTypes = {
    anchor_text: PropTypes.string.isRequired, 
    number_of_responses: PropTypes.number.isRequired, 
    favorable: PropTypes.bool,
    scorable: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
  };

  render() {
    const {
      anchor_text,
      number_of_responses,
      favorable = false,
      scorable,
      total
    } = this.props;
    const { tooltipId } = this.state;

    return (
      <div
        className={`item-response-anchor ${favorable ? 'favorable' : ''} ${scorable ? 'scorable' : ''}`}
        key={anchor_text}
        style={ { width: `${number_of_responses * 100.0/ total}%` } }
      >
        <div className="response-count" id={tooltipId}>{number_of_responses}</div>
        <div className="response-anchor">{anchor_text}</div>
        <UncontrolledTooltip className="item-response-anchor-tooltip" placement="bottom" target={tooltipId} delay={0}>
          {number_of_responses} ({toPercentage(number_of_responses / total)}%) participants responded "{anchor_text}"
          {favorable && (<p>This is a favorable response choice.</p>)}
          {!scorable && (<p>This choice does not affect your results negatively.</p>)}
        </UncontrolledTooltip>
      </div>
    );
  }
}

export default LikertItemResponseAnchor;
