import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import SurveyItem from 'components/SurveyItem/SurveyItem';
import TabbedDetails from 'components/TabbedDetails/TabbedDetails';
import LikertItemResponseDistribution from 'components/LikertItemResponseDistribution/LikertItemResponseDistribution';
import DemographicItemResponseDistribution from 'components/DemographicItemResponseDistribution/DemographicItemResponseDistribution';
import ResultComparison from 'components/ResultComparison/ResultComparison';
import ResultDisaggregation from 'components/ResultDisaggregation/ResultDisaggregation';

import './SurveyItemDetails.styles.scss';

export const SurveyItemDetailsBasePropsTypes = {
  color: PropTypes.string,
  total_responses: PropTypes.number,
  item: PropTypes.string.isRequired,
  item_score: PropTypes.number,
  threshold_met: PropTypes.bool.isRequired,
  missing_responses: PropTypes.number,
  number_of_responses: PropTypes.number,
  benchmarks: PropTypes.arrayOf(PropTypes.number),
  response_distribution: PropTypes.arrayOf(PropTypes.shape({
    anchor_text: PropTypes.string.isRequired,
    number_of_responses: PropTypes.number,
    favorable: PropTypes.bool,
    scorable: PropTypes.bool,
  })).isRequired,
  disaggregated_results: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      score: PropTypes.number,
      number_of_responses: PropTypes.number,
      threshold_met: PropTypes.bool.isRequired,
    })).isRequired,
  })),
};

class SurveyItemDetails extends Component {
  static propTypes = {
    defaultExpanded: PropTypes.bool,
    minScore: PropTypes.number.isRequired,
    maxScore: PropTypes.number.isRequired,
    scoreSuffix: PropTypes.string,
    decimalPlaces: PropTypes.number.isRequired,
    benchmarkLabels: PropTypes.arrayOf(PropTypes.string),
    ...SurveyItemDetailsBasePropsTypes,
  }

  static defaultProps = {
    defaultExpanded: false,
  }

  constructor(props) {
    super(props);

    this.state = { expanded: !!props.defaultExpanded };
  }

  itemScorable = () => (
    this.props.response_distribution.some(({ scorable }) => scorable)
  );

  detailsTabsProps = () => {
    const { response_distribution, benchmarks, disaggregated_results, threshold_met } = this.props;
    const tabs = [];

    if(response_distribution && response_distribution.length > 0 && threshold_met) {
      tabs.push({
        name: 'Distribution of Responses',
        renderContent: this.renderResponseDistributions,
      });
    }

    if(benchmarks && benchmarks.length > 0) {
      tabs.push({
        name: 'Compare Results',
        renderContent: this.renderResultComparison,
      });
    }

    if (disaggregated_results && disaggregated_results.length > 0 && threshold_met) {
      tabs.push({
        name: 'Disaggregations',
        renderContent: this.renderResultDisaggregation,
      });
    }

    return tabs;
  };

  renderResultDisaggregation = () => {
    const { disaggregated_results, minScore, maxScore, scoreSuffix, decimalPlaces } = this.props;
    const disaggregatedResults = disaggregated_results.map(({ title, results }) => ({
      subGroupName: title,
      subGroupResults: results.map(({ value, score, number_of_responses, threshold_met }) => ({
        title: value,
        score,
        thresholdMet: threshold_met,
        numberRespondents: number_of_responses,
      })),
    }));

    return (
      <ResultDisaggregation
        minScore={minScore}
        maxScore={maxScore}
        scoreSuffix={scoreSuffix}
        decimalPlaces={decimalPlaces}
        disaggregatedResults={disaggregatedResults}
      />
    );
  }

  renderResultComparison = () => {
    const { minScore, maxScore, scoreSuffix, item_score, benchmarks, benchmarkLabels, decimalPlaces, threshold_met } = this.props;
    return (
      <ResultComparison
        minScore={minScore}
        maxScore={maxScore}
        score={item_score}
        scoreSuffix={scoreSuffix}
        decimalPlaces={decimalPlaces}
        thresholdMet={threshold_met}
        comparisons={benchmarks.map((benchScore, i) => ({
          title: benchmarkLabels[i],
          score: benchScore,
        }))}
      />
    );
  }

  renderResponseDistributions = () => {
    const { response_distribution, total_responses } = this.props;
    return (
      <LikertItemResponseDistribution
        distributions={response_distribution}
        total={total_responses}
      />
    );
  };

  renderDemographicItemDistribution = () => {
    const { threshold_met, response_distribution, total_responses, missing_responses = 0 } = this.props;
    return (
      <DemographicItemResponseDistribution
        threshold_met={threshold_met}
        response_distribution={response_distribution}
        total_responses={total_responses}
        missing_responses={missing_responses}
      />
    );
  }

  renderLikertItemDetails = () => {
    const detailsTabs = this.detailsTabsProps();

    if (detailsTabs.length === 0) {
      return (
        <div className="no-details-message">
          No item details are available for this item :(
        </div>
      );
    }

    return (<TabbedDetails tabs={detailsTabs} />);
  }

  renderDetails = () => {
    const { threshold_met } = this.props;

    if (!threshold_met) {
      return (
        <div className="no-details-message">
          This item did not receive the minimum number of responses required.
        </div>
      );
    }

    if(this.itemScorable()) {
      return this.renderLikertItemDetails();
    }

    return this.renderDemographicItemDistribution();
  }

  toggleExpand = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }));
  }

  render() {
    const { expanded } = this.state;
    const { threshold_met, color, item, item_score, decimalPlaces, scoreSuffix } = this.props;
    return (
      <div className="survey-item-details">
        <SurveyItem
          color={color}
          scorable={this.itemScorable()}
          thresholdMet={threshold_met}
          itemText={item}
          score={item_score}
          decimalPlaces={decimalPlaces}
          scoreSuffix={scoreSuffix}
        />
        {threshold_met && <div className={`expand-details-container ${expanded ? 'expanded' : ''}`}>
          <Button color="link" size="sm" className="toggle-expand" onClick={this.toggleExpand}>
            {expanded ? '- Hide': '+ Expand'} Item Details
          </Button>
        </div>}
        {expanded && <div className="details-container">
          {this.renderDetails()}
        </div>}
      </div>
    );
  }
}

export default SurveyItemDetails;
