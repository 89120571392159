const LOAD_REPORT_STARTED = 'LOAD_REPORT_STARTED';
const LOAD_REPORT_SUCCESSFUL = 'LOAD_REPORT_SUCCESSFUL';
const LOAD_REPORT_FAILED = 'LOAD_REPORT_FAILED';
const SET_CURRENT_REPORT_ID = 'SET_CURRENT_REPORT_ID';
const SET_CURRENT_REPORT_SCOPE_INDEX = 'SET_CURRENT_REPORT_SCOPE_INDEX';
const LOAD_PDF_REPORT_DATA_STARTED = 'LOAD_PDF_REPORT_DATA_STARTED';
const LOAD_PDF_REPORT_DATA_SUCCESSFUL = 'LOAD_PDF_REPORT_DATA_SUCCESSFUL';
const LOAD_PDF_REPORT_DATA_FAILED = 'LOAD_PDF_REPORT_DATA_FAILED';

export {
  LOAD_REPORT_STARTED,
  LOAD_REPORT_SUCCESSFUL,
  LOAD_REPORT_FAILED,
  SET_CURRENT_REPORT_ID,
  SET_CURRENT_REPORT_SCOPE_INDEX,
  LOAD_PDF_REPORT_DATA_STARTED,
  LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  LOAD_PDF_REPORT_DATA_FAILED,
};
